import { FC, useState } from "react";
import { Grid, Typography, Divider, Checkbox } from "@mui/material";
import CommonButton from "./CommonButton";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store/store";
import { CheckCouponReturnDto } from "../client";
import CommonLabel from "./CommonLabel";
import CommonInputWithEndAdorment from "./CommonInputWithEndAdorment";
import { IngMax50DiscountCoupon } from "../utils/constants";

interface Props {
  id: string;
  onContunieDisabled: () => boolean;
  onContunieTriggered: () => void;
  paidPrice: number;
  activeDiscountCoupon: CheckCouponReturnDto;
  currentPhase: string;
  setPhase: (e: string) => void;
  kvkkAgreement: boolean;
  communicationAgreement: boolean;
  setKvkk: () => void;
  setCommunication: () => void;
  discountCoupon: CheckCouponReturnDto;
  checkDiscountCouponLocal: (e: string) => void;
}

const SubscriptionPaymentSummaryWithoutLogin: FC<Props> = ({
  id,
  onContunieDisabled,
  onContunieTriggered,
  paidPrice = 0,
  activeDiscountCoupon,
  currentPhase,
  setPhase,
  kvkkAgreement,
  communicationAgreement,
  setCommunication,
  setKvkk,
  discountCoupon,
  checkDiscountCouponLocal,
}) => {
  const { pricing_options } = useSelector((state: RootState) => state.purchase);

  const [localDiscountCoupon, setLocalDiscountCoupon] = useState("");

  const enterCoupon = () => {
    checkDiscountCouponLocal(localDiscountCoupon);
    setLocalDiscountCoupon("");
  };

  return (
    <Grid
      container
      columns={{ xs: 4, sm: 8, md: 12 }}
      sx={{ marginTop: { xs: "0px", sm: "0px", md: "30px" } }}
      rowSpacing={3}
    >
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "26px",
            color: "#9CA4AB",
          }}
        >
          Toplam
        </Typography>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "26px",
            color: "#171725",
          }}
        >
          ₺{pricing_options.find((e) => e.id == id)?.price}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "26px",
            color: "#9CA4AB",
          }}
        >
          Seçtiğiniz Plan
        </Typography>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "26px",
            color: "#171725",
          }}
        >
          {pricing_options.find((e) => e.id == id)?.name}
        </Typography>
      </Grid>
        <Grid
          item
          xs={4}
          sm={8}
          md={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: "18px",
              lineHeight: "26px",
              color: "#9CA4AB",
            }}
          >
            İndirim Tutarı
          </Typography>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 600,
              fontSize: "18px",
              lineHeight: "26px",
              color: "#171725",
            }}
          >
            {activeDiscountCoupon.id ? `%${activeDiscountCoupon.discount_percent} İndirim` : "-"}
          </Typography>
        </Grid>
      <Divider
        style={{
          width: "100%",
          border: "1px solid #E9EBED",
          marginTop: "24px",
        }}
      />
      {//THIS IS FOR ING AGREEMENT - AFTER AGREEMENT DONE REMOVE THIS CONDITION!
        discountCoupon.id != IngMax50DiscountCoupon && (
          <Grid item xs={4} sm={8} md={12} style={{display:"flex", flexDirection:"column", alignItems:"start"}}>
        <CommonLabel label="İndirim Kodu" />
        <CommonInputWithEndAdorment
          buttonText={discountCoupon.id == "" ? "Onayla" : "İptal Et"}
          _onChange={(e: string) => setLocalDiscountCoupon(e)}
          _value={
            discountCoupon.id == "" ? localDiscountCoupon : discountCoupon.id
          }
          buttonDisable={false}
          buttonOnClick={() => enterCoupon()}
          buttonCSS={{
            maxWidth:"140px",
            padding: "10px 30px",
            backgroundColor: discountCoupon.id == "" ? "#2574FF" : "#FFFFFF",
            border: discountCoupon.id == "" ? "" : "1px solid #2574FF",
            marginTop: "5px",
            marginBottom: "5px",
            cursor:"pointer"
          }}
          buttonTextColor={discountCoupon.id == "" ? "#FFFFFF" : "#2574FF"}
          _placeHolder="İndirim kodunuz varsa bu alana giriniz"
          _disabled={discountCoupon.id != "" ? true : false}
        />
      </Grid>
        )
      }
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "26px",
            color: "#9CA4AB",
          }}
        >
          Ödenecek Tutar
        </Typography>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "26px",
            color: "#2574FF",
          }}
        >
          ₺{paidPrice}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{
          textAlign: "start",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "22px",
            color: "#78828A",
            marginBottom: "10px",
          }}
        >
          {id == "life_time"
            ? "Ömür boyu planını seçtiğiniz için daha sonra herhangi bir ücret alınmayacaktır. Ödeme tek seferliktir."
            : "Abonelik sürenizin bitiminde aboneliğiniz otomatik yenilenecektir."}
        </Typography>
      </Grid>
      <Grid item xs={4} sm={8} md={12}>
        {currentPhase == "address" ? (
          <>
            <div
              style={{
                textAlign: "start",
                marginTop: "4%",
                marginBottom: "2%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                sx={{
                  color: "#DADADA",
                  padding: "2px",
                  transform: "scale(1.2)",
                }}
                required={true}
                value={kvkkAgreement}
                defaultChecked={kvkkAgreement}
                onChange={setKvkk}
              />
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "11px",
                  lineHeight: "16px",
                  color: "#2574FF",
                  marginLeft: "5px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open(`/official-document/user_agreement`, "_blank")
                }
              >
                Kullanıcı Sözleşmesi
              </Typography>
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "11px",
                  lineHeight: "16px",
                  color: "#9393AA",
                  marginLeft: "3px",
                  marginRight: "3px",
                }}
              >
                ve
              </Typography>
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "11px",
                  lineHeight: "16px",
                  color: "#2574FF",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => window.open(`/official-document/kvkk`, "_blank")}
              >
                KVKK Aydınlatma Metni
              </Typography>
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "11px",
                  lineHeight: "16px",
                  color: "#9393AA",
                }}
              >
                ’ni onaylıyorum.
              </Typography>
            </div>
            <div
              style={{
                textAlign: "start",
                marginBottom: "4%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                sx={{
                  color: "#DADADA",
                  padding: "2px",
                  transform: "scale(1.2)",
                }}
                required={true}
                value={communicationAgreement}
                defaultChecked={communicationAgreement}
                onChange={setCommunication}
              />
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "11px",
                  lineHeight: "16px",
                  color: "#2574FF",
                  marginLeft: "5px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open(
                    `/official-document/communication_permission`,
                    "_blank"
                  )
                }
              >
                İletişim İzni
              </Typography>
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "11px",
                  lineHeight: "16px",
                  color: "#9393AA",
                }}
              >
                ’ni okudum/ kabul ediyorum.
              </Typography>
            </div>
          </>
        ) : null}
        <CommonButton
          text={currentPhase == "address" ? "Ödeme Adımına Geç" : "Ödeme Yap"}
          customCSS={{ paddingTop: "16px", paddingBottom: "16px" }}
          disabled={onContunieDisabled()}
          onClick={onContunieTriggered}
        />

        {currentPhase != "address" && (
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "26px",
              color: "#9CA4AB",
              marginTop: "16px",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setPhase("address")}
          >
            Geri
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default SubscriptionPaymentSummaryWithoutLogin;
