import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import { Buckets } from "../utils/constants";
import { useNavigate } from "react-router";

interface Props {
  id: string;
  title: string;
  description: string;
  short_description: string;
  is_picture_uploaded: boolean;
}

const HomeBulletin: FC<Props> = ({
  id,
  title = "",
  description = "",
  short_description = "",
  is_picture_uploaded = false,
}) => {

  const navigate = useNavigate()

  const imageGridStyle = {
    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24)), url(${Buckets.BulettinBucket}/${id}.jpg)`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: "16px",
    minHeight: "200px",
    cursor:"pointer"
  };

  return (
    <Grid
      container
      columns={{ xs: 4, sm: 8, md: 12 }}
      style={{ position: "relative" }}
    >
      <Grid item xs={4} sm={8} md={4} onClick={() => navigate(`/bulletin/${id}`)} style={imageGridStyle}></Grid>
      <Grid
        item
        xs={4}
        sm={8}
        md={8}
        sx={{ textAlign: "start", padding:{xs:"12px 5px", sm:"", md: "5px 30px"} }}
      >
        <Typography
          style={{
            fontSize: "20px",
            fontFamily: "Montserrat",
            lineHeight: "28px",
            color: "#000000",
            fontWeight: "700",
            marginBottom: "16px",
            cursor:"pointer"
          }}
          onClick={() => navigate(`/bulletin/${id}`)}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#66707A",
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
            marginBottom: {xs:"30px",sm:"30px",md:"22px"},
            cursor:"pointer"
          }}
          onClick={() => navigate(`/bulletin/${id}`)}
        >
          {short_description}
        </Typography>
        <Typography
          sx={{
            position: "absolute",
            bottom: "10px",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "17px",
            lineHeight: "20px",
            color: "#2574FF",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => navigate(`/bulletin/${id}`)}
        >
          Devamını Oku
        </Typography>
      </Grid>
    </Grid>
  );
};

export default HomeBulletin;
