import { FC, useState } from "react";
import { Grid, Tabs, Tab, Box, Typography } from "@mui/material";
import CommonLabel from "./CommonLabel";
import CommonTextField from "./CommonTextField";
import CommonMaskedInput from "./CommonMaskedInput";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store/store";
import CommonInputWithEndAdorment from "./CommonInputWithEndAdorment";
import { INewCreditCard, ISelectCardForPayment } from "../utils/commonModels";
import { CheckCouponReturnDto, SavedCard } from "../client";
import PickSavedCardItem from "./PickSavedCardItem";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid
          container
          spacing={3}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ textAlign: "start" }}
        >
          {children}
        </Grid>
      )}
    </div>
  );
}

interface Props {
  setField: (e: INewCreditCard) => void;
  values: INewCreditCard;
  selectSavedCard: (e: ISelectCardForPayment) => void;
  selectedCard: ISelectCardForPayment;
}

const SubscriptionPaymentCardNoDiscountField: FC<Props> = ({
  setField,
  values,
  selectSavedCard,
  selectedCard,
}) => {
  const [value, setValue] = useState<number>(0);
  const { saved_cards } = useSelector((state: RootState) => state.purchase);

  const tabNamesConstant =
    saved_cards.length == 0
      ? [
          {
            name: "Yeni Kart",
            value: 0,
          },
        ]
      : [
          {
            name: "Yeni Kart",
            value: 0,
          },
          {
            name: "Kayıtlı Kart",
            value: 1,
          },
        ];

  const setSelectedCardLocally = (e: SavedCard) => {
    if (
      e.card_bin_number == selectedCard.card_bin_number &&
      e.card_last_four_digits == selectedCard.card_last_four_digits
    ) {
      selectSavedCard({
        card_bin_number: "",
        card_last_four_digits: "",
      });
    } else {
      selectSavedCard({
        card_bin_number: e.card_bin_number,
        card_last_four_digits: e.card_last_four_digits,
      });
    }
  };

  return (
    <Grid
      container
      columns={{ xs: 4, sm: 8, md: 12 }}
      spacing={3}
      style={{ marginTop: "12px" }}
    >
      <Grid item xs={4} sm={8} md={12}>
        <Tabs
          orientation="horizontal"
          value={value}
          onChange={(event: React.SyntheticEvent, newValue: number) =>
            setValue(newValue)
          }
          sx={{
            "& .MuiTabs-indicator": { border: "1px solid #2574FF" },
            width: "100%",
            alignItems: "start",
          }}
        >
          {tabNamesConstant.map((item, index) => {
            return (
              <Tab
                key={index}
                disableRipple
                {...a11yProps(item.value)}
                style={{ marginRight: "20px" }}
                label={
                  <Typography
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: value == item.value ? "700" : "600",
                      fontSize: "18px",
                      lineHeight: "26px",
                      color: value == item.value ? "#171725" : "#78828A",
                      textTransform: "none",
                    }}
                  >
                    {item.name}
                  </Typography>
                }
              />
            );
          })}
        </Tabs>
      </Grid>
      <Grid item xs={4} sm={8} md={12}>
        <TabPanel value={value} index={0}>
          <Grid item xs={4} sm={8} md={12}>
            <CommonLabel label="Kartın Üzerindeki İsim Soyisim" />
            <CommonTextField
              _placeHolder="İsim soyisim giriniz"
              _onChange={(e: string) =>
                setField({ ...values, card_holder_name: e })
              }
            />
          </Grid>
          <Grid item xs={4} sm={8} md={4}>
            <CommonLabel label="Kart Numarası" />
            <CommonMaskedInput
              _placeHolder="Örnek:  31111 1111 1111 1111"
              _value={values.card_number}
              _onChange={(e: string) => setField({ ...values, card_number: e })}
              _format={"#### #### #### ####"}
            />
          </Grid>
          <Grid item xs={4} sm={8} md={4}>
            <CommonLabel label="Son Kullanım Tarihi" />
            <CommonMaskedInput
              _placeHolder="AA/YY"
              _value={""}
              _onChange={(e: string) =>
                setField({ ...values, expiration_date: e })
              }
              _format={"##/##"}
            />
          </Grid>
          <Grid item xs={4} sm={8} md={4}>
            <CommonLabel label="CVV" />
            <CommonMaskedInput
              _placeHolder="111"
              _value={""}
              _onChange={(e: string) => setField({ ...values, cvc: e })}
              _format={"###"}
            />
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {saved_cards.map((e: SavedCard, index: number) => (
            <Grid item xs={4} sm={8} md={5} key={index}>
              <PickSavedCardItem
                bankName={e.bank_name}
                cardType={e.card_type}
                lastFour={e.card_last_four_digits}
                binNumber={e.card_bin_number}
                isCardSelected={
                  e.card_bin_number == selectedCard.card_bin_number &&
                  e.card_last_four_digits == selectedCard.card_last_four_digits
                    ? true
                    : false
                }
                onSelected={() => setSelectedCardLocally(e)}
              />
            </Grid>
          ))}
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default SubscriptionPaymentCardNoDiscountField;
