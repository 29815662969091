import { Box, Grid, Typography } from "@mui/material";
import { FC } from "react";

interface Props {
  icon: string;
  color: string;
  title: string;
  text: string;
}

const LandingContentCard: FC<Props> = ({ icon, color, title, text }) => {
  return (
    <Grid
      container
      columns={{ xs: 4, sm: 8, md: 12 }}
      sx={{ backgroundColor: color, borderRadius: "12px", padding:{xs:"12px", sm:"12px", md:"24px"} }}
    >
      <Grid item>
        <Box component="img" src={icon} sx={{width:{xs:"28px", sm:"28px", md:"56px"}}}/>
      </Grid>
      <Grid item style={{marginLeft:"20px"}}>
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontWeight: 600,
            fontSize: {xs:"12px",sm:"12px",md:"24px"},
            lineHeight: {xs:"16px", sm:"16px", md:"32px"},
            color: "#171725",
            marginBottom:"4px"
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: {xs:"7px",sm:"7px",md:"14px"},
            lineHeight: {xs:"7px",sm:"7px",md:"14px"},
            color: "#434E58",
          }}
        >
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LandingContentCard;
