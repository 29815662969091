import { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { OnboardingParentSchool, OnboardingParentSolution, OnboardingSurvey } from "../assests";
import { Container, Grid, Typography } from "@mui/material";
import { Autoplay } from "swiper";

const AuthOnboardingSlider: FC = () => {
  const sliderItems = [
    {
      title: "Anketler",
      description: "Bilimsel anketlerimizle kendinizi ve çocuğunuzu keşfedin.",
      icon: OnboardingSurvey,
    },
    {
      title: "Ebeveyn Çözümleri",
      description:
        "Size özgü makale ve videolar aracılığıyla tüm sorularınıza yanıt alın.",
      icon: OnboardingParentSolution,
    },
    {
      title: "Anne Baba Okulu",
      description: "Anne Baba Okulu dersleri ile gelişim yolculuğuna çıkın.",
      icon: OnboardingParentSchool,
    },
  ];

  const dotGenerator = (order: number) => {
    const dotStyle = {
      width: "8px",
      height: "8px",
      opacity: 0.4,
      backgroundColor: "#F9F9F9",
      margin:"4px",
      borderRadius:"50%"
    };

    const stickStyle = {
      width: "24px",
      height: "8px",
      backgroundColor: "#F9F9F9",
      borderRadius: "100px",
      margin:"4px"
    };

    switch (order) {
      case 0:
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={stickStyle}></div>
            <div style={dotStyle}></div>
            <div style={dotStyle}></div>
          </div>
        );
      case 1:
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={dotStyle}></div>
            <div style={stickStyle}></div>
            <div style={dotStyle}></div>
          </div>
        );
      case 2:
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={dotStyle}></div>
            <div style={dotStyle}></div>
            <div style={stickStyle}></div>
          </div>
        );
      default:
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={stickStyle}></div>
            <div style={dotStyle}></div>
            <div style={dotStyle}></div>
          </div>
        );
    }
  };

  return (
    <Swiper
      slidesPerView={1}
      onSlideChange={() => console.log("")}
      onSwiper={(swiper) => console.log(swiper)}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
    >
      {sliderItems.map((e, index) => {
        return (
          <SwiperSlide key={index}>
            <Container maxWidth="sm" style={{ marginTop: "10%" }}>
              <Grid
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                }}
              >
                <Grid
                  item
                  xs={4}
                  sm={8}
                  md={12}
                  style={{ textAlign: "start", marginBottom: "12px" }}
                >
                  <Typography
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "700",
                      fontSize: "32px",
                      lineHeight: "40px",
                      color: "#F9F9F9",
                    }}
                  >
                    {e.title}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={8}
                  md={12}
                  style={{ textAlign: "start", marginBottom: "30px" }}
                >
                  <Typography
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#E3E9ED",
                    }}
                  >
                    {e.description}
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={8} md={12} style={{ textAlign: "start" }}>
                  {dotGenerator(index)}
                </Grid>
                <Grid item xs={4} sm={8} md={12} style={{ textAlign: "start",marginTop:"25%" }}>
                  <img src={e.icon} alt="" />
                </Grid>
              </Grid>
            </Container>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default AuthOnboardingSlider;
