/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum FavoriteType {
    ARTICLE = 'article',
    TRAINING_SET = 'training_set',
    SURVEY = 'survey',
    PARENT_SCHOOL_MODULE = 'parent_school_module',
    TRAINING_SET_ARTICLE = 'training_set_article',
    PARENT_SCHOOL_MODULE_ARTICLE = 'parent_school_module_article',
    GAME_CARD = 'game_card',
}
