import { FC, useState } from "react";
import { Typography, Grid } from "@mui/material";
import CommonLabel from "./CommonLabel";
import CommonTextField from "./CommonTextField";
import CommonButton from "./CommonButton";
import CommonAlert from "./CommonAlert";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import CommonLoading from "./CommonLoading";
import { changePasswordIn } from "../redux/authSlice";

const ChangePasswordInApp: FC = () => {
  const [password, setPassword] = useState<string>("");
  const [passwordRepeat, setPasswordRepeat] = useState<string>("");

  const [message, setMessage] = useState<string | null>(null);
  const [type, setType] = useState<string>("success")

  const dispatch = useDispatch<AppDispatch>();
  const { loading } = useSelector((state: RootState) => state.auth);

  const submitPasswordChange = async () => {
    try {
      if (password !== passwordRepeat) {
        setType("error")
        setMessage("Şifre ve şifre tekrarı aynı olmalı");
        return;
      }
      await dispatch(changePasswordIn({new_password:password}))
      setType("success")
      setMessage("Şifre güncellendi.");

    } catch (error) {}
  };

  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }} columnSpacing={5}>
      <CommonAlert
        _open={message == null ? false : true}
        _message={message == null ? "" : message}
        _type={type}
        _onClose={() => setMessage(null)}
      />
      <CommonLoading loading={loading} />
      <Grid item xs={4} sm={8} md={12}>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "28px",
            color: "#171725",
            marginBottom: "24px",
          }}
        >
          Şifremi Değiştir
        </Typography>
      </Grid>
      <Grid item xs={4} sm={8} md={6}>
        <CommonLabel label="Yeni Şifre Belirle" />
        <CommonTextField
          _type="password"
          _placeHolder="******"
          _onChange={(e) => setPassword(e)}
        />
      </Grid>
      <Grid item xs={4} sm={8} md={6}>
        <CommonLabel label="Yeni Şifreni Tekrar Gir" />
        <CommonTextField
          _type="password"
          _placeHolder="******"
          _onChange={(e) => setPasswordRepeat(e)}
        />
      </Grid>
      <Grid item xs={4} sm={8} md={12} style={{ marginTop: "32px" }}>
        <CommonButton
          text="Şifremi Güncelle"
          onClick={() => submitPasswordChange()}
          customCSS={{ width: "max-content", padding: "12px 24px" }}
          disabled={
            password.length < 6 || passwordRepeat.length < 6 ? true : false
          }
        />
      </Grid>
    </Grid>
  );
};

export default ChangePasswordInApp;
