import { FC } from 'react'
import { Container, Grid, Typography } from '@mui/material'
import { NotFound404 } from '../assests'
import { CommonLightButton, HeadingTitle } from '../components'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store/store'
import { useNavigate } from 'react-router'

const Error:FC = () => {
  const navigate = useNavigate()
  const {access_token} = useSelector((state:RootState) => state.auth);

  return (
    <Container maxWidth="lg" style={{ marginTop: "30px", marginBottom: "30px", height:"90vh",display:"flex",alignItems:"center"}}>
      <Grid container columns={{xs:4, sm:8, md:12}}>
        <Grid item xs={4} sm={8} md={12}>
          <img src={NotFound404} alt=""/>
        </Grid>
        <Grid item xs={4} sm={8} md={12} style={{marginTop:"40px"}}>
            <HeadingTitle title="Sayfa Bulunamadı" />
            <Typography style={{fontFamily:"Montserrat", fontWeight:"500",fontSize:"16px",lineHeight:"24px",color:"#66707A"}}>
              Üzgünüz, aradığın sayfayı bulamadık. 
            </Typography>
        </Grid>
        <Grid item xs={4} sm={8} md={12} style={{marginTop:"40px"}}>
          <CommonLightButton text='Anasayfaya Dön' customCSS={{width:"max-content"}} onClick={() => access_token != "" ? navigate("/home") : navigate("/login")}/> 
        </Grid>
      </Grid>
    </Container>
  )
}

export default Error