import { Typography } from "@mui/material";
import { FC } from "react";
import { BankingAsset_Advantage, BankingAsset_AmericanExpress, BankingAsset_Axess, BankingAsset_Bonus, BankingAsset_CardFinans, BankingAsset_Master, BankingAsset_Maximum, BankingAsset_Paracard, BankingAsset_Paraf, BankingAsset_Troy, BankingAsset_Visa, BankingAsset_World } from "../assests";
import { INewCreditCard } from "../utils/commonModels";

interface Props {
    card:INewCreditCard
    bank_name:string
    card_association:string
}

const PaymentCardVirtual: FC<Props> = ({
    card,
    bank_name,
    card_association
}) => {
    const { card_holder_name, card_number, expiration_date, cvc} = card

    const getCardAssociationIcon = () => {
      switch (card_association) {
        case "VISA":
          return BankingAsset_Visa
        case "MASTER_CARD":
          return BankingAsset_Master
        case "TROY":
          return BankingAsset_Troy
        case "AMERICAN_EXPRESS":
          return BankingAsset_AmericanExpress
        default:
          break;
      }
    }

    const getCardFamilyNameIcon = () => {
      switch (bank_name) {
        case "Bonus":
          return BankingAsset_Bonus
        case "Axess":
          return BankingAsset_Axess
        case "World":
          return BankingAsset_World
        case "Maximum":
          return BankingAsset_Maximum
        case "Paraf":
          return BankingAsset_Paraf
        case "Cardfinans":
          return BankingAsset_CardFinans
        case "Advantage":
          return BankingAsset_Advantage
        case "Paracard":
          return BankingAsset_Paracard
        default:
          break;
      }
    }

  return (
    <div
      style={{
        borderRadius: "16px",
        border: "1px solid #E9EBED",
        backgroundColor: "#F9F9F9",
        padding: "23px 19px 12px 19px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "25px"
        }}
      >
        <img src={getCardFamilyNameIcon()} alt="" />
        <img src={getCardAssociationIcon()} alt="" />
      </div>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "22px",
          color: "#66707A",
          letterSpacing: "3px",
        }}
      >
        {card_number}
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "3px",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "12px",
            lineHeight: "22px",
            color: "#66707A",
            letterSpacing: "2.28px",
          }}
        >
          {expiration_date}
        </Typography>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "12px",
            lineHeight: "22px",
            color: "#66707A",
            letterSpacing: "2.28px",
          }}
        >
          {cvc}
        </Typography>
      </div>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "700",
          fontSize: "14px",
          lineHeight: "22px",
          color: "#66707A",
          letterSpacing: "0.028px",
          marginTop: "14px",
        }}
      >
        {card_holder_name}
      </Typography>
    </div>
  );
};

export default PaymentCardVirtual;
