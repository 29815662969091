import { FC } from "react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

interface Props {
    customCSS?: {}
    currentStep:number
}

const RegisterFlowProgressBar: FC<Props> = ({
  customCSS,
  currentStep
}) => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: "8px",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: "10px",
      backgroundColor: theme.palette.mode === "light" ? "#2574FF" : "#ECF1F6",
    },
    minWidth:"90px"
  }));
  const setPercentage = (related_bar_index:number) => {
    switch (related_bar_index) {
      case 1:
        if(currentStep == 1){
          return 50
        }else{
          return 100
        }
      case 2:
        if(currentStep < 2){
          return 0
        }else{
          return 100
        }
      case 3:
        if(currentStep == 3){
          return 50
        }else if(currentStep > 3) {
          return 100
        }
        break;
      default:
        break;
    }
    return 0
  }
  
  return (
    <Grid container columns={{xs:12}} style={customCSS} columnSpacing={2}>
      <Grid item xs={4}>
        <BorderLinearProgress variant="determinate" value={setPercentage(1)} />
      </Grid>
      <Grid item xs={4}>
        <BorderLinearProgress variant="determinate" value={setPercentage(2)} />
      </Grid>
      <Grid item xs={4}>
        <BorderLinearProgress variant="determinate" value={setPercentage(3)} />
      </Grid>
    </Grid>

  );
};

export default RegisterFlowProgressBar;
