import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import QuoteShareButton from "./QuoteShareButton";
import { Buckets } from "../utils/constants";

interface Props {
  id: string;
  quote: string;
  author: string;
  is_cover_image_uploaded: boolean;
}

const HomeDailyQuote: FC<Props> = ({
  id,
  quote = "",
  author = "",
  is_cover_image_uploaded = false,
}) => {
  const containerStyle = {
    padding: "40px",
    borderRadius: "24px",
    backgroundImage:
      `linear-gradient(270deg, rgba(13, 12, 15, 0) 16.73%, #0D0C0F 100%), url(${Buckets.QuoteBucket}/${id}.jpg)`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize:"cover",
  };

  return (
    <Grid container style={containerStyle}>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "flex-start",
          marginBottom: "12px",
        }}
      >
        <div
          style={{
            background: "rgba(253, 253, 253, 0.08)",
            borderRadius: "12px",
            padding: "8px 16px",
            color: "#FDFDFD14",
          }}
        >
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "20px",
              color: "#F9F9F9",
            }}
          >
            Günün Sözü
          </Typography>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          maxWidth: {
            xs: "100%",
            sm: "100%",
            md: "75%",
          },
          textAlign: "start",
          marginBottom: "8px",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "30px",
            lineHeight: "37px",
            color: "#F9F9F9",
          }}
        >
          {quote}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "start", marginBottom: "39px" }}>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "22px",
            color: "#9CA4AB",
          }}
        >
          {author}
        </Typography>
      </Grid>
      <Grid xs={12} style={{
        display: "flex",
        alignItems: "flex-start",
      }}>
        <QuoteShareButton _onClick={() => {return false}} />
      </Grid>
    </Grid>
  );
};

export default HomeDailyQuote;
