import { FC, useEffect, useState } from "react";
import { Container, Grid, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import {
  CommonLoading,
  ParentSchoolModuleItem,
  ParentSchoolModuleProgress,
  ParentSchoolVideoContentHeader,
  VideoPlayer,
} from "../components";
import { getAllParentSchoolContent } from "../redux/parentSchoolSlice";
import { IParentSchoolPageSelectedContent } from "../utils/commonModels";
import { Buckets } from "../utils/constants";
import { ArticleType, OpenAPI, ProgressLogItemType, ProgressLogNameType, TrainingSetArticleItem } from "../client";
import useGoogleReCaptchaV3 from "../utils/googleReCaptchaV3.ts/useGoogleReCaptchaV3";
import { doesUserHasAccessToContent, getVideoUrl } from "../utils/commonFuncs";
import InfiniteScroll from "react-infinite-scroll-component";
import parse from "html-react-parser";
import { getArticle } from "../redux/articleSlice";
import { addProgress } from "../redux/logSlice";
import { UseVideoPageTimeCounter } from "../hooks";

const ParentSchool: FC = () => {
  // const { executeReCaptcha } = useGoogleReCaptchaV3({
  //   siteKey: `${process.env.REACT_APP_RECAPTCHA_KEY}`,
  // });
  const dispatch = useDispatch<AppDispatch>();
  const { loading, parentSchoolContents, error } = useSelector(
    (state: RootState) => state.parentSchool
  );
  const { access_token, profile } = useSelector((state: RootState) => state.auth);
  const {single_article, loading:article_loading} = useSelector((state:RootState) => state.article)

  const [selected, setSelected] =
    useState<IParentSchoolPageSelectedContent | null>(null);

  const [videoUrlFetched, setVideoUrlFetched] = useState<string>("");
  const [reCaptchaLoading, setReCaptchaLoading] = useState<boolean>(false);
  const {count, resetCounter} = UseVideoPageTimeCounter()

  useEffect(() => {
    if(count >= 1195 && selected != null){
      resetCounter()
      fetchURL()
    }
  }, [count])

  useEffect(() => {
    if (OpenAPI.TOKEN == undefined && access_token != "") {
      OpenAPI.TOKEN = access_token;
    }
    if (parentSchoolContents.modules.length == 0) {
      dispatch(
        getAllParentSchoolContent({
          start_after_module_id: parentSchoolContents.last_module_id,
        })
      );
    }
  }, [parentSchoolContents]);

  useEffect(() => {
    if (
      parentSchoolContents.modules.length > 0 &&
      parentSchoolContents.modules.length <= 20
    ) {
      setSelected({
        moduleId: parentSchoolContents.modules[0].id,
        moduleName: "1. Modül",
        article: parentSchoolContents.modules[0].articles[0],
      });
    }
  }, [parentSchoolContents]);

  async function fetchURL() {
    setReCaptchaLoading(true);
    if (selected?.article.type === ArticleType.VIDEO) {
      // const token = await executeReCaptcha("LoadReCaptchaOnClick");
      const newUrl = await getVideoUrl(selected.article.article_id, "");
      setVideoUrlFetched(newUrl);
    }
    setReCaptchaLoading(false);
  }

  useEffect(() => {  
    fetchURL();
  }, [selected]);

  const loadMore = async () => {
    try {
      let currentYPosition = 0;
      try {
        currentYPosition = window.pageYOffset;
      } catch (error) {}

      if (parentSchoolContents.last_module_id != null && !loading) {
        await dispatch(
          getAllParentSchoolContent({
            start_after_module_id: parentSchoolContents.last_module_id,
          })
        );
      }
      window.scrollTo(0, currentYPosition);
    } catch (error) {}
  };

  const contentEndCallBack = () => {
    try {
      const modulesDuplicated = parentSchoolContents.modules
      const currentModuleIndex = modulesDuplicated.findIndex((e)=> e.id == selected?.moduleId)

      if(modulesDuplicated.length - 1 >= currentModuleIndex){
        const contentIndex = modulesDuplicated[currentModuleIndex].articles.findIndex(e => e.article_id == selected?.article.article_id)
        const moduleList =  modulesDuplicated[currentModuleIndex]

        let userHasAccess = doesUserHasAccessToContent(profile?.is_user_premium ? profile.is_user_premium : false, moduleList.articles[contentIndex + 1]?.show_to_free_plan as boolean)

        if(moduleList.articles.length - 1 >= contentIndex && userHasAccess){
          selectNewContent({
            moduleId:moduleList.id,
            moduleName:moduleList.name,
            article: moduleList.articles[contentIndex + 1]
          })
        }
      }
    } catch (error) {}
  }

  const selectNewContent = (item: IParentSchoolPageSelectedContent) => {
    try {
      if(item.article.type == ArticleType.TEXT){
        dispatch(getArticle({articleId:item.article.article_id}))
      }
      setSelected(item)

      const currentModule = parentSchoolContents.modules.find((e) => e.id == item.moduleId)
      dispatch(addProgress(
        {
          item_id: `${item.moduleId}_${item.article.article_id}`,
          progress_log_name: ProgressLogNameType.COMPLETED,
          item_type: ProgressLogItemType.PARENT_SCHOOL_MODULE_ARTICLE,
          item_name: item.article.article_name,
          module_name: item.moduleName,
          total_items_count: currentModule?.articles.length,
          article_type: item.article.type,
        }
      ))

    } catch (error) {
      
    }
  }

  return (
    <Container
      maxWidth="xl"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <CommonLoading loading={loading || reCaptchaLoading || article_loading} />
      <Grid
        container
        columns={{ xs: 4, sm: 8, md: 12 }}
        columnSpacing={1}
        rowSpacing={1}
      >
        <Grid item xs={4} sm={8} md={9}>
          {selected != null && selected.article.type == ArticleType.VIDEO ? (
            <VideoPlayer
              url={selected ? videoUrlFetched : ""}
              thumbnailUrl={
                selected
                  ? `${Buckets.ArticleImageBucket}/${selected.article.article_id}.jpg`
                  : ""
              }
              contentEndCallBack={contentEndCallBack}
            />
          ) : (
            <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "33px",
                  color: "#000000",
                  textAlign: "start",
                }}
              >
                {parse(single_article.text_content ? single_article.text_content : "")}
              </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={false}
          sm={false}
          md={3}
          sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
        >
          <ParentSchoolModuleProgress />
        </Grid>
        <Grid item xs={4} sm={8} md={9}>
          {selected && (
            <ParentSchoolVideoContentHeader
              contentId={selected?.article.article_id}
              title={selected != null ? selected.article.article_name : ""}
              parentSolutionName={selected != null ? selected.moduleName : ""}
              parentSchoolModuleId={selected?.moduleId}
            />
          )}
        </Grid>
        <Grid item xs={4} sm={8} md={12}>
          <InfiniteScroll
            dataLength={parentSchoolContents.modules.length}
            next={() => loadMore()}
            hasMore={parentSchoolContents.last_module_id != null ? true : false}
            loader={null}
          >
            {!loading &&
              parentSchoolContents.modules.map((e, index) => (
                <ParentSchoolModuleItem
                  order={index + 1}
                  title={e.name}
                  description={e.short_description}
                  articles={e.articles}
                  selectedItem={selected}
                  moduleId={e.id}
                  onContentSelected={(item: IParentSchoolPageSelectedContent) =>
                    selectNewContent(item)
                  }
                />
              ))}
          </InfiniteScroll>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ParentSchool;
