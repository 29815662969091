import { FC } from "react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

interface Props {
  percentage: number;
  mainColor?: string;
}

const ContentLinearProgressBar: FC<Props> = ({
  percentage = 0,
  mainColor = "#2574FF",
}) => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: "10px",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: "10px",
      backgroundColor: theme.palette.mode === "light" ? mainColor : "#ECF1F6",
    },
  }));

  return <BorderLinearProgress variant="determinate" value={percentage} />;
};

export default ContentLinearProgressBar;
