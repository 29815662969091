import { FC, MouseEvent } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import ParentSolutionListingItem from "./ParentSolutionListingItem";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch,RootState } from "../redux/store/store";
import { addItemToFavorite, removeItemFromFavorite } from "../redux/favoriteSlice";
import { FavoriteType, ProgressStatusType } from "../client";

interface Props {
  items: {
    id: string;
    title: string;
    video_article_count: number;
    text_article_count: number;
    total_watch_time?: number;
  }[];
}

const HomeParentSolutionSlider: FC<Props> = ({ items }) => {
  const dispatch = useDispatch<AppDispatch>()
  const { progress_status_items } = useSelector(
    (state: RootState) => state.log
  );

  const addContentToFavorite = (
    e: MouseEvent,
    id: string,
    name_of_content: string
  ) => {
    try {
      e.stopPropagation();
      dispatch(
        addItemToFavorite({
          id_of_favorite: id,
          name_of_favorite: name_of_content,
          favourite_type: FavoriteType.TRAINING_SET,
        })
      );
    } catch (error) {}
  };

  const removeContentFromFavorite = (e: MouseEvent, id: string) => {
    try {
      e.stopPropagation();
      dispatch(removeItemFromFavorite({ favorite_item_id: id }));
    } catch (error) {}
  };


  return (
    <Swiper
      breakpoints={{
        320: {
          slidesPerView: 1.2,
          spaceBetween: 10,
        },
        480: {
          slidesPerView: 2.2,
          spaceBetween: 10,
        },
        640: {
          slidesPerView: 3.2,
          spaceBetween: 15,
        },
        1200: {
          slidesPerView: 3.2,
          spaceBetween: 15,
        },
      }}
      className="mySwiper"
    >
      {items.map((e, index) => {
            return (
              <SwiperSlide key={index}>
                <ParentSolutionListingItem
                  id={e.id}
                  title={e.title}
                  video_article_count={e.video_article_count}
                  text_article_count={e.text_article_count}
                  total_watch_time={e.total_watch_time}
                  is_picture_uploaded={true}
                  addContentToFavorite={(e, id, name_of_content) =>
                    addContentToFavorite(e, id, name_of_content)
                  }
                  removeContentFromFavorite={(e, id) =>
                    removeContentFromFavorite(e, id)
                  }
                  percentage={
                    progress_status_items.filter(
                      (k) =>
                        k.progress_status_type ==
                          ProgressStatusType.TRAINING_SET && k.item_id == e.id
                    ).length > 0
                      ? (100 *
                          progress_status_items.filter(
                            (k) =>
                              k.progress_status_type ==
                                ProgressStatusType.TRAINING_SET &&
                              k.item_id == e.id
                          )[0].completed_item_count) /
                        progress_status_items.filter(
                          (k) =>
                            k.progress_status_type ==
                              ProgressStatusType.TRAINING_SET &&
                            k.item_id == e.id
                        )[0].total_items_count
                      : 0
                  }
                />
              </SwiperSlide>
            );
          })}
    </Swiper>
  );
};

export default HomeParentSolutionSlider;
