import { FC } from 'react'
import { PatternFormat } from 'react-number-format'
import { TextField } from '@mui/material'

interface Props {
    _format:string
    _onChange:(e:string) => void
    _value?:string
    _defaultValue?:string
    _placeHolder?:string
    _disabled?:boolean
    _textAlign?:string
}

const CommonMaskedInput:FC<Props> = ({
    _format="",
    _onChange,
    _value="",
    _defaultValue="",
    _placeHolder="",
    _disabled=false,
    _textAlign="start"
}) => {
  return (
    <PatternFormat
      format={_format}
      fullWidth
      value={_value}
      defaultValue={_defaultValue}
      placeholder={_placeHolder}
      onChange={(e) => _onChange(e.target.value)}
      disabled={_disabled == null || undefined ? false : _disabled}
      customInput={TextField}
      sx={{
        "& fieldset": { border: "1px solid #E9EBED", borderRadius:"12px"},
        input: {
          color: "#9CA4AB",
          fontFamily: "Montserrat",
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "22px",
          textAlign: _textAlign
        },
        borderRadius: "8px",
        backgroundColor: "rgba(228, 228, 228, 0.25)",
      }}
    />
  )
}

export default CommonMaskedInput