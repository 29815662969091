import { Container, Grid, Typography, Divider, Box } from "@mui/material";
import { FC } from "react";
import {
  FacebookWhite,
  InstagramWhite,
  LinkedinWhite,
  PwLogoWhite,
  TwitterWhite,
} from "../assests";

const LandingPageFooter: FC = () => {
  const social = [
    {
        icon:FacebookWhite,
        url:"https://www.facebook.com/parentwiserapp/"
    },
    {
        icon:TwitterWhite,
        url:"https://twitter.com/parentwiser"
    },
    {
        icon:LinkedinWhite,
        url:"https://tr.linkedin.com/company/parentwiser"
    },
    {
        icon:InstagramWhite,
        url:"https://www.instagram.com/parentwiser/"
    },
  ]

  return (
    <Box
      component="div"
      sx={{
        backgroundColor: "#2574FF",
        paddingTop: {xs:"34px" ,sm:"34px", md:"124px"},
        paddingBottom: "32px",
      }}
    >
      <Container maxWidth="lg">
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid
            item
            xs={4}
            sm={8}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: {xs:"center", sm:"center", md:"start"},
              alignItems: {xs:"center", sm:"center", md:"start"},
            }}
          >
            <img src={PwLogoWhite} alt="" width={205} height={48} />
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#FFFFFF",
                marginTop: "30px",
              }}
            >
              Dr. Özgür Bolat ve ekibi tarafından
              <br /> geliştirilen, "kişiye özgü çocuk
              <br /> yetiştirme" uygulamasıdır.
            </Typography>
          </Grid>
          <Grid
            item
            xs={0}
            sm={0}
            md={6}
            sx={{
              display: {xs:"none", sm:"none", md:"flex"},
              flexDirection: "column",
              textAlign: {xs:"center", sm:"center", md:"end"},
              marginTop:{xs:"20px",sm:"20px",md:"0px"},
              paddingTop:"10px"
            }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "22px",
                lineHeight: "24px",
                color: "#FFFFFF",
              }}
            >
              info@parentwiser.com.tr
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#FFFFFF",
                marginTop: "24px",
              }}
            >
              +90 850 305 71 29
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={0}
            sx={{
              display: {xs:"flex", sm:"flex", md:"none"},
              flexDirection: "row",
              justifyContent: "center",
              marginTop:"24px"
            }}
          >
            {social.map((e) => (
              <img
                src={e.icon}
                width={20}
                height={20}
                alt=""
                style={{
                  padding: "12px",
                  backgroundColor: "#FFFFFF1A",
                  borderRadius: "5px",
                  marginRight: "8px",
                  cursor:"pointer"
                }}
                onClick={() => window.open(e.url, "_blank")}
              />
            ))}
          </Grid>
          <Divider
            style={{
              width: "100%",
              border: "1px solid #FFFFFF",
              marginTop: "50px",
              marginBottom: "24px",
            }}
          />
            <Grid
            item
            xs={4}
            sm={8}
            md={0}
            sx={{
              display: {xs:"flex", sm:"flex", md:"none"},
              flexDirection: "column",
              textAlign: {xs:"center", sm:"center", md:"end"},
              marginTop:{xs:"20px",sm:"20px",md:"0px"}
            }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "22px",
                lineHeight: "24px",
                color: "#FFFFFF",
              }}
            >
              info@parentwiser.com.tr
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#FFFFFF",
                marginTop: "24px",
              }}
            >
              +90 850 305 71 29
            </Typography>
          </Grid>
          <Grid
            item
            xs={0}
            sm={0}
            md={6}
            sx={{
              display: {xs:"none", sm:"none", md:"flex"},
              flexDirection: "row",
              textAlign: "start",
            }}
          >
            {social.map((e) => (
              <img
                src={e.icon}
                width={20}
                height={20}
                alt=""
                style={{
                  padding: "12px",
                  backgroundColor: "#FFFFFF1A",
                  borderRadius: "5px",
                  marginRight: "8px",
                  cursor:"pointer"
                }}
                onClick={() => window.open(e.url, "_blank")}
              />
            ))}
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: {xs:"center", sm:"center", md:"end"},
              marginTop:{xs:"20px",sm:"20px",md:"0px"}
            }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#FFFFFF",
              }}
            >
              © 2023 - ParentWiser
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LandingPageFooter;
