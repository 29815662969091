import { Container, Grid, Tabs, Tab, Box } from "@mui/material";
import { FC, useState } from "react";
import {
  CommonLoading,
  CommunicationPermissionSwitch,
  FontTypeAndSize,
  HeadingTitle,
  SettingsTabLabelItem,
} from "../components";
import { MenuCall, MenuFontType, MenuMailPlus, MenuRing } from "../assests";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { updateUser } from "../redux/authSlice";
import { Gender, MarriageStatus, ParentType } from "../client";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, textAlign: "start" }}>{children}</Box>
      )}
    </div>
  );
}

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

const Settings: FC = () => {
  const { profile, loading } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>();

  const tabNamesConstant = [
    {
      name: "Yazı Tipi ve Metin Boyutu",
      value: 0,
      icon: MenuFontType,
    },
  ];
  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const permissionOnChange = async (e: any, permissionName:string) => {
    await dispatch(updateUser({
      user:{
        email: profile?.email,
        phone: profile?.phone,
        name: profile?.name ? profile.name : "",
        gender: profile?.gender ? profile.gender : Gender.OTHER,
        parent_type: profile?.parent_type ? profile.parent_type : ParentType.OTHER,
        marriage_status: profile?.marriage_status ? profile.marriage_status : MarriageStatus.OTHER,
        city: profile?.city,
        country: profile?.country,
        address: profile?.address,
        county: profile.county,
        notificationPermission: permissionName == "notification" ? e.target.checked : profile?.notificationPermission,
        smsPermission: permissionName == "sms" ? e.target.checked : profile?.smsPermission,
        mailPermission: permissionName == "mail" ? e.target.checked : profile?.mailPermission,
      }
    }))
  };

  return (
    <Container
      maxWidth="xl"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <CommonLoading loading={loading} />
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid
          item
          xs={4}
          sm={8}
          md={4}
          sx={{ textAlign: "start", borderRight: 1, borderColor: "divider" }}
        >
          <HeadingTitle title="Ayarlar" />
          {/* <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{
              "& .MuiTabs-indicator": { display: "none" },
              width: "100%",
            }}
          >
            {tabNamesConstant.map((item, index) => {
              return (
                <Tab
                  key={index}
                  disableRipple
                  label={
                    <SettingsTabLabelItem
                      isSelected={item.value == value}
                      text={item.name}
                      icon={item.icon}
                      isLastItem={false}
                    />
                  }
                  {...a11yProps(item.value)}
                />
              );
            })}
          </Tabs> */}
          <CommunicationPermissionSwitch
            text="Bildirim Al"
            icon={MenuRing}
            isChecked={
              profile.notificationPermission
                ? profile.notificationPermission
                : false
            }
            _onChange={(e) => permissionOnChange(e,"notification")}
          />
          <CommunicationPermissionSwitch
            text="E-posta Al"
            icon={MenuMailPlus}
            isChecked={profile.mailPermission ? profile.mailPermission : false}
            _onChange={(e) => permissionOnChange(e,"mail")}
          />
          <CommunicationPermissionSwitch
            text="SMS Al"
            icon={MenuCall}
            isChecked={profile.smsPermission ? profile.smsPermission : false}
            _onChange={(e) => permissionOnChange(e,"sms")}
          />
        </Grid>
        <Grid item xs={4} sm={8} md={8}>
          {/* <TabPanel value={value} index={0}>
            <FontTypeAndSize />
          </TabPanel> */}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Settings;
