import { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store/store'
import { useNavigate } from 'react-router'
import { setForcedUpdateParamToLocalStorage } from '../utils/commonFuncs'
import { useSearchParams } from 'react-router-dom'

const ForcedPremium:FC = () => {
    const {access_token, profile} = useSelector((state:RootState) => state.auth)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
      if(access_token == "") {
        setForcedUpdateParamToLocalStorage(searchParams.get("pricing_option"), searchParams.get("discountCoupon"))
        navigate("/login")
      }else {
        if(profile.is_user_premium){
            navigate("/home")
        }else {
            navigate("/show-subscription-packages")
        }
      }
    }, [])
    

  return (
    <div></div>
  )
}

export default ForcedPremium