import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ArticleType, GetArticlesResponse, UserService, SearchService,FullTextSearchModel, ArticleModel } from "../client";

export const getFilteredArticles = createAsyncThunk(
    'article/getFilteredArticles',
    async(payload:{startAfterArticleId?:string, articleType:ArticleType, customLimit:number}) => {
        try {
            return await UserService.getArticlesFilteredUserArticlesFilteredGet(payload.startAfterArticleId,payload.articleType, payload.customLimit)
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

export const getArticle = createAsyncThunk(
    'article/getArticle',
    async (payload:{articleId:string}) => {
        try {
            return await UserService.getArticleUserArticleArticleIdGet(payload.articleId)
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

interface IInitialState {
    articles: GetArticlesResponse
    loading: boolean;
    error: string;
    suggested_articles:FullTextSearchModel[];
    single_article:ArticleModel
}

const initialState: IInitialState = {
    articles: {
        articles: [] as Array<ArticleModel>,
        last_article_id: undefined
    },
    loading: false,
    error: "",
    suggested_articles:[],
    single_article:{} as ArticleModel
}

export const articleSlice = createSlice({
    name: "article",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getFilteredArticles.pending, (state) => {
            state.loading = true;
            state.error = ''
        })
        builder.addCase(getFilteredArticles.fulfilled, (state, action) => {
            if(state.articles.last_article_id){
                state.articles.articles = [...state.articles.articles, ...action.payload.articles]
                state.articles.last_article_id = action.payload.last_article_id
            }else{
                state.articles.articles = action.payload.articles
                state.articles.last_article_id = action.payload.last_article_id
            }
            state.loading = false;
            state.error = ''
        });
        builder.addCase(getFilteredArticles.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
        builder.addCase(getArticle.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(getArticle.fulfilled, (state, action) => {
            state.single_article = action.payload
            state.loading = false
        })
        builder.addCase(getArticle.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
    }
})

export default articleSlice.reducer