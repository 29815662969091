import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import { Buckets } from "../utils/constants";
import DetailBackButton from "./DetailBackButton";
import DetailShareButton from "./DetailShareButton";
import DetailFavoriteButton from "./DetailFavoriteButton";
import EvaluateContentButton from "./EvaluateContentButton";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { FavoriteType, UserFeedBackContentType } from "../client";
import { addItemToFavorite, removeItemFromFavorite } from "../redux/favoriteSlice";
import { openContentEvaluateModal, openShareContentModal } from "../redux/generalControllerSlice";

interface Props {
  id: string;
  title: string;
  is_picture_uploaded: boolean;
}

const TextArticleHeader: FC<Props> = ({
  id = "",
  title = "",
  is_picture_uploaded = false,
}) => {
  const navigate = useNavigate();
  const { favorite_items } = useSelector((state: RootState) => state.favorite);
  const dispatch = useDispatch<AppDispatch>()

  const containerStyle = {
    background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.74) 100%), url(${
      is_picture_uploaded ? `${Buckets.ArticleImageBucket}/${id}.jpg` : ""
    })`,
    padding: "30px",
    borderRadius: "24px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  const addContentToFavorite = () => {
    try {
      dispatch(addItemToFavorite({
        id_of_favorite:id,
        name_of_favorite:title,
        favourite_type:FavoriteType.ARTICLE
      }))
    } catch (error) {}
  }

  const removeContentFromFavorite = () => {
    try {
      dispatch(removeItemFromFavorite({favorite_item_id:id}))
    } catch (error) {}
  }

  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }} style={containerStyle}>
      <Grid item xs={4} sm={8} md={6}>
        <DetailBackButton onClick={() => navigate(-1)} />
      </Grid>
      <Grid
        item
        xs={false}
        sm={false}
        md={6}
        sx={{
          display: { xs: "none", sm: "none", md: "flex" },
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <DetailShareButton
          onClick={() => dispatch(openShareContentModal())}
          customCSS={{ marginRight: "5px" }}
        />
        <DetailFavoriteButton
          isLiked={favorite_items.some((e) => {
            return (
              (e.favourite_type == FavoriteType.ARTICLE ||
                e.favourite_type == FavoriteType.TRAINING_SET_ARTICLE) &&
              e.id == id
            );
          })}
          onClick={favorite_items.some((e) => {
            return (
              (e.favourite_type == FavoriteType.ARTICLE ||
                e.favourite_type == FavoriteType.TRAINING_SET_ARTICLE) &&
              e.id == id
            );
          }) ? () => removeContentFromFavorite() : () => addContentToFavorite()}
          customCSS={{ marginRight: "5px" }}
        />
        <EvaluateContentButton
          onClick={() => dispatch(openContentEvaluateModal({content_id:id, content_type:UserFeedBackContentType.ARTICLE}))}
          text="İçeriği Değerlendir"
        />
      </Grid>
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        sx={{
          maxWidth: { xs: "100%", sm: "100%", md: "85%" },
          textAlign: "start",
          marginTop: "15%",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "40px",
            lineHeight: "48px",
            color: "#FFFFFF",
          }}
        >
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TextArticleHeader;
