import { FC } from "react";
import { Grid } from "@mui/material";
import CommonLabel from "./CommonLabel";
import CommonTextField from "./CommonTextField";
import CommonMultiLineTextField from "./CommonMultiLineTextField";
import { IPackagePaymentAddress } from "../utils/commonModels";
import CommonMaskedInput from "./CommonMaskedInput";

interface Props {
  setField: (e: IPackagePaymentAddress) => void;
  values: IPackagePaymentAddress;
}

const SubscriptionPaymentAddress: FC<Props> = ({ setField, values }) => {
  return (
    <Grid
      container
      columns={{ xs: 4, sm: 8, md: 12 }}
      spacing={3}
      style={{ marginTop: "24px" }}
    >
      <Grid item xs={4} sm={8} md={12}>
        <CommonLabel label="İsim Soyisim" />
        <CommonTextField
          _placeHolder="İsim soyisim giriniz"
          _onChange={(e: string) => setField({ ...values, nameSurname: e })}
          _value={values.nameSurname}
        />
      </Grid>
      <Grid item xs={4} sm={8} md={4}>
        <CommonLabel label="Ülke" />
        <CommonTextField
          _placeHolder="Ülke seçiniz"
          _onChange={(e: string) => setField({ ...values, country: e })}
          _value={values.country}
        />
      </Grid>
      <Grid item xs={4} sm={8} md={4}>
        <CommonLabel label="Şehir" />
        <CommonTextField
          _placeHolder="Şehir seçiniz"
          _onChange={(e: string) => setField({ ...values, city: e })}
          _value={values.city}
        />
      </Grid>
      <Grid item xs={4} sm={8} md={4}>
        <CommonLabel label="İlçe" />
        <CommonTextField
          _placeHolder="İlçe seçiniz"
          _onChange={(e: string) => setField({ ...values, county: e })}
          _value={values.county}
        />
      </Grid>
      <Grid item xs={4} sm={8} md={12}>
        <CommonLabel label="Fatura Adresi" />
        <CommonMultiLineTextField
          _onChange={(e: string) => setField({ ...values, invoiceAddress: e })}
          _value={values.invoiceAddress}
        />
      </Grid>
    </Grid>
  );
};

export default SubscriptionPaymentAddress;
