import { Typography } from "@mui/material";
import { FC } from "react";

interface Props {
  title: string;
  data: string;
}

const SubscriptionInformation: FC<Props> = ({ title, data }) => {
  return (
    <div
      style={{
        borderRadius: "16px",
        border: "1px solid #E3E9ED",
        background: "#FFFFFF",
        padding: "12px 20px",
      }}
    >
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "22px",
          color: "#78828A",
        }}
      >
        {title}
      </Typography>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontSize: "18px",
          fontWeight: 700,
          lineHeight: "26px",
          color: "#2574FF",
        }}
      >
        {data}
      </Typography>
    </div>
  );
};

export default SubscriptionInformation;
