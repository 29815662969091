import { FC } from "react";
import HeadingTitle from "./HeadingTitle";
import RegisterTextField from "./RegisterTextField";
import RegisterDatePicker from "./RegisterDatePicker";
import RegisterChildGenderListItem from "./RegisterChildGenderList";
import { ChildCreateModel, Gender } from "../client";

interface Props {
  onChildNameChanged:(e:any) => void
  onChildBirthdayChanged:(e:string) => void
  onChildGenderdayChanged:(e:Gender) => void
  children:ChildCreateModel
}

const RegisterSetChildren: FC<Props> = ({
  onChildNameChanged,
  onChildBirthdayChanged,
  onChildGenderdayChanged,
  children
}) => {
  return (
    <>
      <HeadingTitle title="Çocuğunuzun adı" _marginBottom="16px" />
      <RegisterTextField _placeHolder="Çocuğun adını gir" _onChange={(e) => onChildNameChanged(e)} _value={children?.name}/>
      <HeadingTitle
        title="Çocuğunuzun doğum tarihi"
        _marginBottom="16px"
        _marginTop="46px"
      />
      <RegisterDatePicker _placeHolder="Çocuğun doğum tarihini gir" _onChange={(e) => onChildBirthdayChanged(e)}/>
      <HeadingTitle
        title="Çocuğunuzun cinsiyeti"
        _marginBottom="16px"  
        _marginTop="46px"
      />
      <RegisterChildGenderListItem selectedName={children?.gender} _onClicked={(e:Gender) => onChildGenderdayChanged(e)} />
    </>
  );
};

export default RegisterSetChildren;
