import { Grid, Typography } from '@mui/material';
import { FC } from 'react'
import { Play,Book, Clock } from '../assests';

interface Props {
    description:string;
    video_article_count?:number;
    text_article_count?:number;
    watch_time_count?:number;
}

const ParentSolutionDetailDescription:FC<Props> = ({
    description="",
    video_article_count=0,
    text_article_count=0,
    watch_time_count=0
}) => {

    const containerStyle = {
        backgroundColor:"#F9F9F9",
        borderRadius:"24px",
        padding:"25px 30px"
    }

  return (
    <Grid container columns={{xs:4, sm:8, md:12}} style={containerStyle}>
        <Grid item xs={4} sm={8} md={12} style={{textAlign:"start",marginBottom:"15px"}}>
            <Typography style={{fontFamily:"Montserrat",fontWeight:"700",fontSize:"24px",lineHeight:"32px",color:"#0D0C0F"}}>
                Ders Açıklaması
            </Typography>
        </Grid>
        <Grid item xs={4} sm={8} md={12} style={{textAlign:"start", marginBottom:"15px"}}>
            <Typography style={{fontFamily:"Montserrat", fontSize:"15px",fontWeight:"500",lineHeight:"23px",color:"#434E58"}}>
                {description}
            </Typography>
        </Grid>
        <Grid item xs={4} sm={8} md={12}>
            <div style={{display:"flex",marginBottom:"10px"}}>
                <img src={Play} alt="" style={{marginRight:"8px"}}/>
                <Typography style={{fontFamily:"Montserrat", fontWeight:"700",fontSize:"16px",lineHeight:"24px",color:"#2574FF"}}>
                    {video_article_count} Video
                </Typography>
            </div>
            <div style={{display:"flex",marginBottom:"10px"}}>
                <img src={Book} alt="" style={{marginRight:"8px"}}/>
                <Typography style={{fontFamily:"Montserrat", fontWeight:"700",fontSize:"16px",lineHeight:"24px",color:"#2574FF"}}>
                    {text_article_count} Makale
                </Typography>
            </div>
            <div style={{display:"flex",marginBottom:"10px"}}>
                <img src={Clock} alt="" style={{marginRight:"8px"}} />
                <Typography style={{fontFamily:"Montserrat", fontWeight:"700",fontSize:"16px",lineHeight:"24px",color:"#2574FF"}}>
                    {watch_time_count}'
                </Typography>
            </div>
        </Grid>
    </Grid>
  )
}

export default ParentSolutionDetailDescription