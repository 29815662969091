import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IyzicoSaveExternalNewCard, PricingOptionModel, PurchaseService, SavedCard } from "../client";

export const getMySavedCards = createAsyncThunk(
    'purchase/getMySavedCards',
    async () => {
        try {
            return await PurchaseService.getSavedCardsPurchaseGetSavedCardsGet()
        } catch (error: any) {
            throw new Error(error?.message)
        }
    }
)

export const addNewCard = createAsyncThunk(
    'purchase/addNewCard',
    async (payload: IyzicoSaveExternalNewCard, { rejectWithValue }) => {
        try {
            return await PurchaseService.saveNewPaymentCardPurchaseSaveNewCardPost(payload)
        } catch (error: any) {
            return rejectWithValue(error?.message)
            // throw new Error(error?.message)
        }
    }
)

export const deletePaymentCard = createAsyncThunk(
    'purchase/deletePaymentCard',
    async (payload: { cardBinNumber: string, cardLastFourDigits: string }) => {
        try {
            return await PurchaseService.deleteSavedCardPurchaseDeleteSavedCardDelete(payload.cardBinNumber, payload.cardLastFourDigits)
        } catch (error: any) {
            throw new Error(error?.message)
        }
    }
)

export const getAvailablePurchasePackages = createAsyncThunk(
    'purchase/getAvailablePurchasePackages',
    async() => {
        try {
            return await PurchaseService.getCurrentPricingOptionsPurchaseGetCurrentPricingOptionsGet()
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

export const getGiftDiscountRate = createAsyncThunk(
    'purchase/getGiftDiscountRate',
    async() => {
        try {
            return await PurchaseService.getDiscountPercentageInGiftPurchasePurchaseGetDiscountPercentageInGiftPurchaseGet()
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

interface IInitialState {
    loading: boolean
    error: string
    saved_cards: Array<SavedCard>
    save_card_error: string
    pricing_options: Array<PricingOptionModel>
    gift_discount_rate: number
}

const initialState: IInitialState = {
    loading: false,
    error: "",
    saved_cards: [] as Array<SavedCard>,
    save_card_error: "",
    pricing_options: [] as Array<PricingOptionModel>,
    gift_discount_rate: 1
}

export const purchaseSlice = createSlice({
    name: "purchase",
    initialState,
    reducers: {
        setSaveCardError(state) {
            state.save_card_error = ""
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getMySavedCards.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(getMySavedCards.fulfilled, (state, action) => {
            state.loading = false
            state.error = ''
            state.saved_cards = action.payload
        })
        builder.addCase(getMySavedCards.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
        builder.addCase(addNewCard.pending, (state) => {
            state.loading = true
            state.save_card_error = ''
        })
        builder.addCase(addNewCard.fulfilled, (state, action) => {
            if (action.payload && !action?.payload.is_request_successful) {
                state.save_card_error = action?.payload.error_detail || ""
            }
            state.loading = false
        })
        builder.addCase(addNewCard.rejected, (state, action) => {
            state.loading = false
            state.save_card_error = 'Beklenmedik bir hata meydana geldi. Bizimle iletişime geçin.'
        })
        builder.addCase(deletePaymentCard.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(deletePaymentCard.fulfilled, (state, action) => {
            if (!action?.payload.is_request_successful) {
                state.error = action?.payload.error_detail || ""
            } else {
                state.saved_cards = state.saved_cards.filter((e) => e.card_bin_number != action.meta.arg.cardBinNumber && e.card_last_four_digits != action.meta.arg.cardLastFourDigits)
            }
            state.loading = false
        })
        builder.addCase(deletePaymentCard.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })


        builder.addCase(getAvailablePurchasePackages.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(getAvailablePurchasePackages.fulfilled, (state, action) => {
            const packages = action.payload.filter(e => e.id != "weekly" && e.id != "life_time")
            state.pricing_options = packages.sort((a,b) => (a.order ? a.order : 0) - (b.order ? b.order : 0))
            state.loading = false
        })
        builder.addCase(getAvailablePurchasePackages.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })

        builder.addCase(getGiftDiscountRate.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(getGiftDiscountRate.fulfilled, (state, action) => {
            state.gift_discount_rate = action.payload.discount_percentage
            state.loading = false
        })
        builder.addCase(getGiftDiscountRate.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
    }
})

export const { setSaveCardError } = purchaseSlice.actions
export default purchaseSlice.reducer