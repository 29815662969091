import { Container, Grid } from "@mui/material";
import { FC, useEffect, useState } from "react";
import {
  BulletinListingItem,
  CommonLoading,
  HeadingTitle,
  LoadMorePaginationButton,
} from "../components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { OpenAPI } from "../client";
import { getBulettins } from "../redux/bulettinSlice";

const Bulletins: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { bulettin, loading: bulettin_loading, lastDocumentId } = useSelector(
    (state: RootState) => state.bulettin
  );
  const { access_token } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (OpenAPI.TOKEN == undefined && access_token != "") {
      OpenAPI.TOKEN = access_token;
    }

    if (bulettin.length == 0) {
      dispatch(
        getBulettins({ paginationItemCount: 20, lastDocumentId: lastDocumentId })
      );
    }
  }, []);

  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <CommonLoading loading={bulettin_loading} />
      <Grid
        container
        columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }}
        spacing={2}
        rowSpacing={"30px"}
      >
        <Grid item xs={4} sm={8} md={12} lg={12} style={{ textAlign: "start" }}>
          <HeadingTitle title="Bu Haftanın Bülteni" _marginBottom="0px" />
        </Grid>
        {bulettin.length > 0 && (
          <Grid item xs={4} sm={8} md={3} lg={3}>
            <BulletinListingItem
              id={bulettin[0].id}
              title={bulettin[0].title}
              is_picture_uploaded={
                bulettin[0].is_picture_uploaded
                  ? bulettin[0].is_picture_uploaded
                  : false
              }
            />
          </Grid>
        )}
        <Grid
          item
          xs={4}
          sm={8}
          md={12}
          lg={12}
          style={{
            marginTop: "28px",
            textAlign: "start",
          }}
        >
          <HeadingTitle title="Tüm Bültenler" _marginBottom="0px" />
        </Grid>
        {!bulettin_loading &&
          bulettin.map((e, index) => {
            return (
              <Grid item xs={4} sm={8} md={3} key={index}>
                <BulletinListingItem
                  id={e.id}
                  title={e.title}
                  is_picture_uploaded={
                    e.is_picture_uploaded ? e.is_picture_uploaded : false
                  }
                />
              </Grid>
            );
          })}
          <Grid item xs={4} sm={8} md={12} style={{ marginTop: "10px" }}>
          {!bulettin_loading && lastDocumentId? (
            <LoadMorePaginationButton
              text="Daha Fazla Yükle"
              onClick={() =>
                dispatch(
                    getBulettins({ paginationItemCount: 20, lastDocumentId: lastDocumentId })
                )
              }
            />
          ) : null}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Bulletins;
