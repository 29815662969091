import { Typography, Box } from "@mui/material";
import { FC } from "react";
import CommonButton from "./CommonButton";
import { ArrowRight, BlueBackgroundManKid, TickSolidBlue, TickSolidRed } from "../assests";
import { LearnWithQuestionRelatedContentType } from "../client";
import { Buckets } from "../utils/constants";
import { useNavigate } from "react-router";

interface Props {
  is_answer_correct: boolean;
  related_content_type?: LearnWithQuestionRelatedContentType;
  related_content_id?: string;
  related_content_name?:string;
  answer_explained?:string
  nextQuestion: () => void
}

const LearnWithQuestionResultItem: FC<Props> = ({
  is_answer_correct = false,
  related_content_type = null,
  related_content_id = null,
  related_content_name = null,
  answer_explained = null,
  nextQuestion
}) => {

  const navigate = useNavigate()

  function customBucketPicker(){
    try {
      if(related_content_type == null){
        return ""
      }

      switch (related_content_type) {
        case LearnWithQuestionRelatedContentType.ARTICLE:
          return `${Buckets.ArticleImageBucket}/${related_content_id}.jpg`
        case LearnWithQuestionRelatedContentType.TRAINING_SET:
          return `${Buckets.ParentSolutionBucket}/${related_content_id}.jpg`
        case LearnWithQuestionRelatedContentType.SURVEY:
          return ""
        case LearnWithQuestionRelatedContentType.PARENT_SCHOOL_MODULE:
          return ""
        case LearnWithQuestionRelatedContentType.PARENT_SCHOOL_MODULE_CONTENT:
          return `${Buckets.ArticleImageBucket}/${related_content_id}.jpg`
        default:
          return "";
      }
    } catch (error) {
      return ""
    }
  }

  function redirectContent(){
    try {
      if(related_content_type == null){
        return
      }
      switch (related_content_type) {
        case LearnWithQuestionRelatedContentType.ARTICLE:
          navigate(`/article/${related_content_id}`)
          return
        case LearnWithQuestionRelatedContentType.TRAINING_SET:
          navigate(`/parentsolution/${related_content_id}`)
          return
        case LearnWithQuestionRelatedContentType.SURVEY:
          navigate(`/survey/${related_content_id}`)
          return
        case LearnWithQuestionRelatedContentType.PARENT_SCHOOL_MODULE:
          navigate('/parentschool')
          return
        case LearnWithQuestionRelatedContentType.PARENT_SCHOOL_MODULE_CONTENT:
          navigate('/parentschool')
          return
        default:
          return
      }
      
    } catch (error) {
      return
    }
  }

  return (
    <Box
      component="div"
      sx={{
        width: {xs:"90%", sm:"90%", md:"70%"},
        background: `url(${BlueBackgroundManKid}), #2574FF`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        borderRadius: "16px",
        padding: {xs:"20px 20px", sm:"20px 20px",md:"40px 31px"},
        marginTop: "20px",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          background: "#FFFFFF",
          borderRadius: "16px",
          width:"100%"
        }}
      >
        <div
          style={{
            background: is_answer_correct
              ? "rgba(37, 116, 255, 0.2)"
              : "rgba(250, 65, 105, 0.25)",
            borderRadius: "16px 16px 0px 0px",
            display:"flex",
            textAlign:"center",
            justifyContent:"center"
          }}
        >
          <img src={is_answer_correct ? TickSolidBlue : TickSolidRed} alt="" style={{marginRight:"5px"}} />
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "700",
              fontSize: "20px",
              opacity: 1,
              lineHeight: "24px",
              color: is_answer_correct ? "#2574FF" : "#FA4169",
              paddingTop: "23px",
              paddingBottom: "23px",
            }}
          >
            {is_answer_correct ? "Doğru Cevap" : "Yanlış Cevap"}
          </Typography>
        </div>

        <div
          style={{
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "17px",
              lineHeight: "25px",
              color: "#272755",
              marginTop: "30px",
              marginBottom: "30px",
            }}
          >
            {answer_explained}
          </Typography>

          <CommonButton
            text="Sonraki Soru"
            customCSS={{ marginBottom: "30px" }}
            onClick={nextQuestion}
          />
        </div>
      </div>
      {related_content_id != null && related_content_type != null ? (
        <>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "17px",
              lineHeight: "26px",
              color: "#F0F0F0",
              marginTop: "32px",
              marginBottom: "16px",
            }}
          >
            İlgili İçeriğe Gitmek İçin Tıkla
          </Typography>
          <Box component="div"
            sx={{
              background: "#FFFFFF",
              borderRadius: "16px",
              display: "flex",
              alignItems: "center",
              padding: "5px 4px",
              width: {xs:"90%",sm:"90%", md:"70%"},
              cursor:"pointer"
            }}
            onClick={() => redirectContent()}
          >
            <img
              src={customBucketPicker()}
              alt=""
              style={{
                width: "60px",
                height: "60px",
                background:
                  "linear-gradient(0deg, rgba(0, 0, 0, 0.26), rgba(0, 0, 0, 0.26))",
                borderRadius: "16px",
                marginRight: "12px",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "80%",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "15px",
                  lineHeight: "24px",
                  color: "#272755",
                  width: "85%",
                  textAlign: "start",
                }}
              >
                {related_content_name}
              </Typography>
              <img src={ArrowRight} alt="" />
            </div>
          </Box>
        </>
      ) : null}
    </Box>
  );
};

export default LearnWithQuestionResultItem;
