/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ProgressLogNameType {
    COMPLETED = 'completed',
    STARTED = 'started',
}
