import { FC } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { Cross, RateEmptyStar, RateFilledStar } from "../assests";
import CommonMultiLineTextField from "./CommonMultiLineTextField";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { addEvaluationFeedBack, setEvaluationData } from "../redux/generalControllerSlice";
import CommonLoading from "./CommonLoading";
import { UserFeedBackType,UserFeedBackOperatingSystem } from "../client";

interface Props {
  isOpen: boolean;
  onCancelButtonFunc: () => void;
}

const RateContentModal: FC<Props> = ({
  isOpen = false,
  onCancelButtonFunc,
}) => {
  const { evaluation_data,evaluate_submit_loading } = useSelector(
    (state: RootState) => state.generalController
  );
  const { profile } = useSelector((state:RootState) => state.auth)

  const dispatch = useDispatch<AppDispatch>();

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#FFFFFF",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const starTypeDefiner = (index: number) => {
    if (index <= evaluation_data.rate) {
      return RateFilledStar;
    } else {
      return RateEmptyStar;
    }
  };

  const onStarClicked = (index: number) => {
    dispatch(setEvaluationData({ rate: index, text: evaluation_data.text }));
  };

  return (
    <Modal
      open={isOpen}
      onClose={onCancelButtonFunc}
      style={{
        backdropFilter: "blur(2px)",
        background: "rgba(39, 39, 85, 0.6)",
      }}
    >
      <Box sx={style}>
        <CommonLoading loading={evaluate_submit_loading} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            width: "100%",
            marginTop: "33px",
          }}
        >
          <img
            src={Cross}
            alt=""
            style={{ marginRight: "30px", cursor: "pointer" }}
            width={"20px"}
            onClick={() => onCancelButtonFunc()}
          />
        </div>
        <div
          style={{ width: "100%", padding: "0px 27px", textAlign: "center" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <img
              src={starTypeDefiner(1)}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => onStarClicked(1)}
            />
            <img
              src={starTypeDefiner(2)}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => onStarClicked(2)}
            />
            <img
              src={starTypeDefiner(3)}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => onStarClicked(3)}
            />
            <img
              src={starTypeDefiner(4)}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => onStarClicked(4)}
            />
            <img
              src={starTypeDefiner(5)}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => onStarClicked(5)}
            />
          </div>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "400",
              fontSize: "15px",
              lineHeight: "24px",
              marginBottom: "24px",
              color: "#0D0C0F",
              opacity: 0.75,
              marginTop: "10px",
            }}
          >
            5 üzerinden değerlendirin
          </Typography>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "20px",
              lineHeight: "28px",
              marginBottom: "24px",
              color: "#0D0C0F",
            }}
          >
            İçeriği Nasıl Buldunuz?
          </Typography>
          <div style={{ width: "350px", marginBottom: "32px" }}>
            <CommonMultiLineTextField
              _placeHolder="Bir geri bildirim yazın"
              _value={evaluation_data.text}
              _onChange={(e: string) =>
                dispatch(
                  setEvaluationData({ rate: evaluation_data.rate, text: e })
                )
              }
            />
          </div>
          <Button
            variant="outlined"
            style={{
              marginBottom: "32px",
              border: "1px solid #0D0C0F",
              borderRadius: "12px",
              padding: "12px 27px",
            }}
            onClick={() => dispatch(addEvaluationFeedBack({
              rate:evaluation_data.rate,
              text:evaluation_data.text,
              type:UserFeedBackType.EVALUATE,
              content_id:evaluation_data.content_id,
              content_type:evaluation_data.content_type,
              user_mail:profile?.email,
              operating_system:UserFeedBackOperatingSystem.WEB_OS
            }))}
            disabled={evaluation_data.rate == 0 ? true : false}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                textTransform: "none",
                color: "#0D0C0F",
              }}
            >
              Geri Bildirim Gönder
            </Typography>
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default RateContentModal;
