import { Container, Grid, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import {
  ArrowDownGrey,
  BlueFamily,
  LandingAskQuestion,
  LandingAskQuestionBlue,
  LandingDevices,
  LandingDevicesBlue,
  LandingFamilyAccount,
  LandingFamilyAccountBlue,
  LandingNoAds,
  LandingNoAdsBlue,
  LandingParentSchool,
  LandingParentSchoolBlue,
  LandingPersonalize,
  LandingPersonalizeBlue,
  LandingRight,
  LandingSurvey,
  LandingSurveyBlue,
} from "../assests";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { OpenAPI } from "../client";
import { getAvailablePurchasePackages } from "../redux/purchaseSlice";
import { WebDiscountCoupon, WebDiscountRate } from "../utils/constants";
import LandingDiscountedPriceCard from "./LandingDiscountedPriceCard";

export   const featureList = [
  {
    icon: LandingParentSchool,
    text: "Anne Baba Okulu sadece Premium’da",
    iconSm: LandingParentSchoolBlue,
  },
  {
    icon: LandingSurvey,
    text: "Bilimsel Anketler sadece Premium’da",
    iconSm: LandingSurveyBlue,
  },
  {
    icon: LandingAskQuestion,
    text: "Ayda 1 kez Dr. Özgür Bolat ile Premium kullanıcılara özel soru cevap seansı",
    iconSm: LandingAskQuestionBlue,
  },
  {
    icon: LandingFamilyAccount,
    text: "Aile Üyeliği ile ikinci Premium hesap ücretsiz",
    iconSm: LandingFamilyAccountBlue,
  },
  {
    icon: LandingNoAds,
    text: "Reklamları kaldırın",
    iconSm: LandingNoAdsBlue,
  },
  {
    icon: LandingPersonalize,
    text: "Kişiselleştirilmiş içerik",
    iconSm: LandingPersonalizeBlue,
  },
  {
    icon: LandingDevices,
    text: "Tüm cihazlardan erişim (Bilgisayar, Tablet, Telefon) ",
    iconSm: LandingDevicesBlue,
  },
];

const LandingPackageSection: FC = () => {
  const { loading, pricing_options } = useSelector(
    (state: RootState) => state.purchase
  );
  const { access_token } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (OpenAPI.TOKEN === undefined && access_token !== "") {
      OpenAPI.TOKEN = access_token;
    }
    dispatch(getAvailablePurchasePackages());
  }, []);

  return (
    <Container maxWidth="lg">
      <Grid
        container
        columns={{ xs: 4, sm: 8, md: 12 }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          xs={4}
          sm={8}
          md={12}
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
            marginTop: "86px",
            marginBottom: "160px",
            justifyContent: "center",
          }}
        >
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#BFC6CC",
              textTransform: "none",
            }}
          >
            Aşağı Kaydır
          </Typography>
          <img src={ArrowDownGrey} alt="" />
        </Grid>
        <Grid item xs={4} sm={8} md={12} style={{ marginBottom: "32px" }}>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontWeight: { xs: 500, sm: 500, md: 700 },
              fontSize: { xs: "24px", sm: "24px", md: "52px" },
              lineHeight: { xs: "41px", sm: "41px", md: "60px" },
              color: { xs: "#66707A", sm: "#66707A", md: "#061C3D" },
            }}
          >
            Web sitemize özel:
          </Typography>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontWeight: 700,
              fontSize: { xs: "42px", sm: "42px", md: "52px" },
              lineHeight: { xs: "48px", sm: "48px", md: "60px" },
              color: "#0EAD69",
              textAlign: { xs: "start", sm: "start", md: "center" },
            }}
          >
            12 ay taksit İmkanı
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sm={8}
          md={0}
          sx={{ display: { xs: "flex", sm: "flex", md: "none" }, flexDirection:"column", padding: "7px"}}
        >
          {featureList.map((e) => (
            <div
              style={{
                display: "flex",
                textAlign: "start",
                alignItems: "center",
                marginBottom:"10px"
              }}
            >
              <img
                src={e.iconSm}
                alt=""
                style={{
                  width: "24.15px",
                  height: "24.15px",
                  marginRight: "14px",
                }}
              />
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "24px",
                  color: "#000000",
                }}
              >
                {e.text}
              </Typography>
            </div>
          ))}
        </Grid>
        <Grid
          item
          xs={4}
          sm={8}
          md={12}
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{
            border: { xs: "", sm: "", md: "1px solid #E4E4E4" },
            borderRadius: { xs: "0px", sm: "0px", md: "20px" },
          }}
        >
          <Grid
            item
            xs={4}
            sm={8}
            md={6}
            sx={{ padding: { xs: "0px", sm: "0px", md: "48px" } }}
            className="scrollPoint"
          >
            {pricing_options.map((e) => (
              <Grid item xs={4} sm={8} md={12}>
                <LandingDiscountedPriceCard
                  price={e.price}
                  id={e.id}
                  isHighlighted={e?.is_highlighted ? e.is_highlighted : false}
                  name={e.name}
                />
              </Grid>
            ))}
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontWeight: 400,
                fontSize: { xs: "14px", sm: "14px", md: "18px" },
                lineHeight: { xs: "26px", sm: "26px", md: "26px" },
                color: "#061C3D",
                textAlign: { xs: "start", sm: "start", md: "start" },
              }}
            >
              Dilediğin zaman iptal edebilirsin.
            </Typography>
          </Grid>
          <Grid item xs={4} sm={8} md={0} sx={{display:{sx:"flex", sm:"flex", md:"none"}}}>
              <img
                src={LandingRight}
                alt=""
                style={{ width:"100%" }}
              />
          </Grid>
          <Grid
            item
            xs={0}
            sm={0}
            md={6}
            sx={{
              backgroundImage: `url(${BlueFamily})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: "#2574FF",
              padding: "42px",
              display: { xs: "none", sm: "none", md: "flex" },
              flexDirection: "column",
              justifyContent: "space-around",
              borderRadius:"0px 20px 20px 0px"
            }}
          >
            {featureList.map((e) => (
              <div
                style={{
                  display: "flex",
                  textAlign: "start",
                  alignItems: "center",
                }}
              >
                <img
                  src={e.icon}
                  alt=""
                  style={{
                    width: "41.47px",
                    height: "41.47px",
                    marginRight: "24px",
                  }}
                />
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "27px",
                    color: "#FFFFFF",
                  }}
                >
                  {e.text}
                </Typography>
              </div>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LandingPackageSection;
