/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum MarriageStatus {
    MARRIED = 'married',
    SINGLE = 'single',
    DIVORCED = 'divorced',
    OTHER = 'other',
}
