import { Button, Typography } from "@mui/material"
import { FC } from "react"

interface Props {
    text:string;
    fontSize?:string;
    onClick?: () => void;
    disabled?:boolean;
    textColor?:string;
    customCSS?: {};
}

const CommonButton:FC<Props> = ({text="", onClick,fontSize="18px",disabled=false,customCSS,textColor}) => {

    const buttonStyle = {
        backgroundColor: disabled ? "#BFC6CC" :"#2574FF",
        paddingTop:"16px",
        paddingBottom:"16px",
        borderRadius:"16px",
        ...customCSS
    }

  return (
    <Button style={buttonStyle} onClick={onClick} type="submit" fullWidth disabled={disabled}>
        <Typography style={{
            fontSize:fontSize == null || undefined ? "18px" : fontSize,
            fontWeight:"600",
            lineHeight:"26px",
            fontFamily:"Montserrat",
            color:textColor == null || undefined ? "#F9F9F9" : textColor,
            textTransform:"none",
        }}>
            {text}
        </Typography>
    </Button>
  )
}

export default CommonButton