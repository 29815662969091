import { FC, useEffect, useState } from "react";
import { NameLogoBlue } from "../assests";
import { Container, Divider, Grid } from "@mui/material";
import {
  CommonLoading,
  DetailBackButton,
  DetailShareButton,
  DetailFavoriteButton,
  SurveyStart,
  SurveyQuestion,
  CommonLightButton,
  CommonButton,
  HeadingTitle,
} from "../components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { CompleteSurveyAnswer, FavoriteType, OpenAPI } from "../client";
import { useNavigate, useParams } from "react-router";
import { getSurvey } from "../redux/surveySlice";
import { submitSurvey } from "../redux/logSlice";
import {
  addItemToFavorite,
  removeItemFromFavorite,
} from "../redux/favoriteSlice";
import { openShareContentModal } from "../redux/generalControllerSlice";

const Survey: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { loading, single_survey } = useSelector(
    (state: RootState) => state.survey
  );
  const { loading: logLoading } = useSelector((state: RootState) => state.log);
  const { access_token, profile } = useSelector(
    (state: RootState) => state.auth
  );
  const { favorite_items } = useSelector((state: RootState) => state.favorite);
  const dispatch = useDispatch<AppDispatch>();

  const [localAnswers, setLocalAnswers] = useState<Array<CompleteSurveyAnswer>>(
    [] as Array<CompleteSurveyAnswer>
  );

  const [currentQuestionOrder, setCurrentQuestionOrder] = useState<number>(0);

  useEffect(() => {
    if (OpenAPI.TOKEN == undefined && access_token != "") {
      OpenAPI.TOKEN = access_token;
    }
    if (id != undefined || id != null) {
      dispatch(getSurvey({ survey_token: id }));
    } else {
      navigate(-1);
    }
  }, []);

  const setAnswerLocally = (question_id: number, answer_id: number) => {
    if (localAnswers.length == 0) {
      const firstItemInArr = [
        {
          question_id: question_id,
          answer_id: answer_id,
        },
      ];
      setLocalAnswers(firstItemInArr);
      return;
    }

    if (localAnswers.find((e) => e.question_id == question_id)) {
      const relatedIndex = localAnswers.findIndex(
        (e) => e.question_id == question_id
      );
      let duplicatedAnswers = localAnswers;
      duplicatedAnswers[relatedIndex] = {
        question_id: question_id,
        answer_id: answer_id,
      };
      setLocalAnswers([...duplicatedAnswers]);
      return;
    }

    setLocalAnswers([
      ...localAnswers,
      {
        question_id: question_id,
        answer_id: answer_id,
      },
    ]);
    return;
  };

  const saveAnswer = async () => {
    if (single_survey.questions.length - 1 !== currentQuestionOrder) {
      setCurrentQuestionOrder(currentQuestionOrder + 1);
    } else {
      const temp_id = `${profile.id}--${single_survey.survey_id}`;
      const currentDate = new Date();
      const isoDateFormat = currentDate.toISOString();
      await dispatch(
        submitSurvey({
          child_id: undefined,
          survey_token: id ? id : "",
          answers: localAnswers,
          survey_title: single_survey.survey_title,
          survey_description: single_survey.survey_description,
          survey_id: single_survey.survey_id,
          temp_id: temp_id,
          completed_at: isoDateFormat,
        })
      );
      navigate(`/survey-result/${temp_id}/${isoDateFormat}`);
    }
  };

  const goBack = () => {
    if (currentQuestionOrder != 0) {
      setCurrentQuestionOrder(currentQuestionOrder - 1);
    }
  };

  const goBackHeader = () => {
    if (currentQuestionOrder != 0) {
      setCurrentQuestionOrder(currentQuestionOrder - 1);
    } else {
      navigate("/surveys");
    }
  };

  const addContentToFavorite = async () => {
    try {
      dispatch(
        addItemToFavorite({
          id_of_favorite: id ? id : "",
          name_of_favorite: single_survey.survey_title,
          favourite_type: FavoriteType.SURVEY,
        })
      );
    } catch (error: any) {
      console.log("err", error);
    }
  };

  const removeContentFromFavorite = () => {
    try {
      dispatch(
        removeItemFromFavorite({
          favorite_item_id: id ? id : "",
        })
      );
    } catch (error) {}
  };

  return (
    <>
      <img
        src={NameLogoBlue}
        alt=""
        style={{ marginTop: "20px", marginBottom: "20px" }}
      />
      <Divider style={{ width: "100%", border: "1px solid #E9EBED" }} />
      <CommonLoading loading={loading || logLoading} />
      <Container
        maxWidth="md"
        style={{ marginTop: "40px", marginBottom: "40px"}}
      >
        {!loading && Object.keys(single_survey).length > 3 ? (
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid
              item
              xs={4}
              sm={8}
              md={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <DetailBackButton
                onClick={goBackHeader}
                customCss={{
                  height: "50px",
                  borderRadius: "16px",
                  padding: "1% 2%",
                }}
              />
              <HeadingTitle
                title={`Soru ${currentQuestionOrder + 1}`}
                _marginBottom="0px"
              />
              <div style={{ display: "flex" }}>
                <DetailShareButton
                  onClick={() => dispatch(openShareContentModal())}
                  customCSS={{ height: "50px" }}
                />
                <DetailFavoriteButton
                  isLiked={
                    favorite_items.find(
                      (e) =>
                        e.favourite_type == FavoriteType.SURVEY && e.id == id
                    )
                      ? true
                      : false
                  }
                  onClick={
                    favorite_items.find(
                      (e) =>
                        e.favourite_type == FavoriteType.SURVEY && e.id == id
                    )
                      ? () => removeContentFromFavorite()
                      : () => addContentToFavorite()
                  }
                  customCSS={{ marginLeft: "5px", height: "50px" }}
                />
              </div>
            </Grid>
            <Grid item xs={4} sm={8} md={12}>
              {/* <SurveyStart title="" description="" totalQuestionCount={0} /> */}
              <SurveyQuestion
                question_id={
                  single_survey.questions[currentQuestionOrder].question_id
                }
                question={
                  single_survey.questions[currentQuestionOrder].question_content
                }
                answers={single_survey.questions[currentQuestionOrder].answers}
                onAnswerClicked={setAnswerLocally}
                localAnswers={localAnswers}
                percentage={
                  (100 * currentQuestionOrder + 1) /
                  single_survey.questions.length
                }
              />
            </Grid>
            <Grid
              item
              xs={4}
              sm={8}
              md={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "30px",
              }}
            >
              <CommonLightButton
                text="Geri"
                customCSS={{ width: "fit-content", padding: "14px 45px" }}
                onClick={goBack}
              />
              <CommonButton
                text="Devam Et"
                customCSS={{ width: "fit-content", padding: "14px 45px" }}
                disabled={
                  localAnswers.find(
                    (e) =>
                      e.question_id ==
                      single_survey.questions[currentQuestionOrder].question_id
                  )
                    ? false
                    : true
                }
                onClick={saveAnswer}
              />
            </Grid>
          </Grid>
        ) : null}
      </Container>
    </>
  );
};

export default Survey;
