/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum NotificationTarget {
    ARTICLE = 'article',
    SURVEY = 'survey',
    PARENT_SCHOOL_MODULE = 'parent_school_module',
    INFORMATIVE_TEXT = 'informative_text',
    TRAINING_SET = 'training_set',
    GAME_CARD = 'game_card',
    OPEN_LINK = 'open_link',
    LEARN_WITH_QUESTION = 'learn_with_question',
    BULLETIN = 'bulletin',
}
