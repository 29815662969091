import { useState, useEffect } from "react"
import { TrainingSetArticleItem } from "../client";

export default function UseVideoPageTimeCounter() {
  const [count, setCount] = useState<number>(0)

  useEffect(() => {
    const id = setInterval(() => setCount((oldCount) => oldCount + 1), 1000);
    return () => {
      clearInterval(id);
    };
  }, [])

  useEffect(() => {
    setCount(0)
  }, [])

  const resetCounter = () => {
    setCount(0)
  }

  return {
    count, resetCounter
  }
}