import { Container, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { CameraPurple, GreenBook, OrangeQuestion, OzgurBolatYellowBg, PinkTeacher } from "../assests";
import LandingContentCard from "./LandingContentCard";

const LandingContentSummary: FC = () => {
  const cardData = [
    {
      icon: CameraPurple,
      color: "#F1EBFF",
      title: "300+",
      text: "VİDEO",
    },
    {
      icon: GreenBook,
      color: "#E7F5E8",
      title: "200+",
      text: "MAKALE",
    },
    {
      icon: OrangeQuestion,
      color: "#FFF9EE",
      title: "300+",
      text: "SOHBET KARTI",
    },
    {
      icon: PinkTeacher,
      color: "#FEEDFA",
      title: "52 HAFTA",
      text: "ANNE BABA OKULU",
    },
  ];

  return (
    <Container maxWidth="lg" sx={{marginTop:{xs:"40px", sm:"40px", md:"100px"}, marginBottom:"100px"}}>
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid
          item
          xs={4}
          sm={8}
          md={8}
          style={{
            textAlign: "start",
            display: "flex",
            flexDirection: "column",
            alignItems:"start",
          }}
        >
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "700",
              fontSize: "42px",
              lineHeight: "60px",
              color: "#0D0C0F",
              marginBottom: "24px",
            }}
          >
            Dr. Özgür Bolat videolarıyla ve makaleleriyle ParentWiser’da
          </Typography>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "400",
              fontSize: "18px",
              lineHeight: "26px",
              color: "#42526B",
              marginBottom: "24px",
            }}
          >
            Çocuğunuzla yaşadığınız veya çocuğunuzda çözmek istediğiniz
            sorunlarla ilgili çözüm odaklı eğitimler alın.
          </Typography>
          <Grid item xs={4} sm={8} md={0} sx={{
              display:{xs:"flex", sm:"flex", md:"none"},
              alignItems:"center",
              justifyContent:"center",
              marginBottom:"20px"
          }}>
          <img
            src={OzgurBolatYellowBg}
            style={{
              width: "100%",
            }}
          />
          </Grid>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={3} sx={{
            width:{xs:"100%", sm:"100%", md:"95%"}
          }}>
            {cardData.map((e) => (
              <Grid item xs={2} sm={4} md={6}>
                <LandingContentCard
                  icon={e.icon}
                  color={e.color}
                  title={e.title}
                  text={e.text}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={0} sm={0} md={4} sx={{
          display:{xs:"none", sm:"none", md:"flex"},
          alignItems:"center",
          justifyContent:"center"
        }}>
          <img
            src={OzgurBolatYellowBg}
            style={{
              width: "100%",
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default LandingContentSummary;
