import { Button, Typography } from "@mui/material";
import { FC } from "react";
import { ShareArrow } from "../assests";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store/store";
import { openShareContentModal } from "../redux/generalControllerSlice";

interface Props {
  _onClick: () => {};
}

const QuoteShareButton: FC<Props> = ({ _onClick }) => {
  
  const dispatch = useDispatch<AppDispatch>()

  const buttonContainerStyle = {
    borderRadius: "16px",
    backgroundColor: "#2574FF",
    padding: "16px 24px",
  };

  return (
    <Button
      onClick={() => _onClick()}
      style={buttonContainerStyle}
      startIcon={<img src={ShareArrow} alt="" />}
    >
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#F9F9F9",
          textTransform: "none",
        }}
        onClick={() => dispatch(openShareContentModal())}
      >
        Paylaş
      </Typography>
    </Button>
  );
};

export default QuoteShareButton;
