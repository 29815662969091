import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import { CircleEmpty, TickSolidWhite } from "../assests";

interface Props {
  is_highlighted: boolean;
  price: number;
  netPrice: number;
  onClicked: () => void;
  name: string;
}

const GiftPackageItem: FC<Props> = ({
  is_highlighted,
  price,
  netPrice,
  onClicked,
  name
}) => {
  return (
    <Grid
      container
      columns={{ xs: 4, sm: 8, md: 12 }}
      style={{
        backgroundColor: is_highlighted ? "#2574FF" : "#F9F9F9",
        padding: "14px 16px",
        border: is_highlighted ? "1px solid #2574FF" : "1px solid #E9EBED",
        borderRadius: "16px",
        cursor: "pointer",
      }}
      onClick={onClicked}
    >
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{
          textAlign: "start",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "24px",
            color: is_highlighted ? "#FFFFFF" : "#171725",
          }}
        >
          {name}
        </Typography>
      </Grid>
      <Grid item xs={3} sm={7} md={10} style={{ textAlign: "start" }}>
        {price.toString().split(".").length == 1 ? (
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 700,
              fontSize: "14px",
              color: is_highlighted ? "#ECF1F6" : "#9CA4AB",
              textDecoration: "line-through",
            }}
          >
            ₺{price}
          </Typography>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
            }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "14px",
                color: is_highlighted ? "#ECF1F6" : "#9CA4AB",
                textDecoration: "line-through",
              }}
            >
              ₺{price.toString().split(".")[0]}
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: 600,
                fontSize: "10px",
                color: is_highlighted ? "#ECF1F6" : "#9CA4AB",
                textDecoration: "line-through",
              }}
            >
              .{price.toString().split(".")[1]}
            </Typography>
          </div>
        )}

        {netPrice.toString().split(".").length == 1 ? (
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 700,
              fontSize: "24px",
              color: is_highlighted ? "#FFFFFF" : "#2574FF",
            }}
          >
            ₺{netPrice}
          </Typography>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
            }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "24px",
                color: is_highlighted ? "#FFFFFF" : "#2574FF",
              }}
            >
              ₺{netPrice.toString().split(".")[0]}
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: 600,
                fontSize: "20px",
                color: is_highlighted ? "#FFFFFF" : "#2574FF",
              }}
            >
              .{netPrice.toString().split(".")[1]}
            </Typography>
          </div>
        )}
      </Grid>
      <Grid
        item
        xs={1}
        sm={1}
        md={2}
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
        }}
      >
        {is_highlighted ? (
          <img src={TickSolidWhite} />
        ) : (
          <img src={CircleEmpty} />
        )}
      </Grid>
    </Grid>
  );
};

export default GiftPackageItem;
