import { FC, useState } from "react";
import { Container, Grid, Tabs, Tab, Box } from "@mui/material";
import {
  MenuArticle,
  MenuArticleSelected,
  MenuParentSchool,
  MenuParentSchoolSelected,
  MenuParentSolution,
  MenuParentSolutionSelected,
  MenuPLogo,
  MenuPLogoSelected,
  MenuSurvey,
  MenuSurveySelected,
} from "../assests";
import {
  HeadingTitle,
  SettingsTabLabelItem,
  VideoPlayer,
  WhatIsArticle,
  WhatIsParentSchool,
  WhatIsParentSolution,
  WhatIsSurvey,
} from "../components";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, textAlign: "start" }}>{children}</Box>
      )}
    </div>
  );
}

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

const WhatIsParentWiser: FC = () => {
  const tabNamesConstant = [
    {
      name: "ParentWiser Nedir?",
      value: 0,
      icon: MenuPLogo,
      selectedIcon:MenuPLogoSelected
    },
    {
      name: "Anne Baba Okulu Nedir?",
      value: 1,
      icon: MenuParentSchool,
      selectedIcon:MenuParentSchoolSelected
    },
    {
      name: "Ebeveyn Çözümleri Nedir?",
      value: 2,
      icon: MenuParentSolution,
      selectedIcon:MenuParentSolutionSelected
    },
    {
      name: "Makaleler Nedir?",
      value: 3,
      icon: MenuArticle,
      selectedIcon: MenuArticleSelected
    },
    {
      name: "Anketler Nedir?",
      value: 4,
      icon: MenuSurvey,
      selectedIcon:MenuSurveySelected
    },
  ];
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container
      maxWidth="xl"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid
          item
          xs={4}
          sm={8}
          md={4}
          sx={{ textAlign: "start", borderRight: 1, borderColor: "divider" }}
        >
          <HeadingTitle title="ParentWiser Nedir?" />
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{
              "& .MuiTabs-indicator": { display: "none" },
              width:"100%"
            }}
          >
            {tabNamesConstant.map((item, index) => {
              return (
                <Tab
                  key={index}
                  disableRipple
                  label={
                    <SettingsTabLabelItem
                      isSelected={item.value == value}
                      text={item.name}
                      icon={item.value == value ? item.selectedIcon : item.icon}
                      isLastItem={index == tabNamesConstant.length - 1}
                    />
                  }
                  {...a11yProps(item.value)}
                />
              );
            })}
          </Tabs>
        </Grid>
        <Grid item xs={4} sm={8} md={8}>
          <TabPanel value={value} index={0}>
            <VideoPlayer url="https://storage.googleapis.com/keen-scion-372914-cache-bucket/what_is_parentwiser.mp4" />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <WhatIsParentSchool />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <WhatIsParentSolution />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <WhatIsArticle />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <WhatIsSurvey />
          </TabPanel>
        </Grid>
      </Grid>
    </Container>
  );
};

export default WhatIsParentWiser;
