import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ListOfUserNotifications, UserNotificationModel, UserService } from "../client";

export const getAllNotifications = createAsyncThunk(
    "notification/getAllNotifications",
    async(payload: {last_notification_id?: string}) => {
        try {
            return await UserService.getNotificationsUserGetNotificationsGet(payload.last_notification_id)
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

export const readNotification = createAsyncThunk(
    "notification/readNotification",
    async(payload:{notificationId:string}) => {
        try {
            return await UserService.setNotificationAsReadUserSetNotificationAsReadNotificationIdPut(payload.notificationId)
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

interface IInitialState {
    loading:boolean;
    error:string;
    notifications: ListOfUserNotifications
}

const initialState: IInitialState = {
    loading:false,
    error:"",
    notifications: {
        user_notifications: [] as UserNotificationModel[],
        last_notification_id: undefined
    }
} 

export const notificationSlice = createSlice({
    name:"notification",
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder.addCase(getAllNotifications.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getAllNotifications.fulfilled, (state, action) => {
            if(state.notifications.last_notification_id != undefined){
                state.notifications.user_notifications = [...state.notifications.user_notifications, ...action.payload.user_notifications]
            }else {
                state.notifications.user_notifications = action.payload.user_notifications
            }
            state.notifications.last_notification_id = action.payload.last_notification_id

            state.loading = false
            state.error = ''
        })
        builder.addCase(getAllNotifications.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ""
        })
        builder.addCase(readNotification.pending, (state, action) => {
            const readedNotificationIndex = state.notifications.user_notifications.findIndex((item) => item.id === (action.meta.arg.notificationId as string))
            state.notifications.user_notifications[readedNotificationIndex].is_readed = true
        })
        builder.addCase(readNotification.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(readNotification.rejected, (state, action) => {
            state.loading = false
        })
    }
})

export default notificationSlice.reducer