import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { ChildCreateModel, ChildModel, UserService } from "../client";
import { ChildPatchModel } from "../client";

export const getAllChildren = createAsyncThunk(
    'child/getAllChildren',
    async () => {
        try {
            return await UserService.getListOfChildrenUserChildrenGet()
        } catch (error: any) {
            throw new Error(error?.message)
        }
    }
)

export const updateSingleChild = createAsyncThunk(
    'child/updateSingleChild',
    async (payload: ChildPatchModel) => {
        try {
            return await UserService.patchChildUserChildChildIdPatch(payload)
        } catch (error: any) {
            throw new Error(error?.message)
        }
    }
)

export const removeChildFromUser = createAsyncThunk(
    'child/removeChildFromUser',
    async (payload: { child_id: string }) => {
        try {
            return await UserService.removeChildFromUserUserRemoveChildFromUserChildIdDelete(payload.child_id)
        } catch (error: any) {
            throw new Error(error?.message)
        }
    }
)

export const addChildWithList = createAsyncThunk(
    'child/addChildWithList',
    async (payload:{child_create_model_arr:ChildCreateModel[]}) => {
        try {
            return await UserService.createNewChildWithListUserChildAddWithListPost(payload.child_create_model_arr)
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

export const addSingleChild = createAsyncThunk(
    'child/addSingleChild',
    async (payload:{child:ChildCreateModel}) => {
        try {
            return await UserService.createNewChildUserChildPost(payload.child)
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

interface IInitialState {
    loading: boolean
    error: string
    children: Array<ChildModel>
    child: ChildModel
}

const initialState: IInitialState = {
    loading: false,
    error: "",
    children: [] as Array<ChildModel>,
    child:{} as ChildModel
}

export const childSlice = createSlice({
    name: "child",
    initialState,
    reducers: {
        updateChildLocally: (state, action) => {
            state.child = action.payload

        },
        setLocalChild: (state, action) => {
            state.child = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllChildren.pending, (state) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(getAllChildren.fulfilled, (state, action) => {
            state.children = action.payload.children
            state.loading = false
            state.error = ""
        })
        builder.addCase(getAllChildren.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
        builder.addCase(updateSingleChild.pending, (state) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(updateSingleChild.fulfilled, (state, action) => {
            // if (action.payload.is_request_successful) {
            //     const index = state.children.findIndex((e) => e.id == action.meta.arg.child_id)
            //     state.children[index].name = action.meta.arg.name
            //     state.children[index].date_of_birth = action.meta.arg.date_of_birth
            //     state.children[index].gender = action.meta.arg.gender
            // }
            state.loading = false
            state.error = ""
        })
        builder.addCase(updateSingleChild.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
        builder.addCase(removeChildFromUser.pending, (state) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(removeChildFromUser.fulfilled, (state, action) => {
            if (action.payload.is_request_successful) {
                const childs = current(state.children)
                const filteredChildArr = childs.filter((e) => e.id !== action.meta.arg.child_id)
                state.children = filteredChildArr
            }
            state.loading = false
            state.error = ""

        })
        builder.addCase(removeChildFromUser.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
        builder.addCase(addChildWithList.pending, (state) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(addChildWithList.fulfilled, (state, action) => {
            state.loading = false
            state.error = ""

        })
        builder.addCase(addChildWithList.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
        builder.addCase(addSingleChild.pending, (state) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(addSingleChild.fulfilled, (state, action) => {
            state.loading = false
            state.error = ""

        })
        builder.addCase(addSingleChild.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
    }
})

export default childSlice.reducer
export const { updateChildLocally,setLocalChild } = childSlice.actions