import { FC } from 'react'
import { TextField } from '@mui/material';
import { GrayCallendar } from '../assests';

interface Props {
    formName?: string;
    _disabled?: boolean;
    _value?: string | number;
    _defaultValue?: string | number;
    _type?: string;
    _placeHolder?: string;
    _onChange?: (e: any) => void;
  }

const ChildrenBirthdateInput:FC<Props> = ({
    formName,
    _disabled,
    _value,
    _defaultValue,
    _type,
    _placeHolder = "",
    _onChange,
}) => {
  return (
    <TextField
    id="outlined-basic"
    disabled={_disabled == null || undefined ? false : _disabled}
    required
    fullWidth
    type={"date"}
    name={formName}
    value={_value}
    defaultValue={_defaultValue}
    placeholder={_placeHolder}
    onChange={(e) => _onChange && _onChange(e.target.value)}
    sx={{
      "& fieldset": { border: "none" },
      input: {
        color: "#9CA4AB",
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "22px",
      },
      borderRadius: "8px",
      backgroundColor: "rgba(228, 228, 228, 0.25)",
    }}
    InputProps={{
        endAdornment:(
            <img src={GrayCallendar} alt="" style={{cursor:"pointer"}}/>
        )
    }}
  />
  )
}

export default ChildrenBirthdateInput