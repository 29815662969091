import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserFavoriteModel, UserService,CreateUserFavoriteModel } from "../client";
import { ReduxStoreStatus } from "../utils/constants";

export const getAllUserFavorites = createAsyncThunk(
    'favorite/getAllUserFavorites',
    async () => {
        try {
            return await UserService.getUserFavoritesUserUserFavoritesGet()
        } catch (error: any) {
            throw new Error(error?.message)
        }
    }
)

export const addItemToFavorite = createAsyncThunk(
    'favorite/addItemToFavorite',
    async (payload:IaddItemToFavorite) => {
        try {
            return await UserService.addUserFavoriteUserAddUserFavoritePost({favourite_type:payload.favourite_type, id_of_favorite:payload.id_of_favorite})
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

export const removeItemFromFavorite = createAsyncThunk(
    'favorite/removeItemFromFavorite',
    async(payload:{favorite_item_id:string}) => {
        try {
            return await UserService.deleteUserFavoriteUserUserFavoriteUserFavoriteIdDelete(payload.favorite_item_id)
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

interface IaddItemToFavorite extends CreateUserFavoriteModel {
    name_of_favorite:string
}

interface IInitialState {
    loading: boolean,
    error: string,
    favorite_items: UserFavoriteModel[],
    status:ReduxStoreStatus 
}

const initialState: IInitialState = {
    loading: false,
    error: "",
    favorite_items: [],
    status:ReduxStoreStatus.default
}

export const favoriteSlice = createSlice({
    name: "favorite",
    initialState,
    reducers: {
        logOutFavs (state){
            state.status=ReduxStoreStatus.default
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllUserFavorites.pending, (state) => {
            state.loading = true
            state.status = ReduxStoreStatus.default
            state.error = ''
        })
        builder.addCase(getAllUserFavorites.fulfilled, (state, action) => {
            state.loading = false
            state.error = ''
            state.favorite_items = action.payload.user_favorites
            state.status = ReduxStoreStatus.fetched
        })
        builder.addCase(getAllUserFavorites.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
            state.status = ReduxStoreStatus.default
        })
        builder.addCase(addItemToFavorite.pending, (state, {meta}) => {
            state.loading = true
            state.error = ''
            const _newFavorite:UserFavoriteModel = {
                id: meta.arg.id_of_favorite,
                favourite_type:meta.arg.favourite_type,
                name_of_favorite:meta.arg.name_of_favorite,
                created_at:new Date().toISOString()
            }

            state.favorite_items.unshift(_newFavorite)
        })
        builder.addCase(addItemToFavorite.fulfilled, (state,action) => {
            state.loading = false
        })
        builder.addCase(addItemToFavorite.rejected, (state,action) => {
            state.loading = false
            if(state.favorite_items.length > 0 && state.favorite_items[0].id == action.meta.arg.id_of_favorite){
                state.favorite_items = state.favorite_items.splice(0,1)
            }
        })
        builder.addCase(removeItemFromFavorite.pending, (state, {meta}) => {
            state.loading=true
            state.error = ''
            state.favorite_items = state.favorite_items.filter(item => item.id !== meta.arg.favorite_item_id)
        })
        builder.addCase(removeItemFromFavorite.fulfilled, (state,action) => {
            state.loading = false
        })
        builder.addCase(removeItemFromFavorite.rejected, (state,action) => {
            state.loading = false
        })
    }
})

export const {logOutFavs} = favoriteSlice.actions
export default favoriteSlice.reducer