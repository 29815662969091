import { Checkbox, Grid, Typography } from "@mui/material";
import { FC } from "react";
import {
  InstallmentTableChecked,
  InstallmentTableDefaultUnchecked,
} from "../assests";
import { InstallmentOptions } from "../client";

interface Props {
  options: Array<InstallmentOptions>;
  selectedInstallmenOption?:number
  onCheckboxClicked:(e:number) => void
  price?: string
}

const DynamicInstallmentTable: FC<Props> = ({ options,selectedInstallmenOption,onCheckboxClicked, price }) => {

  return (
    <Grid
      container
      columns={{ xs: 4, sm: 8, md: 12 }}
      style={{
        borderRadius: "16px",
        border: "1px solid #E9EBED",
        backgroundColor: "#F9F9F9",
      }}
    >
      <Grid
        item
        container
        xs={4}
        sm={8}
        md={12}
        style={{
          backgroundColor: "#F1F1F1",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
        }}
      >
        <Grid item xs={2} sm={4} md={6} style={{ padding: "9px 16px" }}>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "22px",
              color: "#78828A",
            }}
          >
            Taksit Sayısı
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sm={4}
          md={6}
          style={{ borderLeft: "1px solid #E3E9ED", padding: "9px 16px" }}
        >
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "22px",
              color: "#78828A",
            }}
          >
            Aylık Ödeme
          </Typography>
        </Grid>
      </Grid>
        {options.map((e) => (
            <Grid
            item
            container
            xs={4}
            sm={8}
            md={12}
            style={{ borderTop: "1px solid #E3E9ED" }}
          >
            <Grid
              item
              xs={2}
              sm={4}
              md={6}
              style={{
                padding: "9px 16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                icon={<img src={InstallmentTableDefaultUnchecked} alt="" />}
                checkedIcon={<img src={InstallmentTableChecked} alt="" />}
                style={{ padding: "0px", marginRight: "5px" }}
                onChange={() => onCheckboxClicked(e.installment_number)}
                checked={selectedInstallmenOption === e.installment_number ? true : false}
              />
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "22px",
                  color: "#78828A",
                }}
              >
                {e.installment_number === 1 ? "Tek Çekim" : e.installment_number+" Taksit"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sm={4}
              md={6}
              style={{ borderLeft: "1px solid #E3E9ED", padding: "9px 16px" }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "22px",
                  color: "#78828A",
                }}
              >
                {price} TL
              </Typography>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
};

export default DynamicInstallmentTable;
