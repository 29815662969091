import { FC, useState } from "react";
import { Grid, Typography, } from "@mui/material";
import CommonLoading from "./CommonLoading";
import SavedCardItem from "./SavedCardItem";
import AddCardBlankItem from "./AddCardBlankItem";
import CommonLabel from "./CommonLabel";
import CommonTextField from "./CommonTextField";
import CommonButton from "./CommonButton";
import CommonMaskedInput from "./CommonMaskedInput";
import { IyzicoSaveExternalNewCard } from "../client";
import { ISaveCustomCard } from "../utils/commonModels";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import {
  addNewCard,
  deletePaymentCard,
  getMySavedCards,
  setSaveCardError,
} from "../redux/purchaseSlice";
import CommonAlert from "./CommonAlert";
import { RedTrash } from "../assests";

const MySavedPaymentCards: FC = () => {
  const [mode, setMode] = useState<string>("Listing");
  const [newCardData, setNewCardData] = useState<ISaveCustomCard>({
    cardHolderName: "",
    cardNumber: "",
    expireDate: "",
  } as ISaveCustomCard);

  const dispatch = useDispatch<AppDispatch>();
  const { save_card_error, saved_cards } = useSelector(
    (state: RootState) => state.purchase
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const removeCardClicked = async(binNumber:string, lastFour:string) => {
    try {
      await dispatch(deletePaymentCard({cardBinNumber:binNumber, cardLastFourDigits:lastFour}))
    } catch (error) {}
  }

  const isSaveButtonDisable = () => {
    if (
      newCardData.cardNumber.replaceAll(" ", "").length == 16 &&
      newCardData.cardHolderName.trim().split(" ").length >= 2 &&
      newCardData.expireDate.replaceAll(" ", "").length == 5
    ) {
      return false;
    } else {
      return true;
    }
  };

  const saveNewCardSubmitted = async () => {
    try {
      const body = {
        cardHolderName: newCardData.cardHolderName,
        cardNumber: newCardData.cardNumber.replaceAll(" ", ""),
        expireMonth: newCardData.expireDate.split("/")[0],
        expireYear: "20" + newCardData.expireDate.split("/")[1],
      } as IyzicoSaveExternalNewCard;

      await dispatch(addNewCard(body));
      if (save_card_error == "") {
        await dispatch(getMySavedCards());
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={2}>
      <CommonLoading loading={false} />
      <CommonAlert
        _type={"warning"}
        _open={save_card_error == "" ? false : true}
        _onClose={() => dispatch(setSaveCardError())}
        _message={save_card_error}
      />
      <Grid item xs={4} sm={8} md={12}>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "28px",
            color: "#171725",
            marginBottom: "24px",
          }}
        >
          {mode == "Listing" ? "Kayıtlı Kartlarım" : "Yeni Kart Ekle"}
        </Typography>
      </Grid>
      {mode == "Listing" ? (
        <>
          {saved_cards.map((e) => (
            <Grid item xs={4} sm={8} md={5}>
              <SavedCardItem
                bankName={e.bank_name}
                cardType={e.card_association}
                lastFour={e.card_last_four_digits}
                binNumber={e.card_bin_number}
                onDotsMenuClicked={(e:React.MouseEvent<HTMLElement>) => handleClickListItem(e)}
                ancherOpen={open}
                _ancherEl={anchorEl}
                _setAnchorEl={setAnchorEl}
                removeCardTrigger={removeCardClicked}
              />
            </Grid>
          ))}
          <Grid item xs={4} sm={8} md={5}>
            <AddCardBlankItem _onClick={() => setMode("Create")} />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={4} sm={8} md={12}>
            <CommonLabel label="İsim Soyisim" />
            <CommonTextField
              _placeHolder="Kart üzerindeki ismi ve soyismi giriniz"
              _value={newCardData.cardHolderName}
              _onChange={(e: string) =>
                setNewCardData({ ...newCardData, cardHolderName: e })
              }
            />
          </Grid>
          <Grid item xs={4} sm={8} md={12}>
            <CommonLabel label="Kart Numarası" />
            <CommonMaskedInput
              _placeHolder="**** **** **** ****"
              _value={newCardData.cardNumber}
              _onChange={(e: string) =>
                setNewCardData({ ...newCardData, cardNumber: e })
              }
              _format={"#### #### #### ####"}
            />
          </Grid>
          <Grid item xs={4} sm={8} md={6}>
            <CommonLabel label="Son Kullanma Tarihi" />
            <CommonMaskedInput
              _placeHolder="Ay / Yıl"
              _value={newCardData.expireDate}
              _onChange={(e: string) =>
                setNewCardData({ ...newCardData, expireDate: e })
              }
              _format={"##/##"}
            />
          </Grid>
          <Grid item xs={4} sm={8} md={6}></Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={6}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CommonButton
              text="Kartı Kaydet"
              customCSS={{ width: "fit-content", padding: "16px 47px" }}
              disabled={isSaveButtonDisable()}
              onClick={saveNewCardSubmitted}
            />
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "26px",
                color: "#9CA4AB",
                cursor: "pointer",
              }}
              onClick={() => setMode("Listing")}
            >
              İptal
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default MySavedPaymentCards;
