/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IsRequestSuccessfulModel } from '../models/IsRequestSuccessfulModel';
import type { ListOfParents } from '../models/ListOfParents';
import type { routers__sebit_router__SebitAuthorizationDto } from '../models/routers__sebit_router__SebitAuthorizationDto';
import type { routers__sebit_router__SebitRegisterDto } from '../models/routers__sebit_router__SebitRegisterDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SebitService {

    /**
     * Authorize With Sebit
     * @param sebitToken
     * @returns routers__sebit_router__SebitAuthorizationDto Successful Response
     * @throws ApiError
     */
    public static authorizeWithSebitSebitAuthorizeSebitTokenGet(
        sebitToken: string,
    ): CancelablePromise<routers__sebit_router__SebitAuthorizationDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sebit/authorize/{sebit_token}',
            path: {
                'sebit_token': sebitToken,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Register With Sebit
     * @param requestBody
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static registerWithSebitSebitRegisterPost(
        requestBody: routers__sebit_router__SebitRegisterDto,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sebit/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Mba Users
     * @returns ListOfParents Successful Response
     * @throws ApiError
     */
    public static getMbaUsersSebitGetMbaUsersGet(): CancelablePromise<ListOfParents> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sebit/get-mba-users',
            errors: {
                404: `Not found`,
            },
        });
    }

}
