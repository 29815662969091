import { FC } from "react"
import HeadingTitle from "./HeadingTitle"
import RegisterTextField from "./RegisterTextField"

interface Props {
    name?:string
    onSetName:(e:string) => void
}

const RegisterSetName:FC<Props> = ({
    name="",
    onSetName,
}) => {
  return (
    <>
        <HeadingTitle title="Adın ve Soyadın" _marginBottom="32px" />
        <RegisterTextField _value={name} _placeHolder="Adını ve soyadını gir" _onChange={(e) => onSetName(e)}/>
    </>
  )
}

export default RegisterSetName