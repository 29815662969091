import { FC } from "react";
import { Typography } from "@mui/material";

const WhatIsParentSchool: FC = () => {
  const courseGains = [
    "Kendi ebeveynlik anlayışının temellerini keşfedecek",
    "Sorumluluk bilinci kazandırmayı öğrenecek",
    "Çocuğuna karşı sergilediği ebeveynlik davranışlarını analiz edecek",
    "Mutlu çocuk yetiştirmenin temel prensiplerini anlayacak",
    "Çocuğuyla gerçek sevgi bağı kurmayı öğrenecek",
    "Başarılı çocuk yetiştirmenin yöntemini öğrenecek",
    "Çocuklarıyla koşulsuz ilişki kurmayı öğrenecek",
    "Çocuklarda sorunların çıkmasını engelleyecek",
    "Birey olmanın prensiplerini öğrenecek",
  ];

  return (
    <>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "600",
          fontSize: "20px",
          lineHeight: "28px",
          color: "#171725",
        }}
      >
        Anne Baba Okulu Nedir?
      </Typography>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#78828A",
          marginTop: "30px",
        }}
      >
        Çocuğumuzla yaşadığımız sorunları çocuğumuzla ilişkimizi bozmadan çözmek
        önemli ama asıl önemli olan sorunların çıkmasını engellemek. Bunun için
        de ebeveynlerin çocuğu ile güçlü bir ilişki kurması gerekir. Örneğin
        çocuğumuzu dinlersek, çocuğumuz kendini duyurmak için ‘ağlama’ gereği
        duymayacak veya çocuğumuzun öfkesini sağlıklı şekilde ifade etmesini
        öğretirsek, ‘alt ıslatma’ sorunu çıkmayacak. Sorun temelli eğitimlere ek
        olarak, Anne Baba Okulunda ebeveynlerin daha yetkin olması için birçok
        modül bulunmaktadır.
      </Typography>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#78828A",
          marginTop: "23px",
          marginBottom: "23px",
        }}
      >
        Ebeveynler yıl boyunca alacağı dersler ile şu kazanımlara sahip
        olacaktır;
      </Typography>
      {courseGains.map((item, index) => {
        return (
          <div
            style={{
              display: "list-item",
              marginLeft: "25px",
              color: "#78828A",
              fontSize: "13px",
            }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#78828A",
                marginBottom: "3px",
              }}
              key={index}
            >
              {item}
            </Typography>
          </div>
        );
      })}
    </>
  );
};

export default WhatIsParentSchool;
