import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LearnWithQuestionItemModel, SetLearnWithQuestionAnswer, UserService } from "../client";

export const getLearnWithQuestions = createAsyncThunk(
    'learnWithQuestion/getLearnWithQuestions',
    async () => {
        try {
            return await UserService.getLearnWithQuestionsUserGetLearnWithQuestionsGet(10)
        } catch (error: any) {
            throw new Error(error?.message)
        }
    }
)

export const submitQuestion = createAsyncThunk(
    'learnWithQuestion/submitQuestion',
    async (payload: SetLearnWithQuestionAnswer) => {
        try {
            return await UserService.setLearnWithQuestionsAnswerUserSetLearnWithQuestionsAnswerPost(payload)
        } catch (error: any) {
            throw new Error(error?.message)
        }
    }
)

interface IInitialState {
    loading: boolean
    error: string
    questions: Array<LearnWithQuestionItemModel>
}

const initialState: IInitialState = {
    loading: false,
    error: "",
    questions: [] as LearnWithQuestionItemModel[]
}

export const learnWithQuestionSlice = createSlice({
    name: "learnWithQuestion",
    initialState,
    reducers: {
        removeFirstItemOfQuestions(state) {
            state.questions = state.questions.slice(1)
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getLearnWithQuestions.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(getLearnWithQuestions.fulfilled, (state, action) => {
            if (state.questions.length > 0) {
                state.questions = [...state.questions, ...action.payload]
            } else {
                state.questions = action.payload
            }
            state.loading = false
            state.error = ""
        })
        builder.addCase(getLearnWithQuestions.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
        builder.addCase(submitQuestion.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(submitQuestion.fulfilled, (state, action) => {
            state.loading = false
            state.error = ""
        })
        builder.addCase(submitQuestion.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
    }
})

export const { removeFirstItemOfQuestions } = learnWithQuestionSlice.actions
export default learnWithQuestionSlice.reducer