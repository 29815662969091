import { Container, Grid, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { CommonLoading, HomeArticleSlider, HomeParentSolutionSlider } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { useNavigate, useParams } from "react-router";
import {
  ArticleType,
  OpenAPI,
  SearchDocumentType,
  TrainingSetArticleItem,
} from "../client";
import { getFullTextSearch } from "../redux/searchSlice";
import { makeFirstLettersUpperCase } from "../utils/commonFuncs";

const Search: FC = () => {
  const { search_word } = useParams();
  const navigate = useNavigate();

  const { loading, searched_items } = useSelector(
    (state: RootState) => state.search
  );
  const { access_token } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (OpenAPI.TOKEN == undefined && access_token != "") {
      OpenAPI.TOKEN = access_token;
    }
    if (search_word != undefined || search_word != null) {
      dispatch(getFullTextSearch({ query: search_word.replaceAll("-", " ") }));
    } else {
      navigate(-1);
    }
  }, [search_word]);

  const totalWatchTimeCalculator = (articles: TrainingSetArticleItem[]) => {
    if (articles.length == 0) {
      return 0;
    }
    let total = 0;

    articles.forEach((element) => {
      total += element?.video_duration_in_seconds
        ? element.video_duration_in_seconds
        : 0;
    });
    return Math.floor(total / 60);
  };

  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <CommonLoading loading={loading} />
      {!loading ? (
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          {searched_items.filter(
            (x) => x.item_type == SearchDocumentType.TRAINING_SET
          ).length > 0 ? (
            <>
              <Grid
                item
                xs={4}
                sm={8}
                md={12}
                style={{
                  textAlign: "start",
                  display: "flex",
                  marginBottom:"20px"
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "700",
                    fontSize: "20px",
                    lineHeight: "28px",
                    color: "#171725",
                  }}
                >
                  Ebeveyn Çözümleri
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "700",
                    fontSize: "20px",
                    lineHeight: "28px",
                    color: "#2574FF",
                    marginLeft: "10px",
                  }}
                >
                  “{makeFirstLettersUpperCase(search_word ? search_word.replaceAll("-", " ") : "")}”
                </Typography>
              </Grid>
              <Grid item xs={4} sm={8} md={12} style={{marginBottom:"32px"}}>
                <HomeParentSolutionSlider
                  items={
                    searched_items.filter(
                      (e) => e.item_type == SearchDocumentType.TRAINING_SET
                    ).length == 0
                      ? []
                      : searched_items
                          .filter(
                            (e) =>
                              e.item_type == SearchDocumentType.TRAINING_SET
                          )
                          .map((k: any) => {
                            return {
                              id: k?.item?.id,
                              title: k?.item?.name,
                              video_article_count: k?.item?.articles.filter(
                                (e: any) => e.type == ArticleType.VIDEO
                              )?.length,
                              text_article_count: k?.item?.articles.filter(
                                (e: any) => e.type == ArticleType.TEXT
                              )?.length,
                              total_watch_time: totalWatchTimeCalculator(
                                k?.item?.articles
                              ),
                            };
                          })
                  }
                />
              </Grid>
            </>
          ) : null}
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            style={{ textAlign: "start", display: "flex", marginTop: "10px", marginBottom:"20px"}}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "28px",
                color: "#171725",
              }}
            >
              Makaleler
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "28px",
                color: "#2574FF",
                marginLeft: "10px",
              }}
            >
              “{makeFirstLettersUpperCase(search_word ? search_word.replaceAll("-", " ") : "")}”
            </Typography>
          </Grid>
          <Grid item xs={4} sm={8} md={12} style={{marginBottom:"32px"}}>
            <HomeArticleSlider 
                items={searched_items.filter((e) => e.item_type == SearchDocumentType.ARTICLE).map((a) => {
                    return {
                        id:a.item.id,
                        title:a.item.name,
                        is_picture_uploaded:a.item.is_picture_uploaded ? a.item.is_picture_uploaded : false
                    }
                })}
             />
          </Grid>
        </Grid>
      ) : null}
    </Container>
  );
};

export default Search;
