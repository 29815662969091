/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FullTextSearchResponse } from '../models/FullTextSearchResponse';
import type { UserSearchResponse } from '../models/UserSearchResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SearchService {

    /**
     * Full Text Search
     * @param query
     * @returns FullTextSearchResponse Successful Response
     * @throws ApiError
     */
    public static fullTextSearchSearchFullTextSearchGet(
        query: string,
    ): CancelablePromise<FullTextSearchResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/full-text-search',
            query: {
                'query': query,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Article Full Text Search
     * @param query
     * @param includeNotLiveDocuments
     * @returns FullTextSearchResponse Successful Response
     * @throws ApiError
     */
    public static articleFullTextSearchSearchArticleFullTextSearchGet(
        query: string,
        includeNotLiveDocuments: boolean = false,
    ): CancelablePromise<FullTextSearchResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/article-full-text-search',
            query: {
                'query': query,
                'include_not_live_documents': includeNotLiveDocuments,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Training Set Full Text Search
     * @param query
     * @param includeNotLiveDocuments
     * @returns FullTextSearchResponse Successful Response
     * @throws ApiError
     */
    public static trainingSetFullTextSearchSearchTrainingSetFullTextSearchGet(
        query: string,
        includeNotLiveDocuments: boolean = false,
    ): CancelablePromise<FullTextSearchResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/training-set-full-text-search',
            query: {
                'query': query,
                'include_not_live_documents': includeNotLiveDocuments,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Autocomplete
     * @param wordToAutocomplete
     * @returns string Successful Response
     * @throws ApiError
     */
    public static autocompleteSearchAutocompleteGet(
        wordToAutocomplete: string,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/autocomplete',
            query: {
                'word_to_autocomplete': wordToAutocomplete,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * User Full Text Search
     * @param query
     * @returns UserSearchResponse Successful Response
     * @throws ApiError
     */
    public static userFullTextSearchSearchUserFullTextSearchGet(
        query: string,
    ): CancelablePromise<UserSearchResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/user-full-text-search',
            query: {
                'query': query,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
