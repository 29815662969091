import { FC } from "react";
import { Button } from "@mui/material";
import { HearthEmptyWithBlackBorder, HeartRed } from "../assests";

interface Props {
  isLiked: boolean;
  onClick: () => void;
  customCSS?: {};
}

const DetailFavoriteButton: FC<Props> = ({
  isLiked = false,
  onClick,
  customCSS,
}) => {
  return (
    <Button
      style={{
        backgroundColor: "#FFFFFF",
        border: "1px solid #E9EBED",
        borderRadius: "16px",
        padding:"1% 2%",
        display: "flex",
        ...customCSS,
      }}
      onClick={() => onClick()}
    >
      {isLiked ? (
        <img src={HeartRed} alt="" />
      ) : (
        <img src={HearthEmptyWithBlackBorder} alt="" />
      )}
    </Button>
  );
};

export default DetailFavoriteButton;
