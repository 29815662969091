import { FC } from "react";
import { Checkbox } from "@mui/material";

interface Props {
    _required?:boolean
    value:boolean
    onChange:(e:boolean) => void
}

const CommonCheckboxWithParam:FC<Props> = ({
    _required=false,
    value=false,
    onChange
}) => {
    return(
        <Checkbox sx={{
            color:"#DADADA",
            padding:"2px",
            transform:"scale(1.2)"
        }}
        onChange={(e) => onChange(e.target.checked)}
        required={_required}
        checked={value}
        />
    )
}

export default CommonCheckboxWithParam