import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import DetailShareButton from "./DetailShareButton";
import DetailFavoriteButton from "./DetailFavoriteButton";
import EvaluateContentButton from "./EvaluateContentButton";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { FavoriteType, UserFeedBackContentType } from "../client";
import {
  addItemToFavorite,
  removeItemFromFavorite,
} from "../redux/favoriteSlice";
import { openContentEvaluateModal, openShareContentModal } from "../redux/generalControllerSlice";

interface Props {
  contentId: string;
  title: string;
  parentSolutionName: string;
  parentSolutionId:string
}

const ParentSolutionVideoContentHeader: FC<Props> = ({
  contentId,
  title = "",
  parentSolutionName = "",
  parentSolutionId
}) => {
  const { favorite_items } = useSelector((state: RootState) => state.favorite);
  const dispatch = useDispatch<AppDispatch>();

  const SolutionTitleContainer = {
    border: "1px solid #E9EBED",
    borderRadius: "8px",
    padding: "6px 4px",
    display: "flex",
    marginBottom: "10px",
    width: "max-content",
  };

  const addContentToFavorite = async() => {
    try {
      dispatch(
        addItemToFavorite({
          id_of_favorite: `${parentSolutionId}_${contentId}` ,
          name_of_favorite: title,
          favourite_type: FavoriteType.TRAINING_SET_ARTICLE,
        })
      );
    } catch (error:any) {
      console.log("err",error)
    }
  };

  const removeContentFromFavorite = () => {
    try {
      dispatch(removeItemFromFavorite({ favorite_item_id: `${parentSolutionId}_${contentId}` }));
    } catch (error) {}
  };

  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={4} sm={8} md={12}>
        <div style={SolutionTitleContainer}>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "20px",
              color: "#9CA4AB",
            }}
          >
            {parentSolutionName}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={4} sm={8} md={6} sx={{marginBottom:{xs:"10px", sm:"10px", md:"0px"}}}>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "24px",
            lineHeight: "32px",
            color: "#171725",
            textAlign: "start",
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        sm={8}
        md={6}
        sx={{ display: "flex", flexDirection: "row",marginBottom:{xs:"10px", sm:"10px", md:"0px"} }}
      >
        <DetailShareButton
          onClick={() => dispatch(openShareContentModal())}
          customCSS={{
            marginRight: "5px",
            alignSelf: "flex-start",
            padding: "11px 24px",
          }}
        />
        <DetailFavoriteButton
          isLiked={favorite_items.some((e) => {
            return (
              e.favourite_type ==
                FavoriteType.TRAINING_SET_ARTICLE && e.id == `${parentSolutionId}_${contentId}`
            );
          })}
          onClick={
            favorite_items.some((e) => {
              return (
                e.favourite_type ==
                  FavoriteType.TRAINING_SET_ARTICLE && e.id == `${parentSolutionId}_${contentId}`
              );
            })
              ? () => removeContentFromFavorite()
              : () => addContentToFavorite()
          }
          customCSS={{
            marginRight: "5px",
            alignSelf: "flex-start",
            padding: "11px 24px",
          }}
        />
        <EvaluateContentButton
          onClick={() => dispatch(openContentEvaluateModal({content_id:contentId, content_type: UserFeedBackContentType.ARTICLE}))}
          text="Değerlendir"
          customCSS={{ alignSelf: "flex-start", padding: "11px 24px" }}
        />
      </Grid>
    </Grid>
  );
};

export default ParentSolutionVideoContentHeader;
