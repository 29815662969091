export const RECAPTCHA_KEY = `${process.env.REACT_APP_RECAPTCHA_KEY}`

export const isBrowser = typeof window !== 'undefined';

export interface IGoogleReCaptcha {
  ready: (callback: () => void) => void;
  execute: (siteKey: string, options?: { action: string }) => Promise<string>;
}

export interface IProjectWindow extends Window {
  grecaptcha: IGoogleReCaptcha;
}