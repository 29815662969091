import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { LettersMapped } from "../utils/constants";

interface Props {
  is_selected: boolean;
  text:string
  index:number
  onAnswerBoxSelected: (e:string) => void
}

const LearnWithQuestionAnswerBox: FC<Props> = ({ is_selected = false, text="", index=0,onAnswerBoxSelected }) => {
  return (
    <Box component="div"
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: is_selected ? "#2574FF" : "#F9F9F9",
        borderRadius: "16px",
        marginBottom: "16px",
        width: {xs:"90%",sm:"90%",md:"80%"},
        cursor: "pointer",
      }}
      onClick={() => onAnswerBoxSelected(text)}
    >
      <div
        style={{
          background: is_selected ? "#FFFFFF" : "#2574FF",
          borderRadius: "16px",
          padding: "18px 23px",
          marginRight: "22px",
          marginTop: "10px",
          marginBottom: "10px",
          marginLeft: "12px",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "24px",
            lineHeight: "32px",
            color: is_selected ? "#2574FF" : "#FFFFFF",
          }}
        >
          {LettersMapped[index]}
        </Typography>
      </div>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "26px",
          color: is_selected ? "#FFFFFF" : "#272755",
          textAlign:"start"
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default LearnWithQuestionAnswerBox;
