import { FC, useState } from "react";
import HeadingTitle from "./HeadingTitle";
import { TextField, Typography, InputAdornment,Grid } from "@mui/material";
import { LayoutSearchEnd } from "../assests";
import PersonalInfoAreaInterestItem from "./PersonalInfoAreaInterestItem";
import AreaOfInterestItem from "./AreaOfInterestItem";

interface Props {
  interests: Array<string>;
  selected_interests?: Array<string>;
  addInterest: (e: string) => void;
  removeInterest: (e:string) => void;
}

const RegisterSetAreaOfInterests: FC<Props> = ({
  interests = [],
  selected_interests = [],
  addInterest,
  removeInterest
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");

  return (
    <>
      <HeadingTitle title="İlgilendiğiniz Konular" />
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#78828A",
          marginBottom: "25px",
        }}
      >
        Çocuğunuzla ilgili size öncelikli yardımcı olmamızı istediğiniz konuları
        seçin.
      </Typography>

      <TextField
        fullWidth
        type="text"
        placeholder="Çözmek istediğiniz sorunu yazın"
        onChange={(e) => setSearchQuery(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" style={{ cursor: "pointer" }}>
              <img src={LayoutSearchEnd} alt="" />
            </InputAdornment>
          ),
        }}
        sx={{
          "& fieldset": { border: "1px solid #D1D8DD", borderRadius: "8px" },
          input: {
            color: "#BFC6CC",
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "24px",
            textAlign: "center",
            padding: "10px",
          },
          backgroundColor: "#F9F9F9",
          marginBottom: "24px",
        }}
      />
      <Grid container>
        {interests.map((e) => {
          if (selected_interests.includes(e)) {
            return (
              <PersonalInfoAreaInterestItem text={e} _onClick={removeInterest} />
            );
          } else {
            return (
              <AreaOfInterestItem text={e} _onClick={addInterest} />
            );
          }
        })}
      </Grid>
    </>
  );
};

export default RegisterSetAreaOfInterests;
