/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum UserFeedBackContentType {
    ARTICLE = 'article',
    TRAININGSET = 'trainingset',
    SURVEY = 'survey',
    PARENTSCHOOLMODULE = 'parentschoolmodule',
    PARENTSCHOOLARTICLE = 'parentschoolarticle',
}
