import { CreateProgressLog, GetPrivateUrlofArticleVideoResponse, ProgressLogItemType, ProgressLogNameType, ProgressStatusItem, ProgressStatusType, UserService } from "../client";
import { NewProgressLog } from "../redux/logSlice";
import { Buckets, IngDiscountBinNumbers } from "./constants";

export const shuffleAndGetContent = (items: any[], return_data_limit: number): any[] => {
	try {
		return items.slice().sort(() => Math.random() - 0.5).slice(0, return_data_limit);
	} catch (error) {
		return items
	}
};

export const getNameTextLogo = (name?: string) => {
	try {
		if (name == null) {
			return ""
		}

		if (name == undefined) {
			return "";
		}

		const names = name.split(" ");
		if (names.length == 1) {
			return names[0].charAt(0);
		} else {
			let res = "";

			res += names[0].charAt(0);
			res += names[names.length - 1].charAt(0);
			return res;
		}
	} catch (error) {
		return ""
	}
}

export const makeFirstLettersUpperCase = (text: string) => {
	try {
		return text
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	} catch (error) {
		return ""
	}
}

export const doesUserHasAccessToContent = (isUserPremium: boolean, isContentFree: boolean) => {
	try {
		if (isUserPremium) {
			return true
		} else {
			if (isContentFree) {
				return true
			} else {
				return false
			}
		}

	} catch (error) {
		return false
	}
}

export function guidGenerator() {
	var S4 = function () {
		return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
	}
	return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4()
}

export const googleSignInFetchUserFromToken = async (access_token: string) => {
	try {
		let response = await fetch('https://www.googleapis.com/userinfo/v2/me', {
			headers: { Authorization: `Bearer ${access_token}` },
		})
		const user = await response.json()
		return user
	} catch (error: any) {
		return {}
	}
}

export const getVideoUrl = async (articleId: string, recaptchaToken: string, secondRequestCookie?: string) => {
	try {
		// const response = await UserService.getPrivateUrlOfArticleVideoUserGetVideoOfArticleArticleIdGet(articleId, recaptchaToken)
		// return response.url
		const url = `${Buckets.ArticleVideoBucket}/${articleId}.mp4`
		return url
	} catch (error) {
		return ""
	}
}

export const isUserInTurkey = async () => {
	try {
		const response = await fetch('https://ipapi.co/json/')
		const data = await response.json()
		if (data?.country === "TR") {
			return true
		}
		return false
	} catch (error) {
		return false
	}
}

export const generateLocalProgressStatusItem = (item: NewProgressLog, currentStatuses: ProgressStatusItem[]) => {
	let _newStatuses: Array<ProgressStatusItem> = currentStatuses
	try {
		if (item.progress_log_name === ProgressLogNameType.STARTED) {
			return _newStatuses
		}
		const articleIds = currentStatuses
			.filter((i) => i.progress_status_type === ProgressStatusType.ARTICLE)
			.map((e) => e.item_id)

		if (item.item_type === ProgressLogItemType.ARTICLE) {
			if (!articleIds.includes(item.item_id)) {
				_newStatuses.push({
					item_id: item.item_id,
					created_at: new Date().toISOString(),
					completed_item_count: 1,
					total_items_count: 1,
					progress_status_type: ProgressStatusType.ARTICLE,
					item_name: item.item_name || '',
					article_type: item.article_type,
				})
			}
		} else if (item.item_type === ProgressLogItemType.PARENT_SCHOOL_MODULE_ARTICLE) {
			const [moduleId, articleId] = item.item_id.split('_')
			let isNewWatch = false

			if (!articleIds.includes(articleId)) {
				isNewWatch = true

				_newStatuses = [..._newStatuses, {
					item_id: articleId,
					created_at: new Date().toISOString(),
					completed_item_count: 1,
					total_items_count: 1,
					progress_status_type: ProgressStatusType.ARTICLE,
					item_name: item.item_name || '',
				}]
			}

			const parentSchoolIds = currentStatuses
				.filter((i) => i.progress_status_type === ProgressStatusType.PARENT_SCHOOL_MODULE)
				.map((e) => e.item_id)

			if (parentSchoolIds.includes(moduleId) && isNewWatch) {
				const ps_index = _newStatuses.findIndex(obj => {
					return obj.item_id === moduleId
				})
				_newStatuses[ps_index] = { ..._newStatuses[ps_index], completed_item_count: _newStatuses[ps_index].completed_item_count + 1 }
			} else {
				_newStatuses.push({
					item_id: moduleId,
					created_at: new Date().toISOString(),
					completed_item_count: 1,
					total_items_count: item.total_items_count || 1,
					progress_status_type: ProgressStatusType.PARENT_SCHOOL_MODULE,
					item_name: item.module_name || '',
					last_item_id: articleId || '',
				})
			}
		} else if (item.item_type === ProgressLogItemType.TRAINING_SET_ARTICLE) {
			const [moduleId, articleId] = item.item_id.split('_')
			let isNewWatch = false
			if (!articleIds.includes(articleId)) {
				isNewWatch = true
				_newStatuses = [..._newStatuses, {
					item_id: articleId,
					created_at: new Date().toISOString(),
					completed_item_count: 1,
					total_items_count: 1,
					progress_status_type: ProgressStatusType.ARTICLE,
					item_name: item.item_name || '',
					article_type: item.article_type,
				}]
			}
			const trainingSetIds = currentStatuses
				.filter((i) => i.progress_status_type === ProgressStatusType.TRAINING_SET)
				.map((e) => e.item_id)

			if (trainingSetIds.includes(moduleId) && isNewWatch) {
				const ts_index = _newStatuses.findIndex(obj => {
					return obj.item_id === moduleId
				})

				_newStatuses[ts_index] = { ..._newStatuses[ts_index], completed_item_count: _newStatuses[ts_index].completed_item_count + 1 }
			} else {
				_newStatuses = [..._newStatuses, {
					item_id: moduleId,
					created_at: new Date().toISOString(),
					completed_item_count: 1,
					total_items_count: item.total_items_count || 1,
					progress_status_type: ProgressStatusType.TRAINING_SET,
					item_name: item.module_name || '',
					last_item_id: articleId || '',
				}]
			}
		}
		return _newStatuses
	} catch (err) {
		console.log("ERROR IN generateLocalProgressStatusItem ", err)
		return _newStatuses
	}
}

export const setForcedUpdateParamToLocalStorage = (pricingOption:string | null, discountCoupon:string | null) => {
	let redirect_url:string = "/show-subscription-packages"
	try {
		if(pricingOption != null){
			redirect_url=`/package-payment/${pricingOption}`
			if(discountCoupon != null){
				redirect_url += `?coupon=${discountCoupon}`
			}
		}
	} catch (error) {}
	localStorage.setItem("forced_update", redirect_url)
}

export const removeForcedUpdateParamInLocalStorage = () => {
	localStorage.removeItem("forced_update")
}

export const getForcedUpdateUrl = ():string => {
	const value = localStorage.getItem("forced_update")
	if (value == null || value == undefined || value === "true") { // We need === true condition because we had this value previous.
		return "/show-subscription-packages"
	}else {
		return value
	}
}

export const checkForcedUpdateParamExistInLocalStorage = (): boolean => {
	try {
		const value = localStorage.getItem("forced_update")
		if (value == null || value == undefined) {
			return false
		}
		return true
	} catch (error) {
		removeForcedUpdateParamInLocalStorage()
		return false
	}
}

export const getUserIpAddress = async() => {
	try {
		const response = await fetch('https://ipapi.co/json/')
		const data = await response.json()
		return data.ip
	} catch (error) {
		return ""
	}
}

export const checkCardHasRegisteredUnderING = (isNewCard:boolean, binNumber:string):boolean => {
	if(isNewCard){
		const check = IngDiscountBinNumbers.find(e => e === binNumber)
		return check === undefined ? false : true
	}else {
		let binFound = false
		IngDiscountBinNumbers.map((e) => {
			if(binNumber.startsWith(e)){
				binFound = true
			}
		})
		return binFound
	}
}