import { FC } from "react";
import { Typography } from "@mui/material";

const WhatIsSurvey: FC = () => {
  return (
    <>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "600",
          fontSize: "20px",
          lineHeight: "28px",
          color: "#171725",
        }}
      >
        Anketler Nedir?
      </Typography>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#78828A",
          marginTop: "30px",
        }}
      >
        Ebeveynlerin kendilerini geliştirmeleri için kendilerini tanımaları oldukça önemlidir. Örneğin, bir çocuk hırslıysa, anne ve/veya baba çocuğu eleştiriyordur, övüyordur veya başarı baskısı yapıyordur. Bu sorunu çözmek için ailenin ilk önce bilimsel anketlerimizi çözüp, çocuktaki hırsı yaratan kendilerindeki etkeni bulması gerekir. Anne ve baba, kendi davranışlarını değiştirince çocuklardaki birçok sorun çözülecektir. Ayrıca anketlerimiz sayesinde çocuğunuzu da daha yakından tanıyacaksınız. Örneğin, topluluk içinde çekingen kalan bir çocuk ‘utangaç’ mı yoksa ‘içe dönük’ olduğu için mi böyle davranıyor keşfedeceksiniz. Uygulamamızda bilimsel anketlerimizi doldurduktan sonra, sizlere raporlar sunuyoruz. Bu raporlar sayesinde hem kendinizle hem de çocuğunuzla ilgili farkındalık kazanıp, sorunlara daha bilinçli yaklaşabilirsiniz.
      </Typography>
    </>
  );
};

export default WhatIsSurvey;
