import { FC, useState } from "react";
import { Typography, Divider, Button } from "@mui/material";
import { ArrowRightBlue, ArrowRightWhite } from "../assests";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store/store";

interface Props {
  price: number;
  id: string;
  name: string;
  isHighlighted: boolean;
}

const LandingDiscountedPriceCard: FC<Props> = ({ price, id, isHighlighted, name}) => {
  const containerStyle = {
    border: "1px solid #E6E8EC",
    borderRadius: "20px",
    padding: "23px 32px 23px 32px",
    backgroundColor: "#FFFFFF",
    marginBottom:"20px"
  };

  const hoveredContainerStyle = {
    border: "1px solid #FFFFFF",
    borderRadius: "20px",
    padding: "23px 32px 23px 32px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 12px 56px 0px rgba(6, 28, 61, 0.12)",
    marginBottom:"20px"
  };

  const { access_token, profile } = useSelector((state:RootState) => state.auth)

  const [onHovered, setOnHovered] = useState(false);
  const navigate = useNavigate();

  const subTextGenerator = () => {
    switch (id) {
      case "life_time":
        return "Bir kez ödenir";
      case "yearly":
        return "Ayda"; //Becase it shows as 12-monthly payment in desing.
      case "monthly":
        return "Ayda";
      case "weekly":
        return "Hafta";
      default:
        return "";
    }
  };

  const checkPackageIsYearlyForPrice = () => {
    try {
      if (id === "yearly") {
        return Number((price / 12).toString().match(/^\d+(?:\.\d{0,2})?/));
      }
      return price;
    } catch (error) {
      return price;
    }
  };

//   const calculatePriceWithDiscountRate = () => {
//     if (webDiscountRate == 0 || webDiscountRate == null || webDiscountRate == undefined) {
//       return price;
//     }
//     return Number(
//       ((checkPackageIsYearlyForPrice() / 100) * (100-webDiscountRate)).toString().match(/^\d+(?:\.\d{0,2})?/)
//     );
//   };

  const redirectToPackagePayment = (subscriptionPackage:string, discountCoupon:string | null) => {
    if(access_token == ""){
      navigate(`/package-payment-without-login/${subscriptionPackage}${discountCoupon == null ? "" : `?coupon=${discountCoupon}`}`)
    }else {
      if(profile.is_user_premium){
        navigate("/")
      }else {
        navigate(`/package-payment/${subscriptionPackage}${discountCoupon == null ? "" : `?coupon=${discountCoupon}`}`)
      }
    }
  }

  return (
    <div>
      <div
        style={onHovered ? hoveredContainerStyle : containerStyle}
        onMouseEnter={() => setOnHovered(true)}
        onMouseLeave={() => setOnHovered(false)}
      >
        <div style={{ display: "flex" }}>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontWeight: 800,
              fontSize:{xs:"14px",sm:"14px",md: "22px"},
              lineHeight:{xs:"14px",sm:"14px",md: "22px"},
              color: "#000000",
            }}
          >
            {name}
          </Typography>
        </div>
        <Divider
          style={{ color: "#E6E8EC", marginTop: "19px", marginBottom: "19px" }}
        />
        <div style={{ display: "flex" }}>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontWeight: 900,
              fontSize: {xs:"11px",sm:"11px",md:"18px"},
              lineHeight: {xs:"15px",sm:"15px",md:"24px"},
              color: "#2574FF",
              marginRight: "6px",
            }}
          >
            12 ay
          </Typography>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontWeight: 700,
              fontSize: {xs:"11px",sm:"11px",md:"18px"},
              lineHeight: {xs:"15px",sm:"15px",md:"24px"},
              color: "#66707A",
            }}
          >
            taksit imkanı
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
          }}
        >
          <div
            style={{
              display: "flex",
              textAlign: "center",
              alignItems: "baseline",
            }}
          >
            {checkPackageIsYearlyForPrice().toString().split(".").length ==
            1 ? (
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                  fontSize: {xs:"25px",sm:"25px",md:"40px"},
                  lineHeight: {xs:"30px",sm:"30px",md:"48px"},
                  color: "#2574FF",
                }}
              >
                {checkPackageIsYearlyForPrice()} TL
              </Typography>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    fontSize: {xs:"25px",sm:"25px",md:"40px"},
                    lineHeight: {xs:"30px",sm:"30px",md:"48px"},
                    color: "#2574FF",
                  }}
                >
                  {checkPackageIsYearlyForPrice().toString().split(".")[0]}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    fontSize: {xs:"13px",sm:"13px",md:"20px"},
                    lineHeight: {xs:"30px",sm:"30px",md:"48px"},
                    color: "#2574FF",
                  }}
                >
                  .{checkPackageIsYearlyForPrice().toString().split(".")[1]}{" "}
                  TL
                </Typography>
              </div>
            )}
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontWeight: 400,
                fontSize: {xs:"10px", sm:"10px", md:"16px"},
                lineHeight: {xs:"10px", sm:"10px", md:"24px"},
                color: "#66707A",
                marginLeft: "6px",
              }}
            >
              / {subTextGenerator()}
            </Typography>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontWeight: "400",
              fontSize:{xs:"10px", sm:"10px", md:"15px"} ,
              lineHeight: {xs:"15px", sm:"15px", md:"24px"},
              color: "#66707A",
              textTransform: "none",
            }}
          >
            {id === "life_time" ? "/Bir kez ödenir" : (id === "yearly" ? "/Her yıl otomatik yenilenir" : (id === "monthly" ? "/Her ay otomatik yenilenir" : (id === "weekly" ? "/Her hafta otomatik yenilenir" : "")))}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontWeight: 600,
              fontSize: {xs:"10px",sm:"10px",md:"15px"},
              lineHeight: {xs:"15px", sm:"15px",md:"24px"},
              color: "#66707A",
              textTransform: "none",
            }}
          >
            {id === "yearly" ? `${price} TL/Yıl` : (id === "monthly" ? `${price} TL/Ay` : "")}
          </Typography>
        </div>
        <Button
          onClick={() => redirectToPackagePayment(id,null)}
          style={{
            backgroundColor: onHovered ? "#2574FF" : "#F0F5FF",
            borderRadius: "7px",
            padding: "0px 32px 0px 32px",
            marginTop: "16px",
            justifyContent: "start",
          }}
          endIcon={
            <img src={onHovered ? ArrowRightWhite : ArrowRightBlue} alt="" />
          }
          fullWidth
        >
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "700",
              fontSize: "15px",
              lineHeight: "44px",
              color: onHovered ? "#FFFFFF" : "#2574FF",
              textTransform: "none",
            }}
          >
            {name} Seç
          </Typography>
        </Button>
      </div>
    </div>
  );
};

export default LandingDiscountedPriceCard;
