import { Button, Typography } from "@mui/material";
import { FC } from "react";
import { LogOut } from "../assests";

interface Props {
  onClick?: () => void;
  disabled?: boolean;
}

const LogOutButton: FC<Props> = ({ onClick, disabled = false }) => {

    const buttonContainer = {
        border:"1px solid #FA4169",
        paddingTop:"12px",
        paddingBottom:"12px",
        borderRadius:"12px",
        width:"85%"
    }

  return (
    <Button
      style={buttonContainer}
      variant="outlined"
      onClick={onClick}
      type="submit"
      fullWidth
      disabled={disabled}
      startIcon={<img src={LogOut} alt="" />}
    >
      <Typography
        style={{
          fontSize: "16px",
          fontWeight: "600",
          lineHeight: "24px",
          fontFamily: "Montserrat",
          color: "#FA4169",
          textTransform: "none",
        }}
      >
        {"Çıkış Yap"}
      </Typography>
    </Button>
  );
};

export default LogOutButton;
