import { FC } from "react";
import { Typography, Divider } from "@mui/material";
import { ArrowRight } from "../assests";

interface Props {
  isSelected: boolean;
  text: string;
  icon: string;
  isLastItem:boolean;
}

const SettingsTabLabelItem: FC<Props> = ({
  isSelected = false,
  text = "",
  icon = "",
  isLastItem = false
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "start",
          width: "100%",
          marginTop:"10px",
          marginBottom:"25px"
        }}
      >
        <div style={{ display: "flex", alignItems: "start" }}>
          <img src={icon} alt="" style={{ marginRight: "11px"}} />
          <Typography
            style={
              isSelected
                ? {
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "18px",
                    lineHeight: "26px",
                    color: "#0D0C0F",
                    textTransform: "none",
                  }
                : {
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "26px",
                    color: "#78828A",
                    textTransform: "none",
                  }
            }
          >
            {text}
          </Typography>
        </div>
        <img src={ArrowRight} alt="" />
      </div>
      {
        isLastItem ? null : <Divider variant="middle" style={{width:'100%', color:"#E9EBED"}} />
      }
    </>
  );
};

export default SettingsTabLabelItem;
