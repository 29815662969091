import {
  Button,
  Dialog,
  DialogContent,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { EyeSlash, MedalBlue } from "../assests";
import CommonLabel from "./CommonLabel";
import CommonButton from "./CommonButton";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store/store";

interface Props {
  isOpen: boolean;
  onCancelFunc: () => void;
  password: string;
  setPassword: (e: string) => void;
  submitUnsubscribe:() => void
}

const UnsubscribeMeModal: FC<Props> = ({
  isOpen,
  onCancelFunc,
  password,
  setPassword,
  submitUnsubscribe
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { profile } = useSelector((state:RootState) => state.auth)

  return (
    <Dialog open={isOpen} onClose={onCancelFunc} maxWidth="sm">
      <DialogContent
        sx={{
          border: "1px solid #DEE2E6",
          boxShadow:
            "0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          width: "fit-content",
        }}
      >
        <img src={MedalBlue} alt="" />
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "28px",
            marginBottom: "8px",
            marginTop: "28px",
            color: "#0D0C0F",
          }}
        >
          Abonelikten Çıkmak İstiyor musunuz?
        </Typography>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "400",
            fontSize: "15px",
            lineHeight: "24px",
            marginBottom: "24px",
            color: "#0D0C0F",
            maxWidth: "75%",
          }}
        >
          Aboneliğiniz {profile?.premium_ends_at?.split("T")[0]} tarihinde sonlanacaktır. Premium
          avantajlarınızı şimdi sonlandırmak istediğinizden emin misiniz?
        </Typography>
        <CommonLabel label="Şifreni Gir" />
        <TextField
          id="outlined-basic"
          required
          type={showPassword ? "text" : "password"}
          value={password}
          defaultValue={password}
          placeholder="*******"
          onChange={(e: any) => setPassword(e.target.value)}
          sx={{
            "& fieldset": { border: "1px solid #E9EBED", borderRadius: "12px" },
            input: {
              color: "#9CA4AB",
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "22px",
              textAlign: "start",
            },
            borderRadius: "8px",
            backgroundColor: "rgba(228, 228, 228, 0.25)",
            width: "70%",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <img
                  src={EyeSlash}
                  alt=""
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowPassword(!showPassword)}
                />
              </InputAdornment>
            ),
          }}
        />
        <div
          style={{
            marginTop: "32px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            onClick={onCancelFunc}
            variant="outlined"
            style={{
              border: "1px solid #0D0C0F",
              borderRadius: "12px",
              backgroundColor: "#FFFFFF",
              padding: "12px 20px",
              width: "50%",
              marginRight: "10px",
            }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#0D0C0F",
              }}
            >
              Vazgeç
            </Typography>
          </Button>
          <CommonButton
            text="Sonlandır"
            disabled={password.length === 0 ? true : false}
            customCSS={{
              backgroundColor: "#FA4169",
              width: "50%",
              marginLeft: "10px",
            }}
            onClick={submitUnsubscribe}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UnsubscribeMeModal;
