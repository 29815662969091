import { FC } from "react";
import { Checkbox } from "@mui/material";

interface Props {
    _required?:boolean
}

const CommonCheckbox:FC<Props> = ({
    _required=false
}) => {
    return(
        <Checkbox sx={{
            color:"#DADADA",
            padding:"2px",
            transform:"scale(1.2)"
        }}
        required={_required}
        />
    )
}

export default CommonCheckbox