/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IsRequestSuccessfulModel } from '../models/IsRequestSuccessfulModel';
import type { ListOfParents } from '../models/ListOfParents';
import type { routers__on1_router__SebitAuthorizationDto } from '../models/routers__on1_router__SebitAuthorizationDto';
import type { routers__on1_router__SebitRegisterDto } from '../models/routers__on1_router__SebitRegisterDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class On1Service {

    /**
     * Authorize With On1
     * @param sebitToken
     * @returns routers__on1_router__SebitAuthorizationDto Successful Response
     * @throws ApiError
     */
    public static authorizeWithOn1On1AuthorizeSebitTokenGet(
        sebitToken: string,
    ): CancelablePromise<routers__on1_router__SebitAuthorizationDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/on1/authorize/{sebit_token}',
            path: {
                'sebit_token': sebitToken,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Register With On1
     * @param requestBody
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static registerWithOn1On1RegisterPost(
        requestBody: routers__on1_router__SebitRegisterDto,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/on1/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get On1 Users
     * @returns ListOfParents Successful Response
     * @throws ApiError
     */
    public static getOn1UsersOn1GetOn1UsersGet(): CancelablePromise<ListOfParents> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/on1/get-on1-users',
            errors: {
                404: `Not found`,
            },
        });
    }

}
