import { Typography } from "@mui/material";
import { FC } from "react";
import { Plus } from "../assests";

interface Props {
  _onClick: () => void;
}

const AddCardBlankItem: FC<Props> = ({
  _onClick
}) => {
  const containerStyle = {
    background: "#FFFFFF",
    border: "1px solid #F0F0F0",
    borderRadius: "8px",
    padding: "23px 27px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    cursor: "pointer",
  };

  return (
    <div style={containerStyle} onClick={_onClick}>
      <img src={Plus} alt="" />
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "600",
          fontSize: "14px",
          lineHeight: "22px",
          color: "#171725",
        }}
      >
        Yeni Kart Ekle
      </Typography>
    </div>
  );
};

export default AddCardBlankItem;
