import React,{ FC } from "react";
import { Input, InputAdornment } from "@mui/material";
import { SendActive, SendDisabled } from "../assests";

interface Props {
    value:string;
    setValue: (e:string) => void
    submitTriggered: () => void
}
const ChatInput: FC<Props> = ({
    value,
    setValue,
    submitTriggered
}) => {

  const handleKeyDown = (event:React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && value.length > 0) {
      submitTriggered()
    }
  };


  return (
    <Input
      sx={{
        "& fieldset": { border: "none" },
        input: {
          color: "#434E58",
          fontFamily: "Montserrat",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "20px",
        },
        backgroundColor: "#ECF1F6",
        border: "none",
        borderRadius: "15px",
        padding: "15px 27px",
      }}
      fullWidth
      disableUnderline
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder="Sorunuzu buraya yazın"
      endAdornment={
        <InputAdornment
          position="end"
          style={{
            cursor: "pointer",
          }}
          onClick={() => value === "" ? null : submitTriggered()}
        >
          <img src={value === "" ? SendDisabled : SendActive} alt=""/>
        </InputAdornment>
      }
      onKeyDown={handleKeyDown}
    />
  );
};

export default ChatInput;
