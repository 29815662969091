/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum LearnWithQuestionRelatedContentType {
    ARTICLE = 'article',
    TRAINING_SET = 'training_set',
    PARENT_SCHOOL_MODULE = 'parent_school_module',
    PARENT_SCHOOL_MODULE_CONTENT = 'parent_school_module_content',
    SURVEY = 'survey',
}
