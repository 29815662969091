import { Container, Grid, Tabs, Tab, Typography, Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CommonLoading, SurveyForParentItem, SurveyForResultItem } from "../components";
import { AppDispatch, RootState } from "../redux/store/store";
import { getAllSurveys } from "../redux/surveySlice";
import { OpenAPI } from "../client";

const Surveys: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loading, survey_items } = useSelector(
    (state: RootState) => state.survey
  );
  const { access_token } = useSelector((state: RootState) => state.auth);
  const { survey_logs } = useSelector((state:RootState) => state.log)

  const [activeTab, setActiveTab] = useState(0);

  const tabTextSetter = (tabIndex:number, label:string) => {
    if(tabIndex == activeTab){
        return (
            <Typography style={{
                fontFamily:"Montserrat",
                fontWeight:"700",
                fontSize:"17px",
                lineHeight:"26px",
                color:"#171725"
            }}>
                {label}
            </Typography>
        )
    }else{
        return(
            <Typography style={{
                fontFamily:"Montserrat",
                fontWeight:"500",
                fontSize:"17px",
                lineHeight:"26px",
                color:"#78828A"
            }}>
                {label}
            </Typography>
        )
    }
  }

  useEffect(() => {
    if (OpenAPI.TOKEN == undefined && access_token != "") {
      OpenAPI.TOKEN = access_token;
    }
    dispatch(getAllSurveys());
  }, []);

  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: "30px", marginBottom: "30px" }}
      sx={{
        paddingRight: { md: "15%" },
      }}
    >
      <CommonLoading loading={loading} />
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }} rowSpacing={3}>
        <Grid item xs={4} sm={8} md={12}>
          <Box sx={{ borderBottom: 1, borderColor: "divider",}}>
            <Tabs
              value={activeTab}
              centered
              onChange={(e, index) => setActiveTab(index)}
            >
              <Tab label={tabTextSetter(0,"Anketler")} value={0} style={{marginRight:"20%"}}/>
              <Tab label={tabTextSetter(1,"Sonuçlar")}value={1} />
            </Tabs>
          </Box>
        </Grid>
        {activeTab == 0 ? (
          !loading ? (
            survey_items.map((item) => {
              return (
                <Grid item xs={4} sm={8} md={12} >
                  <SurveyForParentItem
                    survey_title={item.survey_title}
                    survey_token={item.survey_token}
                  />
                </Grid>
              );
            })
          ) : null
        ) : (
          survey_logs.map((e) => {
            return(
              <Grid item xs={4} sm={8} md={12} >
                <SurveyForResultItem survey_title={e.survey_title} id={e.id} solved_date={e.completed_at}/>
            </Grid>
            )
          })
        )}
      </Grid>
    </Container>
  );
};

export default Surveys;
