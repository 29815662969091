import { FC } from "react";
import { Button, Typography } from "@mui/material";

interface Props {
  text: string;
  onClick: () => void;
}

const LoadMorePaginationButton: FC<Props> = ({ text = "", onClick }) => {
  return (
    <Button
      style={{
        border: "1px solid #2574FF",
        borderRadius: "12px",
        padding: "16px 32px",
      }}
      onClick={onClick}
    >
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "600",
          fontSize: "14px",
          lineHeight: "22px",
          color: "#2574FF",
        }}
      >
        {text}
      </Typography>
    </Button>
  );
};

export default LoadMorePaginationButton;
