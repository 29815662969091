import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UserService, GetTrainingSetsResponse, TrainingSetModel } from '../client';

export const getAllParentSolutions = createAsyncThunk(
  'parentSolution/getAllParentSolutions',
  async (payload: { startAfterTrainingSetId?: string }) => {
    try {
      return await UserService.getTrainingSetsUserTrainingSetsGet(payload.startAfterTrainingSetId)
    } catch (error: any) {
      throw new Error(error?.message)
    }
  }
);

export const getParentSolution = createAsyncThunk(
  'parentsolution/getParentSolution',
  async (payload: { trainingSetId: string }) => {
    try {
      return await UserService.getTrainingSetUserTrainingSetTrainingSetIdGet(payload.trainingSetId)
    } catch (error: any) {
      throw new Error(error?.message)
    }
  }
)

interface IInitialState {
  parentSolutions: GetTrainingSetsResponse;
  loading: boolean;
  error: string;
  single_parent_solution: TrainingSetModel;
}

const initialState: IInitialState = {
  parentSolutions: {
    training_sets: [] as TrainingSetModel[],
    last_training_set_id: undefined
  },
  loading: false,
  error: "",
  single_parent_solution: {} as TrainingSetModel
};

export const parentSolutionSlice = createSlice({
  name: 'parentSolution',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllParentSolutions.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(getAllParentSolutions.fulfilled, (state, action) => {
      if (state.parentSolutions.last_training_set_id != undefined) {
        state.parentSolutions.training_sets = [...state.parentSolutions.training_sets, ...action.payload.training_sets]
        state.parentSolutions.last_training_set_id = action.payload.last_training_set_id
      } else {
        state.parentSolutions.training_sets = action.payload.training_sets;
        state.parentSolutions.last_training_set_id = action.payload.last_training_set_id
      }
      state.loading = false;
      state.error = ''
    });
    builder.addCase(getAllParentSolutions.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message || ''
    })
    builder.addCase(getParentSolution.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(getParentSolution.fulfilled, (state, action) => {
      state.single_parent_solution = action.payload
      state.loading = false;
    });
    builder.addCase(getParentSolution.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message || ''
    })
  },
});

export default parentSolutionSlice.reducer;