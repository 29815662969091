import { FC } from "react";
import AppleLogin from "react-apple-login";
import { Button, Typography } from "@mui/material";
import { AppleBlack } from "../assests";
import { IAppleOAuthCallbackCredentials } from "../utils/commonModels";

interface Props {
  text: string;
  _onClick: (e:IAppleOAuthCallbackCredentials) => void;
  _marginBottom?: string;
}

const AppleOAuthButton: FC<Props> = ({
  text = "",
  _onClick,
  _marginBottom = "0px",
}) => {
  const buttonContainerStyle = {
    borderRadius: "20px",
    background: "#F9F9F9",
    paddingTop: "12px",
    paddingBottom: "12px",
    width: "100%",
    marginBottom: _marginBottom,
  };

  return (
    <AppleLogin
      clientId="com.parentwiser.parentingapp.sid"
      redirectURI="https://app.parentwiser.com/login"
      usePopup={true}
      callback={(e) => _onClick({code:e.authorization.code, id_token:e.authorization.id_token})}
      scope="email name"
      responseMode="query"
      render={(renderProps) => (
        <Button
          onClick={renderProps.onClick}
          style={buttonContainerStyle}
          startIcon={<img src={AppleBlack} alt="" />}
        >
          <Typography
            style={{
              textTransform: "none",
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#171725",
            }}
          >
            {text}
          </Typography>
        </Button>
      )}
    />
  );
};

export default AppleOAuthButton;
