import { FC } from "react";
import { Typography } from "@mui/material";
import { AskWiserWarning, DoubleChatBallon } from "../assests";

interface Props {
  text:string;
  time:string;
  legalWarningClicked:() => void
}

const AskWiserTextAi: FC<Props> = ({
  text,
  time,
  legalWarningClicked
}) => {
  return (
    <div
      style={{
        paddingRight: "20%",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
      }}
    >
      <div
        style={{
          borderRadius: "0px 15px 15px 15px",
          padding: "15px 20px",
          backgroundColor: "#ECF1F6",
          textAlign: "left",
          width: "fit-content",
          
        }}
      >
        <div style={{display:"flex", alignItems:"center",justifyContent:"space-between"}}> 
          <div
            style={{
              padding: "10px",
              backgroundColor: "#2574FF",
              width: "fit-content",
              borderRadius: "50%",
              display: "flex",
              marginBottom:"21px"
            }}
          >
            <img src={DoubleChatBallon} alt="" />
          </div>
          <div style={{display:"flex", flexDirection:"column", alignItems:"center",marginBottom:"20px", cursor:"pointer"}} onClick={legalWarningClicked}>
            <img src={AskWiserWarning} alt="" />
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
                fontSize: "10px",
                lineHeight: "10px",
                color: "#78828A",
              }}
            >
              Yasal Uyarı
            </Typography>
          </div>
        </div>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "25px",
            color: "#434E58",
          }}
        >
          {text}
        </Typography>
      </div>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: 500,
          fontSize: "12px",
          lineHeight: "20px",
          color: "#78828A",
          textAlign: "right",
        }}
      >
        {time}
      </Typography>
    </div>
  );
};

export default AskWiserTextAi;
