import { Typography } from '@mui/material'
import { FC } from 'react'

interface Props {
    text?:string
}

const SurveyResultSubHeader:FC<Props> = ({
    text=""
}) => {

    const containerStyle = {
        padding:"19px 59px",
        background: "#FFFFFF",
        boxShadow: "0px 10px 20px rgba(0, 64, 128, 0.1)",
        borderRadius: "12px",
        width: "max-content",

    }

  return (
    <div style={containerStyle}>
        <Typography style={{
            fontFamily:"Montserrat",
            fontWeight:"700",
            fontSize:"24px",
            lineHeight:"32px",
            color:"#2574FF"
        }}>
            {text}
        </Typography>
    </div>
  )
}

export default SurveyResultSubHeader