import { FC } from "react";
import { Grid, Typography,Menu, MenuItem } from "@mui/material";
import { DotsVertical, RedTrash } from "../assests";

interface Props {
  bankName: string;
  cardType: string;
  lastFour:string;
  binNumber:string
  onDotsMenuClicked: (e:React.MouseEvent<HTMLElement>) => void
  ancherOpen:boolean
  _ancherEl:HTMLElement | null
  _setAnchorEl:(e:HTMLElement | null) => void
  removeCardTrigger:(binNumber:string, lastFour:string) => void
}

const SavedCardItem: FC<Props> = ({ bankName = "", cardType = "", lastFour="",onDotsMenuClicked,ancherOpen=false,_ancherEl=null,_setAnchorEl,binNumber,removeCardTrigger }) => {
  const containerStyle = {
    background: "#FFFFFF",
    border: "1px solid #F0F0F0",
    borderRadius: "8px",
    padding: "23px 20px",
  };

  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }} style={containerStyle}>
      <Menu
        anchorEl={_ancherEl}
        open={ancherOpen}
        onClose={() => _setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom", 
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={()=>removeCardTrigger(binNumber,lastFour)} style={{
          width:"180px"
        }}>
          <img src={RedTrash} alt="" />
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "20px",
              color: "#171725",
              marginLeft:"5px"
            }}
          >
            Kartı Sil
          </Typography>
        </MenuItem>
      </Menu>
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#000000",
          }}
        >
          {bankName} - {cardType}
        </Typography>
        <img src={DotsVertical} alt="" style={{cursor:"pointer"}} onClick={onDotsMenuClicked}/>
      </Grid>
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{ marginTop: "16px", marginBottom: "2px" }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#434E58",
          }}
        >
          **** **** **** {lastFour}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SavedCardItem;
