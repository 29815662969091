import { FC, MouseEvent } from "react";
import { Grid, Typography } from "@mui/material";
import { HeartRed, HearthEmptyWithShadow } from "../assests";
import { Buckets } from "../utils/constants";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store/store";
import { FavoriteType } from "../client";
import { useNavigate } from "react-router-dom";

interface Props {
  id: string;
  title: string;
  is_picture_uploaded: boolean;
  addContentToFavorite: (
    e: MouseEvent,
    id: string,
    name_of_content: string
  ) => void;
  removeContentFromFavorite: (e: MouseEvent, id: string) => void;
}

const ArticleListingItem: FC<Props> = ({
  id = "",
  title = "",
  is_picture_uploaded = false,
  addContentToFavorite,
  removeContentFromFavorite,
}) => {
  const { favorite_items } = useSelector((state: RootState) => state.favorite);
  let navigate = useNavigate();

  const imageGridStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${
      is_picture_uploaded ? `${Buckets.ArticleImageBucket}/${id}.jpg` : ""
    })`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "180px",
    borderRadius: "24px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "start",
    marginBottom: "16px",
    padding: 10,
    cursor: "pointer",
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={imageGridStyle}
        onClick={() => navigate(`/article/${id}`)}
      >
        {favorite_items.some((e) => {
          return (
            (e.favourite_type == FavoriteType.ARTICLE) &&
            e.id == id
          );
        }) ? (
          <img
            src={HeartRed}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={(e) => removeContentFromFavorite(e, id)}
          />
        ) : (
          <img
            src={HearthEmptyWithShadow}
            style={{ cursor: "pointer" }}
            alt=""
            onClick={(e) => addContentToFavorite(e, id, title)}
          />
        )}
      </Grid>
      <Grid item xs={12} style={{ textAlign: "start" }}>
        <Typography
          component={"span"}
          style={{
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#171725",
            cursor: "pointer",
          }}
          onClick={() => navigate(`/article/${id}`)}
        >
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ArticleListingItem;
