import { FC, useState } from "react";
import FaqAccordion from "./FaqAccordion";
import { Typography } from "@mui/material";

const FaqSupport: FC = () => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const faqData = [
    {
      title: "ParentWiser’a nasıl üye olunur?",
      description:
        "ParentWiser’ı Google Play Store ve App Store’dan indirerek; mail adresi ve oluşturacağınız şifre ile kolayca üye olabilirsiniz.",
    },
    {
      title: "PW Kullanıcı Paneli Nedir?",
      description:
        "ParentWiser Kullanıcı Paneli uygulamaya kayıt olduğunuz e-posta adresi ve şifreniz ile giriş sağlayarak hesap işlemlerinizi düzenleyebileceğiniz bir sayfadır.",
    },
    {
      title:
        "ParentWiser içeriklerine neden kullanıcı panelinden ulaşamıyorum?",
      description:
        "ParentWiser Kullanıcı Paneli yalnızca hesap işlemlerinizi düzenlemek için kullanabileceğiniz bir sayfadır, içerikleri görüntülemek için ParentWiser uygulamasını Google Play veya App Store’dan indirmelisiniz.",
    },
    {
      title: "Üyelik planlarınız hakkında bilgi almak istiyorum.",
      description:
        "ParentWiser’da yer alan içerikler siz değerli kullanıcılarımız için 1 yıl boyunca faydalanılabilecek şekilde düzenlenmiştir, bu bağlamda üyelik planımız 1 yıllıktır.",
    },
    {
      title:
        "Uygulamaya birden fazla çocuk eklenebilir mi? Her çocuk için ayrı hesap mı oluşturmalıyım?",
      description:
        "ParentWiser’a birden fazla çocuk ekleyebilirsiniz. Çocuklarınızın yaş ve sınıflarının farklı olması farketmeksizin tüm içeriklere ulaşabilirisiniz.",
    },
    {
      title:
        "Yurt dışında ikamet ediyorum, uygulamayı nasıl satın alabilir miyim?",
      description:
        "ParentWiser’ı Türk Lirası, Dolar, Euro, Sterlin para birimi üzerinden olan tüm banka ve kredi kartları ile satın alabilirsiniz.",
    },
  ];

  return (
    <>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "600",
          fontSize: "20px",
          lineHeight: "28px",
          color: "#171725",
          marginBottom: "30px",
        }}
      >
        Sıkça Sorulan Sorular
      </Typography>
      {faqData.map((item, index) => (
        <FaqAccordion
          expanded={expanded}
          setExpanded={setExpanded}
          accName={index.toString()}
          title={item.title}
          description={item.description}
        />
      ))}
    </>
  );
};

export default FaqSupport;
