import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@mui/material";
import { FC } from "react";
import CommonButton from "./CommonButton";
import CommonLightButton from "./CommonLightButton";

interface Props {
  isOpen: boolean;
  title: string;
  text: string;
  onCancelButtonFunc: () => void;
  onSubmitButtonFunc: () => void;
  cancelButtonText: string;
  submitButtonText: string;
}

const CommonConfirmationAlert: FC<Props> = ({
  isOpen = false,
  title = "",
  text = "",
  onCancelButtonFunc,
  onSubmitButtonFunc,
  cancelButtonText = "",
  submitButtonText = "",
}) => {
  return (
    <Dialog open={isOpen} onClose={onCancelButtonFunc}>
      <DialogContent
        sx={{
          ".MuiPaper-root": {
            padding: "20px",
          },
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "20px",
            marginBottom:"20px"
          }}
        >
          {title}
        </Typography>
        <DialogContentText>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ backgroundColor: "#F9F9F9", padding:"20px" }}>
        <CommonLightButton
          text={cancelButtonText}
          onClick={onCancelButtonFunc}
        />
        <CommonButton text={submitButtonText} onClick={onSubmitButtonFunc} customCSS={{backgroundColor:"#FA4169"}}/>
      </DialogActions>
    </Dialog>
  );
};

export default CommonConfirmationAlert;
