import { Dialog, DialogContent, Grid, Typography } from '@mui/material'
import { FC } from 'react'
import RegisterTextField from './RegisterTextField';
import RegisterDatePicker from './RegisterDatePicker';
import RegisterChildGenderList from './RegisterChildGenderList';
import CommonButton from './CommonButton';
import { ChildPatchModel, Gender } from '../client';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store/store';
import { updateChildLocally } from '../redux/childSlice';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    updateChild: () => void
}

const UpdateChildModal:FC<Props> = ({
    isOpen,
    onClose,
    updateChild
}) => {
    const { child } = useSelector((state: RootState) => state.child)
    const dispatch = useDispatch<AppDispatch>();

  return (
    <Dialog open={isOpen} onClose={onClose} style={{ width: 'auto'}} hideBackdrop>
        <DialogContent  sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          width: "fit-content",
          borderRadius:"16px"
        }}>
        <Grid container columns={{xs:4, sm:8, md:12}}>
            <Grid item xs={4} sm={8} md={12} style={{ textAlign: "start" }}>
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "28px",
                  color: "#171725",
                  marginBottom: "18px",
                }}
              >
                Çocuğunuzun adı
              </Typography>
              <RegisterTextField
                _placeHolder="Çocuğun adını gir"
                _placeHolderAlign="start"
                _defaultValue={child.name}
                _onChange={(e: string) => dispatch(updateChildLocally({ ...child, name: e }))}
              />
            </Grid>
            <Grid
              item
              xs={4}
              sm={8}
              md={12}
              style={{ textAlign: "start", marginTop: "22px" }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "28px",
                  color: "#171725",
                  marginBottom: "18px",
                }}
              >
                Çocuğunuzun doğum tarihi
              </Typography>
              <RegisterDatePicker
                _placeHolder="Çocuğun doğum tarihini gir"
                _placeHolderAlign="start"
                _defaultValue={child.date_of_birth}
                _onChange={(e: string) =>
                    dispatch(updateChildLocally({ ...child, date_of_birth: e }))
                }
              />
            </Grid>
            <Grid
              item
              xs={4}
              sm={8}
              md={12}
              style={{ textAlign: "start", marginTop: "22px" }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "28px",
                  color: "#171725",
                  marginBottom: "18px",
                }}
              >
                Çocuğunuzun cinsiyeti
              </Typography>
              <RegisterChildGenderList
                selectedName={child?.gender}
                _onClicked={(e: Gender) =>
                    dispatch(updateChildLocally({ ...child, gender: e }))
            }
              />
            </Grid>
            <Grid
              item
              xs={4}
              sm={8}
              md={12}
              style={{ textAlign: "center", marginTop: "30px" }}
            >
              <CommonButton
                text="Kaydet"
                disabled={
                  child?.name && child.date_of_birth && child.gender
                    ? false
                    : true
                }
                onClick={updateChild}
              />
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "18px",
                  fontWeight: "600",
                  lineHeight: "26px",
                  color: "#9CA4AB",
                  marginTop: "16px",
                  cursor:"pointer"
                }}
                onClick={onClose}
              >
                İptal
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
    </Dialog>
  )
}

export default UpdateChildModal