import { FC } from "react";
import { Typography } from "@mui/material";

const UsageAgreement: FC = () => {
  return (
    <>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "600",
          fontSize: "20px",
          lineHeight: "28px",
          color: "#171725",
          marginBottom: "30px",
        }}
      >
        Kullanım Sözleşmesi
      </Typography>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#78828A",
          marginTop: "30px",
        }}
      >
        MADDE 1 – TARAFLAR İşbu sözleşme ve ekleri (EK-1 Gizlilik Sözleşmesi)
        “Çifte Havuzlar Mah. Eski Londra Asfaltı Caddesi Kuluçka Merkezi A1 Blok
        No:151-1C-1B20 Esenler İstanbul” adresinde mukim “Parentwiser Yazılım
        Anonim Şirketi” (bundan böyle "PW" olarak anılacaktır) ile Parentwiser
        mobil uygulamasına iş bu sözleşmede belirtilen koşullar dahilinde kayıt
        olan Kullanıcı ile karşılıklı olarak kabul edilerek yürürlüğe girmiştir.
        MADDE 2 – TANIMLAR 2.1. Uygulama: PW isimli mobil uygulama, mobil
        cihazlarda çalışan mobil uygulama yazılımı ve bu yazılım ile bağlantılı
        olarak oluşturulan ürün ve hizmetlerin sunulduğu tüm sanal mecraları
        ifade etmektedir. (Bundan böyle "Uygulama" olarak anılacaktır.) 2.2.
        Kullanıcı: Uygulamadan PW tarafından sunulan hizmetlerden işbu
        sözleşmede belirtilen koşullar dahilinde yararlanan kişileri ifade
        etmektedir. (Bundan böyle "Kullanıcı” olarak anılacaktır.) 2.3. Sisteme
        Erişim Araçları: Kullanıcının hesabını yönetim sayfası, uygulamaya
        erişim sağlayan kullanıcı adı, şifre, kod, parola gibi yalnızca
        kullanıcının bilgisinde olan bilgileri ifade eder. 2.4. İletişim
        Kanalları: Anında bildirim, e-posta, sms, mms, telefon ile bildirim gibi
        iletişim mecralarını ifade eder. 2.5. Kullanıcı Hesabı Yönetim Sayfası:
        Kullanıcının yalnızca sisteme erişim araçlarını kullanarak ulaştığı
        üyeliğine ilişkin iş ve işlemlerde bulunduğu kendisine özel web
        sayfasını ifade etmektedir. (Bundan böyle kısaca "Portal" olarak
        anılacaktır.) MADDE 3 – KONU VE KAPSAM 3.1. İşbu sözleşmenin konusu;
        kullanıcıya yönelik olarak uygulamada mevcut olan ve ileride PW
        tarafından sunulacak tüm ürün ve hizmetlerin, bu ürün ve hizmetlerden
        yararlanma şartlarının ve tarafların hak ve yükümlülüklerinin
        tespitidir. 3.2. Kullanıcı, işbu sözleşme hükümlerini kabul etmekle,
        uygulama ve portal içinde yer alan kullanıma, üyeliğe ve hizmetlere
        ilişkin PW tarafından açıklanan her türlü beyanı da kabul etmiş
        olmaktadır. Kullanıcı, bahsi geçen beyanlarda belirtilen her türlü
        hususa uygun olarak davranacağını kabul, beyan ve taahhüt eder. MADDE 4
        – UYGULAMA KULLANIM ŞARTLARI 4.1. Kullanıcı, uygulamanın kullanımından
        doğan tüm sorumluluğu ve riski üstlendiğini kabul eder. Uygulama yoluyla
        sağlanan tüm düşünce, tavsiye, hizmet veya diğer bilgi ve materyalin
        doğruluk, tamlık ve kullanılırlığını değerlendirmek yalnızca
        kullanıcının sorumluluğundadır. Uygulamaya üye olan ya da ziyaret eden
        kullanıcıların bilgileri, şirket tarafından gerekli görüldüğü takdirde
        üçüncü kişilerle paylaşılabilir. Kişisel bilgiler, gerektiğinde
        kullanıcıyla iletişime geçmek için de kullanılabilir. 4.2. PW tarafından
        talep edilen bilgiler veya kullanıcı tarafından sağlanan bilgiler veya
        mobil uygulama üzerinden yapılan işlemlerle ilgili bilgiler, PW ve iş
        birliği içinde olduğu kişiler tarafından, PW’nin üyeleri ve müşterileri
        ile yaptığı sözleşmeler ile belirlenen amaçlar ve kapsam dışında olsa
        dahi kullanıcının kimliği ifşa edilmemek kaydıyla, çeşitli istatistiksel
        değerlendirmeler yapmak, veritabanı oluşturmak, pazar araştırmaları
        yapmak veya benzer sebeplerle de kullanılabilir. PW, mobil uygulama
        dahilinde başka uygulamalara link verebilir. Şirket, link vasıtasıyla
        erişilen uygulamaların gizlilik uygulamaları ve içeriklerine yönelik
        herhangi bir sorumluluk taşımamaktadır. MADDE 5 – KARŞILIKLI HAK VE
        YÜKÜMLÜLÜKLER 5.1. Kullanıcı Hak ve Yükümlülükleri: a) Kullanıcı,
        kullanıcı prosedürlerini yerine getirirken, uygulamadaki ürün veya
        hizmetlerden faydalanırken ve uygulamadaki ürün veya hizmetler ile
        ilgili herhangi bir işlemi yerine getirirken işbu sözleşme içerisinde
        yer alan tüm şartlara ve yürürlükteki tüm mevzuata uygun hareket
        edeceğini, işbu sözleşmede belirtilen tüm şart ve kuralları okuduğunu,
        anladığını ve onayladığını kabul eder. b) Kullanıcı, PW’nin yasal
        mevzuat gereği resmi makamlara açıklama yapmakla yükümlü olduğu
        durumlarda kullanıcılara ait, uygulama dahilinde edinilen bilgileri
        resmi makamlara açıklamaya yetkili olacağını ve bu sebeple kendisinden
        her ne nam altında olursa olsun tazminat talep edilemeyeceğini kabul
        eder. c) Kullanıcı, uygulamaya erişmek için kullandığı sisteme erişim
        araçlarını (kullanıcı adı, şifre, kod gibi) gizli tutmakla yükümlüdür.
        Sisteme erişim araçlarının üçüncü bir şahıs tarafından kullanımının
        sonuçlarından tamamı ile Kullanıcı sorumludur. PW, usulsüz giriş
        yapan/yapmaya çalışan kişilerin kimliklerini tespit etmekle yükümlü
        değildir. d) PW, kullanıcı tarafından PW’ye iletilen veya uygulama
        üzerinden kendisi tarafından yüklenen, değiştirilen ve sağlanan
        bilgilerin ve içeriklerin doğruluğunu araştırma, bu bilgi ve içeriklerin
        güvenli, doğru ve hukuka uygun olduğunu taahhüt ve garanti etmekle
        yükümlü ve sorumlu olmadığı gibi, söz konusu bilgi ve içeriklerin yanlış
        veya hatalı olmasından dolayı ortaya çıkacak hiçbir zarardan da sorumlu
        değildir. e) Kullanıcı işbu uygulamanın PW’ye ait olduğunu ve onun
        tarafından işletildiğini kabul eder. İşbu uygulamanın içeriği Fikri ve
        Sınai haklar doğrultusunda korunmaktadır. Kullanıcı, PW ve/veya başka
        bir üçüncü şahsın aynı veya şahsi haklarına, malvarlığına tecavüz teşkil
        edecek nitelikteki uygulama dahilinde bulunan resimleri, metinleri,
        görsel ve işitsel imgeleri, video klipleri, dosyaları, veri tabanlarını,
        katalogları ve listeleri çoğaltmayacağını, kopyalamayacağını,
        dağıtmayacağını, işlemeyeceğini kabul ve taahhüt etmektedir. Uygulamanın
        (genel görünüm, tasarım, metin, imge, logo, ikon, yazılı, elektronik,
        grafik veya makinede okunabilir şekilde sunulan teknik verileri, PW
        markası, uygulanan iş metodu ve iş modeli, yazılım kodu ve diğer kodlar
        da dahil ve fakat bunlarla sınırlı olmamak kaydıyla) tüm unsurları PW’ye
        aittir ve/veya PW tarafından üçüncü bir kişiden alınan telif hakkı
        altında kullanılabilecektir. Fikri ve Sınai haklar kapsamında korunan
        PW’ye ait tüm bu unsurlar önceden ve yazılı olarak izin alınmadan ve
        kaynak gösterilmeden değiştirilemez, kopyalanamaz, çoğaltılamaz, başka
        bir lisana çevrilemez, yeniden yayımlanamaz, yeniden satılamaz,
        paylaşılamaz, dağıtılamaz, sergilenemez, Kullanıcı Sözleşmesi kapsamı
        dışında kullanılamaz veya bunlardan türemiş çalışmalar yapılamaz veya
        hazırlanamaz, aksi şekilde davranılması halinde, sorumlu kişi/kişiler
        PW’nin uğradığı zararı ve/veya lisans verenler de dahil üçüncü kişilerin
        uğradıkları zararlardan dolayı PW’den talep edilen tazminat miktarını,
        mahkeme masrafları ve avukatlık ücreti de dahil olmak üzere karşılamakla
        yükümlü olacaklardır. Ayrıca bu yükümlülüğün ihlalinin tespit edilmesi
        halinde PW, KULLANICI’NIN üyeliliği tek taraflı olarak feshederek,
        sisteme girişini engelleme hakkına sahiptir. Bu halde PW’nin
        kullanıcıdan uygulama içinde elde ettiği verileri kullanmaya devam etme
        hakkı saklıdır. f) Uygulamada ve uygulama üzerinden link verilen
        internet sitelerinde, kullanıcı da dahil olmak üzere üçüncü kişiler
        tarafından yayınlanan içeriklerden dolayı PW’nin sorumluluğu
        bulunmamaktadır. Herhangi bir üçüncü kişi tarafından sağlanan ve
        yayınlanan bilgilerin, içeriklerin, görsel ve işitsel imgelerin
        doğruluğu ve hukuka uygunluğunun taahhüdü bütünüyle bu eylemleri
        gerçekleştiren kişilerin sorumluluğundadır. PW, üçüncü kişiler
        tarafından sağlanan hizmetlerin ve içeriklerin güvenliğini, doğruluğunu
        ve hukuka uygunluğunu taahhüt ve garanti etmemektedir. g) Kullanıcı ve
        PW birbirinden bağımsız taraflardır. İşbu sözleşmenin taraflarca
        onaylanması ve uygulanması, aralarında ortaklık, temsilcilik veya işçi –
        işveren ilişkisi olduğu sonucunu doğurmaz. h) Kullanıcı işbu sözleşme
        kapsamında üçüncü kişilere referans olmak istemesi halinde, göndereceği
        mesajlarının hukuka ve ahlaka aykırı unsurlar içermeyeceğini kabul,
        beyan ve taahhüt eder. Kullanıcı, göndereceği elektronik postalar ile
        üçüncü kişilerce istenmeyen elektronik posta iletişimi olarak
        nitelendirilebilecek yoğunlukta bir iletişimde bulunmayacağını kabul,
        beyan ve taahhüt eder. Kullanıcı, bu madde hükmüne aykırı olarak
        yapacağı bütün iletişimin sorumluluğunun kendisine ait olduğunu, her ne
        sebeple olursa olsun bu iletişimlerden kaynaklı olarak PW’ye bir dava ve
        talep yöneltilmesi halinde; bu dava ve talebin PW tarafından kendisine
        ihbar edilebileceğini, PW’nin savunma yapması için gerekli olan her
        türlü bilgi ve içeriği kendisine savunma için gerekli yasal sürelerin
        geçirilmemesini teminen derhal sağlayacağını, bahsi geçen dava ve talep
        sonucunda PW’nin herhangi bir zarara uğraması durumunda bahsi geçen
        zararın PW tarafından, tazminat hakları saklı kalmak kaydıyla, kendisine
        rücu ettirilebileceğini kabul, beyan ve taahhüt eder. Kullanıcının işbu
        maddeye aykırı hareket etmesi sebebiyle PW işbu sözleşmeyi herhangi bir
        bildirimde bulunmadan tek taraflı olarak feshetme, üyeliği askıya alma
        veya iptal etme ve kullanıcının erişimini engelleme hakkını saklı
        tutmaktadır. ı) Kullanıcı, ilgili formları doldurduktan sonra "Kullanıcı
        Sözleşmesi 'ni okudum, müzakere ettim ve sözleşme şartlarını kabul
        ediyorum" maddesini onayladıktan sonra işbu sözleşme kapsamında
        kullanıcı olma ehliyetine sahip olduğunu beyan ve taahhüt etmektedir.
        Aksi halde, bu hususun PW tarafından tespit edilmesi durumunda
        Kullanıcının sözleşmesi derhal feshedilir. 5.2. PW’nin Hak ve
        Yükümlülükleri a) PW, uygulamada sunulan ürün ve hizmetleri ve
        içerikleri her zaman tek taraflı olarak değiştirebilme, kullanıcının
        sisteme yüklediği bilgileri ve içerikleri kullanıcı da dahil olmak üzere
        üçüncü kişilerin erişimine kapatabilme ve silme hakkını saklı
        tutmaktadır. PW, bu hakkını hiçbir bildirimde bulunmadan ve önel
        vermeden kullanabilir. PW tarafından kullanıcıdan talep edilen
        değişiklik ve/veya düzeltme istekleri belirtilen süre içerisinde yerine
        getirilmediği takdirde doğmuş ve doğabilecek zararlardan PW sorumlu
        olmayacak ve kullanıcının uygulamayı kullanma hakkını dondurabilme veya
        sonlandırma hakkına sahip olacaktır. b) Referans kolaylığı veya çeşitli
        sebepler nedeniyle uygulama içerisinde PW kontrolünde olmayan bir takım
        web sitelerine veya içeriklere linkler verilebilir. Bu linkler bahsi
        geçen web sitesini veya içerik sahibini destekleyici mahiyette olmadığı
        gibi, web sitesi veya içerikte yer alan bilgilere yönelik herhangi bir
        türde bir beyan veya garanti niteliği de taşımamaktadır Uygulama
        üzerindeki linkler vasıtasıyla erişilen portallar, web siteleri,
        dosyalar ve içerikler, bu linkler vasıtasıyla erişilen portallar veya
        web sitelerinden sunulan hizmetler veya ürünler veya bunların içeriği
        hakkında PW’nin herhangi bir sorumluluğu yoktur. c) PW, uygulamada yer
        alan kullanıcı bilgilerini hizmetler dışında da kullanıcı güvenliği,
        kendi yükümlülüğünü ifa ve bazı istatistiki değerlendirmeler ve iç
        denetimler için veya demografik bilgi olarak reklam ve/veya tanıtım için
        dilediği biçimde kullanabilir, bunları bir veri tabanı üzerinde tasnif
        edip muhafaza edebilir. d) PW, işbu uygulama ve uzantısında mevcut her
        tür hizmet, ürün, uygulamayı kullanma koşulları ile uygulamada sunulan
        bilgileri önceden bir ihtara gerek olmaksızın değiştirme, yeniden
        organize etme, uygulamayı durdurma hakkını saklı tutar. Değişiklikler,
        uygulamada yayım anında yürürlüğe girer. Kullanıcılar, uygulamanın
        kullanımı ya da uygulamaya giriş ile bu değişiklikleri de kabul etmiş
        sayılır. Değişiklikler, PW tarafından belirlenen mecra üzerinden
        Kullanıcılara duyurulur. e) PW, uygulama kapsamında verdiği hizmetlerin
        sürekli aktif ve erişilebilir olacağına dair hiçbir garanti
        vermemektedir. PW özellikle yargı ve sair yetkili resmi merci karar ve
        uygulamaları, mücbir sebepler, üçüncü kişilerin sebep olduğu durumlar,
        Internet bağlantı hizmeti sağlayan kuruluşlardan kaynaklanan aksaklıklar
        ve gecikmeler ve benzeri dış etkenler, yanlış kullanım, PW’dan
        kaynaklanabilecek teknik arıza ve sair aksaklıklar, tamir çalışmaları
        veya diğer yönlendirmeler sonucu meydana gelen aksaklıklar konusunda
        hiçbir şekilde sorumluluk kabul etmez. f) PW gerektiğinde, ağın işletim
        güvenliğinin tehlikede olması, ağa erişimin devamlılığı, ağda, yazılımda
        veya kayıtlı dosyalarda meydana gelebilecek arızaların önüne geçebilmek,
        muhtemel aksaklıkları engellemek veya etkisini azaltmak adına ve gerekli
        gördüğü diğer durumlarda hizmete erişimi sınırlandırabilir veya
        durdurabilir. MADDE 6 – HİZMETLER 6.1. PW, uygulama aracılığıyla, abone
        olunan paket bazında tanımlanacak içerikleri sunacaktır. MADDE 7 –
        GİZLİLİK 7.1. PW, kişisel bilgi ve veri güvenliğini önemsemekte ve bu
        hususta gerekli olan tüm tedbirleri almaya özen göstermektedir.
        Kullanıcılar da uygulamayı kullanarak işbu gizlilik sözleşmesi
        hükümlerine uygun davranacaklarını kabul, beyan ve taahhüt ederler. 7.2.
        İşbu gizlilik hükümleri, uygulamada yer alan tüm içerik hakkında
        geçerlidir. 7.3. PW, uygulama kapsamında kullanıcı tarafından
        paylaşılmış olan bilgilere erişebilir. PW, bu bilgileri uygulamanın
        kullanımına yönelik olarak iştirakçileri ile paylaşabilecektir.
        Kullanıcı da bu bilgilerin uygulamanın amacına yönelik olarak kendisi
        ile iletişime geçilmek için kullanılacağını kabul eder. 7.4.
        Uygulamadaki sistemle ilgili sorunların tanımlanabilmesi ve uygulamada
        çıkabilecek muhtemel sorunların acil olarak giderilmesi için, PW
        gerektiğinde kullanıcıların IP adresini, sosyal ağ kullanıcı hesabında
        kayıtlı bilgileri kaydedebilir ve bu kayıtları anılan bu amaçlarla
        kullanabilir. Bu IP adresleri, PW tarafından kullanıcılarını ve
        ziyaretçilerini genel anlamda tanımlamak ve kapsamlı şekilde demografik
        veri toplayabilmek amacıyla kullanılabilir. 7.5. Uygulama kapsamında
        elde edilen bilgiler PW ve iş birliği içinde olduğu diğer kişi ve
        kurumlar ile, kullanıcının kimliği anonim olarak kalmak kaydıyla çeşitli
        istatistiki değerlendirmeler, izinli pazarlama, veri tabanı oluşturma
        çabaları ve pazar araştırmaları yapma gibi durumlar dahilinde
        paylaşılabilir. 7.6. PW, uygulama içerisinde başkaca sitelere link
        (bağlantı) sağlayabilir, anlaşmalı olduğu 3. partilerin reklamlarını
        yayınlayabilir, Kullanıcıları reklamlar aracılığıyla reklam veren veya
        anlaşmalı 3. partilerin sitesine yönlendirebilir. PW, bu bağlantı
        yoluyla erişilen diğer Uygulamaların gizlilik uygulama ve politikalarına
        ya da barındırdıkları içeriklere yönelik olmak üzere hiç bir sorumluluk
        taşımamaktadır. 7.7. PW, aşağıda sayılan hallerde ise işbu gizlilik
        bildirimi hükümleri dışına çıkarak kullanıcılara ait bilgileri üçüncü
        kişilere açıklayabilecektir. Bu durumlar; • Hukuk kurallarının getirdiği
        zorunluluklara uyulmasının gerektiği haller, • PW’nin, kullanıcılarıyla
        arasındaki sözleşmelerin gereklerinin yerine getirilmesi ve bunların
        uygulamaya konulmalarıyla ilgili hallerde, • Yetkili idari ve/veya adli
        makamlar tarafından usuli yöntemine uygun olarak yürütülen bir araştırma
        veya soruşturma doğrultusunda Kullanıcılarla ilgili bilgi talep edilmesi
        halleri, • Kullanıcıların haklarını veya güvenliklerini koruma amacıyla
        bilgi verilmesinin gerekli olduğu haller. 7.8. PW tarafından Uygulamalar
        içerisinde düzenlenebilecek periyodik veya periyodik olmayan anketlere
        cevap veren Kullanıcılardan talep edilebilecek bilgiler de PW ve iş
        birliği içindeki kişi ya da kurumlar tarafından bu kullanıcılara
        doğrudan pazarlama yapmak, istatistiki analizler ve akademik çalışmalar
        yapmak ve özel bir veri tabanı oluşturmak amacıyla da
        kullanılabilecektir. 7.9. PW, işbu gizlilik bildiriminde geçen hükümleri
        gerekli gördüğü zamanda Uygulamada yayınlamak şartıyla değiştirebilir.
        PW’nin değişiklik yaptığı gizlilik bildirimi hükümleri Uygulamada
        yayınlandığı tarihte yürürlüğe girmiş kabul edilir. MADDE 8 –
        UYGULANACAK HUKUK VE YETKİLİ MAHKEME İşbu sözleşmenin uygulanmasında ve
        yorumlanmasında Türk Hukuku uygulanacaktır. İşbu sözleşmeden dolayı
        doğan veya doğabilecek her türlü ihtilafın hallinde İstanbul Çağlayan
        Mahkemeleri ve İcra Daireleri yetkilidir. MADDE 9 – FİKRİ MÜLKİYET
        HAKLARI Uygulama'nın (genel görünüm, tasarım, metin, imge, logo, ikon,
        demonstratif, yazılı, elektronik, grafik veya makinede okunabilir
        şekilde sunulan teknik verileri, PW markası, uygulanan iş metodu ve iş
        modeli, yazılım kodu ve diğer kodlar da dahil ve fakat bunlarla sınırlı
        olmamak kaydıyla) tüm unsurları PW’ye aittir ve/veya PW tarafından
        üçüncü bir kişiden alınan telif hakkı altında kullanılmaktadır. Fikri ve
        Sınai haklar kapsamında korunan PW’ye ait tüm bu unsurlar önceden izin
        alınmadan ve kaynak gösterilmeden değiştirilemez, kopyalanamaz,
        çoğaltılamaz, başka bir lisana çevrilemez, yeniden yayımlanamaz, yeniden
        satılamaz, paylaşılamaz, dağıtılamaz, sergilenemez, Kullanıcı Sözleşmesi
        kapsamı dışında kullanılamaz veya bunlardan türemiş çalışmalar yapılamaz
        veya hazırlanamaz, aksi şekilde davranılması halinde, sorumlu kişi
        kişiler PW’nin uğradığı zararı ve/veya lisans verenler de dahil üçüncü
        kişilerin uğradıkları zararlardan dolayı PW’den talep edilen tazminat
        miktarını, mahkeme masrafları ve avukatlık ücreti de dahil olmak üzere
        karşılamakla yükümlü olacaklardır. MADDE 10 – SÖZLEŞME DEĞİŞİKLİKLERİ
        PW, tamamen kendi takdirine bağlı ve tek taraflı olarak, işbu kullanıcı
        sözleşmesini veya herhangi bir hükmünü ve eklerini, uygun göreceği
        herhangi bir zamanda, uygulamada ilan etmek suretiyle değiştirebilir. İş
        bu kullanıcı sözleşmesinin değişen hükümleri, ilan edildikleri tarihte
        veya önceden belirtilen geçerlilik tarihi olması halinde ise geçerlilik
        tarihinde geçerlilik kazanacak; geri kalan hükümler, aynen yürürlükte
        kalarak hüküm ve sonuçlarını doğurmaya devam edecektir. İşbu kullanıcı
        sözleşmesi, üyelerin tek taraflı beyanları ile değiştirilemez. MADDE 11
        – MÜCBİR SEBEPLER 11.1. Mücbir sebep kapsamı, salgın hastalık, doğal
        afet, isyan, savaş, grev, PW’nin gerekli bilgi güvenliği önlemleri
        almasına karşın uygulama, portal ve sisteme yapılan saldırılar da dahil
        ve fakat bunlarla sınırlı olmamak kaydıyla PW’nin makul kontrolü
        haricinde gelişen ve PW’nin gerekli özeni göstermesine rağmen
        önleyemediği kaçınılamayacak olaylar olarak yorumlanacaktır. 11.2.
        Mücbir sebep sayılan tüm durumlarda, taraflar işbu sözleşme ile
        belirlenen edinimlerinden herhangi birini geç veya eksik ifa etme veya
        ifa etmeme nedeniyle yükümlü değildir. MADDE 12 – KAYITLARIN GEÇERLİLİĞİ
        Kullanıcı, işbu sözleşmeden doğabilecek ihtilaflarda PW’nin kendi veri
        tabanında, sunucularında tuttuğu elektronik ve sistem kayıtlarının,
        ticari kayıtlarının, defter kayıtlarının, muteber bağlayıcı, kesin ve
        münhasır delil teşkil edeceğini ve bu maddenin HMK 193. madde anlamında
        delil sözleşmesi niteliğinde olduğunu kabul, beyan ve taahhüt eder.
        MADDE 13 – SÖZLEŞMENİN FESHİ İşbu kullanıcı sözleşmesi, kullanıcı
        uygulamaya üye olduğu sürece yürürlükte kalacak ve taraflar arası hüküm
        ve sonuçlarını doğurmaya devam edecektir. Ancak sözleşmede yer alan ve
        PW’nin tek taraflı fesih hakkına başvurduğu hallerde sözleşme sona ermiş
        sayılacaktır. Böyle bir fesih halinde dahi KULLANICI, işbu sözleşmenin
        kendisine yüklediği Fikri Mülkiyet haklarını konusundaki yükümlülüklere
        ve gizlilik hükümlerine SÜRESİZ olarak uymakla yükümlüdür. Aksi halde,
        bu yükümlülüklerin ihlali hakkındaki hükümler kıyasen uygulanır. MADDE
        14 – TEBLİGAT ADRESLERİ 14.1. Kullanıcıların, PW’ye bildirdiği
        elektronik posta adresi, bu sözleşme ile ilgili olarak yapılacak her
        türlü bildirim için yasal adresin isteneceği elektronik posta olarak
        kabul edilir. 14.2. Taraflar, mevcut elektronik postalarındaki
        değişiklikleri yazılı olarak diğer tarafa 3 (üç) gün içinde
        bildirmedikçe, eski elektronik postalara yapılacak isteklerin geçerli
        olacağını ve kendilerine yapılmış sayılacağını kabul ederler. MADDE 15
        –YÜRÜRLÜK 15.1. Kullanıcı, işbu sözleşmede yer alan maddelerin tümünü
        okuduğunu, anladığını, kabul ettiğini ve kendisiyle ilgili olarak
        verdiği bilgilerin doğruluğunu onayladığını beyan, kabul ve taahhüt
        eder. 15.2. İşbu sözleşme, kullanıcının, üyelik talebine, elektronik
        olarak onay verilmesi ile karşılıklı olarak kabul edilerek yürürlüğe
        girmiş olup, ekleriyle birlikte uygulanacaktır. Ekler ile işbu sözleşme
        hükümleri arasında çelişki olması halinde işbu sözleşme hükümleri
        geçerli olacaktır. 15.3. İşbu sözleşme 15 (Onbeş) maddeden oluşmakta
        olup, kullanıcının sitede yer alan kullanıcı sözleşmesini onaylaması
        neticesi, tarafların serbest iradeleri ile kurulmuştur.
      </Typography>
    </>
  );
};

export default UsageAgreement;
