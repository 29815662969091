import { FC, useState, useEffect } from "react";
import { NameLogoBlue } from "../assests";
import { Divider, Box, Grid, Typography } from "@mui/material";
import {
  CommonAlert,
  CommonLabel,
  CommonLoading,
  CommonTextField,
  SubscriptionPaymentAddress,
  SubscriptionPaymentCards,
  SubscriptionPaymentSummaryWithoutLogin,
  TextInputWithRegex,
} from "../components";
import { useNavigate, useParams } from "react-router";
import {
  CheckCouponReturnDto,
  Gender,
  InstallmentOptions,
  MarriageStatus,
  OpenAPI,
  ParentType,
  PurchaseService,
  RegisterDevicePlatform,
  UserService,
} from "../client";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import {
  INewCreditCard,
  IPackagePaymentAddress,
  ISelectCardForPayment,
} from "../utils/commonModels";
import { setCredentialsForSebit, updateUser } from "../redux/authSlice";
import { getAvailablePurchasePackages } from "../redux/purchaseSlice";
import { checkCardHasRegisteredUnderING, guidGenerator, isUserInTurkey } from "../utils/commonFuncs";
import { useSearchParams } from "react-router-dom";
import { UseMetaConversation } from "../hooks";
import { IngMax50DiscountCoupon, MetaEventNames } from "../utils/constants";

const SubscriptionPaymentWithoutLogin: FC = () => {
  const { id } = useParams();
  const {
    access_token,
    profile,
    loading: authLoading,
  } = useSelector((state: RootState) => state.auth);
  const { loading: purchaseLoading, pricing_options } = useSelector(
    (state: RootState) => state.purchase
  );

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [phase, setPhase] = useState("address"); //it could be either payment or address
  const [invoiceAddress, setInvoiceAddress] = useState<IPackagePaymentAddress>({
    nameSurname: profile.name ? profile.name : "",
    country: "Türkiye",
    city: "",
    county: "",
    invoiceAddress: "",
  } as IPackagePaymentAddress);
  const [discountCouponDetail, setDiscountCouponDetail] =
    useState<CheckCouponReturnDto>({
      id: "",
      discount_percent: 0,
      can_be_used_by_pricing_option_ids: [],
    });
  const [discountCouponError, setDiscountCouponError] = useState<string>("");
  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const [creditCard, setCreditCard] = useState<INewCreditCard>({
    card_holder_name: "",
    card_number: "",
    expiration_date: "",
    cvc: "",
  });
  const [paymentError, setPaymentError] = useState<string>("");
  const [selectedSavedCard, setSelectedSavedCard] =
    useState<ISelectCardForPayment>({
      card_bin_number: "",
      card_last_four_digits: "",
    });
  const [discountCouponSuccessMessage, setDiscountCouponSuccessMessage] =
    useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [isUserLocatedInTurkey, setIsUserLocatedInTurkey] =
    useState<boolean>(false);
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [registerError, setRegisterError] = useState<string>("");
  const [isRegisterDone, setIsRegisterDone] = useState<boolean>(false);

  const [kvkkAgreement, setKvkkAgreement] = useState<boolean>(false);
  const [communicationAgreement, setCommunicationAgreement] =
    useState<boolean>(false);

  const [selectedInstallment, setSelectedInstallment] = useState<number>(1)
  const [installmentOptions, setInstallmentOptions] = useState<
    Array<InstallmentOptions>
  >([] as InstallmentOptions[]);
  const [doesInstallmentOptionChecked, setDoesInstallmentOptionChecked] =
    useState<boolean>(false);
  const [bankNameForIcon, setBankNameForIcon] = useState<string>("")
  const [cardAssociationForIcon, setCardAssociationForIcon] = useState<string>("")

  useEffect(() => {
    if(access_token == ""){
      return
    }else {
      if(profile.is_user_premium){
        navigate("/")
      }else {
        const discountCoupon = searchParams.get("coupon")
        navigate(`/package-payment/${id}${(discountCoupon === null || discountCoupon === undefined) ? "" : `?coupon=${discountCoupon}`}`)
      }
    }
  }, [])

  useEffect(() => {
    const fetchCountry = async () => {
      setLocalLoading(true);
      const locationIndex = await isUserInTurkey();
      setIsUserLocatedInTurkey(locationIndex);
      setLocalLoading(false);
    };

    fetchCountry();
  }, []);

  useEffect(() => {
    setLocalLoading(false);
    if (OpenAPI.TOKEN == undefined && access_token != "") {
      OpenAPI.TOKEN = access_token;
    }
    if (id != undefined || id != null || profile.is_user_premium) {
      dispatch(getAvailablePurchasePackages());
      // dispatch(getMySavedCards());
    } else {
      navigate("/home");
    }

    if (searchParams.get("coupon") && searchParams.get("coupon") != null) {
      checkDiscountCouponLocal(searchParams.get("coupon") as string);
    }
  }, []);

  useEffect(() => {
    if (
      !doesInstallmentOptionChecked &&
      creditCard.card_number.replaceAll(" ", "").length >= 6
    ) {
      setDoesInstallmentOptionChecked(true);
      const fetchInstallmentOptions = async () => {
        try {
          const installmentOptionsResponse =
            await PurchaseService.getCardInstallmentOptionsPurchaseGetCardInstallmentOptionsPost(
              id ? id : "",
              creditCard.card_number.replaceAll(" ", "").substring(0, 6),
              discountCouponDetail.id == ""
                ? undefined
                : discountCouponDetail.id
            );

            setInstallmentOptions(installmentOptionsResponse.installment_options)
          setBankNameForIcon(installmentOptionsResponse.card_family_name)
          setCardAssociationForIcon(installmentOptionsResponse.card_association)
        } catch (error) {
          setInstallmentOptions([{installment_number:selectedInstallment,installment_price:calculateWhatUserPay(), total_price:calculateWhatUserPay()}])
        }
      };
      fetchInstallmentOptions()
    }
  }, [discountCouponDetail.id, creditCard.card_number]);

  useEffect(() => {
    if (creditCard.card_number.replaceAll(" ", "").length < 6) {
      setDoesInstallmentOptionChecked(false);
      setSelectedInstallment(1)
      setInstallmentOptions([] as InstallmentOptions[])
      setBankNameForIcon("")
      setCardAssociationForIcon("")
    }
  }, [creditCard]);

  const calculateWhatUserPay = (): number => {
    if(selectedInstallment != 1){
      return Number.parseFloat(Number.parseFloat("" + installmentOptions.find(e => e.installment_number === selectedInstallment)?.total_price).toFixed(2));
    }

    const packagePrice = pricing_options.find((e) => e.id == id)
      ?.price as number;
    const paidPrice =
      packagePrice -
      (packagePrice / 100) * discountCouponDetail.discount_percent;
    return Number.parseFloat(Number.parseFloat("" + paidPrice).toFixed(2));
  };

  useEffect(() => { //THIS IS FOR ING AGREEMENT - AFTER AGREEMENT DONE REMOVE THIS USEEFFECT!
    const checkIngDiscount = async () => {
      try {
        const hasExtraIngDiscountOption = checkCardHasRegisteredUnderING(
          selectedSavedCard.card_bin_number.length === 8 ? false : true,
          selectedSavedCard.card_bin_number.length === 8
            ? selectedSavedCard.card_bin_number
            : creditCard.card_number.replaceAll(" ", "").substring(0, 6)
        );
        if (hasExtraIngDiscountOption && discountCouponDetail.id == "ing40") {
            await checkDiscountCouponLocal(IngMax50DiscountCoupon);
        }
      } catch (error) {}
    };
    checkIngDiscount()
    if(creditCard.card_number.length === 0 && discountCouponDetail.id === IngMax50DiscountCoupon){
      handleCouponInput("")
    }
  }, [creditCard.card_number,
      selectedSavedCard.card_bin_number
    ])

  const onContunieDisabled = (): boolean => {
    if (phase == "address") {
      if (
        invoiceAddress.nameSurname.split(" ").length >= 2 &&
        invoiceAddress.country.length > 2 &&
        invoiceAddress.city.length > 2 &&
        invoiceAddress.county.length > 2 &&
        invoiceAddress.invoiceAddress.length > 5 &&
        kvkkAgreement === true &&
        communicationAgreement === true
      ) {
        if (!isRegisterDone) {
          if (userName.length > 2 && password.length > 2) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      }
      return true;
    } else {
      if (
        (creditCard.card_holder_name.split(" ").length >= 2 &&
          creditCard.card_number.replaceAll(" ", "").length == 16 &&
          creditCard.cvc.length == 3 &&
          creditCard.expiration_date.replaceAll("/", "").length == 4) ||
        selectedSavedCard.card_bin_number.length > 2
      ) {
        return false;
      }
      return true;
    }
  };

  const onContunieTriggered = async () => {
    try {
      if (phase == "address") {
        if (!isRegisterDone) {
          try {
            setLocalLoading(true);
            const registerResponse = await UserService.createUserUserUserPost({
              phone: isUserLocatedInTurkey ? parseInt(userName) : undefined,
              email: isUserLocatedInTurkey ? undefined : userName,
              password: password as string,
              register_platform: RegisterDevicePlatform.WEB_OS,
            });
            dispatch(
              setCredentialsForSebit({
                access_token: registerResponse.access_token,
                refresh_token: registerResponse.refresh_token,
              })
            );
            setIsRegisterDone(true);
          } catch (error: any) {
            setRegisterError("Kullanıcı Kayıtlı.");
            setTimeout(() => navigate(`/forced-premium?pricing_option=${id}${discountCouponDetail.id ? `&discountCoupon=${discountCouponDetail.id}` : ""}`), 2000);
            setLocalLoading(false);
            return;
          }
        }

        try {
          await dispatch(
            updateUser({
              user: {
                email: isUserLocatedInTurkey ? undefined : userName,
                phone: isUserLocatedInTurkey ? parseInt(userName) : undefined,
                name: invoiceAddress.nameSurname,
                gender: profile.gender ? profile.gender : Gender.OTHER,
                parent_type: profile.parent_type
                  ? profile.parent_type
                  : ParentType.OTHER,
                marriage_status: profile.marriage_status
                  ? profile.marriage_status
                  : MarriageStatus.OTHER,
                address: invoiceAddress.invoiceAddress,
                country: invoiceAddress.country,
                city: invoiceAddress.city,
                county: invoiceAddress.county,
              },
            })
          );
        } catch (error) {}
        setPhase("payment");

        setLocalLoading(false);
        UseMetaConversation({event_name:MetaEventNames.purchaseWithoutLoginPageAddressPhase, user_id:profile.id,custom_data:{url:"/package-payment-without-login",phase:"address"}})
      } else {
        setLocalLoading(true);
        if (
          selectedSavedCard.card_bin_number.length > 2 &&
          selectedSavedCard.card_last_four_digits.length > 2
        ) {
          const paymentResponseSavedCard =
            await PurchaseService.createPaymentWithSavedCreditCardPurchaseCreatePaymentWithSavedCreditCardPost(
              id ? id : "",
              selectedSavedCard.card_bin_number,
              selectedSavedCard.card_last_four_digits,
              {
                city: invoiceAddress.city,
                country: invoiceAddress.country,
                address: invoiceAddress.invoiceAddress,
                county: invoiceAddress.county,
              },
              discountCouponDetail.id == ""
                ? undefined
                : discountCouponDetail.id,
              selectedInstallment
            );
          setLocalLoading(false);
          if (paymentResponseSavedCard.is_request_successful) {
            navigate(`/payment-success-start-education/${guidGenerator()}`);
          } else {
            setPaymentError(
              paymentResponseSavedCard?.error_detail
                ? paymentResponseSavedCard.error_detail
                : "Satın alma işleminde bir hata meydana geldi"
            );
          }
        } else {
          const paymentResponse =
            await PurchaseService.makePremiumWithNewCreditCardPurchaseMakePremiumWithNewCreditCardPost(
              id ? id : "",
              {
                credit_card: {
                  card_holder_name: creditCard.card_holder_name,
                  card_number: creditCard.card_number.replaceAll(" ", ""),
                  expiration_month: creditCard.expiration_date.split("/")[0],
                  expire_year: "20" + creditCard.expiration_date.split("/")[1],
                  cvc: creditCard.cvc,
                },
                address_detail: {
                  city: invoiceAddress.city,
                  country: invoiceAddress.country,
                  address: invoiceAddress.invoiceAddress,
                  county: invoiceAddress.county,
                },
              },
              discountCouponDetail.id == ""
                ? undefined
                : discountCouponDetail.id,
              selectedInstallment
            );
          setLocalLoading(false);
          if (paymentResponse.is_request_successful) {
            UseMetaConversation({event_name:MetaEventNames.purchaseWithoutLogin, user_id:profile.id, custom_data:{value:calculateWhatUserPay(), currency:"TRY"}})
            navigate(`/payment-success-start-education/${guidGenerator()}`);
          } else {
            setPaymentError(
              paymentResponse?.error_detail
                ? paymentResponse.error_detail
                : "Satın alma işleminde bir hata meydana geldi"
            );
          }
        }
      }
    } catch (error: any) {
      setLocalLoading(false);
      setPaymentError(
        error?.body?.detail
          ? error?.body?.detail
          : "Satın alma işleminde bir hata meydana geldi"
      );
    }
  };

  const checkDiscountCouponLocal = async (couponCode: string) => {
    try {
      setLocalLoading(true);
      const couponResponse =
        await PurchaseService.checkCouponCodePurchaseCheckCouponCodePost(
          couponCode
        );
      if (
        couponResponse.can_be_used_by_pricing_option_ids?.includes(id ? id : "")
      ) {
        setDiscountCouponDetail(couponResponse);
        setDoesInstallmentOptionChecked(false)
        setDiscountCouponSuccessMessage("Kupon başarılı bir şekilde eklendi");
      } else {
        setDiscountCouponError("İndirim Kodu Bu Paket İçin Kullanılamıyor");
      }
      setLocalLoading(false);
    } catch (error) {
      setDiscountCouponDetail({
        id: "",
        discount_percent: 0,
        can_be_used_by_pricing_option_ids: [],
      });
      setDiscountCouponError("Kupon geçerli değil.");
      setLocalLoading(false);
    }
  };

  const handleCouponInput = async (couponCode: string) => {
    if (discountCouponDetail.id != "") {
      setDiscountCouponDetail({
        id: "",
        discount_percent: 0,
        can_be_used_by_pricing_option_ids: [],
      });
      setDoesInstallmentOptionChecked(false)
      
    } else {
      checkDiscountCouponLocal(couponCode);
    }
  };

  return (
    <>
      <img
        src={NameLogoBlue}
        alt=""
        style={{ marginTop: "20px", marginBottom: "20px", cursor: "pointer" }}
        onClick={() => navigate("/home")}
      />
      <Divider style={{ width: "100%", border: "1px solid #E9EBED" }} />
      <CommonLoading loading={authLoading || purchaseLoading || localLoading} />
      <CommonAlert
        _type={"warning"}
        _open={discountCouponError == "" ? false : true}
        _onClose={() => setDiscountCouponError("")}
        _message={discountCouponError}
      />
      <CommonAlert
        _type={"warning"}
        _open={paymentError == "" ? false : true}
        _onClose={() => setPaymentError("")}
        _message={paymentError}
      />
      <CommonAlert
        _type={"success"}
        _open={discountCouponSuccessMessage == "" ? false : true}
        _onClose={() => setDiscountCouponSuccessMessage("")}
        _message={discountCouponSuccessMessage}
      />
      <CommonAlert
        _type={"error"}
        _open={registerError != "" ? true : false}
        _onClose={() => setRegisterError("")}
        _message={registerError}
      />
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid
          item
          xs={4}
          sm={8}
          md={7}
          sx={{
            marginTop: "40px",
            marginBottom: "24px",
            display: "flex",
            justifyContent: "center",
            padding: { xs: "10px", sm: "10px", md: "0px" },
          }}
        >
          <Box
            component="div"
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "80%",
              },
              textAlign: "start",
            }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "24px",
                lineHeight: "32px",
                color: "#171725",
              }}
            >
              {phase == "address" ? "" : "Ödeme"}
            </Typography>
            {phase == "address" ? (
              <>
                {!isRegisterDone && (
                  <>
                    <Typography
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: 700,
                        fontSize: "18px",
                        lineHeight: "26px",
                        color: "#171725",
                      }}
                    >
                      Üyelik Bilgileri
                    </Typography>
                    <div
                      style={{
                        backgroundColor: "#2574FF",
                        height: "2px",
                        borderRadius: "20px",
                        maxWidth: "180px",
                        marginTop: "4px",
                      }}
                    ></div>
                    <div
                      style={{
                        textAlign: "start",
                        width: "100%",
                        marginTop: "24px",
                      }}
                    >
                      <CommonLabel
                        label={
                          isUserLocatedInTurkey
                            ? "Cep Telefonu Numarası"
                            : "Email"
                        }
                      />
                      <TextInputWithRegex
                        _placeHolder={
                          isUserLocatedInTurkey
                            ? "Cep Telefonu Numaranı Gir"
                            : "Email girin"
                        }
                        formName="username"
                        _value={userName}
                        _type={isUserLocatedInTurkey ? "number" : "text"}
                        _onChange={(e: string) => setUserName(e)}
                      />
                    </div>
                    <div
                      style={{
                        textAlign: "start",
                        width: "100%",
                        marginTop: "3%",
                      }}
                    >
                      <CommonLabel label="Şifre" />
                      <CommonTextField
                        _placeHolder="ParentWiser Şifreni Oluştur"
                        _type="password"
                        _value={password}
                        formName="password"
                        _onChange={(e: string) => setPassword(e)}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "14px",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "22px",
                          color: "#78828A",
                        }}
                      >
                        Mevcut üyeliğiniz varsa
                      </Typography>
                      <Typography
                        onClick={() => navigate(`/forced-premium?pricing_option=${id}${discountCouponDetail.id ? `&discountCoupon=${discountCouponDetail.id}` : ""}`)}
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "22px",
                          color: "#2574FF",
                          cursor: "pointer",
                          marginLeft: "5px",
                          textDecoration: "underline",
                        }}
                      >
                        tıklayın.
                      </Typography>
                    </div>
                  </>
                )}
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "26px",
                    color: "#171725",
                    marginTop: "32px",
                  }}
                >
                  Fatura Bilgileri
                </Typography>
                <div
                  style={{
                    backgroundColor: "#2574FF",
                    height: "2px",
                    borderRadius: "20px",
                    maxWidth: "180px",
                    marginTop: "4px",
                  }}
                ></div>
                <SubscriptionPaymentAddress
                  setField={(e: IPackagePaymentAddress) => setInvoiceAddress(e)}
                  values={invoiceAddress}
                />
              </>
            ) : (
              <SubscriptionPaymentCards
                setField={(e: INewCreditCard) => setCreditCard(e)}
                values={creditCard}
                selectSavedCard={setSelectedSavedCard}
                selectedCard={selectedSavedCard}
                card={creditCard}
                installmentOptions={installmentOptions}
                selectedInstallmenOption={selectedInstallment}
                onCheckboxClicked={(e) => setSelectedInstallment(e)}
                bank_name={bankNameForIcon}
                card_association={cardAssociationForIcon}
              />
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
          sm={8}
          md={5}
          sx={{
            background: "#F9F9F9",
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            padding: { xs: "10px", sm: "10px", md: "0px" },
          }}
        >
          <Box
            component="div"
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "70%",
              },
            }}
          >
            <SubscriptionPaymentSummaryWithoutLogin
              id={id ? id : ""}
              onContunieDisabled={onContunieDisabled}
              onContunieTriggered={onContunieTriggered}
              activeDiscountCoupon={discountCouponDetail}
              paidPrice={calculateWhatUserPay()}
              currentPhase={phase}
              setPhase={setPhase}
              kvkkAgreement={kvkkAgreement}
              communicationAgreement={communicationAgreement}
              setKvkk={() => setKvkkAgreement(!kvkkAgreement)}
              setCommunication={() =>
                setCommunicationAgreement(!communicationAgreement)
              }
              discountCoupon={discountCouponDetail}
              checkDiscountCouponLocal={handleCouponInput}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SubscriptionPaymentWithoutLogin;
