import { FC } from "react";
import { Typography } from "@mui/material";

const WhatIsParentSolution: FC = () => {
  return (
    <>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "600",
          fontSize: "20px",
          lineHeight: "28px",
          color: "#171725",
        }}
      >
        Ebeveyn Çözümleri Nedir?
      </Typography>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#78828A",
          marginTop: "30px",
        }}
      >
        Ebeveyn çözümleri, sorun  temelli bilgiler sunar. Ebeveyn çözümlerindeki videoları izleyerek ve makaleleri okuyarak, aileler, çocuklarla yaşadığı sorunların temel sebeplerini anlar ve direkt çözümler öğrenir. Örneğin iki kardeş arasında kardeş kıskançlığı varsa, ebeveyn ‘Kardeş Kıskançlığı’ eğitiminde bulunan videoları izleyerek ve makaleleri okuyarak bu sorunu derinlemesine anlar ve çözümler öğrenir.
      </Typography>
    </>
  );
};

export default WhatIsParentSolution;
