import { Modal, Box, Typography, Button } from "@mui/material";
import { FC } from "react";
import { Cross, ModalWarning } from "../assests";

interface Props {
  isOpen: boolean;
  onCancelButtonFunc: () => void;
}

const BecomePremiumModal: FC<Props> = ({
  isOpen = false,
  onCancelButtonFunc,
}) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#FFFFFF",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Modal
      open={isOpen}
      onClose={onCancelButtonFunc}
      style={{
        backdropFilter: "blur(2px)",
        background: "rgba(39, 39, 85, 0.6)",
      }}
    >
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ width: "20px", height: "20px" }}></div>
          <img
            src={ModalWarning}
            alt=""
            width={40}
            style={{ paddingTop: "20px", marginLeft: "30px" }}
          />
          <img
            src={Cross}
            alt=""
            style={{ marginRight: "30px", cursor: "pointer" }}
            width={"20px"}
            onClick={() => onCancelButtonFunc()}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "25px 30px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "20px",
              lineHeight: "28px",
              marginBottom: "8px",
              color: "#0D0C0F",
            }}
          >
            Premium Ol
          </Typography>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "400",
              fontSize: "15px",
              lineHeight: "24px",
              marginBottom: "24px",
              color: "#0D0C0F",
              maxWidth: "75%",
              textAlign: "center",
            }}
          >
            Bu içeriğe sadece ParentWiser Premium kullanıcıları erişebilir.
          </Typography>
          <Button
            onClick={() => console.log("")}
            variant="outlined"
            style={{
              border: "1px solid #0D0C0F",
              borderRadius: "12px",
              backgroundColor: "#FFFFFF",
              padding: "12px 60px",
            }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#0D0C0F",
              }}
              onClick={() => window.location.href = "/show-subscription-packages"}
            >
              Premium Seçeneklerini Gör
            </Typography>
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default BecomePremiumModal;
