import { FC, useState } from "react";
import { Grid, Typography } from "@mui/material";
import CommonLoading from "./CommonLoading";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import {
  Buckets,
  MarriageStatusesExplained,
  ParentTypesExplained,
} from "../utils/constants";
import { getNameTextLogo } from "../utils/commonFuncs";
import CommonLabel from "./CommonLabel";
import CommonTextField from "./CommonTextField";
import CommonSelectBox from "./CommonSelectBox";
import {
  Body_change_profile_picture_user_change_profile_picture_post,
  Gender,
  MarriageStatus,
  ParentType,
} from "../client";
import CommonButton from "./CommonButton";
import { IEditProfile } from "../utils/commonModels";
import { updateUser, uploadProfilePicture } from "../redux/authSlice";

const EditProfile: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { profile, loading } = useSelector((state: RootState) => state.auth);
  const [userInLocal, setUserInLocal] = useState<IEditProfile>({
    name: profile?.name ? profile.name : "",
    email: profile?.email,
    phone: profile?.phone,
    marriage_status: profile?.marriage_status
      ? profile.marriage_status
      : MarriageStatus.OTHER,
    parent_type: profile?.parent_type ? profile.parent_type : ParentType.OTHER,
  });
  const [newProfilePicture, setNewProfilePicture] = useState<File | null>(null);

  const updateMyProfile = async () => {
    if (newProfilePicture) {
      await dispatch(
        uploadProfilePicture(
          {file: newProfilePicture}
        )
      );
    }

    await dispatch(
      updateUser({
        user: {
          email: userInLocal.email,
          phone: userInLocal.phone,
          name: userInLocal.name,
          gender: profile?.gender ? profile.gender : Gender.OTHER,
          parent_type: userInLocal.parent_type,
          marriage_status: userInLocal.marriage_status,
          city: profile?.city,
          country: profile?.country,
          address: profile?.address,
          county: profile.county,
          notificationPermission: profile.notificationPermission,
          smsPermission: profile.smsPermission,
          mailPermission: profile.mailPermission,
        },
      })
    );
  };

  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }} columnSpacing={5}>
      <CommonLoading loading={loading} />
      <Grid item xs={4} sm={8} md={12}>
        {profile?.is_profile_picture_uploaded ? (
          <div
            style={{
              border: "2px solid #FFFFFF",
              width: "96px",
              height: "96px",
              borderRadius: "30px",
              backgroundImage: `url(${Buckets.UserProfilePictureBucket}/${profile.id}.jpg)`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          />
        ) : newProfilePicture == null ? (
          <>
            <input
              accept="image/jpeg"
              type="file"
              id="profile-picture"
              style={{ display: "none" }}
              onChange={(e: any) => setNewProfilePicture(e.target.files[0])}
            />
            <label
              htmlFor="profile-picture"
              style={{
                border: "2px solid #FFFFFF",
                boxShadow: "0px 10px 20px rgba(0, 64, 128, 0.1)",
                width: "96px",
                height: "96px",
                borderRadius: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#2574FF",
                cursor: "pointer",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "38px",
                  lineHeight: "32px",
                  color: "#FFFFFF",
                }}
              >
                {getNameTextLogo(profile.name)}
              </Typography>
            </label>
          </>
        ) : (
          <img
            style={{
              border: "2px solid #FFFFFF",
              width: "96px",
              height: "96px",
              borderRadius: "30px",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              cursor: "pointer",
            }}
            src={URL.createObjectURL(newProfilePicture)}
            alt=""
          />
        )}
      </Grid>
      <Grid item xs={4} sm={8} md={12} style={{ marginTop: "45px" }}>
        <CommonLabel label="İsim Soyisim" />
        <CommonTextField
          _defaultValue={userInLocal?.name}
          _onChange={(e: string) => setUserInLocal({ ...userInLocal, name: e })}
        />
      </Grid>
      <Grid item xs={4} sm={8} md={12} style={{ marginTop: "24px" }}>
        <CommonLabel label="E-posta Adresi" />
        <CommonTextField
          _defaultValue={userInLocal?.email}
          _onChange={(e: string) =>
            setUserInLocal({ ...userInLocal, email: e })
          }
        />
      </Grid>
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{ marginTop: "24px", marginBottom: "24px" }}
      >
        <CommonLabel label="Telefon Numarası" />
        <CommonTextField
          _defaultValue={userInLocal?.phone}
          _onChange={(e: number) =>
            setUserInLocal({ ...userInLocal, phone: e })
          }
        />
      </Grid>
      <Grid item xs={4} sm={8} md={6}>
        <CommonLabel label="Medeni Durum" />
        <CommonSelectBox
          value={userInLocal?.marriage_status}
          items={MarriageStatusesExplained}
          _onChange={(e: string) =>
            setUserInLocal({
              ...userInLocal,
              marriage_status: e as MarriageStatus,
            })
          }
        />
      </Grid>
      <Grid item xs={4} sm={8} md={6}>
        <CommonLabel label="Yakınlık Derecesi" />
        <CommonSelectBox
          value={userInLocal?.parent_type}
          items={ParentTypesExplained}
          _onChange={(e: string) =>
            setUserInLocal({ ...userInLocal, parent_type: e as ParentType })
          }
        />
      </Grid>
      <Grid item xs={4} sm={8} md={12} style={{ marginTop: "48px" }}>
        <CommonButton
          text="Bilgilerimi Güncelle"
          customCSS={{ padding: "12px 24px", width: "max-content" }}
          fontSize="16px"
          onClick={updateMyProfile}
        />
      </Grid>
    </Grid>
  );
};

export default EditProfile;
