import { createBrowserRouter } from "react-router-dom";
import {
  Home,
  Articles,
  ParentSolutions,
  Login,
  Surveys,
  Article,
  Error,
  ParentSolution,
  WhatIsParentWiser,
  Support,
  ParentSchool,
  Register,
  ResetPassword,
  VerifyCodeOTP,
  ResetCreateNewPassword,
  RegisterFlow,
  Favorites,
  ManageFamilyAccount,
  Children,
  Bulletin,
  Settings,
  PersonalInformations,
  MySubscription,
  Search,
  SurveyResult,
  Survey,
  LearnWithQuestion,
  PackageOptions,
  SubscriptionPayment,
  PaymentSuccessStartEducation,
  AskWiser,
  PremiumCodeSuccess,
  SebitRegister,
  GiftPremium,
  SebitInitialPage,
  GiftPaymentSuccess,
  ParentSchoolCertificate,
  LoginWithToken,
  On1InitialPage,
  On1Register,
  Landing,
  ForcedPremium,
  SubscriptionPaymentWithoutLogin,
  Bulletins,
  Notifications
} from "../pages";
import { Layout } from "../components";
import OfficialDocument from "../pages/OfficialDocument";
import IngDiscountLanding from "../pages/IngDiscountLanding";

export const routerItems = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <Home />
      </Layout>
    ),
    errorElement: <Error />,
  },
  {
    path: "/home",
    element: (
      <Layout>
        <Home />
      </Layout>
    ),
    errorElement: <Error />,
  },
  {
    path: "/articles",
    element: (
      <Layout>
        <Articles />
      </Layout>
    ),
    errorElement: <Error />,
  },
  {
    path: "/parentsolutions",
    element: (
      <Layout>
        <ParentSolutions />
      </Layout>
    ),
    errorElement: <Error />,
  },
  {
    path: "/parentschool",
    element: (
      <Layout>
        <ParentSchool />
      </Layout>
    ),
    errorElement: <Error />,
  },
  {
    path: "/surveys",
    element: (
      <Layout>
        <Surveys />
      </Layout>
    ),
    errorElement: <Error />,
  },
  {
    path: "/survey/:id",
    element: <Survey />,
    errorElement: <Error />,
  },
  {
    path: "/survey-result/:id/:completed_at",
    element: <SurveyResult />,
    errorElement: <Error />,
  },
  {
    path: "/article/:id",
    element: (
      <Layout>
        <Article />
      </Layout>
    ),
    errorElement: <Error />,
  },
  {
    path: "/bulletin/:id",
    element: (
      <Layout>
        <Bulletin />
      </Layout>
    ),
    errorElement: <Error />,
  },
  {
    path:"bulletins",
    element: (
      <Layout>
        <Bulletins />
      </Layout>
    )
  },
  {
    path: "/parentsolution/:id",
    element: (
      <Layout>
        <ParentSolution />
      </Layout>
    ),
    errorElement: <Error />,
  },
  {
    path: "/search/:search_word",
    element: (
      <Layout>
        <Search />
      </Layout>
    ),
    errorElement: <Error />,
  },
  {
    path: "/whatisparentwiser",
    element: (
      <Layout>
        <WhatIsParentWiser />
      </Layout>
    ),
    errorElement: <Error />,
  },
  {
    path: "/settings",
    element: (
      <Layout>
        <Settings />
      </Layout>
    ),
    errorElement: <Error />,
  },
  {
    path: "/support",
    element: (
      <Layout>
        <Support />
      </Layout>
    ),
    errorElement: <Error />,
  },
  {
    path: "/favorites",
    element: (
      <Layout>
        <Favorites />
      </Layout>
    ),
    errorElement: <Error />,
  },
  {
    path: "/manage-family-member",
    element: (
      <Layout>
        <ManageFamilyAccount />
      </Layout>
    ),
    errorElement: <Error />,
  },
  {
    path: "/children",
    element: (
      <Layout>
        <Children />
      </Layout>
    ),
    errorElement: <Error />,
  },
  {
    path: "/personal-informations",
    element: (
      <Layout>
        <PersonalInformations />
      </Layout>
    ),
    errorElement: <Error />,
  },
  {
    path: "/my-subscription",
    element: (
      <Layout>
        <MySubscription />
      </Layout>
    ),
    errorElement: <Error />,
  },
  {
    path: "/gift-to-friend",
    element: (
      <Layout>
        <GiftPremium />
      </Layout>
    ),
    errorElement: <Error />,
  },
  {
    path: "/learn-with-question",
    element: <LearnWithQuestion />,
    errorElement: <Error />,
  },
  {
    path: "/register-flow",
    element: <RegisterFlow />,
    errorElement: <Error />,
  },
  {
    path: "/show-subscription-packages",
    element: <PackageOptions />,
    errorElement: <Error />,
  },
  {
    path: "/package-payment/:id",
    element: <SubscriptionPayment />,
    errorElement: <Error />,
  },
  {
    path:"/package-payment-without-login/:id",
    element: <SubscriptionPaymentWithoutLogin />,
    errorElement: <Error />
  },
  {
    path: "/payment-success-start-education/:id", //id for security purpose
    element: <PaymentSuccessStartEducation />,
    errorElement: <Error />,
  },
  {
    path: "/premium-code-success/:id",
    element: <PremiumCodeSuccess />,
    errorElement: <Error />,
  },
  {
    path: "/ask-wiser",
    element: (
      <Layout>
        <AskWiser />
      </Layout>
    ),
    errorElement: <Error />,
  },
  {
    path: "/gift-success/:code",
    element: (
        <GiftPaymentSuccess />
    ),
    errorElement: <Error />,
  },
  {
    path: "/notifications",
    element: (
      <Layout>
        <Notifications />
      </Layout>
    ),
    errorElement: <Error />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/sso/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/sebit-register/:token/:name/:surname/:username",
    element: <SebitRegister />,
  },
  {
    path: "/onbir-register/:token/:name/:surname/:username",
    element: <On1Register />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/verify-code",
    element: <VerifyCodeOTP />,
  },
  {
    path: "/create-new-password/:type/:otp",
    element: <ResetCreateNewPassword />,
  },
  {
    path: "/vcloud",
    element: <SebitInitialPage />,
  },
  {
    path: "/vcloud-onbir",
    element: <On1InitialPage />,
  },
  {
    path:"/official-document/:type",
    element: <OfficialDocument />
  },
  {
    path: "/certificate/:encrypted_user_id",
    element: <ParentSchoolCertificate />,
  },
  {
    path: "/login-with-token/:token",
    element: <LoginWithToken />,
  },
  {
    path:'/premium',
    element: <Landing />
  },
  {
    path:'/ing',
    element: <IngDiscountLanding />
  },
  {
    path:"/forced-premium",
    element: <ForcedPremium />
  }
]);
