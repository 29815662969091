/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ArticleType {
    TEXT = 'text',
    VIDEO = 'video',
}
