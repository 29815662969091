import { Container, Grid, Typography } from "@mui/material";
import { FC, useState, useRef, useEffect } from "react";
import {
  AiChatContentSuggestion,
  AskWiserLegalWarningModal,
  AskWiserTextAi,
  AskWiserTextMe,
  ChatInput,
  ChatTyping,
  CommonLoading,
} from "../components";
import { HandShake } from "../assests";
import { AskWiserChat } from "../utils/commonModels";
import { OpenAPI, UserService } from "../client";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store/store";

const AskWiser: FC = () => {
  let messages = useRef<AskWiserChat[]>([]);
  const [currentTempMessage, setCurrentTempMessage] = useState<string>("");
  const [messagesState, setMessagesState] = useState<AskWiserChat[]>([]);
  const [legalWarningModal, setLegalWarningModal] = useState<boolean>(false)

  const { access_token, profile } = useSelector((state: RootState) => state.auth);

  const setMessageFunc = async (message: AskWiserChat[]) => {
    try {
      let tempArr = messages.current;
      tempArr.concat(message);
      message.forEach((element) => {
        tempArr.push(element);
      });
      messages.current = tempArr;
      setCurrentTempMessage("");
      setMessagesState(messages.current);
    } catch (error) {
      console.log("set message error");
    }
  };

  const submitUserMessage = async () => {
    try {
      await setMessageFunc([
        {
          isUser: true,
          message: currentTempMessage,
          time: new Date(),
          isLoading: false,
          isSuggestion: false,
        },
        {
          isUser: false,
          message: "",
          time: new Date(),
          isLoading: true,
          isSuggestion: false,
        },
      ]);
      await fetchEventSource(
        `${OpenAPI.BASE}/user/ask-wiser-question-long-polling?score=0&question=${currentTempMessage}&content_id=${null}`,
        {
          method: "GET",
          headers: {
            contentType: "application/json",
            Authorization: `Bearer ${access_token}`,
            Accept: "application/json",
          },
          async onmessage(res) {
            messages.current[messages.current.length - 1].isLoading = false;
            let messageParsed = JSON.parse(res.data);
            if (messageParsed.is_last === false) {
              messages.current[messages.current.length - 1].message +=
                messageParsed.message;
            }
            setMessagesState([...messages.current]);
          },
          onerror(err) {
            console.log("on error:", err);
            throw new Error(err);
          },
        }
      );

      const prepData =
        await UserService.askWiserPrepareDataSetUserAskWiserPrepareDataSetPost({
          question: currentTempMessage,
        });

        if (prepData.score >= 0.82) {
          await setMessageFunc([
            {
              isUser: false,
              message: "",
              time: new Date(),
              isLoading: false,
              isSuggestion: true,
              content_id: prepData.related_article_content?.article_id,
              content_title: prepData.related_article_content?.article_title,
            },
          ]);
        }
    } catch (error) {
      console.log(error);
    }
  };

  const extractJustName = () => {
    try {
      const customName = profile.name 
      if(customName == null || customName == undefined){
        return ""
      }

      if(customName.split(" ").length == 1){
        return customName
      }

      return customName.split(" ")[0]
    } catch (error) {
      return ""
    }
  }

  const getMinute = (min:string) => {
    if(min.length == 1){
      return `0${min}`
    } else {
      return min
    }
  }

  return (
    <Container
      maxWidth="md"
      style={{ marginTop: "30px", marginBottom: "30px", height: "82vh" }}
    >
      <CommonLoading loading={false} />
      <AskWiserLegalWarningModal isOpen={legalWarningModal} onButtonFunc={() => setLegalWarningModal(false)} />
      <Grid
        container
        columns={{ xs: 4, sm: 8, md: 12 }}
        style={{
          height: "100%",
          position: "relative",
        }}
      >
        {messagesState.length === 0 ? (
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            style={{
              height: "100%",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img src={HandShake} />
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "26px",
                color: "#272755",
                marginTop: "24px",
                marginBottom: "16px",
              }}
            >
              Merhaba, {extractJustName()}
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: 400,
                fontSize: "15px",
                lineHeight: "26px",
                color: "#272755",
              }}
            >
              Sana nasıl yardımcı olabilirim?
            </Typography>
          </Grid>
        ) : (
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            style={{
              height: "87%",
              overflowY: "scroll",
            }}
          >
            {messagesState.map((e: AskWiserChat) => {
              return e.isUser ? (
                <AskWiserTextMe
                  key={`${e.isUser}- ${e.time}`}
                  text={e.message}
                  time={
                    e.time.getHours().toString() +
                    "." +
                    getMinute(e.time.getMinutes().toString())
                  }
                />
              ) : e.isLoading ? (
                <ChatTyping key={`${e.isUser}- ${e.time}`} />
              ) : e.isSuggestion ? (
                <AiChatContentSuggestion                 key={`${e.isUser}- ${e.time}`}
                  content_id={e?.content_id ? e.content_id : ""}
                  content_title={e?.content_title ? e.content_title : ""}
                  time={
                    e.time.getHours().toString() +
                    "." +
                    getMinute(e.time.getMinutes().toString())
                  }
                />
              ):(
                <AskWiserTextAi
                key={`${e.isUser}- ${e.time}`}
                text={e.message}
                legalWarningClicked={() => setLegalWarningModal(true)}
                time={
                  e.time.getHours().toString() +
                  "." +
                  getMinute(e.time.getMinutes().toString())
                }
              />
              )
            })}
          </Grid>
        )}
        <Grid
          item
          xs={4}
          sm={8}
          md={12}
          style={{
            position: "absolute",
            bottom: "0px",
            width: "100%",
          }}
        >
          <ChatInput
            value={currentTempMessage}
            setValue={setCurrentTempMessage}
            submitTriggered={submitUserMessage}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default AskWiser;
