import { Modal, Box, Typography, Button } from "@mui/material";
import { FC } from "react";
import CommonLabel from "./CommonLabel";
import CommonTextField from "./CommonTextField";
import { RedTrash } from "../assests";
import CommonButton from "./CommonButton";
import CommonLoading from "./CommonLoading";

interface Props {
  isOpen: boolean;
  onCancelButtonFunc: () => void;
  onSubmitButtonFunc: () => void;
  value: string;
  valueOnChange: (e: string) => void;
  _loading:boolean
}

const RemoveMyAccountModal: FC<Props> = ({
  isOpen,
  onCancelButtonFunc,
  onSubmitButtonFunc,
  value,
  valueOnChange,
  _loading
}) => {
  const style = {
    padding: "30px 40px",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#FFFFFF",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Modal
      open={isOpen}
      onClose={onCancelButtonFunc}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <CommonLoading loading={_loading} />
        <img src={RedTrash} alt="" width={40} />
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "28px",
            color: "#0D0C0F",
            marginBottom: "8px",
            marginTop: "25px",
          }}
        >
          Hesabı Silmek İstiyor musunuz?
        </Typography>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "400",
            fontSize: "15px",
            lineHeight: "24px",
            color: "#0D0C0F",
            opacity: 0.75,
          }}
        >
          Bu hesabı gerçekten silmek istiyor musunuz?
        </Typography>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "400",
            fontSize: "15px",
            lineHeight: "24px",
            color: "#0D0C0F",
            marginBottom: "24px",
            opacity: 0.75,
          }}
        >
          Bu işlem geri alınamaz.
        </Typography>
        {/* <CommonLabel label="Şifreni Gir" />
        <CommonTextField
          _placeHolder="******"
          _type="password"
          _defaultValue={value}
          _onChange={(e: string) => valueOnChange(e)}
        /> */}
        <div
          style={{
            marginTop: "30px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            onClick={onCancelButtonFunc}
            variant="outlined"
            style={{
              border: "1px solid #0D0C0F",
              borderRadius: "12px",
              backgroundColor: "#FFFFFF",
              padding: "12px 20px",
              width: "50%",
              marginRight: "10px",
            }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#0D0C0F",
              }}
            >
              İptal Et
            </Typography>
          </Button>
          <CommonButton
            text="Hesabı Sil"
            customCSS={{
              backgroundColor: "#FA4169",
              width: "50%",
              marginLeft: "10px",
            }}
            onClick={onSubmitButtonFunc}
          />
        </div>
      </Box>
    </Modal>
  );
};

export default RemoveMyAccountModal;
