import { Container, Grid, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { BulletinHeader, CommonLoading } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { OpenAPI } from "../client";
import { useNavigate, useParams } from "react-router";
import { getBulletin } from "../redux/bulettinSlice";
import parse from "html-react-parser";

const Bulettin: FC = () => {
  let { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const { single_bulletin, loading } = useSelector(
    (state: RootState) => state.bulettin
  );
  const { access_token } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (OpenAPI.TOKEN == undefined && access_token != "") {
      OpenAPI.TOKEN = access_token;
    }
    if (id != undefined || id != null) {
      dispatch(getBulletin({ bulletinId: id }));
    } else {
      navigate(-1);
    }
  }, []);

  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <CommonLoading loading={loading} />
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={8} md={12}>
          {!loading && Object.keys(single_bulletin).length > 4 ? (
            <BulletinHeader
              id={single_bulletin.id}
              title={single_bulletin.title}
              is_picture_uploaded={
                single_bulletin?.is_picture_uploaded
                  ? single_bulletin.is_picture_uploaded
                  : false
              }
            />
          ) : null}
        </Grid>
        <Grid
          item
          xs={4}
          sm={8}
          md={12}
          style={{ marginTop: "30px", marginBottom: "40px" }}
        >
          {!loading && Object.keys(single_bulletin).length > 4 ? (
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "33px",
                color: "#000000",
                textAlign: "start",
              }}
            >
              {parse(single_bulletin.description as string)}
            </Typography>
          ) : (
            <Typography></Typography>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Bulettin;
