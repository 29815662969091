import { FC } from 'react'
import { Grid, Typography } from '@mui/material';
import { CircleEmpty, TickSolidBlue24x } from '../assests';

interface Props {
    bankName: string;
    cardType: string;
    lastFour:string;
    binNumber:string;
    isCardSelected:boolean
    onSelected:() => void
  }

const PickSavedCardItem:FC<Props> = ({
    bankName,
    cardType,
    lastFour,
    binNumber,
    isCardSelected,
    onSelected
}) => {
    const containerStyle = {
        background: "#FFFFFF",
        border: "1px solid #F0F0F0",
        borderRadius: "8px",
        padding: "23px 20px",
      };

  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }} style={containerStyle}>
    <Grid
      item
      xs={4}
      sm={8}
      md={12}
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#000000",
        }}
      >
        {bankName}
      </Typography>
      <img src={isCardSelected ? TickSolidBlue24x : CircleEmpty} alt="" style={{cursor:"pointer"}} onClick={onSelected}/>
    </Grid>
    <Grid
      item
      xs={4}
      sm={8}
      md={12}
      style={{ marginTop: "16px", marginBottom: "2px" }}
    >
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#434E58",
        }}
      >
        **** **** **** {lastFour}
      </Typography>
    </Grid>
  </Grid>
  )
}

export default PickSavedCardItem