import { Container, Grid, Typography, Box, Modal, Button } from "@mui/material";
import { FC, useState } from "react";
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Articles, AskWiser, ConverstationsCard, LearningWithQuestions, ParentSchool, ParentSolutions, Surveys, ArticleFirst, ArticleSecond, AskWiserContent, CardFirst, CardSecond, LearningWithQuestionsFirst, LearningWithQuestionsSecond, SurveysFirst, SurveysSecond, ParentSchoolFirst, ParentSchoolSecond, ParentSolutionsFirst, ParentSolutionsSecond } from "../assests"

const LandingPageFeatures: FC = () => {

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedVideo, setSelectedVideo] = useState('');

    const openModal = (content: string, type: boolean) => {
        if (type) {
            setSelectedVideo(content);
            setModalOpen(true);
        } else {
            setSelectedImage(content)
            setModalOpen(true);
        }

    };
    const closeModal = () => {
        setSelectedImage('');
        setSelectedVideo('');
        setModalOpen(false);
    };

    interface Feature {
        title: string;
        description: string;
        backgroundColor: string,
        image: string,
        exampleContent: string[];
        fontSize?: string,
        exampleTitle: string,
        video?: boolean,
        links?: string[]
    }

    const features: Feature[] = [
        {
            title: "ANNE BABA OKULU",
            description: "52 Modülden oluşan derslerle ebeveynliğinizi her açıdan geliştirin.",
            image: ParentSchool,
            backgroundColor: "#F1EBFF",
            video: true,
            exampleContent: [
                ParentSchoolFirst,
                ParentSchoolSecond,
            ],
            links: [
                "https://www.youtube.com/embed/ANacitgNjn8",
                "https://www.youtube.com/embed/ANacitgNjn8"
            ],
            exampleTitle: "Örnek İçerikler"
        },
        {
            title: "EBEVEYN ÇÖZÜMLERİ",
            description: "50’den fazla sorunun bilimsel çözümlerini hemen bulun.",
            backgroundColor: "#E7F5E8",
            image: ParentSolutions,
            video: true,
            exampleContent: [
                ParentSolutionsFirst,
                ParentSolutionsSecond,
            ],
            links: [
                "https://www.youtube.com/embed/1bkj9sTYwv0",
                "https://www.youtube.com/embed/4D1_2ic6CD0"
            ],
            exampleTitle: "Örnek İçerikler"
        },
        {
            title: "BİLİMSEL ANKETLER",
            description: "Uzmanlar tarafından hazırlanmış anketlerle kendinizi ve çocuğunuzu tanıyın.",
            backgroundColor: "#FFF9EE",
            image: Surveys,
            exampleContent: [
                SurveysFirst,
                SurveysSecond
            ],
            exampleTitle: "Örnek Anketler"
        },
        {
            title: "MAKALELER",
            description: "Bilimsel ve güvenilir makalelerle en doğru bilgiye hızla ulaşın.",
            backgroundColor: "#FEEDFA",
            image: Articles,
            exampleContent: [
                ArticleFirst,
                ArticleSecond
            ],
            exampleTitle: "Örnek Makale"
        },
        {
            title: "SOHBET KARTLARI",
            description: "Hem hoş zaman geçirin hem de çocuğunuzu ve kendinizi daha iyi tanıyın.",
            backgroundColor: "#F0F5FF",
            image: ConverstationsCard,
            exampleContent: [
                CardFirst,
                CardSecond
            ],
            exampleTitle: "Örnek Kart"
        },
        {
            title: "SORULARLA ÖĞRENİYORUM",
            description: "Soru-cevap formatında kendinizi ve çocuğunuza yaklaşımınızı geliştirin.",
            backgroundColor: "#F5F6F7",
            image: LearningWithQuestions,
            exampleContent: [
                LearningWithQuestionsFirst,
                LearningWithQuestionsSecond
            ],
            exampleTitle: "Örnek Soru",
            fontSize: "18px"
        },
        {
            title: "WISER’A SOR",
            description: "Yapay zeka ile sohbet edin ve ihtiyacınız olan bilgilere anında ulaşın.",
            backgroundColor: "#EBFFFF",
            image: AskWiser,
            exampleContent: [
                AskWiserContent
            ],
            exampleTitle: "Örnek Sohbet"
        }
    ]


    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Container maxWidth="lg" style={{ marginTop: 128 }}>
            <Grid container spacing={2}>
                {features.map((feature, index) => (
                    <Grid item xs={12} lg={6} key={index} style={{ margin: index === features.length - 1 ? 'auto' : 0 }}>
                        <Box bgcolor={feature.backgroundColor} borderRadius={12} p={2} height={400} display="flex" alignItems="center">
                            <Grid container spacing={2} direction="row" alignItems="center" flexWrap={isSmallScreen ? 'wrap' : 'nowrap'}>
                                <Grid item>
                                    <div style={{ position: "relative", display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 48 }}>
                                        <img src={feature.image} alt="" style={{ width: 144, height: 313, borderRadius: 12, zIndex: 1 }} />
                                        <div style={{ position: "absolute", width: 243, height: 243, borderRadius: "50%", backgroundColor: "white", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
                                    </div>
                                </Grid>
                                <Grid>
                                    <div style={{ marginLeft: 62, textAlign: 'left' }}>
                                        <Typography style={{
                                            fontFamily: "Montserrat",
                                            fontWeight: "700",
                                            fontSize: feature.fontSize ? feature.fontSize : "24px",
                                            lineHeight: "32px",
                                            color: "#0D0C0F",
                                        }}>{feature.title}</Typography>
                                        <Typography style={{
                                            fontFamily: "Montserrat",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            lineHeight: "21px",
                                            color: "#0D0C0F",
                                            marginBottom: "34px",
                                            marginTop: "16px",
                                        }} dangerouslySetInnerHTML={{ __html: feature.description }} />
                                        <Typography style={{
                                            fontFamily: "Montserrat",
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            lineHeight: "21px",
                                            color: "#0D0C0F",
                                            marginBottom: 16
                                        }}>{feature.exampleTitle}</Typography>
                                        <Box>
                                            <Box>
                                                {feature.exampleContent.map((content: string, contentIndex: number) => (
                                                    <img
                                                        onClick={() => feature.video ? openModal(feature.links![contentIndex], true) : openModal(content, false)}
                                                        key={contentIndex}
                                                        src={content}
                                                        alt="example content"
                                                        height={feature.video ? 70 : 143}
                                                        width={feature.video ? 110 : 66}
                                                        style={{ marginRight: 16, borderRadius: 16, cursor: "pointer" }}
                                                    />
                                                ))}
                                            </Box>
                                        </Box>
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <Modal open={modalOpen} onClose={closeModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        maxWidth: '80%',
                        maxHeight: '80%',
                        overflow: 'auto',
                        textAlign: 'center',
                    }}
                >
                    {selectedVideo ? (
                        <iframe width="720" height="500" src={selectedVideo} />
                    ) : selectedImage ? (
                        <img
                            src={selectedImage}
                            alt="Full Screen"
                            style={{ maxWidth: 300, maxHeight: '100%' }}
                        />
                    ) : null}
                </Box>
            </Modal>
        </Container>
    );

}

export default LandingPageFeatures;
