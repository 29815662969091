import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { SolidChecked } from '../assests'

interface Props {
    width:string
    isSelected:boolean
    text:string
    _onClick:() => void
}

const SurveyAnswerBox:FC<Props> = ({
    width,
    isSelected=false,
    text="",
    _onClick
}) => {

    const defaultStyle = {
        width:{xs:"90%", sm:"90%", md:"60%"},
        cursor:"pointer",
        background: "#F9F9F9",
        borderRadius: "16px",
        paddingTop:"12px",
        paddingBottom:"12px",
        marginBottom:"8px"
    }

    const selectedStyle = {
        width:{xs:"90%", sm:"90%", md:"60%"},
        cursor:"pointer",
        borderRadius: "16px",
        paddingTop:"12px",
        paddingBottom:"12px",
        marginBottom:"8px",
        background: "rgba(37, 116, 255, 0.2)",
        border: "1px solid #2574FF",
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between"
    }

  return (
    <Box component="div" sx={isSelected ? selectedStyle : defaultStyle} onClick={_onClick}>
        {
            isSelected ? <img src={SolidChecked} alt="" style={{marginLeft:"15px"}}/> : null
        }
        <Typography style={{
            fontFamily:"Montserrat",
            fontWeight:"500",
            fontSize:"16px",
            lineHeight:"24px",
            color:"#272755",
            marginRight:isSelected ? "15px":"0px"
        }}>
            {text}
        </Typography>
        {
            isSelected ? <div style={{width:"24px",height:"24px"}}></div> : null
        }
    </Box>
  )
}

export default SurveyAnswerBox