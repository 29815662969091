import { FC } from "react";
import { Typography } from "@mui/material";

interface Props {
  title: string;
  _marginBottom?: string;
  _color?: string;
  _marginTop?: string;
}
//Heading/H6/Bold
const HeadingTitle: FC<Props> = ({
  title = "",
  _marginBottom = "24px",
  _color = "#171725",
  _marginTop = "0px",
}) => {
  return (
    <Typography
      style={{
        fontFamily: "Montserrat",
        fontWeight: "700",
        fontSize: "24px",
        lineHeight: "32px",
        color: _color,
        marginBottom: _marginBottom,
        marginTop:_marginTop
      }}
    >
      {title}
    </Typography>
  );
};

export default HeadingTitle;
