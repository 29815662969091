import { FC } from "react";
import { Grid, Divider, Typography } from "@mui/material";
import { CommonLightButton } from "../components";
import { NameLogoBlue, CirculerPatern, CreditCardSucess } from "../assests";
import { useNavigate, useParams } from "react-router";

const GiftPaymentSuccess: FC = () => {
  const navigate = useNavigate();
  const { code } = useParams();

  return (
    <>
      <img
        src={NameLogoBlue}
        alt=""
        style={{ marginTop: "20px", marginBottom: "20px",cursor:"pointer" }}
        onClick={() => navigate("/home")}
      />
      <Divider style={{ width: "100%", border: "1px solid #E9EBED" }} />
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid
          item
          xs={4}
          sm={8}
          md={12}
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "70px",
          }}
        >
          <img src={CirculerPatern} alt="" style={{ position: "relative" }} />
          <img
            src={CreditCardSucess}
            alt=""
            style={{ position: "absolute", marginTop: "55px" }}
          />
        </Grid>
        <Grid item xs={4} sm={8} md={12}>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 600,
              fontSize: "24px",
              lineHeight: "32px",
              color: "#171725",
              marginTop: "32px",
            }}
          >
            Ödemeniz Başarılı!
          </Typography>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#9CA4AB",
              marginTop: "24px",
              marginBottom: "32px",
            }}
          >
            Hediye ParentWiser satın alma işleminiz başarılı. <br />
            Hediye Kodunu email yoluyla yakınınıza ilettik.
          </Typography>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#9CA4AB",
              marginTop: "24px",
            }}
          >
            Gönderilen Hediye Kodu:
          </Typography>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#2574FF",
              marginBottom: "32px",
            }}
          >
            {code}
          </Typography>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#9CA4AB",
              marginTop: "24px",
              marginBottom: "32px",
            }}
          >
            Bu kod ile yakınınız ParentWiser’ı ücretsiz olarak kullanabilir.{" "}
            <br />
            Bu kodu "Aboneliğim" sekmesindeki indirim kodu alanında
            kullanabilirsiniz.
          </Typography>
        </Grid>
        <Grid item xs={4} sm={8} md={12}>
          <CommonLightButton
            text="Anasayfaya dön"
            customCSS={{ width: "fit-content" }}
            fontSize="16px"
            onClick={() => navigate("/home")}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default GiftPaymentSuccess;
