import { Container, Grid, Typography, Box } from "@mui/material";
import { FC, useRef } from "react";
import { Autoplay } from "swiper";
import { Avatar, FiveStars, ArrowRightWhite, ArrowLeftBlue } from "../assests";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperClass } from 'swiper';

const LandingComments: FC = () => {

    const swiperRef = useRef<SwiperClass | null>(null);

    const comments = [
        {
            name: "Beyza Kökçü",
            comment: "Sadece ebeveynler için görünse de aynı zamanda alanda çalışan öğretmenler, ruh sağlığı profesyonelleri için de harika bir kaynak.",
            backgroundColor: "#FFF9EE"
        },
        {
            name: "Nilgün Gölgen",
            comment: "Çocuklarımızla sağlıklı bir iletişimin nasıl yapılabileceği, ortaya çıkan krizlerin nasıl çözülebileceği gibi binlerce sorunun cevabı uygulamada var.",
            backgroundColor: "#E7F5E8"
        },
        {
            name: "Hakan Utku",
            comment: "Bir ebeveynin boş kaldıkça kullanması gereken çok iyi bir uygulama. Çocuk yetiştirmede ne kadar yanlışlar yaptığınızı fark edeceksiniz.",
            backgroundColor: "#F5F6F7"
        },
        {
            name: "Beyza Kökçü",
            comment: "Sadece ebeveynler için görünse de aynı zamanda alanda çalışan öğretmenler, ruh sağlığı profesyonelleri için de harika bir kaynak.",
            backgroundColor: "#FFF9EE"
        },
        {
            name: "Nilgün Gölgen",
            comment: "Çocuklarımızla sağlıklı bir iletişimin nasıl yapılabileceği, ortaya çıkan krizlerin nasıl çözülebileceği gibi binlerce sorunun cevabı uygulamada var.",
            backgroundColor: "#E7F5E8"
        },
        {
            name: "Hakan Utku",
            comment: "Bir ebeveynin boş kaldıkça kullanması gereken çok iyi bir uygulama. Çocuk yetiştirmede ne kadar yanlışlar yaptığınızı fark edeceksiniz.",
            backgroundColor: "#F5F6F7"
        },
        {
            name: "Nazmiye Çelikel",
            comment: "Ebeveyn olarak bana katkıları için çok teşekkür ederim.",
            backgroundColor: "#F1EBFF"
        },
        {
            name: "Gökçen Toprak",
            comment: "İyi bir ebeveyn olma konusunda parça parça duyduğumuz şeyleri veya hiç duymadığımız şeyler içerikte var, çok başarılı buldum.",
            backgroundColor: "#F0F5FF"
        }
    ];

    return (
        <Container maxWidth={false} style={{ marginTop: 128, marginBottom: 128 }}>
            <Container maxWidth="lg" style={{ marginBottom: 72, display: "flex", flexDirection: "row", alignItems: "center" }} >
                <Typography
                    style={{
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                        fontSize: "56px",
                        lineHeight: "60px",
                        color: "#0D0C0F",
                        textAlign: "left"
                    }}
                >Kullanıcılarımız<br />Ne Dedi?</Typography>
                <Box style={{ marginLeft: "auto", display: "flex", flexDirection: "row" }}>
                    <Box
                        onClick={() => swiperRef.current?.slidePrev()}
                        style={{ height: 64, width: 64, borderRadius: 64, backgroundColor: "#F0F5FF", display: "flex", alignItems: "center", justifyContent: "center", marginRight: 16, cursor: "pointer" }}>
                        <img src={ArrowLeftBlue} alt="" color="white" />
                    </Box>
                    <Box
                        onClick={() => swiperRef.current?.slideNext()}
                        style={{ height: 64, width: 64, borderRadius: 64, backgroundColor: "#2574FF", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                        <img src={ArrowRightWhite} alt="" color="white" />
                    </Box>
                </Box>
            </Container>
            <Swiper
                onBeforeInit={(swiper) => {
                    swiperRef.current = swiper
                }}
                style={{ marginTop: 12 }}
                slidesPerView={4.3}
                onSlideChange={() => console.log("")}
                onSwiper={(swiper) => console.log(swiper)}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay]}
            >
                {comments.map((comment, index) => (
                    <SwiperSlide key={index}>
                        <Grid maxWidth="sm" height={300} style={{ marginTop: "10%", backgroundColor: comment.backgroundColor, margin: 8, borderRadius: 24 }} p={4}>
                            <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 24 }}>
                                <img src={Avatar} alt="Avatar" width={48} height={48} />
                                <Typography
                                    style={{
                                        marginLeft: 12,
                                        fontFamily: "Montserrat",
                                        fontWeight: "700",
                                        fontSize: "14px",
                                        lineHeight: "21px",
                                        color: "#0D0C0F",
                                        textAlign: "left"
                                    }}
                                >{comment.name}</Typography>
                            </Box>
                            <Box>
                                <Typography
                                    style={{
                                        fontFamily: "Montserrat",
                                        fontWeight: "500",
                                        fontSize: "14px",
                                        lineHeight: "21px",
                                        color: "#0D0C0F",
                                        textAlign: "left"
                                    }}
                                >{comment.comment}</Typography>
                            </Box>
                            <Box style={{ display: "flex", alignItems: "left", marginTop: 24 }}>
                                <img src={FiveStars} alt="stars" />
                            </Box>
                        </Grid>
                    </SwiperSlide>
                ))}
            </Swiper>

        </Container>
    );
}

export default LandingComments;
