import { FC } from "react";
import { Typography } from "@mui/material";

const WhatIsArticle: FC = () => {
  return (
    <>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "600",
          fontSize: "20px",
          lineHeight: "28px",
          color: "#171725",
        }}
      >
        Makaleler Nedir?
      </Typography>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#78828A",
          marginTop: "30px",
        }}
      >
        Eğitim Bilimci Dr. Özgür Bolat tarafından ve klinik psikolog ekibimiz
        danışmanlığında kaleme alınan makalelerimiz, ebeveynliğin ve çocuk
        eğitiminin bütün alanları üzerinde güncel ve doğruluğu kanıtlanmış
        bilgiler içeriyor. Makalelerimiz, uyku sorunu, altına kaçırma, ödev
        yapmama ve daha yüzlerce konu hakkında bilgi ve çözüm yolları içeriyor.
      </Typography>
    </>
  );
};

export default WhatIsArticle;
