import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import LearnWithQuestionAnswerBox from "./LearnWithQuestionAnswerBox";

interface Props {
  question: string;
  answers: string[];
  selected_answer: string;
  onAnswerSelected: (e: string) => void;
}

const LearnWithQuestionItem: FC<Props> = ({
  question = "",
  answers = [],
  selected_answer,
  onAnswerSelected,
}) => {
  return (
    <Grid
      container
      columns={{ xs: 4, sm: 8, md: 12 }}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
      }}
    >
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        sx={{
          marginTop: "30px",
          maxWidth: {xs:"90%",sm:"90%",md:"80%"},
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "28px",
            color: "#171725",
          }}
        >
          {question}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "40px",
        }}
      >
        {answers.map((e, index) => (
          <LearnWithQuestionAnswerBox
            is_selected={e === selected_answer ? true : false}
            text={e}
            index={index}
            onAnswerBoxSelected={onAnswerSelected}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default LearnWithQuestionItem;
