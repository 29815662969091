import { Typography } from '@mui/material'
import { FC } from 'react'
import { CircleCross } from '../assests'

interface Props {
    text:string
    _onClick:(e:string) => void
}

const PersonalInfoAreaInterestItem:FC<Props> = ({
    text="",
    _onClick
}) => {

    const containerStyle = {
        background: "#2574FF",
        borderRadius: "16px",
        border: "1px solid #2574FF",
        padding:"13px 24px",
        display:"flex",
        marginLeft:"15px",
        marginBottom:"10px",
        cursor:"pointer",
        maxWidth:"fit-content"
    }

  return (
    <div style={containerStyle} onClick={() => _onClick(text)}>
        <img src={CircleCross} alt="" style={{marginRight:"3px", cursor:"pointer"}}/>
        <Typography style={{
            fontFamily:"Montserrat",
            fontSize:"13px",
            lineHeight:"18px",
            fontWeight:"400",
            color:"#FFFFFF",
            width:"110%"
        }}>
            {text}
        </Typography>
    </div>
  )
}

export default PersonalInfoAreaInterestItem