import { FC, useState } from 'react'
import { Typography } from '@mui/material'
import CommonMultiLineTextField from './CommonMultiLineTextField'
import CommonButton from './CommonButton'
import CommonAlert from './CommonAlert'
import { UserFeedBackType, UserService } from '../client'
import CommonLoading from './CommonLoading'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store/store'
import { UserFeedBackOperatingSystem } from '../client'

const SuggestContent:FC = () => {
  const { profile } = useSelector((state:RootState) => state.auth)

  const [contentText, setContentText] = useState("")
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(false)

  const submitProblem = async() => {
    try {
      setLoading(true)
      setAlert(true)
      await UserService.addUserFeedbackUserAddUserFeedbackPost({
        rate: undefined,
        text: contentText,
        type: UserFeedBackType.SUGGESTION,
        content_id: undefined,
        content_type: undefined,
        user_mail:profile?.email,
        operating_system:UserFeedBackOperatingSystem.WEB_OS
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <>
        <CommonLoading loading={loading} />
        <CommonAlert
          _type={"success"}
          _open={alert}
          _onClose={() => setAlert(false)}
          _message={"Öneriniz başarıyla iletilmiştir."}
        /> 
        <Typography style={{fontFamily:"Montserrat", fontWeight:"600",fontSize:"20px",lineHeight:"28px",color:"#171725",marginBottom:"30px"}}>
            İçerik Öner
        </Typography>
        <Typography style={{fontFamily:"Montserrat", fontWeight:"500",fontSize:"16px",lineHeight:"24px",color:"#78828A", marginBottom:"10px"}}>
            ParetWiser’da görmek istediğiniz bir konu varsa lütfen bize önerin.
        </Typography>
        <CommonMultiLineTextField _placeHolder='Bir içerik önerin' _rows={8} _onChange={(e) => setContentText(e)}/>
        <CommonButton text="Geri Bildirim Gönder" disabled={contentText.length == 0 ? true : false} fontSize='16px' onClick={() => submitProblem()} customCSS={{maxWidth:"250px",marginTop:"30px",paddingTop:"12px",paddingBottom:"12px"}}/>
    </>
  )
}

export default SuggestContent