import { FC, useState } from "react";
import { Grid, Typography, Divider } from "@mui/material";
import CommonButton from "./CommonButton";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store/store";
import { CheckCouponReturnDto } from "../client";
import { useNavigate } from "react-router";
import CommonLabel from "./CommonLabel";
import CommonInputWithEndAdorment from "./CommonInputWithEndAdorment";
import { IngMax50DiscountCoupon } from "../utils/constants";

interface Props {
  id: string;
  onContunieDisabled: () => boolean;
  onContunieTriggered: () => void;
  paidPrice?: number | string;
  activeDiscountCoupon: CheckCouponReturnDto;
  currentPhase: string;
  setPhase: (e: string) => void;
  discountCoupon: CheckCouponReturnDto;
  checkDiscountCouponLocal: (e: string) => void;
  nameOfPackage: string
}

const SubscriptionPaymentSummary: FC<Props> = ({
  id,
  onContunieDisabled,
  onContunieTriggered,
  paidPrice = 0,
  activeDiscountCoupon,
  currentPhase,
  setPhase,
  discountCoupon,
  checkDiscountCouponLocal,
  nameOfPackage
}) => {
  const { pricing_options } = useSelector((state: RootState) => state.purchase);
  const navigate = useNavigate();

  const [localDiscountCoupon, setLocalDiscountCoupon] = useState("");

  const enterCoupon = () => {
    checkDiscountCouponLocal(localDiscountCoupon);
    setLocalDiscountCoupon("");
  };

  return (
    <Grid
      container
      columns={{ xs: 4, sm: 8, md: 12 }}
      sx={{ marginTop: { xs: "0px", sm: "0px", md: "30px" } }}
      rowSpacing={3}
    >
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "26px",
            color: "#9CA4AB",
          }}
        >
          Toplam
        </Typography>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "26px",
            color: "#171725",
          }}
        >
          ₺{paidPrice}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "26px",
            color: "#9CA4AB",
          }}
        >
          Seçtiğiniz Plan
        </Typography>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "26px",
            color: "#171725",
          }}
        >
          {nameOfPackage}
        </Typography>
      </Grid>
      {activeDiscountCoupon.id != "" && (
        <Grid
          item
          xs={4}
          sm={8}
          md={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: "18px",
              lineHeight: "26px",
              color: "#9CA4AB",
            }}
          >
            İndirim Tutarı
          </Typography>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 600,
              fontSize: "18px",
              lineHeight: "26px",
              color: "#171725",
            }}
          >
            %{activeDiscountCoupon.discount_percent} İndirim
          </Typography>
        </Grid>
      )}
      <Divider
        style={{
          width: "100%",
          border: "1px solid #E9EBED",
          marginTop: "24px",
        }}
      />
      {//THIS IS FOR ING AGREEMENT - AFTER AGREEMENT DONE REMOVE THIS CONDITION!
        discountCoupon.id != IngMax50DiscountCoupon && (
          <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <CommonLabel label="İndirim Kodu" />
        <CommonInputWithEndAdorment
          buttonText={discountCoupon.id == "" ? "Onayla" : "İptal Et"}
          _onChange={(e: string) => setLocalDiscountCoupon(e)}
          _value={
            discountCoupon.id == "" ? localDiscountCoupon : discountCoupon.id
          }
          buttonDisable={false}
          buttonOnClick={() => enterCoupon()}
          buttonCSS={{
            maxWidth: "140px",
            padding: "10px 30px",
            backgroundColor: discountCoupon.id == "" ? "#2574FF" : "#FFFFFF",
            border: discountCoupon.id == "" ? "" : "1px solid #2574FF",
            marginTop: "5px",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          buttonTextColor={discountCoupon.id == "" ? "#FFFFFF" : "#2574FF"}
          _placeHolder="İndirim kodunuz varsa bu alana giriniz"
          _disabled={discountCoupon.id != "" ? true : false}
        />
      </Grid>
        )
      }
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "26px",
            color: "#9CA4AB",
          }}
        >
          Ödenecek Tutar
        </Typography>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "26px",
            color: "#2574FF",
          }}
        >
          ₺{paidPrice}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{
          textAlign: "start",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "22px",
            color: "#78828A",
            marginBottom: "10px",
          }}
        >
          {id == "life_time"
            ? "Ömür boyu planını seçtiğiniz için daha sonra herhangi bir ücret alınmayacaktır. Ödeme tek seferliktir."
            : "Abonelik sürenizin bitiminde aboneliğiniz otomatik yenilenecektir."}
        </Typography>
      </Grid>
      <Grid item xs={4} sm={8} md={12}>
        <CommonButton
          text="Ödeme Adımına Geç"
          customCSS={{ paddingTop: "16px", paddingBottom: "16px" }}
          disabled={onContunieDisabled()}
          onClick={onContunieTriggered}
        />
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "26px",
            color: "#9CA4AB",
            marginTop: "16px",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() =>
            currentPhase == "address"
              ? navigate("/show-subscription-packages")
              : setPhase("address")
          }
        >
          Geri
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SubscriptionPaymentSummary;
