import { Button, Typography } from "@mui/material";
import { FC } from "react";
import { HandLike } from "../assests";

interface Props {
  onClick: () => void;
  text: string;
  customCSS?: {};
}

const EvaluateContentButton: FC<Props> = ({ onClick, text = "", customCSS }) => {
  return (
    <Button
      style={{
        background: "#2574FF",
        borderRadius: "16px",
        padding:"1.5% 6%",
        ...customCSS,
      }}
      startIcon={<img src={HandLike} alt="" />}
      onClick={onClick}
    >
      <Typography style={{
        color:"#F9F9F9",
        fontSize:"16px",
        fontFamily:"Montserrat",
        fontWeight:"600",
        lineHeight:"26px",
        textTransform:"none"
      }}>{text}</Typography>
    </Button>
  );
};

export default EvaluateContentButton;
