import { FC } from "react";
import ReactPlayer from "react-player";
import "../customCSS/react-player.css";

interface Props {
  url: string;
  thumbnailUrl?: string;
  contentEndCallBack?: () => void
}

const VideoPlayer: FC<Props> = ({ url = "", thumbnailUrl = "", contentEndCallBack }) => {
  return (
    <div className="player-wrapper">
      <ReactPlayer
        url={url}
        controls={true}
        light={thumbnailUrl}
        playing={true}
        className="react-player"
        width="100%"
        height="100%"
        onStart={() => console.log("")}
        onEnded={contentEndCallBack}
      />
    </div>
  );
};

export default VideoPlayer;
