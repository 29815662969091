/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ProgressLogItemType {
    ARTICLE = 'article',
    TRAINING_SET_ARTICLE = 'training_set_article',
    PARENT_SCHOOL_MODULE_ARTICLE = 'parent_school_module_article',
}
