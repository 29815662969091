import { TextField } from "@mui/material";
import { FC } from "react";

interface Props {
  formName?: string;
  _disabled?: boolean;
  _value?: string | number;
  _defaultValue?: string | number;
  _type?: string;
  _rows?:number;
  _placeHolder?: string;
  _onChange?: (e: any) => void;
}

const CommonMultiLineTextField: FC<Props> = ({
  formName,
  _disabled,
  _value,
  _defaultValue,
  _type,
  _placeHolder = "",
  _rows=5,
  _onChange,
}) => {
  return (
    <TextField
      id="outlined-basic"
      disabled={_disabled == null || undefined ? false : _disabled}
      required
      multiline
      fullWidth
      rows={_rows}
      type={_type == null || undefined ? "text" : _type}
      name={formName}
      value={_value}
      defaultValue={_defaultValue}
      placeholder={_placeHolder}
      onChange={(e) => _onChange && _onChange(e.target.value)}
      sx={{
        "& fieldset": { border: "none" },
        input: {
          color: "#9CA4AB",
          fontFamily: "Montserrat",
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "22px",
        },
        borderRadius: "16px",
        backgroundColor: "rgba(228, 228, 228, 0.25)",
      }}
    />
  );
};

export default CommonMultiLineTextField;
