import { FC } from "react";
import "../customCSS/chat-typing.css"

const ChatTyping: FC = () => {
  return (
    <div className="typing">
      <div className="typing__dot"></div>
      <div className="typing__dot"></div>
      <div className="typing__dot"></div>
    </div>
  );
};

export default ChatTyping;
