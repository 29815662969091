import { FC, useState, useEffect } from "react";
import { NameLogoBlue } from "../assests";
import { Container, Divider, Grid, Typography } from "@mui/material";
import {
  CommonLoading,
  DetailBackButton,
  HeadingTitle,
  DetailShareButton,
  LearnWithQuestionItem,
  CommonButton,
  LearnWithQuestionResultItem,
} from "../components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { openShareContentModal } from "../redux/generalControllerSlice";
import { useNavigate } from "react-router";
import {
  LearnWithQuestionRelatedContentType,
  OpenAPI,
  SetLearnWithQuestionAnswer,
} from "../client";
import { getLearnWithQuestions, removeFirstItemOfQuestions, submitQuestion } from "../redux/learnWithQuestionSlice";

const LearnWithQuestion: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { loading, error, questions } = useSelector(
    (state: RootState) => state.learnWithQuestion
  );
  const { access_token } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (OpenAPI.TOKEN == undefined && access_token != "") {
      OpenAPI.TOKEN = access_token;
    }
    dispatch(getLearnWithQuestions());
  }, []);

  useEffect(() => {
    if(currentQuestion.id == "" && questions.length >0 ){
          setCurrentQuestion({
      id: questions[0].id,
      question: questions[0].question,
      answer: "",
      is_correct_answer_selected: false,
      order: questions[0].order,
    });
    }
  }, [questions])

  //phase could be question or answer
  const [phase, setPhase] = useState<string>("question");
  const [currentQuestion, setCurrentQuestion] =
    useState<SetLearnWithQuestionAnswer>({
      id: "",
      question: "",
      answer: "",
      is_correct_answer_selected: false,
      order: 0
    });

    const handleSubmitAnswer = async() => {
      try {
        await dispatch(submitQuestion(currentQuestion))        
        if(questions.length == 1){
          await dispatch(getLearnWithQuestions())
        }
        dispatch(removeFirstItemOfQuestions())
        setPhase("question")
      } catch (error) {}
    }

  return (
    <>
      <img
        src={NameLogoBlue}
        alt=""
        style={{ marginTop: "20px", marginBottom: "20px", cursor:"pointer" }}
        onClick={() => navigate("/home")}
      />
      <Divider style={{ width: "100%", border: "1px solid #E9EBED" }} />
      <CommonLoading loading={loading} />
      <Container
        maxWidth="md"
        style={{ marginTop: "40px", marginBottom: "40px" }}
      >
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <DetailBackButton
              onClick={() => phase === "question" ? navigate("/home") : setPhase("question")}
              customCss={{
                height: "50px",
                borderRadius: "16px",
                padding: "1% 2%",
              }}
            />
            <HeadingTitle title={`Sorularla Öğreniyorum`} _marginBottom="0px" />
            <div style={{ display: "flex" }}>
              <DetailShareButton
                onClick={() => dispatch(openShareContentModal())}
                customCSS={{ height: "50px" }}
              />
            </div>
          </Grid>
          {phase == "question" ? (
            <>
              <Grid xs={4} sm={8} md={12}>
                <LearnWithQuestionItem
                  question={questions.length > 0 ? questions[0].question : ""}
                  answers={questions.length > 0 ? questions[0].answers : []}
                  selected_answer={
                    currentQuestion?.answer ? currentQuestion.answer : ""
                  }
                  onAnswerSelected={(e:string) => setCurrentQuestion({...currentQuestion, answer:e})}
                />
              </Grid>
              <Grid item xs={4} sm={8} md={12} style={{ marginTop: "50px" }}>
                <CommonButton
                  text="Cevapla"
                  customCSS={{ width: "fit-content", padding: "16px 120px" }}
                  onClick={() => setPhase("answer")}
                  disabled={currentQuestion.answer == "" ? true :false}
                />
                {/* <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "18px",
                    lineHeight: "26px",
                    color: "#9CA4AB",
                    marginTop: "16px",
                    cursor: "pointer",
                  }}
                >
                  Soruyu Atla
                </Typography> */}
              </Grid>
            </>
          ) : (
            <Grid
              item
              xs={4}
              sm={8}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LearnWithQuestionResultItem
                is_answer_correct={questions[0].right_answer === currentQuestion.answer ? true : false}
                related_content_id={questions[0].related_content_id}
                related_content_type={questions[0].related_content_type}
                related_content_name={questions[0].related_content_name}
                answer_explained={questions[0].right_answer_explained}
                nextQuestion={handleSubmitAnswer}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default LearnWithQuestion;
