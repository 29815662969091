import { FC, useState,useEffect } from "react";
import { Modal, Box } from "@mui/material";
import ReactPlayer from "react-player";

interface Props {
  open: boolean;
  setClose: () => void;
}

const LandingPromoteVideoModal: FC<Props> = ({ open, setClose }) => {
  const [playing, setPlaying] = useState<boolean>(false);
  const closeHandle = () => {
    setPlaying(false)
    setClose()
  };

  useEffect(() => {
    if(open){
        setPlaying(true)
    }
  }, [open])
  

  return (
    <Modal
      keepMounted
      open={open}
      onClose={closeHandle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: { xs: "350px", sm: "350px", md: "650px" },
          borderRadius: "24px",
        }}
      >
        <div className="player-wrapper">
          <ReactPlayer
            url={"https://www.youtube.com/watch?v=KHJHbbmV5zw"}
            controls={true}
            playing={playing}
            className="react-player"
            width="100%"
            height="100%"
            onEnded={closeHandle}
          />
        </div>
      </Box>
    </Modal>
  );
};

export default LandingPromoteVideoModal;
