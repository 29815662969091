import { Typography } from "@mui/material";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { AppDispatch } from "../redux/store/store";
import { setLocalSearchWord } from "../redux/searchSlice";

interface Props {
  text: string;
}

const HomePopularSearchItem: FC<Props> = ({ text = "" }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>()

  const redirectToSearch = () => {
    dispatch(setLocalSearchWord(text))
    navigate(`/search/${text.trimStart().trimEnd().replace(" ", "-")}`)

  }

  return (
    <div
      style={{
        backgroundColor: "#F9F9F9",
        padding: "8px 16px",
        borderRadius: "8px",
        marginRight: "8px",
        whiteSpace:"nowrap"
      }}
      onClick={() => redirectToSearch()}
    >
      <Typography
        style={{
          cursor: "pointer",
          fontFamily: "Montserrat",
          fontWeight: "600",
          fontSize: "14px",
          lineHeight: "22px",
        }}
      >
        {text}
      </Typography>
    </div>
  );
};

export default HomePopularSearchItem;
