import { FC } from "react";
import { Select, MenuItem, Typography } from "@mui/material";

interface IItem {
  key:string
  name:string
}
interface Props {
    value?:string
    items:IItem[]
    _onChange:(e:string) => void
    _disabled?:boolean

}

const CommonSelectBox:FC<Props> = ({
    value,
    items = [],
    _onChange,
    _disabled=false
}) => {
  return (
    <Select
      labelId="demo-multiple-name-label"
      id="demo-multiple-name"
      fullWidth
      disabled={_disabled}
      value={value}
      onChange={(e) => _onChange(e.target.value)}
      sx={{
        "& fieldset": { border: "1px solid #E9EBED", borderRadius:"12px"  },
        input: {
          color: "#9CA4AB",
          fontFamily: "Montserrat",
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "22px",
        },
        borderRadius: "8px",
        backgroundColor: "rgba(228, 228, 228, 0.25)",
      }}
    >
      {items.map((item) => (
        <MenuItem
          key={item.key}
          value={item.key}
        >
          <Typography style={{
            fontFamily:"Montserrat",fontSize:"14px", fontWeight:"500",lineHeight:"22px", color:"#9CA4AB"
          }}>
            {item.name}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  );
};

export default CommonSelectBox;
