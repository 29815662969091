import { FC, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import { Minus, Plus } from "../assests";

interface Props {
  expanded: string | false;
  setExpanded: (current: any) => void;
  accName: string;
  title: string;
  description: string;
}

const FaqAccordion: FC<Props> = ({
  expanded,
  setExpanded,
  accName,
  title,
  description,
}) => {
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Accordion
      expanded={expanded === accName}
      onChange={handleChange(accName)}
      style={{
        backgroundColor: "#F9F9F9",
        boxShadow: "none",
        borderRadius: "12px",
        padding: "13px",
        marginBottom:"12px",
        position:"unset"
      }}
    >
      <AccordionSummary
        expandIcon={
          expanded === accName ? (
            <img src={Minus} alt="" />
          ) : (
            <img src={Plus} alt="" />
          )
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "18px",
            lineHeight: "26px",
            color: "#171725",
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "15px",
            lineHeight: "23px",
            color: "#78828A",
          }}
        >
          {description}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default FaqAccordion;
