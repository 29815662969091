import { Button } from "@mui/material";
import { FC } from "react";
import { Share } from "../assests";

interface Props {
  onClick: () => void;
  customCSS?: {};
}

const DetailShareButton: FC<Props> = ({ onClick, customCSS}) => {
  return (
    <Button
      style={{
        backgroundColor: "#FFFFFF",
        border: "1px solid #E9EBED",
        borderRadius: "16px",
        padding:"1% 2%",
        display: "flex",
        ...customCSS,
      }}
      onClick={() => onClick()}
    >
      <img src={Share} alt="" />
    </Button>
  );
};

export default DetailShareButton;
