import { Backdrop, CircularProgress } from "@mui/material" 
import {FC} from 'react'

interface Props {
    loading:boolean
}

const CommonLoading:FC<Props> = ({loading}) => {
  return (
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={loading}
  >
    <CircularProgress style={{color:"#4124fb"}}  size={80}/>
  </Backdrop>
  )
}

export default CommonLoading