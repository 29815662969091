import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import PersonalInfoAreaInterestItem from "./PersonalInfoAreaInterestItem";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store/store";
import AreaOfInterestItem from "./AreaOfInterestItem";

interface Props {
  interests: Array<string>;
  removeInterest:(e:string) => void
  addInterest:(e:string) => void
}

const AreaOfInterest: FC<Props> = ({ interests = [],removeInterest,addInterest }) => {
  const { profile } = useSelector((state: RootState) => state.auth);

  return (
    <Grid
      container
      columns={{ xs: 4, sm: 8, md: 12 }}
      columnSpacing={2}
      rowSpacing={2}
    >
      <Grid item xs={4} sm={8} md={12}>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "28px",
            color: "#171725",
            marginBottom: "14px",
          }}
        >
          İlgilendiğim Konular
        </Typography>
      </Grid>
      <Grid item xs={4} sm={8} md={12}>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#78828A",
            marginBottom: "24px",
          }}
        >
          Çocuğunuzla ilgili size öncelikli yardımcı olmamızı istediğiniz
          konular:
        </Typography>
      </Grid>
      {profile.user_interests
        ? profile.user_interests.map((e) => {
            return <PersonalInfoAreaInterestItem text={e} _onClick={removeInterest}/>;
          })
        : null}
      <Grid item xs={4} sm={8} md={12}>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "28px",
            color: "#171725",
            marginBottom: "14px",
            marginTop: "40px",
          }}
        >
          Yeni İlgi Alanı Ekle
        </Typography>
      </Grid>
      {interests.filter((e) => !profile.user_interests?.some((k) => e== k)).map((e) => {
        return <AreaOfInterestItem text={e} _onClick={addInterest}/>;
      })}
    </Grid>
  );
};

export default AreaOfInterest;
