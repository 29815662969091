import { Typography } from "@mui/material";
import { FC } from "react";

interface Props {
  isSelected: boolean;
  text: string;
  _onClicked:() => void
}

const RegisterListItem: FC<Props> = ({ isSelected = false, text = "",_onClicked }) => {

    const defaultStyle = {
        background: "rgba(240, 240, 240, 0.5)",
        borderRadius:"8px",
        paddingTop:"14px",
        paddingBottom:"14px",
        width:"100%",
        cursor:"pointer",
        marginBottom:"14px"
    }

    const selectedStyle = {
        background: "rgba(37, 116, 255, 0.25)",
        border:"2px solid #2574FF",
        borderRadius:"8px",
        paddingTop:"14px",
        paddingBottom:"14px",
        width:"100%",
        cursor:"pointer",
        marginBottom:"14px"
    }

  return (
    <div style={isSelected ? selectedStyle : defaultStyle} onClick={() => _onClicked()}>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "600",
          fontSize: "18px",
          lineHeight: "26px",
          color: "#0D0C0F",
        }}
      >
        {text}
      </Typography>
    </div>
  );
};

export default RegisterListItem;
