import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetWhatBringsUserSuggestionItem, UserService } from "../client";

export const getAreaOfInterests = createAsyncThunk(
    'areaOfInterest/getAreaOfInterests',
    async() => {
        try {
            return await UserService.getAreaOfInterestsUserGetAreaOfInterestsGet()
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

export const getWhatBringsYouOptions = createAsyncThunk(
    'areaOfInterest/getWhatBringsYouOptions',
    async () => {
        try {
            return await UserService.getWhatBringsUserSuggestionsUserGetWhatBringsUserSuggestionsGet()
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

export const setWhatBringsYouOptions = createAsyncThunk(
    'areaOfInterest/setWhatBringsYouOptions',
    async (payload:{selected_id:string}) => {
        try {
            return await UserService.setWhatBringsUserUserSetWhatBringsUserSelectedIdPost(payload.selected_id)
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

interface IInitialState {
    loading:boolean
    error:string
    interests:Array<string>
    whatBringsYouOptions:Array<GetWhatBringsUserSuggestionItem>
}

const initialState:IInitialState = {
    loading:false,
    error:"",
    interests: [] as Array<string>,
    whatBringsYouOptions: [] as Array<GetWhatBringsUserSuggestionItem>
}

export const areaOfInterestSlice = createSlice({
    name:"areaOfInterest",
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder.addCase(getAreaOfInterests.pending, (state) => {
            state.loading = true;
            state.error = ''
          })
          builder.addCase(getAreaOfInterests.fulfilled, (state, action) => {
            state.interests = action.payload
            state.loading = false;
            state.error = ''
          });
          builder.addCase(getAreaOfInterests.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
          })
          builder.addCase(getWhatBringsYouOptions.pending, (state) => {
            state.loading = true;
            state.error = ''
          })
          builder.addCase(getWhatBringsYouOptions.fulfilled, (state, action) => {
            state.whatBringsYouOptions = action.payload
            state.loading = false;
            state.error = ''
          });
          builder.addCase(getWhatBringsYouOptions.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
          })
          builder.addCase(setWhatBringsYouOptions.pending, (state) => {
            state.loading = true;
            state.error = ''
          })
          builder.addCase(setWhatBringsYouOptions.fulfilled, (state, action) => {
            state.loading = false;
            state.error = ''
          });
          builder.addCase(setWhatBringsYouOptions.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
          })
    }
})

export default areaOfInterestSlice.reducer