import { FC } from "react";
import { Typography } from "@mui/material";
import { ShareFacebook, ShareInstagram, ShareTelegram, ShareTwitter } from "../assests";

const SuggestToFriend: FC = () => {
  return (
    <>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "600",
          fontSize: "20px",
          lineHeight: "28px",
          color: "#171725",
          marginBottom: "30px",
        }}
      >
        Arkadaşlarına Öner
      </Typography>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#78828A",
          marginBottom: "20px",
        }}
      >
        Aşağıdaki linki arkadaşlarınızla paylaşarak onları da ParentWiser ailesinin bir üyesi yapabilirsiniz.
      </Typography>
      <div style={{padding:"17px 15px",backgroundColor:"#F9F9F9", border:"1px solid #2574FF", borderRadius:"12px",display:"flex",alignItems:"center", width: "max-content",marginBottom:"30px"}}>
        <Typography style={{fontFamily:'Montserrat',fontWeight:"500",fontSize:"14px",lineHeight:"22px",color:"#434E58",marginRight:"60px"}}>
          onelink.to/8cxm3z
        </Typography>
        <Typography style={{fontFamily:'Montserrat',fontWeight:"700",fontSize:"12px",lineHeight:"20px",color:"#2574FF",cursor:"pointer"}} onClick={()=>navigator.clipboard.writeText("https://onelink.to/8cxm3z")}>
            Linki Kopyala
        </Typography>
      </div>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "400",
          fontSize: "13px",
          lineHeight: "22px",
          color: "#0D0C0F",
          marginBottom: "15px",
        }}
      >
        Sosyal medyada paylaş
      </Typography>
      <div>
        <img src={ShareFacebook} alt="" style={{cursor:"pointer"}} onClick={() => window.open("https://www.facebook.com/parentwiserapp","_blank")}/>
        <img src={ShareInstagram} alt="" style={{marginLeft:"20px",cursor:"pointer",marginRight:"20px"}} onClick={() => window.open("https://www.instagram.com/parentwiser/","_blank")}/>
        <img src={ShareTwitter} alt="" style={{marginRight:"20px",cursor:"pointer"}} onClick={() => window.open("https://twitter.com/parentwiser","_blank")} />
        {/* <img src={ShareTelegram} alt="" style={{cursor:"pointer"}}/> */}
      </div>
    </>
  );
};

export default SuggestToFriend;
