/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum UserFeedBackOperatingSystem {
    IOS = 'Ios',
    ANDROID = 'Android',
    WEB_OS = 'WebOs',
    OTHER = 'Other',
}
