/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SearchDocumentType {
    ARTICLE = 'article',
    TRAINING_SET = 'training_set',
}
