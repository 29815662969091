import { Container, Grid, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import {
  CommonAlert,
  CommonButton,
  CommonLabel,
  CommonLoading,
  CommonTextField,
  HeadingTitle,
  UnsubscribeMeModal,
} from "../components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { Buckets } from "../utils/constants";
import { getNameTextLogo, guidGenerator } from "../utils/commonFuncs";
import { PurchaseService, UserService } from "../client";
import { getMySubscriptionDetail, getUserProfile } from "../redux/authSlice";
import { useNavigate } from "react-router";
import SubscriptionInformation from "../components/SubscriptionInformation";

const MySubscription: FC = () => {
  const { profile, loading, subscription_detail } = useSelector(
    (state: RootState) => state.auth
  );

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const [localError, setLocalError] = useState<string | null>(null);
  const [premiumCodeInput, setPremiumCodeInput] = useState<string>("");
  const [unsubscribeSuccess, setUnsubscribeSuccess] = useState<boolean>(false)

  const [showUnsubscribeButton, setShowUnsubscribeButton] =
    useState<boolean>(false);
  const [unsubscribeMePassword, setunsubscribeMePassword] =
    useState<string>("");
  const [isUnsubscribeModalOpen, setisUnsubscribeModalOpen] =
    useState<boolean>(false);

  const itemContainerOptionStyle = {
    background: "#FFFFFF",
    border: "1px solid #F0F0F0",
    borderRadius: "8px",
    padding: "38px 37px",
    width: "100%",
  };

  const activateWithPremiumCode = async () => {
    try {
      setLocalLoading(true);
      setLocalError(null);

      try {
        const activateResponse =
          await UserService.activatePremiumWithPremiumCodeUserActivatePremiumWithCodePost(
            premiumCodeInput
          );

        if (activateResponse.is_request_successful) {
          dispatch(getUserProfile());
          setLocalLoading(false);
          navigate(`/premium-code-success/${guidGenerator()}`);
          return;
        }
      } catch (error) {}

      const discountCouponCheck =
        await PurchaseService.checkCouponCodePurchaseCheckCouponCodePost(
          premiumCodeInput
        );
      navigate(`/show-subscription-packages?coupon=${discountCouponCheck.id}`);

      setLocalLoading(false);
    } catch (error: any) {
      console.log(error);
      setLocalError("Geçersiz Kod.");
      setLocalLoading(false);
    }
  };

  const checkUserHasCancellableSubscription = async() => {
    try {
      const response = await UserService.doesPremiumBoughtOnWebUserDoesPremiumBoughtOnWebGet()
      setShowUnsubscribeButton(response.is_request_successful)
    } catch (error) {
      setShowUnsubscribeButton(false)
    }
  }

  useEffect(() => {
    dispatch(getMySubscriptionDetail());
    if(profile?.is_user_premium){
      checkUserHasCancellableSubscription()
    }
  }, []);

  const dateDifferences = () => {
    try {
      if (!profile.is_user_premium) {
        return "-";
      }

      if (subscription_detail.package == "life_time") {
        return "Sınırsız Üyelik";
      }

      if (profile.premium_ends_at) {
        const today = new Date();
        const endDate = new Date(profile.premium_ends_at);

        const diffInTime = endDate.getTime() - today.getTime();
        const diffInDays = diffInTime / (1000 * 3600 * 24);

        if (Math.round(diffInDays) > 5000) {
          return "Sınırsız Üyelik";
        }

        return Math.round(diffInDays).toString();
      }
      return "-";
    } catch (error) {
      return "-";
    }
  };

  const renewalDate = () => {
    try {
      if (!profile.is_user_premium) {
        return "-";
      }

      if (subscription_detail.package == "life_time") {
        return "-";
      }

      if (profile.premium_ends_at) {
        const today = new Date();
        const endDate = new Date(profile.premium_ends_at);

        const diffInTime = endDate.getTime() - today.getTime();
        const diffInDays = diffInTime / (1000 * 3600 * 24);

        if (Math.round(diffInDays) > 5000) {
          return "-";
        }
      }

      return profile.premium_ends_at
        ? profile.premium_ends_at.split("T")[0]
        : "-";
    } catch (error) {
      return "-";
    }
  };

  const subscriptionTitle = () => {
    try {
      if (!profile.is_user_premium) {
        return "Ücretsiz Üyesiniz";
      }

      if (subscription_detail?.package == "life_time") {
        return "Sınırsız Premium Üyesiniz";
      }

      return "Premium Üyesiniz";
    } catch (error) {
      return "-";
    }
  };

  const subsStartDate = () => {
    try {
      if (!profile.is_user_premium) {
        return "-";
      }
      return subscription_detail.start_date
        ? subscription_detail.start_date.split("T")[0]
        : "-";
    } catch (error) {
      return "-";
    }
  };

  const unsubscribeMeFromWebPayment = async() => {
    try {
      setLocalLoading(true)
      setLocalError(null)
      setUnsubscribeSuccess(false)
      setisUnsubscribeModalOpen(false)

      const response = await PurchaseService.unsubscribeWebPaymentPurchaseUnsubscribeWebPaymentPost(unsubscribeMePassword)
      
      if(response.is_request_successful){
        setShowUnsubscribeButton(false)
        setUnsubscribeSuccess(true)
      }else {
        setLocalError(response?.error_detail ? response.error_detail : "Beklenmedik bir hata oluştu. Lütfen bizimle iletişime geçtin.")
      }
      setLocalLoading(false)
      setunsubscribeMePassword("")

    } catch (error) {
      setUnsubscribeSuccess(false)
      setLocalLoading(false)
      setunsubscribeMePassword("")
      setLocalError("Beklenmedik bir hata oluştu. Lütfen bizimle iletişime geçtin.")
    }
  }

  const cancelUnsubscribeModal = () => {
    setisUnsubscribeModalOpen(false)
    setunsubscribeMePassword("")
  }

  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <CommonAlert
        _open={localError == null ? false : true}
        _message={localError == null ? "" : localError}
        _type={"error"}
        _onClose={() => setLocalError(null)}
      />
      <CommonAlert
        _type={"success"}
        _open={unsubscribeSuccess}
        _onClose={() => setUnsubscribeSuccess(false)}
        _message={"Abonelik süreniz bittiğinde yenilenmeyecektir."}
      />
      <CommonLoading loading={loading || localLoading} />
      <Grid container columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}>
        <Grid item xs={4} sm={8} md={12} style={{ textAlign: "start" }}>
          <HeadingTitle title="Aboneliğim" _marginBottom="12px" />
        </Grid>
        <Grid
          item
          container
          xs={4}
          sm={8}
          md={12}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{
            borderRadius: "8px",
            border: "1px solid #F0F0F0",
            backgroundColor: "#F9F9F9",
            padding: "32px 22px",
            marginBottom: "32px",
          }}
        >
          <Grid
            item
            xs={4}
            sm={8}
            md={10}
            sx={{ display: "flex", alignItems: "center" }}
          >
            {profile?.is_profile_picture_uploaded ? (
              <div
                style={{
                  border: "2px solid #FFFFFF",
                  width: "96px",
                  height: "96px",
                  borderRadius: "35px",
                  backgroundImage: `url(${Buckets.UserProfilePictureBucket}/${profile.id}.jpg)`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  marginRight: "30px",
                }}
              ></div>
            ) : (
              <div
                style={{
                  border: "2px solid #FFFFFF",
                  boxShadow: "0px 10px 20px rgba(0, 64, 128, 0.1)",
                  width: "96px",
                  height: "96px",
                  borderRadius: "35px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#2574FF",
                  marginRight: "30px",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "700",
                    fontSize: "38px",
                    lineHeight: "32px",
                    color: "#FFFFFF",
                  }}
                >
                  {getNameTextLogo(profile.name)}
                </Typography>
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "start",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "15px",
                  lineHeight: "24px",
                  color: "#78828A",
                  marginBottom: "8p",
                }}
              >
                Üyelik Tipiniz
              </Typography>
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "19px",
                  lineHeight: "28px",
                  color: "#2574FF",
                }}
              >
                {subscriptionTitle()}
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={2}
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "end",
            }}
          >
            {showUnsubscribeButton ? (
              <CommonButton
                text="Abonelikten Çık"
                customCSS={{ width: "fit-content" }}
                fontSize="14px"
                onClick={() => setisUnsubscribeModalOpen(true)}
              />
            ) : null}
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={4}
            sx={{
              marginTop: "22px",
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingRight: "10px",
            }}
          >
            <SubscriptionInformation
              title="Abonelik Tarihi"
              data={subsStartDate()}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={4}
            sx={{ marginTop: "22px", padding: "10px" }}
          >
            <SubscriptionInformation
              title="Abonelik Yenileme Tarihi"
              data={renewalDate()}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={4}
            sx={{
              marginTop: "22px",
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "10px",
            }}
          >
            <SubscriptionInformation
              title="Abonelik Bitişine Kalan Gün"
              data={dateDifferences()}
            />
          </Grid>
        </Grid>

        {profile?.is_user_premium ? null : (
          <Grid
            item
            xs={4}
            sm={8}
            md={6}
            lg={6}
            sx={{
              display: "flex",
              paddingRight: { xs: "0px", sm: "0px", md: "25px" },
              marginBottom: { xs: "25px", sm: "25px", md: "0px" },
            }}
          >
            <div
              style={{
                ...itemContainerOptionStyle,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "8px",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "700",
                    fontSize: "24px",
                    lineHeight: "28px",
                    color: "#2574FF",
                  }}
                >
                  Size özel
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "24px",
                    lineHeight: "28px",
                    color: "#2574FF",
                    marginLeft: "6px",
                  }}
                >
                  avantajlarla
                </Typography>
              </div>
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "17px",
                  lineHeight: "17px",
                  color: "#272755",
                  marginBottom: "32px",
                }}
              >
                Hemen Sınırsız İçeriğe Geçin
              </Typography>
              <CommonButton
                text="Premium Seçeneklerini Gör"
                fontSize="16px"
                customCSS={{ padding: "12px 24px", width: "fit-content" }}
                onClick={() => navigate("/show-subscription-packages")}
              />
            </div>
          </Grid>
        )}
        {profile?.is_user_premium ? null : (
          <Grid
            item
            xs={4}
            sm={8}
            md={6}
            lg={6}
            sx={{
              display: "flex",
              paddingLeft: { xs: "0px", sm: "0px", md: "25px" },
            }}
          >
            <div
              style={{
                ...itemContainerOptionStyle,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "22px",
                  color: "#78828A",
                  marginBottom: "20px",
                }}
              >
                Mevcut bir premium kodunuz varsa aşağıdaki kutucuğa girerek
                hesabınızı premium yapabilirsiniz.
              </Typography>
              <CommonLabel label="İndirim Kodunu Giriniz" />
              <CommonTextField
                _placeHolder="Kodu Girin"
                _textAlign="center"
                _onChange={(e: string) => setPremiumCodeInput(e)}
              />
              <CommonButton
                text="Kodu Onayla"
                customCSS={{
                  marginTop: "14px",
                  width: "fit-content",
                  padding: "12px 24px",
                }}
                fontSize="16px"
                disabled={premiumCodeInput.length >= 3 ? false : true}
                onClick={activateWithPremiumCode}
              />
            </div>
          </Grid>
        )}
      </Grid>
      <UnsubscribeMeModal
        isOpen={isUnsubscribeModalOpen}
        onCancelFunc={cancelUnsubscribeModal}
        password={unsubscribeMePassword}
        setPassword={(e) => setunsubscribeMePassword(e)}
        submitUnsubscribe={unsubscribeMeFromWebPayment}
      />
    </Container>
  );
};

export default MySubscription;
