import { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { NoRecordParentSolutionLogIcon } from '../assests'

const NoRecordParentSolutionLog:FC = () => {
  return (
    <Grid container columns={{xs:4, sm:8, md:12}} style={{
        background: "#F9F9F9",
        borderRadius:"24px",
        padding:"40px 30px",
    }}>
        <Grid item xs={12} style={{marginBottom:"30px"}}>
            <img src={NoRecordParentSolutionLogIcon} alt="" />
        </Grid>
        <Grid item xs={12} style={{marginBottom:"10px"}}>
            <Typography style={{fontFamily:"Montserrat",fontWeight:"700",fontSize:"20px",lineHeight:"28px",color:"#000000"}}> 
                Kayıt Yok
            </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography style={{fontFamily:"Montserrat",fontWeight:"500",fontSize:"14px",lineHeight:"22px",color:"#78828A"}}> 
        Makale ve videolar aracılığıyla tüm sorularınıza Ebeveyn Çözümleri ile yanıt alın.
            </Typography>
        </Grid>
    </Grid>
  )
}

export default NoRecordParentSolutionLog