import { FC } from 'react'
import { Button } from '@mui/material';
import { Cross } from '../assests';

interface Props {
    _onClick: () => void;
    customCSS?: {};
  }

const DetailCloseButton:FC<Props> = ({_onClick, customCSS}) => {
  return (
    <Button
    style={{
      backgroundColor: "#FFFFFF",
      border: "1px solid #E9EBED",
      borderRadius: "16px",
      padding:"1% 2%",
      display: "flex",
      ...customCSS,
    }}
    onClick={_onClick}
  >
    <img src={Cross} alt="" />
  </Button>
  )
}

export default DetailCloseButton