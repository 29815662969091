import { Typography } from '@mui/material'
import {FC} from 'react'

interface Props{
    label:string;
    _marginBottom?:string
}

const CommonLabel:FC<Props> = ({label="", _marginBottom="8px"}) => {
  return (
    <Typography style={{
        fontFamily:"Montserrat",
        fontWeight:"500",
        fontSize:"14px",
        lineHeight:"22px",
        color:"#78828A",
        marginBottom:_marginBottom
    }}>
        {label}
    </Typography>
  )
}

export default CommonLabel