import { FC } from "react";
import { Google } from "../assests";
import { Button, Typography } from "@mui/material";

interface Props {
  text: string;
  _onClick: () => void;
  _marginBottom?:string
}

const GoogleButton: FC<Props> = ({ text = "", _onClick, _marginBottom="Opx" }) => {
  const buttonContainerStyle = {
    borderRadius: "20px",
    background: "#F9F9F9",
    paddingTop: "12px",
    paddingBottom:"12px",
    width:"100%",
    marginBottom:_marginBottom
  };

  return (
    <Button
      onClick={() => _onClick()}
      style={buttonContainerStyle}
      startIcon={<img src={Google} alt="" />}
    >
        <Typography style={{
            textTransform:"none",
            fontFamily:"Montserrat",
            fontWeight:"600",
            fontSize:"16px",
            lineHeight:"24px",
            color:"#171725"
        }}>
            {text}
        </Typography>
    </Button>
  );
};

export default GoogleButton;
