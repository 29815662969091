import { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SebitService } from "../client";
import { CommonErrorModalNoShadow, CommonLoading } from "../components";
import { setCredentialsForSebit } from "../redux/authSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store/store";
import { logOut } from "../redux/authSlice";
import { logOutFavs } from "../redux/favoriteSlice";
import { logOutLogs } from "../redux/logSlice";

const SebitInitialPage: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>("");

  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    setLoading(true);
    if (token == undefined || token == null || token == "") {
      navigate("/login");
    } else {
      (async () => {
        try {
          await logOutTrigger()

          const checkUser =
            await SebitService.authorizeWithSebitSebitAuthorizeSebitTokenGet(token)
          if (checkUser.has_registered) {
            dispatch(setCredentialsForSebit({access_token:checkUser.app_access_credentials?.access_token, refresh_token: checkUser.app_access_credentials?.refresh_token}))
            setTimeout(() => navigate('/home') ,500);
          } else {
            navigate(`/sebit-register/${checkUser.sebit_credentials?.sebit_token}/${checkUser.sebit_credentials?.name}/${checkUser.sebit_credentials?.lastname}/${(checkUser.sebit_credentials?.email != "" && checkUser.sebit_credentials?.email)  ? checkUser.sebit_credentials.email : "None"}`);
          }
        } catch (error: any) {
          console.log("sebit error", error.body.detail);
          const mainMessage = error?.body?.detail || ''
          setModalMessage(mainMessage + " Please contact us.")
          setIsModalOpen(true)
          // navigate("/login")
        }
      })();
    }
    setLoading(false);
  }, [token]);

  async function logOutTrigger() {
    dispatch(logOut());
    dispatch(logOutFavs());
    dispatch(logOutLogs());
  }

  return (
    <div style={{
      backgroundColor:"#2574FF",
      height:"100vh"
    }}>
      <CommonLoading loading={loading} />
      <CommonErrorModalNoShadow
        isOpen={isModalOpen}
        title="Error Occurred!"
        text={modalMessage}
        buttonText="Go To Login"
        onButtonFunc={() => navigate("/login")}
      />
    </div>
  );
};

export default SebitInitialPage;
