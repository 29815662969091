import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetParentSchoolModuleResponse, ParentSchoolModuleModel, UserService } from "../client";

export const getAllParentSchoolContent = createAsyncThunk(
    'parentSchool/getAllParentSchoolContent',
    async(payload:{start_after_module_id?:string}) => {
        try {
            return await UserService.getParentSchoolUserParentSchoolGet(payload.start_after_module_id)
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

interface IInitialState {
    loading:boolean;
    error:string;
    parentSchoolContents: GetParentSchoolModuleResponse
}

const initialState:IInitialState = {
    loading:false,
    error:"",
    parentSchoolContents:{
        modules:[] as ParentSchoolModuleModel[],
        last_module_id: undefined
    }
}


export const parentSchoolSlice = createSlice({
    name:"parentSchool",
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder.addCase(getAllParentSchoolContent.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getAllParentSchoolContent.fulfilled, (state,action) => {
            if(state.parentSchoolContents.last_module_id != undefined){
                state.parentSchoolContents.modules = [...state.parentSchoolContents.modules, ...action.payload.modules]
                state.parentSchoolContents.last_module_id = action.payload.last_module_id
            } else {
                state.parentSchoolContents.modules = action.payload.modules;
                state.parentSchoolContents.last_module_id = action.payload.last_module_id
            }
            state.loading = false;
            state.error = ""
        })
        builder.addCase(getAllParentSchoolContent.rejected, (state,action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
    }
})

export default parentSchoolSlice.reducer