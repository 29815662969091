import { FC, useState } from "react";
import { Typography } from "@mui/material";
import CommonLabel from "./CommonLabel";
import CommonTextField from "./CommonTextField";
import CommonButton from "./CommonButton";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store/store";
import { joinFamily } from "../redux/authSlice";

const JoinExistingFamily: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [familyCode, setFamilyCode] = useState<string>("")

  const submitFamilyCode = () => {
    dispatch(joinFamily({partner_code:familyCode}))
  }

  return (
    <>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "600",
          fontSize: "20px",
          lineHeight: "28px",
          color: "#171725",
          marginBottom: "30px",
        }}
      >
        Mevcut Aileye Katıl
      </Typography>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#78828A",
          marginBottom: "32px",
        }}
      >
        Aile üyesi davet kodu size iletildiyse lütfen aşağıdaki kutucuğa kodu
        giriniz.
      </Typography>
      <div
        style={{
          width: "75%",
        }}
      >
        <CommonLabel label="Davet Kodunu Giriniz" />
        <CommonTextField _placeHolder="Kodu girin" _onChange={(e) => setFamilyCode(e)}/>
      </div>
      <div
        style={{
          width: "max-content",
          marginTop: "34px",
        }}
      >
        <CommonButton
          text="Kodu Onayla"
          customCSS={{ paddingLeft: "25px", paddingRight: "25px" }}
          fontSize="16px"
          disabled={familyCode.length < 2 ? true : false}
          onClick={() => submitFamilyCode()}
        />
      </div>
    </>
  );
};

export default JoinExistingFamily;
