import { FC, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import {
  LandingBg,
  LandingRight,
  PlayBlue,
  PwLogoHorizontal,
  Rate
} from "../assests";
import CommonButton from "./CommonButton";
import LandingPromoteVideoModal from "./LandingPromoteVideoModal";

const LandingIntroduction: FC = () => {
  const scrollToPrices = () => {
    const section = document.querySelector(".scrollPoint");
    section?.scrollIntoView({ behavior: "smooth", block: "nearest" });
  };

  const [videoOpen, setVideoOpen] = useState<boolean>(false);

  return (
    <div>
      <img style={{position: "absolute", left: 0, width: "100%"}} src={LandingBg} alt="" />
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid
          item
          xs={4}
          sm={8}
          md={12}
          sx={{
            display: "flex",
            paddingTop: "20px",
            paddinBottom: "20px",
            marginBottom: "90px",
            paddingLeft: {
              xs: "0px",
              sm: "0px",
              md: "125px",
              lg: "125px",
              xl: "299px",
            },
            padding: {
              xs: "10px",
              sm: "10px"
            }
          }}
          >
          <img
            src={PwLogoHorizontal}
            alt=""
            style={{
              cursor: "pointer",
              width: "209px",
              height: "66px",
            }}
            />
        </Grid>
        <div style={{display:"flex" ,alignItems: "center", width: "100%"}}>
        <Grid
          item
          xs={4}
          sm={8}
          md={10}
          sx={{
            textAlign: "start",
            display: "flex",
            alignItems: "start",
            paddingBottom: "32px",
            flexDirection: "column",
            paddingLeft: {
              xs: "0px",
              sm: "0px",
              md: "125px",
              lg: "125px",
              xl: "299px",
            },
            padding: {
              xs: "10px",
              sm: "10px"
            }
          }}
          >
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "700",
              fontSize: "56px",
              lineHeight: "66px",
              color: "#0D0C0F",
              marginBottom: "34px",
            }}
            >
            Türkiye’nin<br/>Ebeveynlik Uygulaması
          </Typography>
          <img src={Rate} alt="rate" style={{ marginTop: 32, marginBottom: 32, width: 400}} />
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "400",
              fontSize: "20px",
              lineHeight: "28px",
              color: "#0D0C0F",
              marginBottom: "34px",
            }}
            >
            <span style={{ fontWeight: "bold", color: "#2574FF" }}>  ParentWiser Premium</span> ile ParentWiser deneyiminizi en üst düzeye taşıyın.
          </Typography>
          <div style={{ display: "flex", width: "100%" }}>
            <CommonButton
              text="Fiyatları Gör"
              fontSize="16px"
              onClick={scrollToPrices}
              customCSS={{
                width: "auto",
                paddingLeft: "32px",
                paddingRight: "32px",
                marginRight: "16px",
                paddingTop: "1px",
                paddingBottom: "1px",
              }}
              />
            <Button
              onClick={() => setVideoOpen(true)}
              style={{
                backgroundColor: "#F0F5FF",
                paddingLeft: "32px",
                paddingRight: "32px",
                borderRadius: "16px",
                width: "auto",
              }}
              startIcon={<img src={PlayBlue} alt="" />}
              fullWidth
              >
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "48px",
                  color: "#2574FF",
                  textTransform: "none",
                }}
                >
                Tanıtımı İzle
              </Typography>
            </Button>
          </div>
        </Grid>
        <Grid
          item
          xs={4}
          sm={8}
          md={6}
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: {
              xs: "0px",
              sm: "0px",
              md: "125px",
              lg: "125px",
              xl: "300px",
            },
          }}
        >
          <img
            src={LandingRight}
            alt=""
            style={{ maxWidth: 680, maxHeight: 680, position: "absolute" }}
          />
        </Grid>
      </div>
      </Grid>
      <LandingPromoteVideoModal
        open={videoOpen}
        setClose={() => setVideoOpen(false)}
      />
    </div>
  );
};

export default LandingIntroduction;
