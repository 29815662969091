import { FC } from 'react'
import { Grid,Typography } from '@mui/material'
import { SurveyResultBookPen } from '../assests';
import SurveyShowResultButton from './SurveyShowResultButton';
import { useNavigate } from 'react-router';

interface Props {
    survey_title:string
    id:string
    solved_date?:string
}

const SurveyForResultItem:FC<Props> = ({
    survey_title="",
    id,
    solved_date=""
}) => {
    const navigate = useNavigate()

    const containerStyle = {
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 10px 20px rgba(0, 64, 128, 0.1)",
        borderRadius: "12px",
        padding: "20px 22px",
      };
    
      const iconPartStyle = {
        backgroundColor: "#F9F9F9",
        borderRadius: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      };

      const solvedDateFormatter = (date:string) => {
        try {
          const dateSplitted = date.split("T")
          return `${dateSplitted[0]} ${dateSplitted[1].split(".")[0].replace(":",".").split(":")[0]}`

        } catch (error) {
          return ""
        }
      }

  return (
    <Grid container style={containerStyle} columns={{ xs: 4, sm: 8, md: 12 }}>
    <Grid item xs={1} sm={2} md={1} style={iconPartStyle}>
      <img src={SurveyResultBookPen} alt="" />
    </Grid>
    <Grid
      item
      xs={3}
      sm={6}
      md={7}
      style={{ textAlign: "start", padding: "10px", marginBottom: "10px" }}
    >
      <Typography
        style={{
          fontSize: "20px",
          fontFamily: "Montserrat",
          lineHeight: "28px",
          color: "#171725",
          fontWeight: "700",
          marginBottom: "4px",
        }}
      >
        {survey_title}
      </Typography>
    </Grid>
    <Grid
      item
      xs={0}
      sm={0}
      md={4}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection:"column",
        marginTop:{xs:"10px", sm:"10px", md:"0px"}
      }}
    >
        <SurveyShowResultButton
          _onClick={() => navigate(`/survey-result/${id}/${solved_date}`)}
          _disabled={false}
        />
        <div style={{display:"flex", alignItems:"center",marginTop:"12px"}}>
            <Typography style={{
                fontFamily:"Montserrat",
                fontWeight:"400",
                fontSize:"11px",
                lineHeight:"11px",
                color:"#66707A"
            }}>
                Çözdüğünüz tarih:
            </Typography>
            <div style={{
              backgroundColor: "#F9F9F9",
              borderRadius: "6px",
              marginLeft:"10px",
              padding:"2px 8px",
            }}>
              <Typography style={{
                fontFamily:"Montserrat",
                fontWeight:"700",
                fontSize:"12px",
                lineHeight:"20px",
                color:"#0D0C0F"
              }}>
              {solvedDateFormatter(solved_date)}
              </Typography>
            </div>
        </div>
    </Grid>
  </Grid>
  )
}

export default SurveyForResultItem