import { Typography } from '@mui/material'
import { FC } from 'react'

interface Props {
    text:string
    time:string
}

const AskWiserTextMe:FC<Props> = ({
    text="",
    time=""
}) => {
  return (
    <div style={{
        paddingLeft:"20%",
        display:"flex",
        flexDirection:"column",
        alignItems:"end"
    }}>
        <div style={{
        borderRadius:"15px 0px 15px 15px",
        padding:"15px 20px",
        backgroundColor:"#2574FF",
        textAlign:"right",
        width:"fit-content"
    }}>
        <Typography style={{
            fontFamily:"Montserrat",
            fontWeight:500,
            fontSize:"16px",
            lineHeight:"25px",
            color:"#FFFFFF"
        }}>
        {text}
        </Typography>
    </div>
    <Typography style={{
            fontFamily:"Montserrat",
            fontWeight:500,
            fontSize:"12px",
            lineHeight:"20px",
            color:"#78828A",
            textAlign:"right"
        }}>
        {time}
        </Typography>
    </div>
  )
}

export default AskWiserTextMe