import { FC } from "react";
import { Modal, Box, Typography } from "@mui/material";
import {
  Cross,
  ShareModalFacebook,
  ShareModalTwitter,
  ShareModalWhatsApp,
} from "../assests";

interface Props {
  isOpen: boolean;
  onCancelButtonFunc: () => void;
}

const ShareContentModal: FC<Props> = ({
  isOpen = false,
  onCancelButtonFunc,
}) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#FFFFFF",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minWidth:"350px"
  };

  return (
    <Modal
      open={isOpen}
      onClose={onCancelButtonFunc}
      style={{
        backdropFilter: "blur(2px)",
        background: "rgba(39, 39, 85, 0.6)",
      }}
    >
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            width: "100%",
            marginTop: "33px",
          }}
        >
          <img
            src={Cross}
            alt=""
            style={{ marginRight: "30px", cursor: "pointer" }}
            width={"20px"}
            onClick={() => onCancelButtonFunc()}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "20px",
              lineHeight: "28px",
              marginBottom: "16px",
              color: "#0D0C0F",
            }}
          >
            İçeriği Paylaş
          </Typography>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "400",
              fontSize: "15px",
              lineHeight: "24px",
              marginBottom: "24px",
              color: "#0D0C0F",
              maxWidth: "80%",
              textAlign: "center",
            }}
          >
            Linki kopyalayarak veya sosyal medya iconlarına tıklayarak paylaşım
            yapabilirsiniz.
          </Typography>

          <div
            style={{
              padding: "17px 15px",
              backgroundColor: "#F9F9F9",
              border: "1px solid #2574FF",
              borderRadius: "12px",
              display: "flex",
              alignItems: "center",
              width: "80%",
              marginBottom: "24px",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "22px",
                color: "#434E58",
                marginRight: "60px",
              }}
            >
              onelink.to/8cxm3z
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: "12px",
                lineHeight: "20px",
                color: "#2574FF",
                cursor: "pointer",
              }}
              onClick={() =>
                navigator.clipboard.writeText("https://onelink.to/8cxm3z")
              }
            >
              Linki Kopyala
            </Typography>
          </div>

          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "22px",
              marginBottom: "16px",
              color: "#0D0C0F",
              textAlign: "center",
            }}
          >
            Sosyal medyada paylaş
          </Typography>
          <div style={{ marginBottom: "31px" }}>
            <img src={ShareModalFacebook} alt="" />
            <img
              src={ShareModalTwitter}
              alt=""
              style={{ marginRight: "16px", marginLeft: "16px" }}
            />
            <a
              href="https://web.whatsapp.com/send?text= ParentWiser'a göz atın. https://parentwiser.com.tr"
              rel="nofollow noopener"
              target="_blank"
              className="share-icon"
            >
              <img src={ShareModalWhatsApp} alt="" />
            </a>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ShareContentModal;
