import { FC, MouseEvent } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import FavoriteParentSolutionSliderItem from "./FavoriteParentSolutionSliderItem";
import {
  addItemToFavorite,
  removeItemFromFavorite,
} from "../redux/favoriteSlice";
import { FavoriteType } from "../client";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store/store";

interface Props {
  items: {
    id: string;
    title: string;
  }[];
}

const FavoriteParentSolutionSlider: FC<Props> = ({ items }) => {
  const dispatch = useDispatch<AppDispatch>();

  const addContentToFavorite = (
    e: MouseEvent,
    id: string,
    name_of_content: string
  ) => {
    try {
      e.stopPropagation();
      dispatch(
        addItemToFavorite({
          id_of_favorite: id,
          name_of_favorite: name_of_content,
          favourite_type: FavoriteType.TRAINING_SET,
        })
      );
    } catch (error) {}
  };

  const removeContentFromFavorite = (e: MouseEvent, id: string) => {
    try {
      e.stopPropagation();
      dispatch(removeItemFromFavorite({ favorite_item_id: id }));
    } catch (error) {}
  };

  return (
    <Swiper
      breakpoints={{
        320: {
          slidesPerView: 1.2,
          spaceBetween: 10,
        },
        480: {
          slidesPerView: 2.2,
          spaceBetween: 10,
        },
        640: {
          slidesPerView: 3.2,
          spaceBetween: 15,
        },
        1200: {
          slidesPerView: 3.2,
          spaceBetween: 15,
        },
      }}
      className="mySwiper"
    >
      {items.map((e, index) => {
        return (
          <SwiperSlide key={index}>
            <FavoriteParentSolutionSliderItem
              id={e.id}
              title={e.title}
              addContentToFavorite={(e, id, name_of_content) =>
                addContentToFavorite(e, id, name_of_content)
              }
              removeContentFromFavorite={(e, id) =>
                removeContentFromFavorite(e, id)
              }
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default FavoriteParentSolutionSlider;
