import { FC, useState, useEffect } from "react";
import { NameLogoBlue } from "../assests";
import { Divider, Box, Grid, Typography } from "@mui/material";
import {
  CommonAlert,
  CommonLoading,
  SubscriptionPaymentAddress,
  SubscriptionPaymentCards,
  SubscriptionPaymentSummary,
} from "../components";
import { useNavigate, useParams } from "react-router";
import {
  CheckCouponReturnDto,
  Gender,
  InstallmentOptions,
  MarriageStatus,
  OpenAPI,
  ParentType,
  PurchaseService,
} from "../client";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import {
  INewCreditCard,
  IPackagePaymentAddress,
  ISaveCustomCard,
  ISelectCardForPayment,
} from "../utils/commonModels";
import { updateUser } from "../redux/authSlice";
import {
  getAvailablePurchasePackages,
  getMySavedCards,
} from "../redux/purchaseSlice";
import {
  checkCardHasRegisteredUnderING,
  guidGenerator,
} from "../utils/commonFuncs";
import { useSearchParams } from "react-router-dom";
import {
  IngMax50DiscountCoupon,
  MetaEventNames,
} from "../utils/constants";
import { UseMetaConversation } from "../hooks";
import { paymentClient } from "../apiClient/apiClient";
import { PackageData, PackageResponse } from "../apiClient/models/package"

const SubscriptionPayment: FC = () => {
  const { id } = useParams();
  const {
    access_token,
    profile,
    loading: authLoading,
  } = useSelector((state: RootState) => state.auth);
  const { loading: purchaseLoading, pricing_options } = useSelector(
    (state: RootState) => state.purchase
  );
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [phase, setPhase] = useState("address"); //it could be either payment or address
  const [invoiceAddress, setInvoiceAddress] = useState<IPackagePaymentAddress>({
    nameSurname: profile.name ? profile.name : "",
    country: "Türkiye",
    city: "",
    county: "",
    invoiceAddress: "",
  } as IPackagePaymentAddress);
  const [discountCouponDetail, setDiscountCouponDetail] =
    useState<CheckCouponReturnDto>({
      id: "",
      discount_percent: 0,
      can_be_used_by_pricing_option_ids: [],
    });
  const [discountCouponError, setDiscountCouponError] = useState<string>("");
  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const [creditCard, setCreditCard] = useState<INewCreditCard>({
    card_holder_name: "",
    card_number: "",
    expiration_date: "",
    cvc: "",
  });
  const [paymentError, setPaymentError] = useState<string>("");
  const [selectedSavedCard, setSelectedSavedCard] =
    useState<ISelectCardForPayment>({
      card_bin_number: "",
      card_last_four_digits: "",
    });
  const [discountCouponSuccessMessage, setDiscountCouponSuccessMessage] =
    useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [packageDetails, setPackageDetails] = useState<PackageData>();
  const [selectedInstallment, setSelectedInstallment] = useState<number>(1);
  const [installmentOptions, setInstallmentOptions] = useState<
    Array<InstallmentOptions>
  >([] as InstallmentOptions[]);
  const [doesInstallmentOptionChecked, setDoesInstallmentOptionChecked] =
    useState<boolean>(false);
  const [bankNameForIcon, setBankNameForIcon] = useState<string>("");
  const [cardAssociationForIcon, setCardAssociationForIcon] =
    useState<string>("");

  useEffect(() => {
    setLocalLoading(false);
    if (OpenAPI.TOKEN == undefined && access_token != "") {
      OpenAPI.TOKEN = access_token;
    }
    if (id != undefined || id != null || profile.is_user_premium) {
      dispatch(getAvailablePurchasePackages());
      dispatch(getMySavedCards());
    } else {
      navigate("/home");
    }

    if (searchParams.get("coupon") && searchParams.get("coupon") != null) {
      checkDiscountCouponLocal(searchParams.get("coupon") as string);
    }
  }, []);

  useEffect(() => {
    if (
      (!doesInstallmentOptionChecked &&
        creditCard.card_number.replaceAll(" ", "").length >= 6) ||
      (!doesInstallmentOptionChecked &&
        selectedSavedCard.card_bin_number.length === 8)
    ) {
      setDoesInstallmentOptionChecked(true);
      const fetchInstallmentOptions = async () => {
        try {
          const installmentOptionsResponse =
            await PurchaseService.getCardInstallmentOptionsPurchaseGetCardInstallmentOptionsPost(
              id ? id : "",
              selectedSavedCard.card_bin_number.length === 8
                ? selectedSavedCard.card_bin_number
                : creditCard.card_number.replaceAll(" ", "").substring(0, 6),
              discountCouponDetail.id == ""
                ? undefined
                : discountCouponDetail.id
            );

          setInstallmentOptions(installmentOptionsResponse.installment_options);
          setBankNameForIcon(installmentOptionsResponse.card_family_name);
          setCardAssociationForIcon(
            installmentOptionsResponse.card_association
          );
        } catch (error) {
          setInstallmentOptions([
            {
              installment_number: selectedInstallment,
              installment_price: calculateWhatUserPay(),
              total_price: calculateWhatUserPay(),
            },
          ]);
        }
      };
      fetchInstallmentOptions();
    }
  }, [
    discountCouponDetail.id,
    creditCard.card_number,
    selectedSavedCard.card_bin_number,
  ]);

  useEffect(() => {
    const fetchPackageDetails = async () => {
      try {
        setLocalLoading(true);
        const response = await paymentClient().get(`/pricingPackage/${id}`);
        setPackageDetails(response.data.data);
      } catch (error) {
        console.error("Error fetching package details", error);
      } finally {
        setLocalLoading(false);
      }
    };

    fetchPackageDetails();
  }, [id]);

  useEffect(() => {
    if (creditCard.card_number.replaceAll(" ", "").length < 6) {
      setDoesInstallmentOptionChecked(false);
      setSelectedInstallment(1);
      setInstallmentOptions([] as InstallmentOptions[]);
      setBankNameForIcon("");
      setCardAssociationForIcon("");
    }
  }, [creditCard]);

  useEffect(() => { //THIS IS FOR ING AGREEMENT - AFTER AGREEMENT DONE REMOVE THIS USEEFFECT!
    const checkIngDiscount = async () => {
      try {
        const hasExtraIngDiscountOption = checkCardHasRegisteredUnderING(
          selectedSavedCard.card_bin_number.length === 8 ? false : true,
          selectedSavedCard.card_bin_number.length === 8
            ? selectedSavedCard.card_bin_number
            : creditCard.card_number.replaceAll(" ", "").substring(0, 6)
        );
        if (hasExtraIngDiscountOption && discountCouponDetail.id == "ing40") {
          await checkDiscountCouponLocal(IngMax50DiscountCoupon);
        }
      } catch (error) { }
    };
    checkIngDiscount()
    if (creditCard.card_number.length === 0 && discountCouponDetail.id === IngMax50DiscountCoupon) {
      handleCouponInput("")
    }
  }, [creditCard.card_number,
  selectedSavedCard.card_bin_number
  ])

  const onContunieDisabled = (): boolean => {
    if (phase == "address") {
      if (
        invoiceAddress.nameSurname.split(" ").length >= 2 &&
        invoiceAddress.country.length > 2 &&
        invoiceAddress.city.length > 2 &&
        invoiceAddress.county.length > 2 &&
        invoiceAddress.invoiceAddress.length > 5
      ) {
        return false;
      }
      return true;
    } else {
      if (
        (creditCard.card_holder_name.split(" ").length >= 2 &&
          creditCard.card_number.replaceAll(" ", "").length == 16 &&
          creditCard.cvc.length == 3 &&
          creditCard.expiration_date.replaceAll("/", "").length == 4) ||
        selectedSavedCard.card_bin_number.length > 2
      ) {
        return false;
      }
      return true;
    }
  };

  const onContunieTriggered = async () => {
    try {
      if (phase === "address") {
        try {
          await dispatch(
            updateUser({
              user: {
                email: profile.email,
                phone: profile.phone,
                name: invoiceAddress.nameSurname,
                gender: profile.gender ? profile.gender : Gender.OTHER,
                parent_type: profile.parent_type ? profile.parent_type : ParentType.OTHER,
                marriage_status: profile.marriage_status ? profile.marriage_status : MarriageStatus.OTHER,
                address: invoiceAddress.invoiceAddress,
                country: invoiceAddress.country,
                city: invoiceAddress.city,
                county: invoiceAddress.county,
              },
            })
          );
        } catch (error) {
          console.error("Error updating user details:", error);
        }
  
        setPhase("payment");
        UseMetaConversation({
          event_name: MetaEventNames.purchaseWithLoginPageAddressPhase,
          user_id: profile.id,
          custom_data: { url: "/package-payment", phase: "address" },
        });
  
      } else {
        setLocalLoading(true);
  
        const [expiryMonth, expiryYear] = creditCard.expiration_date.split("/");
        const formattedExpiryYear = expiryYear.length === 2 ? `20${expiryYear}` : expiryYear;
  
        const paymentRequest = {
          advisorId: "",
          appointmentId: "",
          pricingPackageId: packageDetails?.id || "",
          cardDetails: {
            name: creditCard.card_holder_name,
            number: creditCard.card_number.replaceAll(" ", ""),
            expiryMonth: String(Number(expiryMonth)),
            expiryYear: String(Number(formattedExpiryYear)),
            cvc: creditCard.cvc,
          },
        };
  
        try {
          // Await the Axios response and extract the data field
          const response = await paymentClient().post<PackageResponse>("/payment/pay", paymentRequest);
          const paymentResponse: PackageResponse = response.data;
  
          setLocalLoading(false);
  
          if (paymentResponse.success) {
            navigate(`/payment-success-start-education/${guidGenerator()}`);
          } else {
            setPaymentError(paymentResponse.message || "Payment failed.");
          }
        } catch (error: unknown) {
          setLocalLoading(false);
          console.error("Payment error:", error);
  
          const axiosError = error as any;
          setPaymentError(axiosError?.response?.data?.message || "An error occurred during the payment.");
        }
      }
    } catch (error: unknown) {
      setLocalLoading(false);
  
      const err = error as { body?: { detail?: string } };
      setPaymentError(err?.body?.detail || "An error occurred during the payment.");
    }
  };
  
  

  const checkDiscountCouponLocal = async (couponCode: string) => {
    try {
      setLocalLoading(true);
      const couponResponse =
        await PurchaseService.checkCouponCodePurchaseCheckCouponCodePost(
          couponCode
        );
      if (
        couponResponse.can_be_used_by_pricing_option_ids?.includes(id ? id : "")
      ) {
        setDiscountCouponDetail(couponResponse);
        setDoesInstallmentOptionChecked(false);
        setDiscountCouponSuccessMessage("Kupon başarılı bir şekilde eklendi");
      } else {
        setDiscountCouponError("İndirim Kodu Bu Paket İçin Kullanılamıyor");
      }
      setLocalLoading(false);
    } catch (error) {
      setDiscountCouponDetail({
        id: "",
        discount_percent: 0,
        can_be_used_by_pricing_option_ids: [],
      });
      setDiscountCouponError("Kupon geçerli değil.");
      setLocalLoading(false);
    }
  };

  const handleCouponInput = async (couponCode: string) => {
    if (discountCouponDetail.id != "") {
      setDiscountCouponDetail({
        id: "",
        discount_percent: 0,
        can_be_used_by_pricing_option_ids: [],
      });
      setDoesInstallmentOptionChecked(false);
    } else {
      checkDiscountCouponLocal(couponCode);
    }
  };

  const calculateWhatUserPay = (): number => {
    if (selectedInstallment != 1) {
      return Number.parseFloat(
        Number.parseFloat(
          "" +
          installmentOptions.find(
            (e) => e.installment_number === selectedInstallment
          )?.total_price
        ).toFixed(2)
      );
    }
    const packagePrice = pricing_options.find((e) => e.id == id)
      ?.price as number;
    const paidPrice =
      packagePrice -
      (packagePrice / 100) * discountCouponDetail.discount_percent;
    return Number.parseFloat(Number.parseFloat("" + paidPrice).toFixed(2));
  };

  return (
    <>
      <img
        src={NameLogoBlue}
        alt=""
        style={{ marginTop: "20px", marginBottom: "20px", cursor: "pointer" }}
        onClick={() => navigate("/home")}
      />
      <Divider style={{ width: "100%", border: "1px solid #E9EBED" }} />
      <CommonLoading loading={authLoading || purchaseLoading || localLoading} />
      <CommonAlert
        _type={"warning"}
        _open={discountCouponError == "" ? false : true}
        _onClose={() => setDiscountCouponError("")}
        _message={discountCouponError}
      />
      <CommonAlert
        _type={"warning"}
        _open={paymentError == "" ? false : true}
        _onClose={() => setPaymentError("")}
        _message={paymentError}
      />
      <CommonAlert
        _type={"success"}
        _open={discountCouponSuccessMessage == "" ? false : true}
        _onClose={() => setDiscountCouponSuccessMessage("")}
        _message={discountCouponSuccessMessage}
      />
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid
          item
          xs={4}
          sm={8}
          md={7}
          sx={{
            marginTop: "40px",
            marginBottom: "24px",
            display: "flex",
            justifyContent: "center",
            padding: { xs: "10px", sm: "10px", md: "0px" },
          }}
        >
          <Box
            component="div"
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "80%",
              },
              textAlign: "start",
            }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "24px",
                lineHeight: "32px",
                color: "#171725",
              }}
            >
              {phase == "address" ? "Fatura Adresi" : "Ödeme"}
            </Typography>
            {phase == "address" ? (
              <SubscriptionPaymentAddress
                setField={(e: IPackagePaymentAddress) => setInvoiceAddress(e)}
                values={invoiceAddress}
              />
            ) : (
              <SubscriptionPaymentCards
                price={packageDetails?.price}
                setField={(e: INewCreditCard) => setCreditCard(e)}
                values={creditCard}
                selectSavedCard={setSelectedSavedCard}
                selectedCard={selectedSavedCard}
                card={creditCard}
                installmentOptions={installmentOptions}
                onCheckboxClicked={(e) => setSelectedInstallment(e)}
                selectedInstallmenOption={selectedInstallment}
                bank_name={bankNameForIcon}
                card_association={cardAssociationForIcon}
              />
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
          sm={8}
          md={5}
          sx={{
            background: "#F9F9F9",
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            padding: { xs: "10px", sm: "10px", md: "0px" },
          }}
        >
          <Box
            component="div"
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "70%",
              },
            }}
          >
            <SubscriptionPaymentSummary
              id={id ? id : ""}
              nameOfPackage={packageDetails?.name as string}
              onContunieDisabled={onContunieDisabled}
              onContunieTriggered={onContunieTriggered}
              activeDiscountCoupon={discountCouponDetail}
              paidPrice={packageDetails?.price}
              currentPhase={phase}
              setPhase={setPhase}
              discountCoupon={discountCouponDetail}
              checkDiscountCouponLocal={handleCouponInput}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SubscriptionPayment;
