import { FC } from 'react'
import { TextField } from "@mui/material";

interface Props {
    formName?: string;
    _disabled?: boolean;
    _value?: string | number;
    _defaultValue?: string | number;
    _type?: string;
    _placeHolder?: string;
    _onChange?: (e: any) => void;
    _textAlign?:string;
    regex?:string
  }

const TextInputWithRegex:FC<Props> = ({
    formName,
    _disabled,
    _value,
    _defaultValue,
    _type,
    _placeHolder = "",
    _onChange,
    _textAlign = "start",
    regex
}) => {
    return (
        <TextField
          id="outlined-basic"
          disabled={_disabled == null || undefined ? false : _disabled}
          required
          fullWidth
          type={_type == null || undefined ? "text" : _type}
          name={formName}
          value={_value}
          defaultValue={_defaultValue}
          placeholder={_placeHolder}
          onChange={(e) => _onChange && _onChange(e.target.value)}
          onKeyPress={(event) => {
            if (_type == "number" && !/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          sx={{
            "& fieldset": { border: "1px solid #E9EBED", borderRadius:"12px"       },
            input: {
              color: "#9CA4AB",
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "22px",
              textAlign: _textAlign
            },
            borderRadius: "8px",
            backgroundColor: "rgba(228, 228, 228, 0.25)",
          }}
        />
      );
}

export default TextInputWithRegex