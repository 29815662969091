/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddressDetail } from '../models/AddressDetail';
import type { Body_make_premium_with_new_credit_card_purchase_make_premium_with_new_credit_card_post } from '../models/Body_make_premium_with_new_credit_card_purchase_make_premium_with_new_credit_card_post';
import type { Body_purchase_gift_with_new_credit_card_purchase_purchase_gift_with_new_credit_card_post } from '../models/Body_purchase_gift_with_new_credit_card_purchase_purchase_gift_with_new_credit_card_post';
import type { CheckCouponReturnDto } from '../models/CheckCouponReturnDto';
import type { GetDiscountPercentageInGiftPurchaseResponse } from '../models/GetDiscountPercentageInGiftPurchaseResponse';
import type { GiftPurchaseResponse } from '../models/GiftPurchaseResponse';
import type { InAppPurchaseProvider } from '../models/InAppPurchaseProvider';
import type { InstallmentOptionsResponse } from '../models/InstallmentOptionsResponse';
import type { IsRequestSuccessfulModel } from '../models/IsRequestSuccessfulModel';
import type { IyzicoSaveExternalNewCard } from '../models/IyzicoSaveExternalNewCard';
import type { PricingOptionModel } from '../models/PricingOptionModel';
import type { SavedCard } from '../models/SavedCard';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PurchaseService {

    /**
     * Get Current Pricing Options
     * @returns PricingOptionModel Successful Response
     * @throws ApiError
     */
    public static getCurrentPricingOptionsPurchaseGetCurrentPricingOptionsGet(): CancelablePromise<Array<PricingOptionModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/purchase/get-current-pricing-options',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * Check Coupon Code
     * @param couponCode
     * @returns CheckCouponReturnDto Successful Response
     * @throws ApiError
     */
    public static checkCouponCodePurchaseCheckCouponCodePost(
        couponCode: string,
    ): CancelablePromise<CheckCouponReturnDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/purchase/check-coupon-code',
            query: {
                'coupon_code': couponCode,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Card Installment Options
     * @param pricingOptionId
     * @param creditCardBinNumber First 6 digit of credit card number
     * @param couponCode
     * @returns InstallmentOptionsResponse Successful Response
     * @throws ApiError
     */
    public static getCardInstallmentOptionsPurchaseGetCardInstallmentOptionsPost(
        pricingOptionId: string,
        creditCardBinNumber: string,
        couponCode?: string,
    ): CancelablePromise<InstallmentOptionsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/purchase/get-card-installment-options',
            query: {
                'pricing_option_id': pricingOptionId,
                'credit_card_bin_number': creditCardBinNumber,
                'coupon_code': couponCode,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Make Premium Without Credit Card
     * This endpoint is for making user premium with just coupon code
     * @param couponCode
     * @param pricingOptionId
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static makePremiumWithoutCreditCardPurchaseMakePremiumWithoutCreditCardPost(
        couponCode: string,
        pricingOptionId: string,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/purchase/make-premium-without-credit-card',
            query: {
                'coupon_code': couponCode,
                'pricing_option_id': pricingOptionId,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Make Premium With New Credit Card
     * @param pricingOptionId
     * @param requestBody
     * @param couponCode
     * @param installmentNumber
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static makePremiumWithNewCreditCardPurchaseMakePremiumWithNewCreditCardPost(
        pricingOptionId: string,
        requestBody: Body_make_premium_with_new_credit_card_purchase_make_premium_with_new_credit_card_post,
        couponCode?: string,
        installmentNumber: number = 1,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/purchase/make-premium-with-new-credit-card',
            query: {
                'pricing_option_id': pricingOptionId,
                'coupon_code': couponCode,
                'installment_number': installmentNumber,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Saved Cards
     * @returns SavedCard Successful Response
     * @throws ApiError
     */
    public static getSavedCardsPurchaseGetSavedCardsGet(): CancelablePromise<Array<SavedCard>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/purchase/get-saved-cards',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * Delete Saved Card
     * @param cardBinNumber
     * @param cardLastFourDigits
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static deleteSavedCardPurchaseDeleteSavedCardDelete(
        cardBinNumber: string,
        cardLastFourDigits: string,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/purchase/delete-saved-card',
            query: {
                'card_bin_number': cardBinNumber,
                'card_last_four_digits': cardLastFourDigits,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Payment With Saved Credit Card
     * @param pricingOptionId
     * @param cardBinNumber
     * @param cardLastFourDigits
     * @param requestBody
     * @param couponCode
     * @param installmentNumber
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static createPaymentWithSavedCreditCardPurchaseCreatePaymentWithSavedCreditCardPost(
        pricingOptionId: string,
        cardBinNumber: string,
        cardLastFourDigits: string,
        requestBody: AddressDetail,
        couponCode?: string,
        installmentNumber: number = 1,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/purchase/create-payment-with-saved-credit-card',
            query: {
                'pricing_option_id': pricingOptionId,
                'card_bin_number': cardBinNumber,
                'card_last_four_digits': cardLastFourDigits,
                'coupon_code': couponCode,
                'installment_number': installmentNumber,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * In App Purchase Webhook
     * @param paidAmount
     * @param pricingOptionId
     * @param inAppPurchaseProvider
     * @param inAppPurchaseId
     * @param couponCode
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static inAppPurchaseWebhookPurchaseInAppPurchaseWebhookPost(
        paidAmount: number,
        pricingOptionId: string,
        inAppPurchaseProvider: InAppPurchaseProvider,
        inAppPurchaseId: string,
        couponCode?: string,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/purchase/in-app-purchase-webhook',
            query: {
                'paid_amount': paidAmount,
                'pricing_option_id': pricingOptionId,
                'in_app_purchase_provider': inAppPurchaseProvider,
                'in_app_purchase_id': inAppPurchaseId,
                'coupon_code': couponCode,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Save New Payment Card
     * @param requestBody
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static saveNewPaymentCardPurchaseSaveNewCardPost(
        requestBody: IyzicoSaveExternalNewCard,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/purchase/save-new-card',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Discount Percentage In Gift Purchase
     * @returns GetDiscountPercentageInGiftPurchaseResponse Successful Response
     * @throws ApiError
     */
    public static getDiscountPercentageInGiftPurchasePurchaseGetDiscountPercentageInGiftPurchaseGet(): CancelablePromise<GetDiscountPercentageInGiftPurchaseResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/purchase/get-discount-percentage-in-gift-purchase',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * Purchase Gift With New Credit Card
     * @param pricingOptionId
     * @param emailAddressToSendGiftCode
     * @param requestBody
     * @param giftMessageFromPurchaser
     * @returns GiftPurchaseResponse Successful Response
     * @throws ApiError
     */
    public static purchaseGiftWithNewCreditCardPurchasePurchaseGiftWithNewCreditCardPost(
        pricingOptionId: string,
        emailAddressToSendGiftCode: string,
        requestBody: Body_purchase_gift_with_new_credit_card_purchase_purchase_gift_with_new_credit_card_post,
        giftMessageFromPurchaser: string = '',
    ): CancelablePromise<GiftPurchaseResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/purchase/purchase-gift-with-new-credit-card',
            query: {
                'pricing_option_id': pricingOptionId,
                'email_address_to_send_gift_code': emailAddressToSendGiftCode,
                'gift_message_from_purchaser': giftMessageFromPurchaser,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Purchase Gift With Saved Credit Card
     * @param pricingOptionId
     * @param cardBinNumber
     * @param cardLastFourDigits
     * @param emailAddressToSendGiftCode
     * @param requestBody
     * @param giftMessageFromPurchaser
     * @returns GiftPurchaseResponse Successful Response
     * @throws ApiError
     */
    public static purchaseGiftWithSavedCreditCardPurchasePurchaseGiftWithSavedCreditCardPost(
        pricingOptionId: string,
        cardBinNumber: string,
        cardLastFourDigits: string,
        emailAddressToSendGiftCode: string,
        requestBody: AddressDetail,
        giftMessageFromPurchaser: string = '',
    ): CancelablePromise<GiftPurchaseResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/purchase/purchase-gift-with-saved-credit-card',
            query: {
                'pricing_option_id': pricingOptionId,
                'card_bin_number': cardBinNumber,
                'card_last_four_digits': cardLastFourDigits,
                'email_address_to_send_gift_code': emailAddressToSendGiftCode,
                'gift_message_from_purchaser': giftMessageFromPurchaser,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Check Users In App Recurring Purchase Status
     * This endpoint is for checking in app purchase status of users and make them premium if their package renewed.
     * @param cronPassword
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static checkUsersInAppRecurringPurchaseStatusPurchaseCheckUsersInAppRecurringPurchaseStatusGet(
        cronPassword: string,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/purchase/check-users-in-app-recurring-purchase-status',
            query: {
                'cron_password': cronPassword,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unsubscribe Web Payment
     * @param password
     * @returns IsRequestSuccessfulModel Successful Response
     * @throws ApiError
     */
    public static unsubscribeWebPaymentPurchaseUnsubscribeWebPaymentPost(
        password: string,
    ): CancelablePromise<IsRequestSuccessfulModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/purchase/unsubscribe-web-payment',
            query: {
                'password': password,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

}
