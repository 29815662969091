import { FC } from "react";
import { Typography } from "@mui/material";
import CommonLabel from "./CommonLabel";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store/store";

interface Props {
  partnerCode: string;
}

const InviteFamilyMember: FC<Props> = ({ partnerCode }) => {
  const { profile } = useSelector((state: RootState) => state.auth);

  return (
    <>
      {profile.partner_ids?.length == 0 ? (
        profile?.partner_code_of_usage_count == undefined ||
        profile.partner_code_of_usage_count > 0 ? null : (
          <div>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "20px",
                lineHeight: "28px",
                color: "#171725",
                marginBottom: "30px",
              }}
            >
              Aile Üyesi Davet Et
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#78828A",
                marginBottom: "32px",
              }}
            >
              Aile üyesi olarak davet etmek istediğiniz kişiyle kodu
              paylaşabilirsiniz. Bu kodu paylaştığınız kişi çocuklarınızı
              görebilir ve premium üyeyseniz tüm içeriklere sınırsız bir şekilde
              ulaşabilir. Bu kodu sadece tek kişiyle paylaşabilirsiniz.
            </Typography>
            <CommonLabel label="Aile Kodun" />

            <div
              style={{
                padding: "17px 15px",
                backgroundColor: "#F9F9F9",
                border: "1px solid #2574FF",
                borderRadius: "12px",
                display: "flex",
                alignItems: "center",
                width: "max-content",
                marginBottom: "30px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "22px",
                  color: "#434E58",
                  marginRight: "60px",
                }}
              >
                {partnerCode}
              </Typography>
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "12px",
                  lineHeight: "20px",
                  color: "#2574FF",
                  cursor: "pointer",
                }}
                onClick={() => navigator.clipboard.writeText(partnerCode)}
              >
                Kodu Kopyala
              </Typography>
            </div>
          </div>
        )
      ) : null}
    </>
  );
};

export default InviteFamilyMember;
