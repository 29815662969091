import { FC, useEffect, useState } from "react";
import { NameLogoBlue } from "../assests";
import { Divider, Container, Grid, Typography } from "@mui/material";
import { CommonLoading, DetailBackButton, H5BoldHeader } from "../components";
import { useNavigate, useParams } from "react-router";
import { UserService } from "../client";
import parse from "html-react-parser";
import { OfficialDocumentEnums } from "../utils/constants";

const OfficialDocument: FC = () => {
  const navigate = useNavigate();
  const { type } = useParams();

  const [text, setText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const data =
          await UserService.getExplainedTextWithSlugUserGetExplainedTextWithSlugSlugGet(
            type as string
          );
        setText(data.content);
      } catch (error: any) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <img
        src={NameLogoBlue}
        alt=""
        style={{ marginTop: "20px", marginBottom: "20px" }}
      />
      <Divider style={{ width: "100%", border: "1px solid #E9EBED" }} />
      <CommonLoading loading={loading} />
      <Container maxWidth="lg" style={{marginTop:"40px"}}>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 24 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "90vh",
          }}
        >
          <Grid item xs={4} sm={8} md={1}>
            <DetailBackButton
              onClick={() => navigate(-1)}
              customCss={{ height: "50px" }}
            />
          </Grid>
          <Grid item xs={4} sm={8} md={23}>
            <H5BoldHeader title={OfficialDocumentEnums.find((e) => e.slug == type)?.title as string} _marginBottom="0px" />
          </Grid>
          <Grid item xs={4} sm={8} md={24} style={{marginTop:"25px"}}>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#78828A",
                textAlign: "start",
              }}
            >
              {parse(text)}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default OfficialDocument;
