import { FC } from "react";
import { Typography } from "@mui/material";

const AboutPw: FC = () => {
  return (
    <>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "600",
          fontSize: "20px",
          lineHeight: "28px",
          color: "#171725",
          marginBottom: "30px",
        }}
      >
        Hakkında
      </Typography>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#78828A",
          marginTop: "30px",
        }}
      >
        ParentWiser Yazılım Anonim Şirketi; Dr. Özgür Bolat liderliğinde, 2020
        yılında Yıldız Teknik Üniversitesi - Yıldız Teknokent’te kurulmuş
        inovatif bir girişimdir. 
      </Typography>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#78828A",
          marginTop: "30px",
        }}
      > 
        Eğitim Bilimci Dr. Özgür Bolat 2-18 yaş çocuğu
        olan ebeveynler için uzman psikolog ekibiyle birlikte “Kişiye Özgü Çocuk
        Yetiştirme Rehberi” geliştirmiştir. 
      </Typography>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#78828A",
          marginTop: "30px",
        }}
      >
        Uygulamanın amacı, çocuk yetiştirme
        sürecinde ebeveynleri zorlayan konuları ve sık yaşanan sorunları analiz
        ederek, çözüm odaklı içerikler ile anne babalık yolculuğuna bilimsel
        bakış açısı ile eşlik etmektir. 
      </Typography>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#78828A",
          marginTop: "30px",
        }}
      > 
        Uygulama içerisindeki bilimsel anketler,
        Anne Baba Okulu videoları ve Ebeveyn Çözümleri aracılığı ile
        ebeveynlerin Koşulsuz Ebeveynlik Prensiplerini anlayıp, Demokratik Aile
        olma yolcuklarına çıkmasını sağlamaktır.
      </Typography>
    </>
  );
};

export default AboutPw;
