import { FC, useEffect } from "react";
import { Grid, Container, Typography, Skeleton } from "@mui/material";
import { BlueFamily, LandingRight } from "../assests";
import { featureList } from "../components/LandingPackageSection";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { OpenAPI } from "../client";
import { getAvailablePurchasePackages } from "../redux/purchaseSlice";
import { LandingPriceCard } from "../components";
import { IngDiscountCoupon, IngDiscountRate } from "../utils/constants";

const IngDiscountLanding: FC = () => {
  const { loading, pricing_options } = useSelector(
    (state: RootState) => state.purchase
  );
  const { access_token } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (OpenAPI.TOKEN == undefined && access_token != "") {
      OpenAPI.TOKEN = access_token;
    }
    dispatch(getAvailablePurchasePackages());
  }, []);

  return (
    <Container maxWidth="lg">
      <Grid
        container
        columns={{ xs: 4, sm: 8, md: 12 }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: { xs: "40px", sm: "40px", md: "100px" },
          marginBottom: { xs: "10px", sm: "10px", md: "40px" },
        }}
      >
        <Grid item xs={4} sm={8} md={12} style={{ marginBottom: "32px" }}>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontWeight: { xs: 500, sm: 500, md: 700 },
              fontSize: { xs: "24px", sm: "24px", md: "52px" },
              lineHeight: { xs: "41px", sm: "41px", md: "60px" },
              color: { xs: "#66707A", sm: "#66707A", md: "#061C3D" },
            }}
          >
            ING Kullanıcılarına Özel:
          </Typography>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontWeight: 700,
              fontSize: { xs: "42px", sm: "42px", md: "52px" },
              lineHeight: { xs: "48px", sm: "48px", md: "60px" },
              color: "#061C3D",
              textAlign: { xs: "start", sm: "start", md: "center" },
            }}
          >
            ParentWiser Premium'da %40 indirim!
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sm={8}
          md={0}
          sx={{
            display: { xs: "flex", sm: "flex", md: "none" },
            flexDirection: "column",
            padding: "7px",
          }}
        >
          {featureList.map((e) => (
            <div
              style={{
                display: "flex",
                textAlign: "start",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <img
                src={e.iconSm}
                alt=""
                style={{
                  width: "24.15px",
                  height: "24.15px",
                  marginRight: "14px",
                }}
              />
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "24px",
                  color: "#000000",
                }}
              >
                {e.text}
              </Typography>
            </div>
          ))}
        </Grid>
        <Grid
          item
          xs={4}
          sm={8}
          md={12}
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{
            border: { xs: "", sm: "", md: "1px solid #E4E4E4" },
            borderRadius: { xs: "0px", sm: "0px", md: "20px" },
          }}
        >
          <Grid
            item
            xs={4}
            sm={8}
            md={6}
            sx={{ padding: { xs: "0px", sm: "0px", md: "48px" } }}
            className="scrollPoint"
          >
            {loading ? (
              <Grid item xs={4} sm={8} md={12} >
                <Skeleton variant="rounded" height={100} />
                <Skeleton
                  variant="rounded"
                  height={100}
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                />
                                <Skeleton
                  variant="rounded"
                  height={100}
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                />
                                <Skeleton
                  variant="rounded"
                  height={100}
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                />
              </Grid>
            ) : (
              pricing_options.map((e) => (
                <Grid item xs={4} sm={8} md={12}>
                  <LandingPriceCard
                    price={e.price}
                    id={e.id}
                    isHighlighted={e?.is_highlighted ? e.is_highlighted : false}
                    name={e.name}
                    webDiscountCoupon={IngDiscountCoupon}
                    webDiscountRate={IngDiscountRate}
                  />
                </Grid>
              ))
            )}
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontWeight: 400,
                fontSize: { xs: "14px", sm: "14px", md: "18px" },
                lineHeight: { xs: "26px", sm: "26px", md: "26px" },
                color: "#061C3D",
                textAlign: { xs: "start", sm: "start", md: "start" },
              }}
            >
              Dilediğin zaman iptal edebilirsin.
            </Typography>
          </Grid>
          <Grid
            item
            xs={0}
            sm={0}
            md={6}
            sx={{
              backgroundImage: `url(${BlueFamily})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: "#2574FF",
              padding: "42px",
              display: { xs: "none", sm: "none", md: "flex" },
              flexDirection: "column",
              justifyContent: "space-around",
              borderRadius: "0px 20px 20px 0px",
            }}
          >
            {featureList.map((e) => (
              <div
                style={{
                  display: "flex",
                  textAlign: "start",
                  alignItems: "center",
                }}
              >
                <img
                  src={e.icon}
                  alt=""
                  style={{
                    width: "41.47px",
                    height: "41.47px",
                    marginRight: "24px",
                  }}
                />
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "27px",
                    color: "#FFFFFF",
                  }}
                >
                  {e.text}
                </Typography>
              </div>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default IngDiscountLanding;
