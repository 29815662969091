import { FC } from "react";
import { Dialog, DialogContent, Typography, Button } from "@mui/material";
import { ModalWarning } from "../assests";

interface Props {
  isOpen: boolean;
  title: string;
  text: string;
  onButtonFunc: () => void;
  buttonText: string;
}

const CommonErrorModalNoShadow: FC<Props> = ({
  isOpen = false,
  title = "",
  text = "",
  onButtonFunc,
  buttonText = "Tekrar Dene",
}) => {
  return (
    <Dialog open={isOpen} onClose={onButtonFunc} style={{ width: "auto" }} hideBackdrop>
      <DialogContent
        sx={{
          border: "1px solid #DEE2E6",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          width: "fit-content",
          borderRadius:"16px"
        }}
      >
        <img src={ModalWarning} alt="" width={40} />
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "28px",
            marginBottom: "8px",
            marginTop: "28px",
            color: "#0D0C0F",
          }}
        >
          {title}
        </Typography>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "400",
            fontSize: "15px",
            lineHeight: "24px",
            marginBottom: "24px",
            color: "#0D0C0F",
            maxWidth: "60%",
          }}
        >
          {text}
        </Typography>
        <Button
          onClick={onButtonFunc}
          variant="outlined"
          style={{
            border: "1px solid #0D0C0F",
            borderRadius: "12px",
            backgroundColor: "#FFFFFF",
            padding: "12px 60px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#0D0C0F",
            }}
          >
            {buttonText}
          </Typography>
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default CommonErrorModalNoShadow;
