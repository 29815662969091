import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import { NotificationTarget } from "../client";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store/store";
import { readNotification } from "../redux/notificationSlice";

interface Props {
  id:string
  title: string;
  date: string;
  description: string;
  isReaded: boolean;
  notificationTarget?: NotificationTarget;
  notificationTargetDetail?: string;
  navigate:(target:string) => void
}

const NotificationItem: FC<Props> = ({
  id,
  title,
  date,
  description,
  isReaded,
  notificationTarget,
  notificationTargetDetail,
  navigate
}) => {
  const dispatch = useDispatch<AppDispatch>()

  const notificationOnClicked = (notificationTarget?:NotificationTarget, notificationTargetDetail?:string) => {
    try {
      if(!isReaded) {
        dispatch(readNotification({notificationId: id}))
      }
  
      if(notificationTarget == null || notificationTarget == undefined){
        return
      }
  
      switch (notificationTarget) {
        case NotificationTarget.ARTICLE:
          navigate(`/article/${notificationTargetDetail}`)
          break;
        case NotificationTarget.TRAINING_SET:
          navigate(`/parentsolution/${notificationTargetDetail}`)
          break
        case NotificationTarget.SURVEY:
          navigate(`/survey/${notificationTargetDetail}`)
          break;
        case NotificationTarget.PARENT_SCHOOL_MODULE:
          navigate("/parentschool")
          break
        case NotificationTarget.BULLETIN:
          navigate(`/bulletin/${notificationTargetDetail}`)
          break
        case NotificationTarget.OPEN_LINK:
          window.open(`${notificationTargetDetail}`, "_blank")
          break
        default:
          break;
      }
    } catch (error) {
      
    }
  }

  return (
    <Grid
      container
      columns={{ xs: 4, sm: 8, md: 12 }}
      sx={{
        padding: { xs: "11px", sm: "11px", md: "22px" },
        backgroundColor: "#F9F9F9",
        borderRadius: "8px",
        border: `0.5px solid ${isReaded ? "#D1D8DD" : "#2574FF"}`,
        cursor:"pointer"
      }}
      onClick={() => notificationOnClicked(notificationTarget, notificationTargetDetail)}
    >
      <Grid item xs={4} sm={8} md={10}>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "18px",
            lineHeight: "26px",
            color: "#66707A",
            textAlign:"start"
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        xs={0}
        sm={0}
        md={2}
        sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "12px",
            lineHeight: "20px",
            color: "#66707A",
            width:"100%",
            textAlign:"end"
          }}
        >
          {date.split("T")[0]}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        sx={{ marginTop: { xs: "8px", sm: "8px", md: "16px" } }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "12px",
            lineHeight: "20px",
            color: "#66707A",
            textAlign:"start"
          }}
        >
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NotificationItem;
