import { Box, Container, Grid, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import {
  CommonButton,
  CommonCheckboxWithParam,
  CommonLoading,
  CommonMultiLineTextField,
  CommonTextField,
  GiftPackageItem,
  HeadingTitle,
  CommonLabel,
  SubscriptionPaymentCardNoDiscountField,
  CommonLightButton,
  CommonAlert,
} from "../components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { OpenAPI, PurchaseService } from "../client";
import {
  getAvailablePurchasePackages,
  getGiftDiscountRate,
  getMySavedCards,
} from "../redux/purchaseSlice";
import {
  GiftFirstPhase,
  IGiftPaymentAddress,
  INewCreditCard,
  ISelectCardForPayment,
} from "../utils/commonModels";
import { useNavigate } from "react-router";

const GiftPremium: FC = () => {
  const { loading, pricing_options, gift_discount_rate } = useSelector(
    (state: RootState) => state.purchase
  );
  const { access_token, profile } = useSelector(
    (state: RootState) => state.auth
  );
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate()

  const [localLoading, setLocalLoading] = useState<boolean>(false)
  const [step, setStep] = useState<string>("package"); //Options are "package", "payment"
  const [firstPhase, setFirstPhase] = useState<GiftFirstPhase>({
    selectedPackage: null,
    toEmail: "",
    senderNameSurname: profile.name ? profile.name : "",
    message:
      "Parentwiser Ailesi’ne Hoş geldiniz! Koşulsuz ebeveynliğe doğru çıktığınız bu yolculukta sizlerle birlikte olmaktan mutluluk duyuyoruz.",
    sendGiftCodeToMe: true,
  });

  const [addressPhase, setAddressPhase] = useState<IGiftPaymentAddress>({
    country: "Türkiye",
    city: "",
    county: "",
    invoiceAddress: "",
  });

  const [creditCard, setCreditCard] = useState<INewCreditCard>({
    card_holder_name: "",
    card_number: "",
    expiration_date: "",
    cvc: "",
  });
  const [paymentError, setPaymentError] = useState<string>("");
  const [selectedSavedCard, setSelectedSavedCard] =
    useState<ISelectCardForPayment>({
      card_bin_number: "",
      card_last_four_digits: "",
    });

    useEffect(() => {
    if (OpenAPI.TOKEN == undefined && access_token != "") {
      OpenAPI.TOKEN = access_token;
    }

    dispatch(getAvailablePurchasePackages());
    dispatch(getGiftDiscountRate());
    dispatch(getMySavedCards());
  }, []);

  useEffect(() => {
    if (pricing_options.length > 0) {
      setFirstPhase({
        ...firstPhase,
        selectedPackage: pricing_options[pricing_options.length - 1].id,
      });
    }
  }, [pricing_options]);

  const calculateNetPrice = (e: number) => {
    try {
      const discount = (e * gift_discount_rate) / 100;
      const lastPrice = e - discount;
      return parseFloat(lastPrice.toFixed(2));
    } catch (error) {
      return e;
    }
  };

  const firstStepButtonDisabled = () => {
    try {
      const { selectedPackage, toEmail, senderNameSurname, message } =
        firstPhase;
      if (
        selectedPackage != null &&
        message.length > 5 &&
        senderNameSurname.trim().split(" ").length >= 2 &&
        toEmail.includes("@")
      ) {
        return false;
      }
      return true;
    } catch (error) {}
  };

  const secondStepButtonDisabled = () => {
    try {
      const { country, city, county, invoiceAddress } = addressPhase;
      const { card_holder_name, card_number, expiration_date, cvc } =
        creditCard;

      if (
        country.length > 2 &&
        city.length > 2 &&
        county.length > 2 &&
        invoiceAddress.length > 5 &&
        (card_holder_name.split(" ").length >= 2 &&
        card_number.replaceAll(" ", "").length == 16 &&
        cvc.length == 3 &&
        expiration_date.replaceAll("/", "").length == 4) ||
        selectedSavedCard.card_bin_number.length > 2
      ) {
        return false;
      }
      return true;
    } catch (error) {}
  };

  const submitGiftPayment = async() => {
    try {
      setLocalLoading(true)
      //Pay gift with saved card
      if(selectedSavedCard.card_bin_number.length > 2 && selectedSavedCard.card_last_four_digits.length > 2){
        const response = await PurchaseService.purchaseGiftWithSavedCreditCardPurchasePurchaseGiftWithSavedCreditCardPost(
          firstPhase.selectedPackage as string,
          selectedSavedCard.card_bin_number,
          selectedSavedCard.card_last_four_digits,
          firstPhase.toEmail,
          {
            city:addressPhase.city,
            country:addressPhase.country,
            address:addressPhase.invoiceAddress,
            county:addressPhase.county
          },
          firstPhase.message
        )
        navigate(`/gift-success/${response.gift_code}`)
      }else { //Pay gift with new card
        const response = await PurchaseService.purchaseGiftWithNewCreditCardPurchasePurchaseGiftWithNewCreditCardPost(
          firstPhase.selectedPackage as string,
          firstPhase.toEmail,
          {
            address_detail: {
              city:addressPhase.city,
              country:addressPhase.country,
              address:addressPhase.invoiceAddress,
              county:addressPhase.county
            },
            credit_card: {
              card_holder_name:creditCard.card_holder_name,
              card_number:creditCard.card_number.replaceAll(" ",""),
              expiration_month:creditCard.expiration_date.split("/")[0],
              expire_year:"20"+creditCard.expiration_date.split("/")[1],
              cvc:creditCard.cvc
            }
          },
          firstPhase.message
        )
        navigate(`/gift-success/${response.gift_code}`)
      }
      setLocalLoading(false)
    } catch (error:any) {
      setLocalLoading(false)
      setPaymentError(error.body.detail || "Bir hata ile karşılaşıldı. Lütfen iletişime geçin.")
    }
  }

  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <div style={{ textAlign: "start" }}>
        <HeadingTitle
          title="Yakınına ParentWiser Hediye Et"
          _marginBottom="12px"
        />
      </div>
      <CommonAlert         
        _type={"warning"}
        _open={paymentError == "" ? false : true}
        _onClose={() => setPaymentError("")}
        _message={paymentError}/>
      <CommonLoading loading={loading || localLoading} />
      {step == "package" ? (
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          style={{
            borderRadius: "8px",
            border: "1px solid #F0F0F0",
            background: "#FFF",
            padding: "34px",
            width: "fit-content",
          }}
        >
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            style={{ textAlign: "start", marginBottom: "32px" }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: 400,
                fontSize: "24px",
                lineHeight: "28px",
                color: "#2574FF",
              }}
            >
              Bir yakınınıza ParentWiser <br /> deneyimini{" "}
              <span
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "28px",
                  color: "#2574FF",
                  marginRight: "5px",
                }}
              >
                %{gift_discount_rate} indirimli
              </span>
              hediye edin!
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            style={{ textAlign: "start", marginBottom: "16px" }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "22px",
                color: "#171725",
              }}
            >
              Paket Seçimi Yapın
            </Typography>
          </Grid>
          <Grid
            item
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            columnSpacing={2}
            rowSpacing={2}
          >
            {pricing_options.length > 0 &&
              pricing_options.map((e) => (
                <Grid item xs={4} sm={8} md={3} style={{}}>
                  <GiftPackageItem
                    is_highlighted={
                      firstPhase.selectedPackage == e.id ? true : false
                    }
                    price={e.price}
                    netPrice={calculateNetPrice(e.price)}
                    onClicked={() =>
                      setFirstPhase({ ...firstPhase, selectedPackage: e.id })
                    }
                    name={e.name}
                  />
                </Grid>
              ))}
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            sx={{ textAlign: "start", marginBottom: "16px", marginTop: "32px" }}
          >
            <Box
              component="div"
              sx={{ maxWidth: { xs: "100%", sm: "100%", md: "50%" } }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "22px",
                  color: "#171725",
                  marginBottom: "8px",
                }}
              >
                Yakınınızın Eposta Adresi
              </Typography>
              <CommonTextField
                _placeHolder="Yakınınınız eposta adresini gir"
                _onChange={(e) => setFirstPhase({ ...firstPhase, toEmail: e })}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            sx={{
              textAlign: "start",
              marginBottom: "16px",
              marginTop: "16px",
            }}
          >
            <Box
              component="div"
              sx={{ maxWidth: { xs: "100%", sm: "100%", md: "50%" } }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "22px",
                  color: "#171725",
                  marginBottom: "8px",
                }}
              >
                Gönderen
              </Typography>
              <CommonTextField
                _placeHolder="İsminiz Soyisminiz"
                _value={firstPhase.senderNameSurname}
                _onChange={(e) =>
                  setFirstPhase({ ...firstPhase, senderNameSurname: e })
                }
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            sx={{ textAlign: "start", marginBottom: "16px", marginTop: "16px" }}
          >
            <Box
              component="div"
              sx={{ maxWidth: { xs: "100%", sm: "100%", md: "50%" } }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "22px",
                  color: "#171725",
                  marginBottom: "8px",
                }}
              >
                Mesajınız
              </Typography>
              <CommonMultiLineTextField
                _value={firstPhase.message}
                _onChange={(e) => setFirstPhase({ ...firstPhase, message: e })}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            style={{
              textAlign: "start",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CommonCheckboxWithParam
              value={firstPhase.sendGiftCodeToMe}
              onChange={(e) =>
                setFirstPhase({ ...firstPhase, sendGiftCodeToMe: e })
              }
            />
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: "11px",
                lineHeight: "16px",
                color: "#9393AA",
                marginLeft: "5px",
              }}
            >
              Hediye kodunu kendi mail adresime de gönder.
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            style={{ display: "flex", alignItems: "start", marginTop: "32px" }}
          >
            <CommonButton
              text="Ödeme Adımına Geç"
              customCSS={{ width: "fit-content", padding: "12px 24px" }}
              disabled={firstStepButtonDisabled()}
              onClick={() => setStep("payment")}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          style={{
            borderRadius: "8px",
            border: "1px solid #F0F0F0",
            background: "#FFF",
            padding: "24px",
            width: "fit-content",
          }}
          spacing={1}
        >
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            style={{ textAlign: "start", marginBottom: "32px" }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: 400,
                fontSize: "24px",
                lineHeight: "28px",
                color: "#2574FF",
              }}
            >
              Bir yakınınıza ParentWiser <br /> deneyimini{" "}
              <span
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "28px",
                  color: "#2574FF",
                  marginRight: "5px",
                }}
              >
                %{gift_discount_rate} indirimli
              </span>
              hediye edin!
            </Typography>
          </Grid>
          <Grid item xs={4} sm={8} md={4} style={{ textAlign: "start" }}>
            <CommonLabel label="Ülke" />
            <CommonTextField
              _placeHolder="Ülke seçiniz"
              _onChange={(e: string) =>
                setAddressPhase({ ...addressPhase, country: e })
              }
              _value={addressPhase.country}
            />
          </Grid>
          <Grid item xs={4} sm={8} md={4} style={{ textAlign: "start" }}>
            <CommonLabel label="Şehir" />
            <CommonTextField
              _placeHolder="Şehir seçiniz"
              _onChange={(e: string) =>
                setAddressPhase({ ...addressPhase, city: e })
              }
              _value={addressPhase.city}
            />
          </Grid>
          <Grid item xs={4} sm={8} md={4} style={{ textAlign: "start" }}>
            <CommonLabel label="İlçe" />
            <CommonTextField
              _placeHolder="İlçe seçiniz"
              _onChange={(e: string) =>
                setAddressPhase({ ...addressPhase, county: e })
              }
              _value={addressPhase.county}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            style={{ textAlign: "start", marginTop: "32px" }}
          >
            <CommonLabel label="Fatura Adresi" />
            <CommonMultiLineTextField
              _onChange={(e: string) =>
                setAddressPhase({ ...addressPhase, invoiceAddress: e })
              }
              _value={addressPhase.invoiceAddress}
            />
          </Grid>
          <Grid item xs={4} sm={8} md={12}>
            <SubscriptionPaymentCardNoDiscountField
              setField={(e: INewCreditCard) => setCreditCard(e)}
              values={creditCard}
              selectSavedCard={setSelectedSavedCard}
              selectedCard={selectedSavedCard}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            style={{ display: "flex", alignItems: "start", marginTop: "32px" }}
          >
            <CommonLightButton
              text="Geri"
              customCSS={{
                width: "fit-content",
                padding: "13px 35px",
                marginRight: "30px",
              }}
              onClick={() => setStep("package")}
            />{" "}
            <CommonButton
              text="Ödeme Yap"
              customCSS={{ width: "fit-content", padding: "12px 24px" }}
              disabled={secondStepButtonDisabled()}
              onClick={() => submitGiftPayment()}
            />
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default GiftPremium;
