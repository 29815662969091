import { FC } from "react";
import { Divider, Typography } from "@mui/material";
import CommonSwitch from "./CommonSwitch";

interface Props {
  text: string;
  icon: string;
  isChecked?:boolean;
  _onChange:(e:any) => void
}

const CommunicationPermissionSwitch: FC<Props> = ({ text = "", icon = "", isChecked=false, _onChange }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "start",
          width: "100%",
          marginTop: "10px",
          marginBottom: "25px",
          padding:"12px 16px"
        }}
      >
        <div style={{ display: "flex", alignItems: "start" }}>
          <img src={icon} alt="" style={{ marginRight: "11px" }} />
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: "26px",
              color: "#78828A",
              textTransform: "none",
            }}
          >
            {text}
          </Typography>
        </div>
        <CommonSwitch isChecked={isChecked} _onChange={(e:any) => _onChange(e)}/>
      </div>
      {
       <Divider variant="middle" style={{width:'87%', color:"#E9EBED"}} />
    }
    </>
  );
};

export default CommunicationPermissionSwitch;
