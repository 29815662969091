import { FC } from 'react'
import { Grid, Typography } from '@mui/material';
import { Buckets } from '../utils/constants';
import DetailBackButton from './DetailBackButton';
import { useNavigate } from 'react-router';

interface Props {
    is_picture_uploaded:boolean
    id:string
    title:string
}

const BulettinHeader:FC<Props> = ({
    is_picture_uploaded=false,
    id,
    title=""
}) => {

    const navigate = useNavigate()

    const containerStyle = {
        background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.74) 100%), url(${
          is_picture_uploaded ? `${Buckets.BulettinBucket}/${id}.jpg` : ""
        })`,
        padding: "30px",
        borderRadius: "24px",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      };

  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }} style={containerStyle}>
    <Grid item xs={4} sm={8} md={6}>
      <DetailBackButton onClick={() => navigate(-1)} />
    </Grid>
    <Grid
      item
      xs={false}
      sm={false}
      md={6}
      sx={{
        display: { xs: "none", sm: "none", md: "flex" },
        flexDirection: "row",
        justifyContent: "flex-end",
      }}
    > 
    </Grid>
    <Grid
      item
      xs={4}
      sm={8}
      md={12}
      sx={{
        maxWidth: { xs: "100%", sm: "100%", md: "85%" },
        textAlign: "start",
        marginTop: "15%",
      }}
    >
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: "700",
          fontSize: "40px",
          lineHeight: "48px",
          color: "#FFFFFF",
        }}
      >
        {title}
      </Typography>
    </Grid>
  </Grid>
  )
}

export default BulettinHeader