import { Container, Grid } from "@mui/material";
import { FC, useEffect, MouseEvent, useState } from "react";
import {
  ArticleListingItem,
  CommonLoading,
  HeadingTitle,
  HomeArticleSlider,
  LoadMorePaginationButton,
} from "../components";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { getFilteredArticles } from "../redux/articleSlice";
import {
  addItemToFavorite,
  removeItemFromFavorite,
} from "../redux/favoriteSlice";
import { ArticleType, FavoriteType,OpenAPI } from "../client";
import { shuffleAndGetContent } from "../utils/commonFuncs";
import { ISuggestedArticle } from "../utils/commonModels";

const Articles: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { articles, loading } = useSelector(
    (state: RootState) => state.article
  );

  const [suggested, setSuggested] = useState<Array<ISuggestedArticle>>(
    [] as ISuggestedArticle[]
  );
  const { access_token } = useSelector((state: RootState) => state.auth);


  useEffect(() => {
    if (OpenAPI.TOKEN == undefined && access_token != "") {
      OpenAPI.TOKEN = access_token;
    }

    if (articles.articles.length == 0) {
      dispatch(
        getFilteredArticles({
          startAfterArticleId: articles.last_article_id,
          articleType: ArticleType.TEXT,
          customLimit: 30,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (suggested.length == 0 && articles.articles.length > 0){
      setSuggested(shuffleAndGetContent(articles.articles,5).map((e)=> {return({id:e.id, title:e.name, is_picture_uploaded:e.is_picture_uploaded ? e.is_picture_uploaded : false})}))
    }
  }, [articles.articles])
  

  const addContentToFavorite = (
    e: MouseEvent,
    id: string,
    name_of_content: string
  ) => {
    try {
      e.stopPropagation();
      dispatch(
        addItemToFavorite({
          id_of_favorite: id,
          name_of_favorite: name_of_content,
          favourite_type: FavoriteType.ARTICLE,
        })
      );
    } catch (error) {}
  };

  const removeContentFromFavorite = (e: MouseEvent, id: string) => {
    try {
      e.stopPropagation();
      dispatch(removeItemFromFavorite({ favorite_item_id: id }));
    } catch (error) {}
  };

  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <CommonLoading loading={loading} />
      <Grid
        container
        columns={{ xs: 4, sm: 8, md: 12,lg: 12, xl: 12 }}
        spacing={2}
        rowSpacing={"30px"}
      >
        <Grid item xs={4} sm={8} md={12} lg={12}style={{ textAlign: "start" }}>
          <HeadingTitle
            title="Sizin İçin Seçtiğimiz Makaleler"
            _marginBottom="0px"
          />
        </Grid>
        <Grid item xs={4} sm={8} md={12} lg={12}>
          <HomeArticleSlider
            items={suggested}
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={8}
          md={12}
          lg={12}
          style={{
            marginTop: "28px",
            textAlign: "start",
          }}
        >
          <HeadingTitle title="Tüm Makaleler" _marginBottom="0px" />
        </Grid>
        {!loading &&
          articles.articles.map((e, index) => {
            return (
              <Grid item xs={4} sm={8} md={3} key={index}>
                <ArticleListingItem
                  id={e.id}
                  title={e.name}
                  is_picture_uploaded={
                    e?.is_picture_uploaded ? e.is_picture_uploaded : false
                  }
                  addContentToFavorite={(e, id, name_of_content) =>
                    addContentToFavorite(e, id, name_of_content)
                  }
                  removeContentFromFavorite={(e, id) =>
                    removeContentFromFavorite(e, id)
                  }
                />
              </Grid>
            );
          })}
        <Grid item xs={4} sm={8} md={12} style={{ marginTop: "10px" }}>
          {!loading && articles.last_article_id ? (
            <LoadMorePaginationButton
              text="Daha Fazla Yükle"
              onClick={() =>
                dispatch(
                  getFilteredArticles({
                    startAfterArticleId: articles.last_article_id,
                    articleType: ArticleType.TEXT,
                    customLimit: 30,
                  })
                )
              }
            />
          ) : null}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Articles;
