import { FC } from "react";
import { Grid, Typography, Button } from "@mui/material";
import HeadingTitle from "./HeadingTitle";
import { ParentSchoolOutlined } from "../assests";
import ContentLinearProgressBar from "./ContentLinearProgressBar";
import { HomeParentSchoolContBg } from "../assests";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store/store";
import { ProgressStatusType } from "../client";

const HomeParentSchoolContunie: FC = () => {
  const navigate = useNavigate()
  const {progress_status_items} = useSelector((state:RootState) => state.log)
  const filtered_status = progress_status_items.filter((e) => e.progress_status_type == ProgressStatusType.PARENT_SCHOOL_MODULE)

  const containerStyle = {
    // backgroundImage: `url(${HomeParentSchoolContBg})`,
    backgroundColor:"#0EAD69",
    backgroundSize:"cover",
    backgroundRepeat:"no-repeat",
    borderRadius: "24px",
    padding: "25px 27px",
  };

  const iconSideContainer = {
    backgroundColor: "#0EAD69",
    borderRadius: "8px",
    padding: "10px 13px",
    margin: "2px",
    marginRight: "17px",
  };

  return (
    <Grid container style={containerStyle}>
      <Grid item xs={12}>
        <HeadingTitle title="Anne Baba Okulu’na Devam Et" _color="#FFFFFF" />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          marginBottom: "16px",
        }}
      >
        <div
          style={{
            background: "#E9EBED",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            paddingRight:"19px"
          }}
        >
          <div style={iconSideContainer}>
            <img src={ParentSchoolOutlined} alt="" />
          </div>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "#272755",
              lineHeight: "24px",
            }}
          >
            { filtered_status.length == 0 ? "Modül 1": `Modül ${filtered_status.length}`}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: "24px" }}>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "28px",
            color: "#FFFFFF",
          }}
        >
          {filtered_status.length == 0 ? "Bu modülde aile türlerinizi keşfedin." : filtered_status[filtered_status.length-1].item_name}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: "32px" }}>
        <ContentLinearProgressBar percentage={filtered_status.length == 0 ? 0 : (100* filtered_status.length) / 52} mainColor="#FFCE00" />
      </Grid>
      <Grid item xs={12}>
        <Button
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "16px",
            padding: "12px 61px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#0EAD69",
              textTransform:"none"
            }}
            onClick = {() => navigate("/parentschool")}
          >
            {filtered_status.length == 0 ? "Eğitime Başla" : "Eğitime Devam Et"}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default HomeParentSchoolContunie;
