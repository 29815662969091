import { FC, useEffect, useState } from "react";
import {
  CssBaseline,
  Box,
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
  Divider,
} from "@mui/material";
import { NameLogoBlue } from "../assests";
import {
  AuthOnboardingSlider,
  CommonButton,
  CommonLabel,
  CommonTextField,
  GoogleButton,
  CommonAlert,
  CommonLoading,
  CommonCheckbox,
  AppleOAuthButton,
  TextInputWithRegex,
} from "../components";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../redux/store/store";
import { register, setAuthError, setLoading } from "../redux/authSlice";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import {
  googleSignInFetchUserFromToken,
  guidGenerator,
  isUserInTurkey,
} from "../utils/commonFuncs";
import { IAppleOAuthCallbackCredentials } from "../utils/commonModels";
import jwt_decode from "jwt-decode";
import { OfficialDocumentEnums } from "../utils/constants";
import { RegisterDevicePlatform } from "../client";

const theme = createTheme();

const Register: FC = () => {
  const { access_token, loading, error } = useSelector(
    (state: RootState) => state.auth
  );
  const dispatch = useDispatch<AppDispatch>();
  let navigate = useNavigate();

  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const [isUserLocatedInTurkey, setIsUserLocatedInTurkey] =
    useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    dispatch(
      register({
        phone: data.get("username")?.toString().includes("@")
          ? undefined
          : parseInt(data.get("username") as string),
        email: data.get("username")?.toString().includes("@")
          ? (data.get("username") as string)
          : undefined,
        password: data.get("password") as string,
        register_platform: RegisterDevicePlatform.WEB_OS
      })
    );
  };

  useEffect(() => {
    if (error == "" && access_token != "") {
      navigate(`/register-flow/`);
    }
  }, [access_token, error]);

  useEffect(() => {
    dispatch(setLoading(false))
    dispatch(setAuthError(""));
  }, []);

  useEffect(() => {
    const fetchCountry = async () => {
      setLocalLoading(true);
      const locationIndex = await isUserInTurkey();
      setIsUserLocatedInTurkey(locationIndex);
      setLocalLoading(false);
    };

    fetchCountry();
  }, []);

  const registerWithGoogleFunc = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        dispatch(setAuthError(""));
        const userFromToken = await googleSignInFetchUserFromToken(
          tokenResponse.access_token
        );
        if (userFromToken.email) {
          dispatch(
            register({
              email: userFromToken.email,
              password: guidGenerator(),
            })
          );
        } else {
          dispatch(
            setAuthError(
              "Beklenmedik bir hata oluştu. Lütfen bizimle iletişime geçtin."
            )
          );
        }
        return;
      } catch (error) {
        dispatch(
          setAuthError(
            "Beklenmedik bir hata oluştu. Lütfen bizimle iletişime geçtin."
          )
        );
      }
    },
    onError: (error) => dispatch(setAuthError(error.error_description)),
  });

  const registerWithAppleFunc = (
    appleCredential: IAppleOAuthCallbackCredentials
  ) => {
    try {
      dispatch(setAuthError(""));
      const decodedTokenData = jwt_decode(appleCredential.id_token) as any;

      if (decodedTokenData?.email == null) {
        dispatch(
          setAuthError(
            "Beklenmedik bir hata oluştu. Lütfen bizimle iletişime geçtin."
          )
        );
      } else {
        dispatch(
          register({
            email: decodedTokenData.email,
            password: guidGenerator(),
          })
        );
      }
    } catch (error: any) {
      console.log("error in apple register: ", error);
    }
  };
  console.log(loading, localLoading)
  return (
    <ThemeProvider theme={theme}>
      <CommonLoading loading={loading || localLoading} />
      <CommonAlert
        _type={"error"}
        _open={error != "" ? true : false}
        _onClose={() => dispatch(setAuthError(""))}
        _message={"Kullanıcı Kayıtlı."}
      />
      <Grid
        container
        component="main"
        sx={{ height: "100vh" }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={false}
          md={6}
          sx={{
            backgroundColor: { md: "#2574FF" },
            display: { xs: "none", sm: "none", md: "flex" },
          }}
        >
          <AuthOnboardingSlider />
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <Box
            sx={{
              my: 4,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingLeft: { md: 7, lg: 7 },
              paddingRight: { md: 7, lg: 7 },
            }}
          >
            <img src={NameLogoBlue} alt="" />
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: "32px",
                lineHeight: "40px",
                color: "#171725",
                marginTop: "6%",
              }}
            >
              Merhaba 👋
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: "32px",
                lineHeight: "40px",
                color: "#171725",
                marginBottom: "5%",
              }}
            >
              Hoş geldin!
            </Typography>

            <GoogleButton
              text="Google ile üye ol"
              _onClick={() => registerWithGoogleFunc()}
              _marginBottom="24px"
            />
            <AppleOAuthButton
              text="Apple ile üye ol"
              _onClick={registerWithAppleFunc}
            />
            <Divider
              style={{ width: "100%", marginTop: "5%", marginBottom: "4%" }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "22px",
                  color: "#78828A",
                }}
              >
                veya {isUserLocatedInTurkey ? "telefon" : "email"} ile üye ol
              </Typography>
            </Divider>
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <div style={{ textAlign: "start", width: "100%" }}>
                <CommonLabel
                  label={isUserLocatedInTurkey ? "Telefon" : "Email"}
                />
                <TextInputWithRegex
                  _placeHolder={
                    isUserLocatedInTurkey ? "Telefon girin" : "Email girin"
                  }
                  formName="username"
                  _type={isUserLocatedInTurkey ? "number" : "text"}
                />
              </div>
              <div
                style={{ textAlign: "start", width: "100%", marginTop: "3%" }}
              >
                <CommonLabel label="Şifre" />
                <CommonTextField
                  _placeHolder="Şifreni gir"
                  _type="password"
                  formName="password"
                />
              </div>
              <div
                style={{
                  textAlign: "start",
                  marginTop: "4%",
                  marginBottom: "2%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CommonCheckbox _required={true} />
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "11px",
                    lineHeight: "16px",
                    color: "#2574FF",
                    marginLeft: "5px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={()=>window.open(`/official-document/user_agreement`,'_blank')}
                >
                  Kullanıcı Sözleşmesi
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "11px",
                    lineHeight: "16px",
                    color: "#9393AA",
                    marginLeft: "3px",
                    marginRight: "3px",
                  }}
                >
                  ve
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "11px",
                    lineHeight: "16px",
                    color: "#2574FF",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={()=>window.open(`/official-document/kvkk`,'_blank')}
                >
                  KVKK Aydınlatma Metni
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "11px",
                    lineHeight: "16px",
                    color: "#9393AA",
                  }}
                >
                  ’ni onaylıyorum.
                </Typography>
              </div>
              <div
                style={{
                  textAlign: "start",
                  marginBottom: "4%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CommonCheckbox />
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "11px",
                    lineHeight: "16px",
                    color: "#2574FF",
                    marginLeft: "5px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={()=>window.open(`/official-document/communication_permission`,'_blank')}
                >
                  İletişim İzni
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "11px",
                    lineHeight: "16px",
                    color: "#9393AA",
                  }}
                >
                  ’ni okudum/ kabul ediyorum.
                </Typography>
              </div>
              <CommonButton text="Ücretsiz Üye Ol" />
            </form>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Register;
