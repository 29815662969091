import { FC } from "react";
import { Grid } from "@mui/material";
import { LandingIntroduction, LandingContentSummary, LandingPageFooter, LandingPackageSection, LandingPageFeatures, LandingComments } from "../components";

const Landing: FC = () => {
  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={4} sm={8} md={12} sx={{ marginBottom: { xs: "20px", sm: "20px", md: "70px" } }}>
        <LandingIntroduction />
      </Grid>
      <Grid item xs={4} sm={8} md={12}>
        <LandingPackageSection />
      </Grid>
      <Grid item xs={4} sm={8} md={12}>
        <LandingContentSummary />
      </Grid>
      <Grid item xs={4} sm={8} md={12}>
        <LandingPageFeatures />
      </Grid>
      <Grid item xs={12}> {/* Grid item'ın genişliği tüm ekran boyutlarında 12 */}
        <LandingComments />
      </Grid>
      <Grid item xs={4} sm={8} md={12}>
        <LandingPageFooter />
      </Grid>
    </Grid>
  );
};

export default Landing;
