import { Snackbar, Alert } from "@mui/material";
import { FC } from "react";

interface Props {
  _open: boolean;
  _onClose: () => void;
  _message?: string;
  _type: any;
}

const CommonAlert: FC<Props> = ({
  _open,
  _onClose,
  _message,
  _type = "success",
}) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={_open}
      autoHideDuration={6000}
      onClose={_onClose}
    >
      <Alert onClose={_onClose} severity={_type} sx={{ width: "100%" }}>
        {_open ? (Array.isArray(_message) ? _message[0].msg : _message) : null}
      </Alert>
    </Snackbar>
  );
};

export default CommonAlert;
