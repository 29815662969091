import { FC, useEffect, MouseEvent } from "react";
import { Container, Grid } from "@mui/material";
import {
  CommonLoading,
  HeadingTitle,
  ParentSolutionListingItem,
  LoadMorePaginationButton,
  NoRecordParentSolutionLog,
  ProgressParentSolutionSlider,
} from "../components";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { getAllParentSolutions } from "../redux/parentSolutionSlice";
import {
  ArticleType,
  ProgressStatusType,
  TrainingSetArticleItem,
} from "../client";
import {
  addItemToFavorite,
  removeItemFromFavorite,
} from "../redux/favoriteSlice";
import { FavoriteType, OpenAPI} from "../client";

const ParentSolutions: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { parentSolutions, loading } = useSelector(
    (state: RootState) => state.parentSolution
  );

  const { progress_status_items } = useSelector(
    (state: RootState) => state.log
  );
  const { access_token } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (OpenAPI.TOKEN == undefined && access_token != "") {
      OpenAPI.TOKEN = access_token;
    }
    if (parentSolutions.training_sets.length == 0) {
      dispatch(
        getAllParentSolutions({
          startAfterTrainingSetId: parentSolutions.last_training_set_id,
        })
      );
    }
  }, []);

  const totalWatchTimeCalculator = (articles: TrainingSetArticleItem[]) => {
    if (articles.length == 0) {
      return 0;
    }
    let total = 0;

    articles.forEach((element) => {
      total += element?.video_duration_in_seconds
        ? element.video_duration_in_seconds
        : 0;
    });
    return Math.floor(total / 60);
  };

  const addContentToFavorite = (
    e: MouseEvent,
    id: string,
    name_of_content: string
  ) => {
    try {
      e.stopPropagation();
      dispatch(
        addItemToFavorite({
          id_of_favorite: id,
          name_of_favorite: name_of_content,
          favourite_type: FavoriteType.TRAINING_SET,
        })
      );
    } catch (error) {}
  };

  const removeContentFromFavorite = (e: MouseEvent, id: string) => {
    try {
      e.stopPropagation();
      dispatch(removeItemFromFavorite({ favorite_item_id: id }));
    } catch (error) {}
  };

  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <CommonLoading loading={loading} />
      <Grid
        container
        columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }}
        spacing={2}
        rowSpacing={"30px"}
      >
        <Grid item xs={4} sm={8} md={12} lg={12} style={{ textAlign: "start" }}>
          <HeadingTitle title="Öğrenmeye Devam Et" _marginBottom="0px" />
        </Grid>
        {progress_status_items.length > 0 ? (
          <Grid item xs={4} sm={8} md={12} lg={12}>
            <ProgressParentSolutionSlider
              items={progress_status_items
                .filter(
                  (k) =>
                    k.progress_status_type == ProgressStatusType.TRAINING_SET
                )
                .map((e) => {
                  return {
                    id: e.item_id,
                    title: e.item_name,
                    percentage:
                      (100 * e.completed_item_count) / e.total_items_count,
                  };
                })}
            />
          </Grid>
        ) : (
          <Grid item xs={4} sm={8} md={4} lg={4} xl={3}>
            <NoRecordParentSolutionLog />
          </Grid>
        )}
        <Grid
          item
          xs={4}
          sm={8}
          md={12}
          lg={12}
          style={{
            marginTop: "28px",
            textAlign: "start",
          }}
        >
          <HeadingTitle title="Tüm Ebeveyn Çözümleri" _marginBottom="0px" />
        </Grid>
        {!loading &&
          parentSolutions.training_sets.map((e, index) => {
            return (
              <Grid item xs={4} sm={8} md={4} lg={4} xl={3}>
                <ParentSolutionListingItem
                  id={e.id}
                  title={e.name}
                  video_article_count={
                    e.articles.filter((k) => k.type == ArticleType.VIDEO)
                      ?.length
                  }
                  text_article_count={
                    e.articles.filter((k) => k.type == ArticleType.TEXT)?.length
                  }
                  total_watch_time={totalWatchTimeCalculator(e.articles)}
                  is_picture_uploaded={
                    e?.is_picture_uploaded ? e.is_picture_uploaded : false
                  }
                  addContentToFavorite={(e, id, name_of_content) =>
                    addContentToFavorite(e, id, name_of_content)
                  }
                  removeContentFromFavorite={(e, id) =>
                    removeContentFromFavorite(e, id)
                  }
                  percentage={
                    progress_status_items.filter(
                      (k) =>
                        k.progress_status_type ==
                          ProgressStatusType.TRAINING_SET && k.item_id == e.id
                    ).length > 0
                      ? (100 *
                          progress_status_items.filter(
                            (k) =>
                              k.progress_status_type ==
                                ProgressStatusType.TRAINING_SET &&
                              k.item_id == e.id
                          )[0].completed_item_count) /
                        progress_status_items.filter(
                          (k) =>
                            k.progress_status_type ==
                              ProgressStatusType.TRAINING_SET &&
                            k.item_id == e.id
                        )[0].total_items_count
                      : 0
                  }
                />
              </Grid>
            );
          })}
        <Grid item xs={4} sm={8} md={12} lg={12} style={{ marginTop: "10px" }}>
          {!loading && parentSolutions.last_training_set_id ? (
            <LoadMorePaginationButton
              text="Daha Fazla Yükle"
              onClick={() =>
                dispatch(
                  getAllParentSolutions({
                    startAfterTrainingSetId:
                      parentSolutions.last_training_set_id,
                  })
                )
              }
            />
          ) : null}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ParentSolutions;
