import { FC, useState, useEffect } from "react";
import { Box, Container, Grid, Tabs, Tab, Typography } from "@mui/material";
import {
  AreaOfInterest,
  ChangePasswordInApp,
  CommonAlert,
  CommonLoading,
  EditProfile,
  HeadingTitle,
  LogOutButton,
  MySavedPaymentCards,
  RemoveMyAccountModal,
  SettingsTabLabelItem,
} from "../components";
import {
  GrayTrash,
  MenuAreaOfInterest,
  MenuAreaOfInterestSelected,
  MenuCard,
  MenuCardSelected,
  MenuChangePassword,
  MenuChangePasswordSelected,
  MenuSetProfile,
  MenuSetProfileSelected,
} from "../assests";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { OpenAPI, UserService } from "../client";
import { getAreaOfInterests } from "../redux/areaOfInterestSlice";
import { logOut, setUserAreaOfInterests } from "../redux/authSlice";
import { logOutFavs } from "../redux/favoriteSlice";
import { logOutLogs } from "../redux/logSlice";
import { getMySavedCards } from "../redux/purchaseSlice";
import useGoogleReCaptchaV3 from "../utils/googleReCaptchaV3.ts/useGoogleReCaptchaV3";
import { logoutUser } from "../redux/clientStore/userSlice/userSlice";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, textAlign: "start" }}>{children}</Box>
      )}
    </div>
  );
}

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

const PersonalInformations: FC = () => {
  const { executeReCaptcha } = useGoogleReCaptchaV3({ siteKey: `${process.env.REACT_APP_RECAPTCHA_KEY}`  });

  const tabNamesConstant = [
    {
      name: "Profili Düzenle",
      value: 0,
      icon: MenuSetProfile,
      selectedIcon:MenuSetProfileSelected
    },
    {
      name: "Şifremi Değiştir",
      value: 1,
      icon: MenuChangePassword,
      selectedIcon:MenuChangePasswordSelected
    },
    {
      name: "İlgi Alanlarım",
      value: 2,
      icon: MenuAreaOfInterest,
      selectedIcon:MenuAreaOfInterestSelected
    },
    {
      name: "Kayıtlı Kartlarım",
      value: 3,
      icon: MenuCard,
      selectedIcon:MenuCardSelected
    },
  ];

  const dispatch = useDispatch<AppDispatch>();
  const {
    access_token,
    loading: authLoading,
    profile,
  } = useSelector((state: RootState) => state.auth);
  
  const { loading, interests } = useSelector(
    (state: RootState) => state.areaOfInterest
  );

  const {loading:purchaseLoading,saved_cards} = useSelector((state:RootState) => state.purchase)

  useEffect(() => {
    if (OpenAPI.TOKEN == undefined && access_token != "") {
      OpenAPI.TOKEN = access_token;
    }
    dispatch(getAreaOfInterests());
    dispatch(getMySavedCards())
  }, []);

  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const [value, setValue] = useState<number>(0);
  const [removeAccountModalOpen, setRemoveAccountModalOpen] =
    useState<boolean>(false);
  const [passwordForRemove, setPasswordForRemove] = useState<string>("");
  const [localRemoveAccountError, setLocalRemoveAccountError] = useState<
    string | null
  >(null);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const removeInterestFromUser = (interest: string) => {
    const newInterestSet = profile.user_interests?.filter((e) => e != interest);
    dispatch(
      setUserAreaOfInterests({ interests: newInterestSet as Array<string> })
    );
  };

  const addInterestToUser = (interest: string) => {
    const newInterestSet = [
      ...(profile.user_interests as Array<string>),
      interest,
    ];
    dispatch(
      setUserAreaOfInterests({ interests: newInterestSet as Array<string> })
    );
  };

  const removeMyAccountTrigger = async () => {
    try {
      setLocalRemoveAccountError(null)
      setLocalLoading(true);
      const reCapthchaToken = await executeReCaptcha("LoadReCaptchaOnClick")
      const removeAccountResponse = await UserService.deactivateUserByRecaptchaTokenUserDeactivateUserByRecaptchaTokenDelete(reCapthchaToken)

        if (removeAccountResponse.is_request_successful) {
        setRemoveAccountModalOpen(false)
        logOutTrigger()
      } else {
        setRemoveAccountModalOpen(false)
        setLocalRemoveAccountError("Hesap silinemedi, lütfen bizimle iletişime geçin")
      }
      setLocalLoading(false);
    } catch (error: any) {
      setRemoveAccountModalOpen(false)
      setLocalRemoveAccountError("Hesap silinemedi, lütfen bizimle iletişime geçin")
      setLocalLoading(false);
    }
  };

  async function logOutTrigger() {
    dispatch(logOut());
    dispatch(logOutFavs());
    dispatch(logOutLogs());
    dispatch(logoutUser())
  }

  return (
    <Container
      maxWidth="xl"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <CommonLoading loading={loading || authLoading || localLoading || purchaseLoading} />
      <RemoveMyAccountModal
        isOpen={removeAccountModalOpen}
        onCancelButtonFunc={() => setRemoveAccountModalOpen(false)}
        onSubmitButtonFunc={removeMyAccountTrigger}
        value={passwordForRemove}
        valueOnChange={setPasswordForRemove}
        _loading={localLoading}
      />
      <CommonAlert
        _type={"warning"}
        _open={localRemoveAccountError == null ? false : true}
        _onClose={() => setLocalRemoveAccountError(null)}
        _message={
          localRemoveAccountError == null ? "" : localRemoveAccountError
        }
      />
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid
          item
          xs={4}
          sm={8}
          md={4}
          sx={{ textAlign: "start", borderRight: 1, borderColor: "divider" }}
        >
          <HeadingTitle title="Ayarlar" />
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{
              "& .MuiTabs-indicator": { display: "none" },
              width: "100%",
            }}
          >
            {tabNamesConstant.map((item, index) => {
              return (
                <Tab
                  key={index}
                  disableRipple
                  label={
                    <SettingsTabLabelItem
                      isSelected={item.value == value}
                      text={item.name}
                      icon={item.value == value ? item.selectedIcon : item.icon}
                      isLastItem={index == tabNamesConstant.length - 1}
                    />
                  }
                  {...a11yProps(item.value)}
                />
              );
            })}
          </Tabs>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "20%",
            }}
          >
            <LogOutButton onClick={() => logOutTrigger()} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                cursor: "pointer",
              }}
            >
              <img src={GrayTrash} alt="" />
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#78828A",
                  marginLeft: "10px",
                }}
                onClick={() => setRemoveAccountModalOpen(true)}
              >
                Hesabı Sil
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={4} sm={8} md={8}>
          <TabPanel value={value} index={0}>
            <EditProfile />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ChangePasswordInApp />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AreaOfInterest
              interests={interests}
              removeInterest={removeInterestFromUser}
              addInterest={addInterestToUser}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <MySavedPaymentCards/>
          </TabPanel>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PersonalInformations;
