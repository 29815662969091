import { Container, Grid } from "@mui/material";
import { FC, useEffect } from "react";
import { CommonLoading, HeadingTitle, NotificationItem } from "../components";
import { OpenAPI } from "../client";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { getAllNotifications } from "../redux/notificationSlice";
import { useNavigate } from "react-router";

const Notifications: FC = () => {
  const { access_token } = useSelector((state: RootState) => state.auth);
  const { notifications, loading } = useSelector(
    (state: RootState) => state.notification
  );
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate()

  useEffect(() => {
    if (OpenAPI.TOKEN == undefined && access_token != "") {
      OpenAPI.TOKEN = access_token;
    }

    if (notifications.user_notifications.length == 0) {
      dispatch(getAllNotifications({ last_notification_id: undefined }));
    }
  }, []);

  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <CommonLoading loading={loading} />
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={8} md={12} lg={12} style={{ textAlign: "start" }}>
          <HeadingTitle title="Bildirimler" _marginBottom="32px" />
        </Grid>
        {!loading &&
          notifications.user_notifications.map((e) => (
            <Grid item xs={4} sm={8} md={12} style={{ marginBottom: "16px" }}>
              <NotificationItem
                id={e.id}
                title={e.notification_subject}
                date={e.created_at}
                description={e.notification_content}
                isReaded={e.is_readed ? e.is_readed : false}
                notificationTarget={e.notification_target}
                notificationTargetDetail={e.notification_target_detail}
                navigate={(e) => navigate(e)}
              />
            </Grid>
          ))}
      </Grid>
    </Container>
  );
};

export default Notifications;
