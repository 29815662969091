/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum UserFeedBackType {
    PROBLEM = 'problem',
    FEEDBACK = 'feedback',
    SUGGESTION = 'suggestion',
    EVALUATE = 'evaluate',
}
