import { Button } from "@mui/material"
import { FC } from "react"
import { BackArrow } from "../assests"

interface Props {
    onClick: () => void
    customCss?: {}
}

const DetailBackButton:FC<Props> = ({onClick, customCss}) => {
  return (
    <Button style={{
        backgroundColor:"#FFFFFF",
        border: "1px solid #E9EBED",
        borderRadius:"16px",
        padding:"1.5% 3%",
        display:"flex",
        ...customCss
    }} onClick={() => onClick()}>
        <img src={BackArrow} alt="" />
    </Button>
  )
}

export default DetailBackButton