import { FC, useState } from "react";
import { Container, Grid, Typography, Divider } from "@mui/material";
import { NameLogoBlue } from "../assests";
import { CommonButton, H5BoldHeader } from "../components";
import OTPInput from "react-otp-input";

const VerifyCodeOTP: FC = () => {
  const [otp, setOtp] = useState<string>("");

  return (
    <>
      <img
        src={NameLogoBlue}
        alt=""
        style={{ marginTop: "20px", marginBottom: "20px" }}
      />
      <Divider style={{ width: "100%", border: "1px solid #E9EBED" }} />
      <Container maxWidth="lg">
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "90vh",
          }}
        >
          <Grid item xs={4} sm={8} md={12}>
            <H5BoldHeader title="Doğrulama Kodu" _marginBottom="15px" />
          </Grid>
          <Grid item xs={4} sm={8} md={12}>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "24px",
                color: "#9CA4AB",
                marginBottom: "45px",
              }}
            >
              Lütfen telefon numaranıza ya da eposta adresinize gelen doğrulama
              kodunu aşağıya girin.
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            sx={{
              width: { xs: "100%", sm: "100%", md: "50%" },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <OTPInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderInput={(props) => <input {...props} />}
              inputStyle={{
                width: "41px",
                height: "46px",
                margin: "10px 4px",
                fontFamily: "Montserrat",
                fontWeight: "700",
                color: "#78828A",
                fontSize: "24px",
                borderRadius: "12px",
                border: "1px solid #E9EBED",
                backgroundColor: "#F9F9F9",
              }}
            />
            <CommonButton
              text="Devam Et"
              customCSS={{ marginTop: "30px", marginBottom: "24px" }}
            />
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "26px",
                color: "#9CA4AB",
                cursor: "pointer",
              }}
            >
              Tekrar Gönder
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default VerifyCodeOTP;
