import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import { Buckets } from "../utils/constants";
import { useNavigate } from "react-router";

interface Props {
  id: string;
  title: string;
  is_picture_uploaded: boolean;
}

const BulletinListingItem: FC<Props> = ({
  id = "",
  title = "",
  is_picture_uploaded = false,
}) => {
  let navigate = useNavigate();

  const imageGridStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${
      is_picture_uploaded ? `${Buckets.BulettinBucket}/${id}.jpg` : ""
    })`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "180px",
    borderRadius: "24px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "start",
    marginBottom: "16px",
    padding: 10,
    cursor: "pointer",
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={imageGridStyle}
        onClick={() => navigate(`/bulletin/${id}`)}
      ></Grid>
      <Grid item xs={12} style={{ textAlign: "start" }}>
        <Typography
          component={"span"}
          style={{
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#171725",
            cursor: "pointer",
          }}
          onClick={() => navigate(`/bulletin/${id}`)}
        >
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BulletinListingItem;
