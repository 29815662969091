import { FC } from 'react'
import HeadingTitle from './HeadingTitle'
import { ParentType } from '../client'
import RegisterListItem from './RegisterListItem'
import { ParentTypesExplained } from '../utils/constants'

interface Props {
    parent_type?:string
    onSetParentType:(e:ParentType) => void
}

const RegisterSetParentType:FC<Props> = ({
    parent_type,
    onSetParentType
}) => {
  return (
    <>
        <HeadingTitle title='Çocuğa yakınlık derecen nedir?' _marginBottom='32px'/>
        {
          ParentTypesExplained.map((e) => {
            return(
              <RegisterListItem isSelected={parent_type == e.key ? true : false} text={e.name} _onClicked={() => onSetParentType(e.key as ParentType)}/>
            )
          })
        }
    </>
  )
}

export default RegisterSetParentType