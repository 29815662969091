import { FC, useState } from "react";
import { SettingsTabLabelItem, AboutPw, FaqSupport, HeadingTitle, ReportProblem, SuggestContent, SupportFeedback, UsageAgreement, SuggestToFriend} from "../components";
import { Container, Grid, Tabs, Tab, Box } from "@mui/material";
import { MenuAbout, MenuFaq, MenuFeedBack, MenuSuggestContent, MenuSuggestFriend, MenuSupport, MenuSupportSelected, MenuUsageContract } from "../assests";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, textAlign: "start" }}>{children}</Box>
      )}
    </div>
  );
}

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

const Support: FC = () => {
  const tabNamesConstant = [
    {
      name: "Sorun Bildir",
      value: 0,
      icon: MenuSupport,
      selectedIcon:MenuSupportSelected
    },
    {
      name: "Geri Bildirim Ver",
      value: 1,
      icon: MenuFeedBack,
    },
    {
      name: "Arkadaşına Öner",
      value: 2,
      icon: MenuSuggestFriend,
    },
    {
      name: "İçerik Öner",
      value: 3,
      icon: MenuSuggestContent,
    },
    {
      name: "Sıkça Sorulan Sorular",
      value: 4,
      icon: MenuFaq,
    },
    {
      name: "Hakkında",
      value: 5,
      icon: MenuAbout,
    },
    {
      name: "Kullanım Sözleşmesi",
      value: 6,
      icon: MenuUsageContract,
    },
  ];
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container
      maxWidth="xl"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid
          item
          xs={4}
          sm={8}
          md={4}
          sx={{ textAlign: "start", borderRight: 1, borderColor: "divider", display:"flex",flexDirection:"column",alignItems:"flex-start"}}
        >
          <HeadingTitle title="Destek" />
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{
              "& .MuiTabs-indicator": { display: "none" },
              width:"100%"
            }}
          >
            {tabNamesConstant.map((item, index) => {
              return (
                <Tab
                  key={index}
                  disableRipple
                  label={
                    <SettingsTabLabelItem
                      isSelected={item.value == value}
                      text={item.name}
                      icon={item.icon}
                      isLastItem={index == tabNamesConstant.length - 1}
                    />
                  }
                  {...a11yProps(item.value)}
                />
              );
            })}
          </Tabs>
        </Grid>
        <Grid item xs={4} sm={8} md={8}>
            <TabPanel value={value} index={0}>
                <ReportProblem />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <SupportFeedback />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <SuggestToFriend />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <SuggestContent />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <FaqSupport />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <AboutPw />
            </TabPanel>
            <TabPanel value={value} index={6}>
                <UsageAgreement />
            </TabPanel>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Support;
