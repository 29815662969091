import { FC } from 'react'
import { Typography } from '@mui/material';
import { Gender, ParentType } from '../client';
import { Buckets } from '../utils/constants';
import { getNameTextLogo } from '../utils/commonFuncs';
import { RedTrash } from '../assests';

interface Props {
    id: string
    name: string
    is_profile_picture_uploaded: boolean
    removeClicked:(id:string, name:string) => void
}

const FamilyMemberCard:FC<Props> = ({
    id,
    name="",
    is_profile_picture_uploaded = false,
    removeClicked
}) => {
    const itemContainerStyle = {
        background: "#FFFFFF",
        border: "1px solid #F0F0F0",
        borderRadius: "8px",
        padding: "38px 20px",
      };
    
  return (
    <div style={itemContainerStyle}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {is_profile_picture_uploaded ? (
                <div
                  style={{
                    border: "2px solid #FFFFFF",
                    width: "96px",
                    height: "96px",
                    borderRadius: "30px",
                    backgroundImage: `url(${Buckets.UserProfilePictureBucket}/${id}.jpg)`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                ></div>
              ) : (
                <div
                  style={{
                    border: "2px solid #FFFFFF",
                    boxShadow: "0px 10px 20px rgba(0, 64, 128, 0.1)",
                    width: "96px",
                    height: "96px",
                    borderRadius: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor:"#2574FF"
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "700",
                      fontSize: "38px",
                      lineHeight: "32px",
                      color: "#FFFFFF",
                    }}
                  >
                    {getNameTextLogo(name)}
                  </Typography>
                </div>
              )}
            </div>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "22px",
                color: "#9393AA",
                marginBottom: "11px",
                marginTop: "32px",
              }}
            >
              İsim Soyisim
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "23px",
                color: "#272755",
              }}
            >
              {name}
            </Typography>
            <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: "16px",
          }}
        >
          <img src={RedTrash} alt="" />
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "18px",
              color: "#FA4169",
              cursor: "pointer",
            }}
            onClick={() => removeClicked(id,name)}
          >
            Aile Üyeliğininden Çıkar
          </Typography>
        </div>
          </div>
  )
}

export default FamilyMemberCard