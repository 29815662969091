import { FC } from 'react'
import { Button, Typography } from '@mui/material'
import { BlueBookPen } from '../assests';

interface Props {
    _onClick: () => void;
    _disabled?:boolean
  }

const SurveyShowResultButton:FC<Props> = ({
    _onClick,
    _disabled=false
}) => {

    const buttonContainerStyle = {
        boxShadow: "0px 10px 20px rgba(0, 64, 128, 0.1)",
        borderRadius:"24px",
        border:"1px solid #2574FF",
        padding:"5px 25px"
    }

  return (
    <Button onClick={() => _onClick()} style={buttonContainerStyle} variant="outlined" startIcon={<img src={BlueBookPen} alt=""/> } disabled={_disabled}>
    <Typography style={{
      fontFamily:"Montserrat",
      fontWeight:"600",
      fontSize:"16px",
      lineHeight:"24px",
      color:"#2574FF",
      textTransform:"none"
    }}>
      Sonucu Gör
    </Typography>
  </Button>
  )
}

export default SurveyShowResultButton