import { FC } from 'react'
import HeadingTitle from './HeadingTitle'
import RegisterListItem from './RegisterListItem'
import { GetWhatBringsUserSuggestionItem } from '../client'

interface Props {
    optionList:Array<GetWhatBringsUserSuggestionItem>
    selected?:string
    onSetWhatBringsYou:(e:string) => void
}

const RegisterWhatBringsYou:FC<Props> = ({
    optionList,
    selected,
    onSetWhatBringsYou,
}) => {
  return (
    <>
    <HeadingTitle title='Sizi ParentWiser’a getiren öncelikle hangisidir?' _marginBottom='32px'/>
    {
      optionList.map((e:GetWhatBringsUserSuggestionItem) => {
        return(
          <RegisterListItem isSelected={selected == e.id ? true : false} text={e.text} _onClicked={() => onSetWhatBringsYou(e.id)}/>
        )
      })
    }
</>
  )
}

export default RegisterWhatBringsYou