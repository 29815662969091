import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FullTextSearchModel, SearchService } from "../client";

export const getAutoCompleteSuggestions = createAsyncThunk(
    'search/getAutoCompleteSuggestions',
    async(payload:{wordToComplete:string}) => {
        try {
            return await SearchService.autocompleteSearchAutocompleteGet(payload.wordToComplete)
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

export const getFullTextSearch = createAsyncThunk(
    'search/getFullTextSearch',
    async(payload:{query:string}) => {
        try {
            return await SearchService.fullTextSearchSearchFullTextSearchGet(payload.query)
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

interface IInitialState {
    loading:boolean
    error:string
    search_text:string
    autocomplete_suggestions: Array<string>
    searched_items: Array<FullTextSearchModel>
}

const initialState: IInitialState = {
    loading:false,
    error:"",
    search_text:"",
    autocomplete_suggestions:[] as string[],
    searched_items: [] as FullTextSearchModel[]
}

export const searchSlice = createSlice({
    name:"search",
    initialState,
    reducers: {
        setLocalSearchWord(state,action){
            state.search_text= action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAutoCompleteSuggestions.pending, (state) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(getAutoCompleteSuggestions.fulfilled, (state, action) => {
            state.autocomplete_suggestions = action.payload
            state.loading = false
        })
        builder.addCase(getAutoCompleteSuggestions.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
        builder.addCase(getFullTextSearch.pending, (state) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(getFullTextSearch.fulfilled, (state, action) => {
            state.searched_items = action.payload.results
            state.search_text = action.meta.arg.query
            state.loading = false
        })
        builder.addCase(getFullTextSearch.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
    }
})

export const { setLocalSearchWord } = searchSlice.actions
export default searchSlice.reducer