import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BulletinModel, UserService } from "../client";

export const getBulettins = createAsyncThunk(
    'bulettin/getBulettins',
    async (payload:{paginationItemCount:number, lastDocumentId?:string}) => {
        try {
            return await UserService.getBulletionContentUserGetBulletinContentGet(payload.paginationItemCount, payload.lastDocumentId)
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

export const getBulletin = createAsyncThunk(
    'bulettin/getBulletin',
    async (payload:{bulletinId:string}) => {
        try {
            return await UserService.getBulletinContentUserGetBulletinBulletinIdGet(payload.bulletinId)
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

interface IInitialState {
    loading:boolean
    error:string
    bulettin: Array<BulletinModel>
    single_bulletin: BulletinModel
    lastDocumentId:undefined | string
}

const initialState: IInitialState = {
    loading:false,
    error:"",
    bulettin:[] as BulletinModel[],
    single_bulletin:{} as BulletinModel,
    lastDocumentId:undefined
}

export const bulettinSlice = createSlice({
    name:"bulettin",
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder.addCase(getBulettins.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(getBulettins.fulfilled, (state, action) => {
            state.bulettin = action.payload
            if(action.meta.arg.paginationItemCount === action.payload.length) {
                state.lastDocumentId = action.payload[action.payload.length -1].id
            }
            state.lastDocumentId = undefined
            state.loading = false
        })
        builder.addCase(getBulettins.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
        builder.addCase(getBulletin.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(getBulletin.fulfilled, (state, action) => {
            state.single_bulletin = action.payload
            state.loading = false
        })
        builder.addCase(getBulletin.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
    }
})

export default bulettinSlice.reducer