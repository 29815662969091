/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ParentType {
    MOTHER = 'mother',
    FATHER = 'father',
    GRANDFATHER = 'grandfather',
    GRANDMOTHER = 'grandmother',
    BABYKEEPER = 'babykeeper',
    OTHER = 'other',
}
