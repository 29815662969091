import axios from "axios";
import { getUserIpAddress } from "../utils/commonFuncs";
import { IMetaPixelConversationInput } from "../utils/commonModels";

const metaEndpoint = `https://graph.facebook.com/${process.env.REACT_APP_PIXEL_API_VERSION}/${process.env.REACT_APP_PIXEL_ID}/events?access_token=${process.env.REACT_APP_PIXEL_TOKEN}`

export default async function UseMetaConversation(input:IMetaPixelConversationInput) {
    try {
        const userIp = await getUserIpAddress()
        const data = [
            {
                event_name:input.event_name,
                event_time:Math.floor(Date.now() / 1000),
                user_data:{
                    client_ip_address: userIp,
                    client_user_agent: input.user_id
                },
                custom_data:input.custom_data,
                opt_out: false
            }
        ]
        await axios.post(metaEndpoint, {data})
    } catch (error) {
        console.log("Meta conversation error:",error)
    }

}