import { Typography } from "@mui/material";
import { FC } from "react";
import { RegisterChildBoy, RegisterChildGirl } from "../assests";
import { Gender } from "../client";

interface Props {
  selectedName?: string;
  _onClicked: (e: Gender) => void;
}

const RegisterChildGenderList: FC<Props> = ({ selectedName, _onClicked }) => {
  const itemStyle = {
    backgroundColor: "#F0F0F0",
    borderRadius: "38px",
    cursor: "pointer",
    width:"120px",
    height:"120px",
  };

  const itemSelectedStyle = {
    backgroundColor: "#2574FF",
    borderRadius: "38px",
    cursor: "pointer",
    width:"120px",
    height:"120px",
  };

  return (
    <div
      style={{ display: "flex", width: "100%", justifyContent: "space-around",textAlign:"center" }}
    >
      <div
        style={
          selectedName != undefined && selectedName == Gender.FEMALE
            ? itemSelectedStyle
            : itemStyle
        }
        onClick={() => _onClicked(Gender.FEMALE)}
      >
        <div style={{width:"100%", height:"100%", display:"flex", flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
        <img src={RegisterChildGirl} width={28} height={40} alt="" />
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: selectedName != undefined && selectedName == Gender.FEMALE ? "600" :"400",
            fontSize: "15px",
            lineHeight: "18px",
            color: selectedName != undefined && selectedName == Gender.FEMALE ? "#FFFFFF" : "#000000",
          }}
        >
          Kız
        </Typography> 
        </div>
      </div>
      <div
        style={
          selectedName != undefined && selectedName == Gender.MALE
            ? itemSelectedStyle
            : itemStyle
        }
        onClick={() => _onClicked(Gender.MALE)}
      >
                <div style={{width:"100%", height:"100%", display:"flex", flexDirection:"column",alignItems:"center",justifyContent:"center"}}>

        <img src={RegisterChildBoy} width={28} height={40}  alt="" />
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: selectedName != undefined && selectedName == Gender.MALE ? "600" :"400",
            fontSize: "15px",
            lineHeight: "18px",
            color: selectedName != undefined && selectedName == Gender.MALE ? "#FFFFFF" : "#000000",
            marginTop: "10px",
          }}
        >
          Erkek
        </Typography>
        </div>
      </div>
    </div>
  );
};

export default RegisterChildGenderList;
