import { FC } from "react";
import {
  ParentSchoolContentTick,
  PlayGreen,
  ParentSolutionContentLocked,
  ReadyToPlay,
  Lock,
} from "../assests";
import {
  StepConnector,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { stepConnectorClasses } from "@mui/material/StepConnector";
import { ProgressStatusType, TrainingSetArticleItem } from "../client";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { doesUserHasAccessToContent } from "../utils/commonFuncs";
import { openBecomePremiumModal } from "../redux/generalControllerSlice";

interface Props {
  items: TrainingSetArticleItem[];
  onContentSelected: (item: TrainingSetArticleItem) => void;
  parentSolutionId: string;
  currentSelectedItemId?: string;
}

export const trainingSetDoesUserHasAccessToContent = (isUserPremium:boolean, index:number) => {
  try {
    if(isUserPremium){
      return true
    }else{
      if(index === 0 || index === 1){
        return true
      }else {
        return false
      }
    }
  } catch (error) {
    return false
  }
}

const ParentSolutionContentProgressList: FC<Props> = ({
  items = [],
  onContentSelected,
  parentSolutionId,
  currentSelectedItemId,
}) => {
  const { progress_status_items } = useSelector(
    (state: RootState) => state.log
  );
  const { profile } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>();

  const currentParentSolutionWathcedLog = progress_status_items.find(
    (e) =>
      e.progress_status_type == ProgressStatusType.TRAINING_SET &&
      e.item_id == parentSolutionId
  );

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#DADADA",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#0EAD69",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      width: "1px",
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "",
    zIndex: 1,
    color: "#fff",
    width: 24,
    height: 24,
    display: "flex",
    borderRadius: "170%",
    justifyContent: "center",
    alignItems: "center",
  }));

  function ColorlibStepIcon(item: TrainingSetArticleItem, index: number) {
    const icons: { [index: string]: React.ReactElement } = {
      watched: <img src={ParentSchoolContentTick} alt="" />,
      currentlyPlaying: <img src={PlayGreen} alt="" />,
      locked: <img src={ParentSolutionContentLocked} alt="" />,
      readyToPlay: <img src={ReadyToPlay} alt="" />,
    };

    let userHasAccess = doesUserHasAccessToContent(
      profile?.is_user_premium ? profile.is_user_premium : false,
      item?.show_to_free_plan ? item.show_to_free_plan : false
    );

    if (currentParentSolutionWathcedLog == undefined) {
      return (
        <ColorlibStepIconRoot ownerState={{}}>
          {
            icons[
              String(
                userHasAccess
                  ? currentSelectedItemId == item.article_id
                    ? "currentlyPlaying"
                    : "readyToPlay"
                  : "locked"
              )
            ]
          }
        </ColorlibStepIconRoot>
      );
    }
    
    if (
      currentParentSolutionWathcedLog.total_items_count ==
        currentParentSolutionWathcedLog.completed_item_count &&
      userHasAccess
    ) {
      <ColorlibStepIconRoot ownerState={{}}>
        {icons[String("watched")]}
      </ColorlibStepIconRoot>;
    }

    if (currentParentSolutionWathcedLog.last_item_id) {
      const totalWatchOrder = currentParentSolutionWathcedLog.completed_item_count
      if(currentParentSolutionWathcedLog.total_items_count == currentParentSolutionWathcedLog.completed_item_count) {
        return (
          <ColorlibStepIconRoot ownerState={{}}>
            {
              icons["watched"]
            }
          </ColorlibStepIconRoot>
        );
      }
      return (
        <ColorlibStepIconRoot ownerState={{}}>
          {
            icons[
              userHasAccess
                ? totalWatchOrder > index + 1
                  ? "watched"
                  : totalWatchOrder - 1  == index 
                  ? "currentlyPlaying"
                  : "readyToPlay"
                : "locked"
            ]
          }
        </ColorlibStepIconRoot>
      );
    }
    return (
      <ColorlibStepIconRoot ownerState={{}}>
        {icons["locked"]}
      </ColorlibStepIconRoot>
    );
  }

  const containerStyle = {
    backgroundColor: "#F9F9F9",
    borderRadius: "24px",
    padding: "25px 30px",
    display: "block",
  };

  const onContentClicked = (item: TrainingSetArticleItem, index:number) => {
    // const userHasAccess = doesUserHasAccessToContent(
    //   profile?.is_user_premium ? profile.is_user_premium : false,
    //   item?.show_to_free_plan ? item.show_to_free_plan : false
    // );
    const userHasAccess = trainingSetDoesUserHasAccessToContent(profile?.is_user_premium ? profile.is_user_premium : false, index)
    if (userHasAccess) {
      onContentSelected(item);
    } else {
      dispatch(openBecomePremiumModal());
    }
  };

  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }} style={containerStyle}>
      <Typography
        style={{
          textAlign: "start",
          fontFamily: "Montserrat",
          fontSize: "22px",
          fontWeight: "700",
          lineHeight: "30px",
          color: "#0D0C0F",
          marginBottom: "30px",
        }}
      >
        Ders İçeriği
      </Typography>
      <Stepper
        activeStep={0}
        orientation="vertical"
        connector={<ColorlibConnector />}
      >
        {items.map((item, index) => (
          <Step style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}} key={index}>
            <StepLabel StepIconComponent={() => ColorlibStepIcon(item, index)}>
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "28px",
                  color: "#0D0C0F",
                  cursor: "pointer",
                }}
                onClick={() => onContentClicked(item,index)}
              >
                {index + 1 + "- " + item.article_name}
              </Typography>
            </StepLabel>
            {trainingSetDoesUserHasAccessToContent(
              profile?.is_user_premium ? profile.is_user_premium : false,
              index
            ) ? null : (
              <img src={Lock} alt="" />
            )}
          </Step>
        ))}
      </Stepper>
    </Grid>
  );
};

export default ParentSolutionContentProgressList;
