import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import { AddChildPlus } from "../assests";

interface Props {
  _onClick: () => void
}

const AddChildBlankItem: FC<Props> = ({
  _onClick
}) => {  
  const containerStyle = {
    background: "#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #F0F0F0",
    diplay: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 21px",
  };

  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }} style={containerStyle}>
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "80px",
            height: "80px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#F9F9F9",
            border: "1px solid #E9EBED",
            borderRadius: "12px",
            cursor:"pointer"
          }}
          onClick={_onClick}
        >
          <img src={AddChildPlus} alt="" />
        </div>

        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "22px",
            color: "#9CA4AB",
            marginTop: "16px",
          }}
        >
          Çocuk Ekle
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AddChildBlankItem;
