import { Container, Divider, Grid, Typography } from "@mui/material";
import { FC, useState } from "react";
import {
  CommonAlert,
  CommonButton,
  CommonErrorModal,
  CommonLabel,
  CommonLoading,
  CommonTextField,
  H5BoldHeader,
} from "../components";
import { NameLogoBlue } from "../assests";
import { UserService } from "../client";
import { useNavigate } from "react-router-dom";

const ResetPassword: FC = () => {
  let navigate = useNavigate();

  const [phoneOrMail, setPhoneOrMail] = useState<string>("");
  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);

  const sendResetCode = async () => {
    try {
      setLocalLoading(true);
      const sendCodeOTPResponse =
        await UserService.passwordRecoveryUserPasswordRecoveryPost({
          email: phoneOrMail.includes("@") ? phoneOrMail : undefined,
          phone: phoneOrMail.includes("@") ? undefined : parseInt(phoneOrMail),
        });
      if (sendCodeOTPResponse.is_request_successful) {
        navigate(
          `/create-new-password/${
            phoneOrMail.includes("@") ? "email" : "phone"
          }/${phoneOrMail.includes("@") ? phoneOrMail : parseInt(phoneOrMail)}`
        );
      } else {
        setErrorModalOpen(true);
      }
      setLocalLoading(false);
    } catch (error: any) {
      setErrorModalOpen(true);
      setLocalLoading(false);
    }
  };

  return (
    <>
      <img
        src={NameLogoBlue}
        alt=""
        style={{ marginTop: "20px", marginBottom: "20px" }}
      />
      <Divider style={{ width: "100%", border: "1px solid #E9EBED" }} />
      <CommonErrorModal
        isOpen={errorModalOpen}
        title="E-posta / Telefon Hatalı"
        text="Girdiğiniz telefon numarası veya e-posta adresi sistemimizde kayıtlı değil."
        buttonText="Tekrar Dene"
        onButtonFunc={() => setErrorModalOpen(false)}
      />
      <CommonLoading loading={localLoading} />
      <Container maxWidth="lg">
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "90vh",
          }}
        >
          <Grid item xs={4} sm={8} md={12}>
            <H5BoldHeader title="Şifremi Unuttum" _marginBottom="15px" />
          </Grid>
          <Grid item xs={4} sm={8} md={12}>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "24px",
                color: "#9CA4AB",
                marginBottom: "45px",
              }}
            >
              Lütfen telefon numaranızı ya da eposta adresinizi aşağıya girin.
              Size bir doğrulama kodu göndereceğiz.
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            sx={{
              textAlign: "start",
              width: { xs: "95%", sm: "80%", md: "50%" },
            }}
          >
            <CommonLabel label="Telefon veya eposta adresin" />
            <CommonTextField
              _placeHolder="Cep telefonu veya e-posta adresini gir"
              _onChange={(e) => setPhoneOrMail(e)}
            />
            <CommonButton
              text="Kod Gönder"
              customCSS={{ marginTop: "30px" }}
              onClick={() => sendResetCode()}
              disabled={phoneOrMail.length <= 7}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ResetPassword;
